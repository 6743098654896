import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react';

export default class CheckItemComponent extends Component {

    render() {
        return (
            <div style={{marginTop: 10, marginBottom: 10}}>
                <Grid>
                    <Grid.Row columns={5}>
                        <Grid.Column>
                            <span style={{display: 'block', fontSize: 20}}>Estatus</span>
                            {this.props.check.status ? this.props.check.status.nombre : 'Pendiente'}
                        </Grid.Column>
                        <Grid.Column>
                            <span style={{display: 'block', fontSize: 20}}>#Pedido</span>
                            {this.props.check.pedido_id}
                        </Grid.Column>
                        <Grid.Column>
                            <span style={{display: 'block', fontSize: 20}}>Cliente</span>
                            {this.props.check.pedido && this.props.check.pedido.cliente ? this.props.check.pedido.cliente.nombre : ''}
                        </Grid.Column>
                        <Grid.Column>
                            <span style={{display: 'block', fontSize: 20}}>Hora inicio</span>
                            {this.props.check.hora_inicio ? this.props.check.hora_inicio : '-'}
                        </Grid.Column>
                        <Grid.Column>
                            <span style={{display: 'block', fontSize: 20}}>Hora fin</span>
                            {this.props.check.hora_fin ? this.props.check.hora_fin : '-'}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>   
            </div>
        )
    }
}