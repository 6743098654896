import React, {Component} from 'react'
import { Label, Icon } from 'semantic-ui-react'

export default class TaskStatus extends Component {

    render() {
        if (this.props.status === 1){
            return <div><Label color="blue"><Icon name="play"/> {this.props.text}</Label></div>
        } 
        else if (this.props.status === 2) return <div><Label color="green"><Icon name="check"/> Terminada</Label></div>
        else if (this.props.status === 3) return <div><Label color="red"><Icon name="ban"/> Cancelada</Label></div>
        
        return <div><Label><Icon name="clock"/> Pendiente</Label></div>
    }
}