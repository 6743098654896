import React, {Component} from 'react';
import {Button, Form, Grid, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {notify} from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";
import swal from 'sweetalert2';

class TypeMachineEditModal extends Component {
    state = {
        tipo_maquinaria: {
            nombre: ""
        },
        toEdit: 0,
        loading: false
    };

    componentWillReceiveProps(nextProps) {
        if(nextProps.tipo_maquinaria) {
            this.setState({
                tipo_maquinaria: {nombre: nextProps.tipo_maquinaria.nombre},
                toEdit: nextProps.tipo_maquinaria.id
            }) 
        }
    }

    handleSubmit = _ => {
        if(!this.state.tipo_maquinaria.nombre) {
            notify.show('Hace falta el nombre', 'error', 2000);
            swal({
                type: 'error',
                title: 'Error',
                text: 'Hace falta agregar un nombre',
            })
            return;
        }

        this.setState({
            loading: true
        }, () => {
            Feathers.service('tipo_maquinaria').patch(this.state.toEdit, {
                ...this.state.tipo_maquinaria

            }).then(async res => {
                await this.setState({
                    loading: false
                });
                await this.props.onEdit();
                await this.props.onClose();
            }).catch(err => {
                notify.show('Se produjo un error', 'error');
                this.setState({loading: false})
            })
        })
    };

    render() {
        return(
            <Modal open={this.props.open} size={'large'}>
                <Modal.Header style={{backgroundColor: "#f3f3f3"}}>Editar
                    <Button circular size='mini' basic icon='close' floated='right' onClick={this.props.onClose}></Button>
                </Modal.Header>
                <Modal.Content scrolling>
                    <Form size={'large'}>
                        <Grid>
                        <Grid.Row columns={2} divided>
                            <Grid.Column>
                                <Grid.Row>
                                    <Form.Input label='Nombre' name='nombre' value={this.state.tipo_maquinaria.nombre} type='text' placeholder='Tipo de Maquinaria' onChange={(e, {name, value}) => this.setState({ tipo_maquinaria: {...this.state.tipo_maquinaria, [name] : value} })} required/>
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid.Row>
                                    <Button disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit}  positive content='ACTUALIZAR' icon='send' floated='left' style={{marginTop: 25}}/>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>

        );
    }
}

//TypeMachineEditModal.PropTypes = {};

export default TypeMachineEditModal;