import React, { Component } from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Icon,
  Dropdown,
  Search,
  Label,
  Form,
  Popup,
} from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../../redux/FeathersRedux";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import RawMaterialsInfoModal from "./RawMaterialsInfoModal";
import RawMaterialsEditModal from "./RawMaterialsEditModal";
import RawMaterialsTechnicalSheetsModal from "./RawMaterialsTechnicalSheetsModal";

import { exportToXls } from "../../utils/Utils";
//import { parse } from "@fullcalendar/core/datelib/parsing";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);
const renderOrderSearchResults = ({ id, orden }) => (
  <Label key={id} content={orden} />
);
const renderGrupoSearchResults = ({ id, grupo }) => (
  <Label key={id} content={grupo} />
);
const renderChemicalSearchResults = ({ id, quimico }) => (
  <Label key={id} content={quimico} />
);
const renderCodeSearchResults = ({ id, codigo }) => (
  <Label key={id} content={codigo} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

class RawMaterialsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rawMaterials: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      modalOpened: false,
      modalTechnicalSheets: false,
      editModalOpened: false,
      currentRow: {},

      filterOptions: {
        id: null,
        orden: null,
        grupo: null,
        quimico: null,
        codigo: null,
      },
      $sortDirection: null,
      $sortOrderDirection: null,
      // Search Bar Loadings
      loadingRawMaterial: false,
      loadingOrder: false,
      loadingGroup: false,
      loadingChemical: false,
      loadingCode: false,
      // Search Bar Results
      results: null,
      resultsOrder: null,
      resultsGroup: null,
      resultsChemical: null,
      resultCode: null,
      // Search Bar objects
      rawMaterial: "",
      order: "",
      group: "",
      chemical: "",
      code: "",
      shouldExport: false,

      technicalSheets: [],
      columna: "",
      sortDirection: false,
    };

    //this.fetchData = this.fetchData.bind(this);
    this.openInfoModal = this.openInfoModal.bind(this);
    this.closeInfoModal = this.closeInfoModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.onEditedMaterial = this.onEditedMaterial.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.trimStart().toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.trimStart().toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Orden":
        array.data.sort((a, b) => {
          let A = a.orden;
          let B = b.orden;
          return A - B;
        });
        break;
      case "Quimico":
        array.data.sort((a, b) => {
          const nameA = a.quimico.toUpperCase(); // ignore upper and lowercase
          const nameB = b.quimico.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Codigo":
        array.data.sort((a, b) => {
          let nameA = a.codigo ? a.codigo.toUpperCase() : ""; // ignore upper and lowercase
          let nameB = b.codigo ? b.codigo.toUpperCase() : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Costo":
        array.data.sort((a, b) => {
          let A = a.costo;
          let B = b.costo;
          return A - B;
        });
        break;
      case "Mínimo":
        array.data.sort((a, b) => {
          let A = a.min;
          let B = b.min;
          return A - B;
        });
        break;
      case "Máximo":
        array.data.sort((a, b) => {
          let A = a.max;
          let B = b.max;
          return A - B;
        });
        break;
      case "Densidad":
        array.data.sort((a, b) => {
          let A = a.densidad;
          let B = b.densidad;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      $sort: {
        id: -1,
      },
    };

    if (this.state.$sortDirection !== null) {
      query.$sort = {
        nombre: this.state.$sortDirection,
      };
    }

    if (this.state.$sortOrderDirection !== null) {
      query.$sort = {
        orden: this.state.$sortOrderDirection,
      };
    }

    if (this.state.filterOptions.id) {
      query["id"] = this.state.filterOptions.id;
    }

    if (this.state.filterOptions.orden) {
      query["orden"] = this.state.filterOptions.orden;
    }

    if (this.state.filterOptions.grupo) {
      query["grupo"] = this.state.filterOptions.grupo;
    }

    if (this.state.filterOptions.quimico) {
      query["quimico"] = this.state.filterOptions.quimico;
    }

    if (this.state.filterOptions.codigo) {
      query["codigo"] = this.state.filterOptions.codigo;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("materia_prima")
        .find({
          query,
        })
        .then((res) => {
          console.log(res);
          res = this.handleSort(res);
          if (!this.state.shouldExport) {
            this.setState({
              rawMaterials: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
          if (this.state.shouldExport) {
            exportToXls(res, "admin_rawmaterials");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };

  handleResultSelect = async (e, { result }) => {
    this.setState({ rawMaterial: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleOrderResultSelect = async (e, { result }) => {
    this.setState({ order: result.orden });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        orden: result.orden,
      },
    });

    this.fetchData(this.state);
  };

  handleGroupResultSelect = async (e, { result }) => {
    this.setState({ group: result.grupo });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        grupo: result.grupo,
      },
    });

    this.fetchData(this.state);
  };

  handleChemicalResultSelect = async (e, { result }) => {
    this.setState({ chemical: result.quimico });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        quimico: result.quimico,
      },
    });

    this.fetchData(this.state);
  };

  handleCodeResultSelect = async (e, { result }) => {
    this.setState({ code: result.codigo });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        codigo: result.codigo,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingRawMaterial: true, rawMaterial: value });

    setTimeout(() => {
      if (this.state.rawMaterial.length < 1) return this.resetComponent();

      Feathers.service("materia_prima")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loadingRawMaterial: false,
            results: res.data,
          });
        });
    }, 500);
  };

  handleOrderSearchChange = (e, { value }) => {
    this.setState({ loadingOrder: true, order: value });

    setTimeout(() => {
      if (this.state.order.length < 1) return this.resetOrderComponent();

      Feathers.service("materia_prima")
        .find({
          query: {
            orden: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loadingOrder: false,
            resultsOrder: res.data,
          });
        });
    }, 500);
  };

  handleGroupSearchChange = (e, { value }) => {
    this.setState({ loadingGroup: true, group: value });

    setTimeout(() => {
      if (this.state.group.length < 1) return this.resetGroupComponent();

      Feathers.service("materia_prima")
        .find({
          query: {
            grupo: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loadingGroup: false,
            resultsGroup: res.data,
          });
        });
    }, 500);
  };

  handleChemicalSearchChange = (e, { value }) => {
    this.setState({ loadingChemical: true, chemical: value });

    setTimeout(() => {
      if (this.state.chemical.length < 1) return this.resetChemicalComponent();

      Feathers.service("materia_prima")
        .find({
          query: {
            quimico: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loadingChemical: false,
            resultsChemical: res.data,
          });
        });
    }, 500);
  };

  handleCodeSearchChange = (e, { value }) => {
    this.setState({ loadingCode: true, code: value });

    setTimeout(() => {
      if (this.state.code.length < 1) return this.resetCodeComponent();

      Feathers.service("materia_prima")
        .find({
          query: {
            codigo: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loadingCode: false,
            resultCode: res.data,
          });
        });
    }, 500);
  };

  resetComponent = () =>
    this.setState({ loadingRawMaterial: false, results: [], rawMaterial: "" });
  resetOrderComponent = () =>
    this.setState({ loadingOrder: false, resultsOrder: [], order: "" });
  resetGroupComponent = () =>
    this.setState({ loadingGroup: false, resultsGroup: [], group: "" });
  resetChemicalComponent = () =>
    this.setState({
      loadingChemical: false,
      resultsChemical: [],
      chemical: "",
    });
  resetCodeComponent = () =>
    this.setState({ loadingCode: false, resultCode: [], code: "" });

  openInfoModal(row) {
    this.setState({
      modalOpened: true,
      currentRow: row.original,
    });
  }

  openTechnicalSheetsModal = async (row) => {
    await Feathers.service("fichas_tecnicas_mp")
      .find({
        query: {
          $limit: "-1",
          materia_prima_id: row.original.id,
        },
      })
      .then((res) => {
        this.setState({
          technicalSheets: res,
          modalTechnicalSheets: true,
          currentRow: row.original,
        });
      });

    /* this.setState({
      modalTechnicalSheets: true,
      currentRow: row.original,
    }); */
  };

  openEditModal(row) {
    this.setState({
      editModalOpened: true,
      currentRow: row.original,
    });
  }

  closeInfoModal() {
    this.setState({
      modalOpened: false,
    });
  }

  closeModalTechnicalSheets = () => {
    this.setState({
      modalTechnicalSheets: false,
    });
  };

  closeEditModal() {
    this.setState({
      editModalOpened: false,
    });
  }

  onEditedMaterial() {
    this.fetchData({ page: this.state.page, pageSize: this.state.pageSize });
  }

  /* async handleChange(event, row) {
        await Feathers.service('materia_prima').patch(row.original.id, {
            activo: event.target.checked ? 1: 0
        });
        this.fetchData(this.state);
    } */
  handleChange(event, row) {
    Feathers.service("materia_prima").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  handleCleanButton = async () => {
    this.resetComponent();
    this.resetOrderComponent();
    this.resetGroupComponent();
    this.resetChemicalComponent();
    this.resetCodeComponent();
    await this.setState({
      filterOptions: {
        id: null,
      },
    });

    this.fetchData(this.state);
  };

  openUploadFiles = (row) => {
    const materiaId = row.original.id;
    //this.props.dispatch(push(`/rawmaterials/${materiaId}`));
    this.props.dispatch(push(`/reports/technicalSheets/${materiaId}`));
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Orden</label>
                  <Search
                    icon=""
                    loading={this.state.loadingOrder}
                    value={this.state.order}
                    results={this.state.resultsOrder}
                    onResultSelect={this.handleOrderResultSelect}
                    onSearchChange={this.handleOrderSearchChange}
                    resultRenderer={renderOrderSearchResults}
                    placeholder="Orden"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Materia</label>
                  <Search
                    icon=""
                    loading={this.state.loadingRawMaterial}
                    value={this.state.rawMaterial}
                    results={this.state.results}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    resultRenderer={renderSearchResults}
                    placeholder="Materia"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Grupo</label>
                  <Search
                    icon=""
                    loading={this.state.loadingGroup}
                    value={this.state.group}
                    results={this.state.resultsGroup}
                    onResultSelect={this.handleGroupResultSelect}
                    onSearchChange={this.handleGroupSearchChange}
                    resultRenderer={renderGrupoSearchResults}
                    placeholder="Grupo"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Químico</label>
                  <Search
                    icon=""
                    loading={this.state.loadingChemical}
                    value={this.state.chemical}
                    results={this.state.resultsChemical}
                    onResultSelect={this.handleChemicalResultSelect}
                    onSearchChange={this.handleChemicalSearchChange}
                    resultRenderer={renderChemicalSearchResults}
                    placeholder="Químico"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Código</label>
                  <Search
                    icon=""
                    loading={this.state.loadingCode}
                    value={this.state.code}
                    results={this.state.resultCode}
                    onResultSelect={this.handleCodeResultSelect}
                    onSearchChange={this.handleCodeSearchChange}
                    resultRenderer={renderCodeSearchResults}
                    placeholder="Código"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <div style={{ margin: "10px" }}>
            <Icon name="check" color="green" />{" "}
            <span>Materias primas que ya cuentan con fichas técnicas.</span>
          </div>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ReactTable
              columns={[
                {
                  Header: "Nombre",
                  //id: 'nombre',
                  accessor: "nombre",
                  /* Cell: row => (
                    row.original.fichas_tecnicas_mp ?
                    <div style={{ width: "100%",
                    height: "100%",}}>
                      <Icon name='check' color='teal' /> {row.original.nombre} 
                    </div>
                    : (
                      <div style={{width: "100%",
                      height: "100%",}}>
                        {row.original.nombre}
                      </div>
                    )
                  ) */
                },
                {
                  Header: "Orden",
                  id: "orden",
                  accessor: (d) => +d.orden,
                  maxWidth: 80,
                },
                { Header: "Grupo", accessor: "grupo" },
                { Header: "Quimico", accessor: "quimico" },
                { Header: "Codigo", accessor: "codigo" },
                { Header: "Costo", accessor: "costo", maxWidth: 100 },
                { Header: "Mínimo", accessor: "min", maxWidth: 100 },
                { Header: "Máximo", accessor: "max", maxWidth: 100 },
                { Header: "Densidad", accessor: "densidad", maxWidth: 100 },
                {
                  Header: "Acciones",
                  sortable: false,
                  Cell: (row) => {
                    return (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {row.original.fichas_tecnicas_mp ? (
                          <Popup
                            content="Tiene fichas técnicas"
                            trigger={
                              <Button
                                //basic
                                size={"tiny"}
                                compact
                                color="green"
                                icon="check"
                              />
                            }
                          />
                        ) : null}
                        <Button
                          basic
                          size={"tiny"}
                          compact
                          color="grey"
                          icon="eye"
                          onClick={() => this.openInfoModal(row)}
                        />
                        <Popup
                          content="Fichas técnicas"
                          trigger={
                            <Button
                              basic
                              size={"tiny"}
                              compact
                              color="teal"
                              icon="archive"
                              onClick={() => this.openUploadFiles(row)}
                            />
                          }
                        />
                        <Button
                          basic
                          size={"tiny"}
                          compact
                          color="blue"
                          icon="pencil"
                          onClick={() => this.openEditModal(row)}
                        />
                        <Toggle
                          defaultChecked={!!row.original.activo}
                          icons={false}
                          onChange={(event) => this.handleChange(event, row)}
                        />
                      </div>
                    );
                  },
                },
              ]}
              manual
              data={this.state.rawMaterials}
              loading={this.state.loading}
              pages={Math.ceil(this.state.total / this.state.pageSize)}
              onFetchData={this.fetchData}
              defaultPageSize={100}
              style={{ textAlign: "center" }}
              onSortedChange={async (newSorted, column, shiftKey) => {
                let direction;
                if (column.Header == this.state.columna.Header) {
                  direction = this.state.sortDirection ? false : true;
                } else {
                  direction = false;
                }

                this.setState(
                  {
                    columna: column,
                    sortDirection: direction,
                  },
                  () => {
                    console.log(this.state);
                  }
                );
                this.fetchData();
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <RawMaterialsInfoModal
          isOpened={this.state.modalOpened}
          material={this.state.currentRow}
          onClose={this.closeInfoModal}
        />
        <RawMaterialsTechnicalSheetsModal
          isOpened={this.state.modalTechnicalSheets}
          material={this.state.currentRow}
          onClose={this.closeModalTechnicalSheets}
          technicalSheets={this.state.technicalSheets}
        />
        <RawMaterialsEditModal
          isOpened={this.state.editModalOpened}
          material={this.state.currentRow}
          onClose={this.closeEditModal}
          callback={this.onEditedMaterial}
        />
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(RawMaterialsList);

//export default RawMaterialsList;
