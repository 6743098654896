import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Label,
  Icon,
} from "semantic-ui-react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import {
  feathersAuthentication,
  feathersServices,
} from "../redux/FeathersRedux";
import swal from "sweetalert2";
//import { Label } from 'recharts';

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
    };

    this.login = this.login.bind(this);
  }

  login() {
    this.props
      .authenticate(this.state.login, this.state.password)
      .catch((err) => {
        swal({
          type: "error",
          title: "Ocurrió un error",
          text: "Datos incorrectos, intente de nuevo.",
        });
      });
  }

  render() {
    return (
      <div style={{ height: "100vh", background: "" }}>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Image
              src={require("../resources/images/mainLogo.png")}
              style={{ margin: "auto", marginBottom: 40, width: "360px" }}
            />
            <Form
              size="big"
              onSubmit={this.login}
              style={{
                background: "white",
                padding: "1.5rem",
                borderRadius: "1rem",
                boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)",
              }}
            >
              <div style={{ marginBottom: "30px" }}>
                <h2 style={{ color: "#222831", fontSize: "25px" }}>
                  Iniciar Sesión
                </h2>
              </div>
              <Form.Input
                value={this.state.login}
                name="login"
                onChange={(event, { name, value }) =>
                  this.setState({ [name]: value })
                }
                fluid
                icon="user"
                iconPosition="left"
                placeholder="User"
                required
              />
              <Form.Input
                name="password"
                onChange={(event, { name, value }) =>
                  this.setState({ [name]: value })
                }
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                required
              />
              {/* <Button loading={this.props.auth.isLoading}  fluid size='large' style={{background: "#366ed8", color:"white"}}>Ingresar</Button> */}
              <Button
                animated
                loading={this.props.auth.isLoading}
                fluid
                size="large"
                primary /* style={{background: "#366ed8", color:"white"}} */
              >
                <Button.Content visible>Ingresar</Button.Content>
                <Button.Content hidden>
                  <Icon name="arrow right" />
                </Button.Content>
              </Button>
            </Form>
          </Grid.Column>
        </Grid>
        <div></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (login, password) =>
      dispatch(
        feathersAuthentication.authenticate({
          strategy: "local",
          login,
          password,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
