import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Icon, Button, Form, Image} from 'semantic-ui-react'
import Feathers from '../../redux/FeathersRedux'
import {notify} from "react-notify-toast";
import { Dropzone } from '../Dropzone'

class DrawForm extends Component {
    state = {
        draw: {
            nombre: '',
            activo: 1
        },
        loading: false,
        /* files: [],
        image : null, */
    };

    onImageDrop(files, rejectedFiles) {
        this.setState({ files })
    }

    onDropChange = (image) => {
    this.setState({ image })
    }

    renderDropContent(){
    if (!this.state.files.length) return (
      <p>Drop an image or click to select a file to upload.</p>
    )

    return (
      <Image src={this.state.files[0].preview} style={{ height: 130, width: 'auto'}} />
    )
    }

    handleSubmit = _ =>{
        if (!this.state.draw.nombre) {
            notify.show('Hace falta el nombre', 'error', 2000);
            return;
        }

        this.setState({
            loading: true
        }, () => {
            Feathers.service('dibujo').create({
                ...this.state.draw,
                //image: this.state.image.base64
            }).then(res => {
                notify.show('Dibujo Creado', 'success');

                this.setState({
                    loading: false,
                    draw: {
                        nombre: '',
                        activo: 1
                    },
                    /* files: [],
                    image : null, */
                }, _ =>  this.props.onCreate());

            }).catch(err => {
                notify.show('Se produjo un error', 'error');
                this.setState({ loading: false })
            })
        })
    };


    render() {
        return (
            <div>
                <Form size={'large'}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid.Row>
                                    <Form.Input label='Nombre' name='nombre' value={this.state.draw.nombre} type='text' placeholder='Nombre' onChange={(e, {name, value}) => this.setState({ draw: {...this.state.draw, [name] : value} })} required/>
                                </Grid.Row>
                                <Grid.Row>
                                      {/* <Form.Field required>
                                        <label> Imagen </label>
                                        <Dropzone onChange={this.onDropChange} image={this.state.image} />
                                      </Form.Field> */}
                                    <Button disabled={this.state.loading}  style={{
                                        marginTop: 10,
                                    }} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='REGISTRAR' icon='send' labelPosition='left' />
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
        );
    }
}

DrawForm.propTypes = {};

export default DrawForm;
