import React, { Component } from "react";
import { Modal, Button, Form, Popup } from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import moment from "moment";
import ReactTable from "react-table";
import swal from "sweetalert2";
import { exportToPdf } from "../../utils/Utils";

export default class ClientsInfoModal extends Component {
  state = {
    loading: false,
    partidas: [],
    total: 0,
    page: 0,
    pageSize: 5,
  };

  getPartidas = async (state = {}) => {
    if (Object.keys(this.props.order).length !== 0) {
      let pageSize = state.pageSize || 5;

      let partidas = await Feathers.service("partida").find({
        query: {
          pedido_id: this.props.order.id,
          $limit: state.pageSize || 10,
          $skip: pageSize * state.page,
        },
      });
      this.setState({
        total: partidas.total,
        partidas: partidas.data,
      });
    }
  };

  validateOrder = () => {
    this.setState({ loading: true });

    Feathers.service("pedido")
      .patch(this.props.order.id, {
        status_id: 2,
      })
      .then(async () => {
        console.log("notification");
        await Feathers.service("notificacion").patch(
          null,
          { adjunto: null, status: 0 },
          {
            adjunto: this.props.order.id,
          }
        );

        // Feathers.service("revision")
        //   .find({
        //     query: {
        //       pedido_id: this.props.order.id,
        //     },
        //   })
        //   .then((res) => {
        //     Feathers.service("revision")
        //       .patch(res.data[0].id, {
        //         //hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
        //         hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
        //         status_tarea_id: 2,
        //         usuario_id: this.props.user,
        //       })
        //       .then((res) => {
        //         //console.log(res, 'todo bien')
        //       })
        //       .catch((err) => {
        //         console.log("error al saltar tarea de revision", err);
        //       });
        //   });
        this.setState({ loading: false });
        this.props.order.status_id = 2;
        this.props.order.status
          ? (this.props.order.status.nombre = "Iniciado")
          : (this.props.order.status = { nombre: "Iniciado" });

        this.props.callback && this.props.callback();
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  renderInfo = () => {
    if (!this.props.order.status_id) return <span></span>;
    return (
      <Form>
        <Form.Group>
          <Form.Field width={3}>
            <label>Status</label>
            {this.props.order.status.nombre}
          </Form.Field>
          <Form.Field width={5}>
            <label>Cliente</label>
            {this.props.order.cliente.nombre}
          </Form.Field>
          <Form.Field width={5}>
            <label>Vendedor</label>
            {`${this.props.order.usuario.nombre} ${this.props.order.usuario.apellido} `}
          </Form.Field>
          <Form.Field width={3}>
            <label>Teléfono</label>
            {this.props.order.cliente.telefono}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>País</label>
            {this.props.order.cliente.pais}
          </Form.Field>
          <Form.Field>
            <label>Colonia de Entrega</label>
            {this.props.order.col_entrega}
          </Form.Field>
          <Form.Field>
            <label>Dirección de Entrega</label>
            {this.props.order.dir_entrega}
          </Form.Field>
          <Form.Field>
            <label>Municipio de Entrega</label>
            {this.props.order.municipio_entrega}
          </Form.Field>
          <Form.Field>
            <label>Estado de Entrega</label>
            {this.props.order.estado_entrega}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="4">
          <Form.Field>
            <label>Fecha Alta</label>
            {moment(this.props.order.fecha_alta).format("YYYY-MM-DD")}
          </Form.Field>
          <Form.Field>
            <label>Fecha Entrega</label>
            {moment(this.props.order.fecha_entrega).format("YYYY-MM-DD")}
          </Form.Field>
          <Form.Field>
            <label>Flete</label>
            {this.props.order.flete ? this.props.order.flete.precio : "00.00"}
          </Form.Field>
          <Form.Field>
            <label>Orden de compra</label>
            {this.props.order.orden_compra
              ? this.props.order.orden_compra
              : "N/A"}
          </Form.Field>
          <Form.Field>
            <label>Númerto de salida</label>
            {this.props.order.numero_salida
              ? this.props.order.numero_salida
              : "N/A"}
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  renderStatusInfo = () => {
    if (this.props.order.status_id === 1) {
      return (
        <div>
          <this.renderInfo />
        </div>
      );
    }

    return (
      <div>
        <this.renderInfo />
      </div>
    );
  };

  renderStatusButton = () => {
    if (this.props.order.status_id === 1) {
      return (
        <div>
          <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            onClick={this.validateOrder}
            positive
            labelPosition="right"
            icon="check"
            content="VALIDAR PEDIDO"
          />
        </div>
      );
    }

    return <span></span>;
  };

  renderLabelButton = () => {
    if (this.props.order.status_id === 2) {
      return (
        <div>
          <Button
            //loading={this.state.loading}
            //disabled={this.state.loading}
            onClick={() => {
              window.location.href = `https://facturacion.bingham.acsyt.com/api/createLabel/${this.props.order.id}`;

              // window.location.href = `http://127.0.0.1:8000/api/createLabel/${this.props.order.id}`;
            }}
            positive
            labelPosition="right"
            icon="check"
            content="IMPRIMIR ETIQUETAS"
          />
        </div>
      );
    }

    return <span></span>;
  };

  downloadPdf = async (_) => {
    // tenemos que traer la ruta del pdf según el pedido_id
    let pdf = await Feathers.service("pdf").find({
      query: {
        pedido_id: this.props.order.id,
      },
    });
    // esto sirve para ver si si existe un pdf o no
    if (pdf.total > 0) {
      pdf = JSON.parse(JSON.stringify(pdf.data[pdf.data.length - 1]));

      let filename = pdf.url;

      filename = filename.substring(filename.search("cotizacion"));

      exportToPdf(filename);
    } else {
      swal({
        type: "error",
        title: "Oops...",
        text: "La orden no cuenta con un PDF generado.",
      });
    }
  };

  renderDownloadButton = () => {
    return (
      <div>
        <Button
          loading={this.state.loading}
          disabled={this.state.loading}
          onClick={this.downloadPdf}
          positive
          labelPosition="right"
          icon="file"
          content="DESCARGAR PDF"
        />
      </div>
    );
  };

  render() {
    return (
      <Modal open={this.props.isOpened} size={"large"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, float: "left" }}>
              Pedido #{this.props.order.id}
            </div>
            <div
              style={{ float: "right", display: "flex", marginRight: "1rem" }}
            >
              <this.renderDownloadButton />
              <this.renderStatusButton />
              <this.renderLabelButton />
            </div>
            <div>
              <Button
                circular
                size="mini"
                basic
                icon="close"
                onClick={this.props.onClose}
              ></Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Content>
          {this.renderStatusInfo()}
          <ReactTable
            columns={[
              { Header: "Partida", id: "id", accessor: (d) => "#" + d.id },
              {
                Header: "Producto",
                id: "producto",
                accessor: (d) => d.producto.nombre,
              },
              {
                Header: "Cantidad",
                id: "cantidad",
                accessor: (d) => d.cantidad,
              },
              {
                Header: "Material",
                id: "material",
                minWidth: 150,
                accessor: (d) => {
                  if (d.material) {
                    return d.material.nombre;
                  } else {
                    return "N/A";
                  }
                },
              },
              {
                Header: "Dureza",
                id: "dureza",
                accessor: (d) => {
                  if (d.dureza) {
                    return d.dureza.valor;
                  }
                },
              },
              { Header: "DN", id: "dn", accessor: (d) => d.dn },
              { Header: "DE", id: "de", accessor: (d) => d.de },
              { Header: "LC", id: "lc", accessor: (d) => d.lc },
              { Header: "LT", id: "lt", accessor: (d) => d.lt },
              {
                Header: "Kilos",
                id: "kilos",
                accessor: (d) => {
                  return Number.parseFloat(d.kilos).toFixed(3);
                },
              },
              {
                Header: "Precio",
                id: "precio",
                accessor: (d) => "$ " + d.precio,
              },
              {
                Header: "Observaciones",
                id: "observaciones",
                Cell: (row) => (
                  <div>
                    <Popup
                      content={
                        row.original.observaciones
                          ? row.original.observaciones
                          : "SIN OBSERVACIONES"
                      }
                      trigger={
                        <Button
                          size="mini"
                          compact
                          basic
                          color="blue"
                          icon="eye"
                          //onClick={() => this.openInfoModal(row)}
                        />
                      }
                    />
                  </div>
                ),
              },
            ]}
            manual
            data={this.state.partidas}
            loading={this.state.loading}
            pages={Math.ceil(this.state.total / 5)}
            onFetchData={this.getPartidas}
            defaultPageSize={5}
            style={{ textAlign: "center", marginTop: 10 }}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

ClientsInfoModal.defaultProps = {
  order: {
    status: {},
  },
};
