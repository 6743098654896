import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import Config from "../../config";
import { Button, Icon, Modal, Divider, Grid, Header } from "semantic-ui-react";

export default class ReportFileModal extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
    };
  }

  entregables() {
    if (Array.isArray(this.props.data.tarea_file)) {
      return (
        <>
          {this.props.data.tarea_file.map((file) => (
            <Grid.Column key={file.id}>
              <section
                style={{
                  display: "flex",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 6px 1px",
                  borderRadius: "5px",
                  height: "100px",
                  maxWidth: '100%',
                  padding: "0.5rem",
                  //fontSize: '16px',
                  alignItems: "center",
                }}
              >
                <div style={{ width: "10%", textAlign: 'center' }}>
                  <Icon name="file text" size="large" />
                </div>
                <div style={{ width: "90%" }}>
                  <div style={{maxWidth: "80%"}}>
                    <a
                      href={`${Config.apiUrl}/documents/tasks/${file.document}`}
                      target="_blank"
                      
                      >
                      {file.document}
                    </a>
                  </div>
                  <p style={{fontSize: '16px'}}>{file.description}</p>
                </div>
                
              </section>
            </Grid.Column>
          ))}
        </>
      );
    } else if (this.props.data.tarea_file !== undefined) {
  
      return (
        <Grid.Row>
          <Grid.Column>
            <section
              style={{
                display: "flex",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "5px",
                height: "100px",
                padding: "0.5rem",
                color: "red",
                alignItems: "center",
              }}
            >
              <div style={{ width: "10%" }}>
                <Icon name="file pdf outline" size="large" />
              </div>
              <div style={{ width: "90%" }}>
                <a
                  href={`${Config.apiUrl}/documents/tasks/${this.props.data.tarea_file.document}`}
                  target="_blank"
                >
                  {this.props.data.tarea_file.document}
                </a>
              </div>
            </section>
          </Grid.Column>
        </Grid.Row>
      );
    }
  }

  render() {
    return (
      <Modal open={this.props.open} size="large">
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          Información
          <Button
            circular
            size="mini"
            icon="close"
            floated="right"
            basic
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content scrolling >
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column
                width={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon bordered size="large" name="user" />
              </Grid.Column>
              <Grid.Column width={14} style={{ padding: 0, fontSize: "16px" }}>
                <div>
                  <h4>Ricardo Maynez:</h4>
                </div>
                <div>
                  <p>{this.props.data.descripcion_entregable}</p>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="copy outline" />
              Entregables del usuario
            </Header>
          </Divider>
          <Grid columns={2}>{this.entregables()}</Grid>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }
}
