import React, { Component } from 'react';
import { Header, Segment, Grid, Icon, Form, Button, Divider } from 'semantic-ui-react'
import { TaskOrderSegment, TaskStatusSegment, TaskItemSegment} from '../'
import Timer from '../../Timer'
import Feathers from '../../../redux/FeathersRedux';
import swal from 'sweetalert2'
import Incidence from '../../Incidence/Incidence'

const PHASE = 6;


export default class RoughingTask extends Component {

    constructor(props) {
        super(props);

        this.state = {
            producto_id: this.props.task.rodillo.partida.producto_id,
            de_inicial: null,
            lc_final: null,
            de_final: null,
        }

        this.renderForm = this.renderForm.bind(this)
    }


   

    componentDidMount(){
      this.getDeIfPossible()
    }


    getDeIfPossible = async () => { 
      let rodillo_en_contruccion = await Feathers.service('construccion').find({
        query: {
          rodillo_id: this.props.task.rodillo_id,
          incidencia_id: null
        }
      })


      const { data } = rodillo_en_contruccion;

      
      if(data.length > 0) {
        if(data[0].de_final){
          this.setState({
            de_inicial: data[0].de_final
          })
        }
      }
 
    }

    renderInitButton = () => {
        if (this.props.task.hora_inicio) return null;
        return (
            <div style={{marginTop: 20}}>
                <Button onClick={() => this.props.initTask(this.props.task)}  icon='play' fluid color="green" content='Iniciar' />
             </div>
        )
    };

    finishTask = () => {
        if([1, 4].indexOf(this.state.producto_id) !== -1){
            if (!this.props.task.lc_final || !this.props.task.de_final) return swal({
                title: 'Atención',
                text: 'Primero debes capturar la información pendiente para finalizar la tarea',
                type: 'info',
            })
        }

        this.props.finishTask && this.props.finishTask();
    };

    renderForm() {
        if(!this.props.task.hora_inicio) return null;
        if([1, 4].indexOf(this.state.producto_id) !== -1){
          if (!this.props.task.lc_final) {
            return (
              <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Segment>
                  <Form>
                    <Grid>
                      <Grid.Row>

                        <Grid.Column computer={4} tablet={4} mobile={16}>
                          <Form.Input name="lc_final" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='LC final' placeholder='Largo de cara final'/>
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={4} mobile={16}>
                          <Form.Input name="de_inicial" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DE inicial' placeholder='Diámetro externo inicial' value={this.state.de_inicial} />
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={4} mobile={16}>
                          <Form.Input name="de_final" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DE final' placeholder='Diámetro externo final'/>
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={4} mobile={16}>
                          <Button type="button" onClick={this.saveInfo} disabled={!this.state.lc_final || !this.state.de_final || !this.state.de_inicial} fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR' />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Segment>
              </div>
            )
          }

          return (
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>LC Final</span>
                      <span>{this.props.task.lc_final}</span>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>DE Final</span>
                      <span>{this.props.task.de_final}</span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>
          )
        }


    }

    saveInfo = async () => {
        const { de_inicial, lc_final, de_final } = this.state;

  
        if([1, 4].indexOf(this.state.producto_id) !== -1){


        const de_esperado = this.props.task.rodillo.partida.de;
        const lc_esperado = this.props.task.rodillo.partida.lc;

        // let holgura_de = await Feathers.service('configuraciones').find({
        //   query: {
        //     nombre: 'holgura_de'
        //   }
        // })
        
        // holgura_de = +(holgura_de.data[0].valor)
        
        // let holgura_lc = await Feathers.service('configuraciones').find({
        //   query: {
        //     nombre: 'holgura_lc'
        //   }
        // })

        // holgura_lc = +(holgura_lc.data[0].valor)
      
        // if(de_final <  (de_esperado - holgura_de) || de_final >  de_esperado ){
        //   await swal({
        //     type: 'error',
        //     title: 'Oops...',
        //     text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
        //   })
        //   await this.incidence.getWrappedInstance().openFromOutside()
        //   return
        // }

        // if(+(de_inicial) <  (de_esperado - holgura_de) || +(de_inicial) >  de_esperado ){
        //   await swal({
        //     type: 'error',
        //     title: 'Oops...',
        //     text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
        //   })
        //   await this.incidence.getWrappedInstance().openFromOutside()
        //   return
        // }


        // if(lc_final <  (lc_esperado - holgura_lc) || lc_final >  lc_esperado ){
        //   await swal({
        //     type: 'error',
        //     title: 'Oops...',
        //     text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
        //   })
        //     await this.incidence.getWrappedInstance().openFromOutside()
        //     return
        //   }
          

          if(!de_inicial){
            return await swal({
              type: 'error',
              title: 'Oops...',
              text: 'Por favor introducir los valores',
            })
            return 0;
          }

          if(!de_final){
            return await swal({
              type: 'error',
              title: 'Oops...',
              text: 'Por favor introducir los valores',
            })
            return 0;
          }

          if(!lc_final){
            return await swal({
              type: 'error',
              title: 'Oops...',
              text: 'Por favor introducir los valores',
            })
            return 0;
          }
          
        }
        
        await Feathers.service('desbaste').patch(this.props.task.id, {
            de_inicial,
            lc_final,
            de_final
        })
        // await this.props.updateTask && await this.props.updateTask();lo bu
        this.props.finishTask && this.props.finishTask()
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header as='h2'>
                            <Button onClick={this.props.goBack} icon="arrow left" style={{backgroundColor: 'transparent', color: '#000000de'}}/>
                            Tarea de Desbaste
                            </Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Incidence ref={ref => this.incidence = ref} phase={PHASE} task={this.props.task} cancelTaskByIncidence={this.props.cancelTaskByIncidence} rodillo={this.props.task.rodillo_id} pedido={this.props.task.rodillo.partida.pedido_id} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                <TaskStatusSegment task={this.props.task} finishTask={this.finishTask}></TaskStatusSegment>
                <TaskOrderSegment order={this.props.task.rodillo.partida.pedido} kilos={this.props.task.rodillo.partida.kilos}/>

                <Header as='h3' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px', backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='list' />
                    <Header.Content>Información de la partida</Header.Content>
                </Header>
                <TaskItemSegment roller={this.props.task.rodillo.id} item={this.props.task.rodillo.partida} />

                {this.renderForm()}
                {this.renderInitButton()}
            </div>
        )
    }
}
