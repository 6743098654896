import React, {Component} from 'react'
import {Header, Segment, Icon, Grid} from 'semantic-ui-react'

export default class TaskOrderSegment extends Component {

    render() {
        return (
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Header as='h3' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px', backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='info' />
                    <Header.Content>Información del pedido</Header.Content>
                </Header>
                <Segment size={"large"}>
                    <Grid>
                        <Grid.Row columns={5} style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>#Pedido</span>
                                <span>{this.props.order.id}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>#Cliente</span>
                                <span>{this.props.order.cliente.id}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Cliente</span>
                                <span>{this.props.order.cliente.nombre}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Fecha alta</span>
                                <span>{this.props.order.fecha_alta}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Fecha entrega</span>
                                <span>{this.props.order.fecha_entrega}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Kilos totales</span>
                                <span>{this.props.order.partidas && (this.props.order.partidas.kilos) }</span>
                                <span>{this.props.kilos && (this.props.kilos)}</span>
                                
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Orden de compra</span>
                                <span>{this.props.order.orden_compra ? this.props.order.orden_compra  : 'N/A' }</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Número de salida</span>
                                <span>{this.props.order.numero_salida ? this.props.order.numero_salida  : 'N/A' }</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>   
            </div>
        )
    }
}