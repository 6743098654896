import React, {Component} from 'react';
import { Form } from 'semantic-ui-react'

export default class FormInput extends Component {

    constructor() {
        super()

        this.fillInAddress = this.fillInAddress.bind(this)
    }

    componentDidMount() {
        if (window.google) {
            this.autocomplete = new window.google.maps.places.Autocomplete(
                (document.getElementById(this.props.id)), { types: ['geocode']}
            );
    
            this.autocomplete.addListener('place_changed', this.fillInAddress);
        }
    }

    fillInAddress() {
        this.props.placeChanged && this.props.placeChanged(this.autocomplete.getPlace())
    }

    render() {
        return (
            <Form.Input id={this.props.id} label={this.props.label} name={this.props.name} type={this.props.type || 'text'} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} />
        )
    }
}