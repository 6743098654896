import React, { Component } from "react";
import PropTypes from "prop-types";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import { Button, Checkbox, Form, Search, Grid, Label } from "semantic-ui-react";
import Toggle from "react-toggle";
import ColorEditModal from "./ColorEditModal";
import { exportToXls } from "../../utils/Utils";

const renderNameSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class ColorList extends Component {
  constructor() {
    super();

    this.state = {
      colors: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      currentRow: {},
      isEditOpened: false,
      $sortDirection: 1,
      shouldExport: false,

      filterOptions: {
        id: null,
      },

      // search bar objects

      color: null,

      // search bar results

      colorResults: null,

      // search bar loadings

      colorLoadings: false,

      columna: "",
      sortDirection: false,
    };

    this.fetchData = this.fetchData.bind(this);
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.id) {
      query["id"] = this.state.filterOptions.id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("color")
        .find({
          query,
        })
        .then((res) => {
          res = this.handleSort(res);

          if (!this.state.shouldExport) {
            this.setState({
              colors: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "admin_colors");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };

  /* handleChange = (data, row) => {
        Feathers.service('color').patch(row.original.id, {
            activo: data.checked ? 1: 0
        })
            .then(res => {
                this.fetchData();
            })

    }; */

  handleChange(event, row) {
    Feathers.service("color").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  openEditModal = (row) => {
    this.setState(
      {
        currentRow: row.original,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isEditOpened: true,
          });
        }, 100);
      }
    );
  };

  closeEditModal = (_) => {
    this.setState({
      isEditOpened: false,
    });
  };

  handleNameResultSelect = async (e, { result }) => {
    this.setState({ color: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleNameSearchChange = (e, { value }) => {
    this.setState({ colorLoadings: true, color: value });

    setTimeout(() => {
      if (this.state.color.length < 1) return this.resetNameComponent();

      Feathers.service("color")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            colorLoadings: false,
            colorResults: res.data,
          });
        });
    }, 500);
  };

  resetNameComponent = () =>
    this.setState({ colorLoadings: false, colorResults: [], color: "" });

  handleCleanButton = (_) => {
    this.resetNameComponent();

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Color</label>
                  <Search
                    icon=""
                    loading={this.state.colorLoadings}
                    value={this.state.color}
                    results={this.state.colorResults}
                    onResultSelect={this.handleNameResultSelect}
                    onSearchChange={this.handleNameSearchChange}
                    resultRenderer={renderNameSearchResults}
                    placeholder="Color"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre", accessor: "nombre" },

            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size={"tiny"}
                      compact
                      basic
                      color="blue"
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                    {/* <Checkbox toggle onClick={(e, data) => this.handleChange(data, row)} checked={row.original.activo}/> */}
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.colors}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={this.state.pageSize}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />

        <ColorEditModal
          open={this.state.isEditOpened}
          onEdit={this.fetchData}
          color={this.state.currentRow}
          onClose={this.closeEditModal}
        />
      </div>
    );
  }
}

ColorList.propTypes = {};

export default ColorList;
