import React, { Component } from "react";
import {
  Modal,
  Grid,
  Form,
  Label,
  Search,
  Button,
  Input,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import Feathers from "../../redux/FeathersRedux";

const renderSearchResults = (result) => (
  <Label key={result.id} content={result.nombre + " - " + result.quimico} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

export default class AddRawMaterial extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      search: "",
      searchResults: [],
      materia_prima: null,
      partes: "",
    };
  }

  handleResultSelect = (e, { result }) =>
    this.setState({
      search: result.nombre + " - " + result.quimico,
      materia_prima: result,
    });

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, search: value });

    setTimeout(() => {
      if (this.state.search.length < 1) return this.resetSearch();

      Feathers.service("materia_prima")
        .find({
          query: {
            $or: [
              {
                nombre: { $like: `%${value}%` },
                activo: 1
              },
              {
                quimico: { $like: `%${value}%` },
                activo: 1
              },
            ],
          },
        })
        .then((res) => {
          this.setState({
            loading: false,
            searchResults: res.data,
          });
        });
    }, 500);
  };

  resetSearch = () =>
    this.setState({
      loading: false,
      searchResults: [],
      search: "",
      partes: "",
      materia_prima: null,
    });

  close = () => {
    this.resetSearch();
    this.props.toggleModal && this.props.toggleModal();
  };

  add = () => {
    let row = {
      materia_prima: this.state.materia_prima,
      partes: this.state.partes.replace("_", 0),
    };

    this.resetSearch();
    this.props.add && this.props.add(row);
    this.props.closeOnAdd && this.props.toggleModal();
  };

  renderCount = () => {
    if (typeof this.props.count == "undefined") return null;

    return <span>({this.props.count} agregadas)</span>;
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.close} size={"small"}>
        <Modal.Header>Agregar materia prima {this.renderCount()}</Modal.Header>
        <Modal.Content>
          <Form>
            <Grid columns={2}>
              <Grid.Column>
                <Form.Group>
                  <Form.Field width={16}>
                    <label>Materia prima</label>
                    <Search
                      icon=""
                      loading={this.state.loading}
                      value={this.state.search}
                      results={this.state.searchResults}
                      onResultSelect={this.handleResultSelect}
                      onSearchChange={this.handleSearchChange}
                      resultRenderer={renderSearchResults}
                      placeholder="Materia prima"
                      fluid
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Partes</label>
                  <Input
                    type="number"
                    value={this.state.partes}
                    onChange={(event) => {
                      this.setState({ partes: event.target.value });
                    }}
                  />
                </Form.Field>
                {/*  <label>Partes</label>
                            <InputMask mask="999.999" value={this.state.partes} onChange={(event) => {
                                this.setState({ partes: event.target.value })
                            }} /> */}
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close}>CERRAR</Button>
          <Button
            disabled={!this.state.materia_prima || !this.state.partes}
            positive
            onClick={this.add}
            icon="checkmark"
            labelPosition="right"
            content="AGREGAR"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
