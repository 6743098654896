import React, { Component } from "react";
import {
  Button,
  Modal,
  Transition,
  Form,
  Image,
  Icon,
  Input,
} from "semantic-ui-react";
import { Dropzone } from "../Dropzone";
import Feathers from "../../redux/FeathersRedux";
import moment from "moment";
import { connect } from "react-redux";
import feathersRestClient from "@feathersjs/rest-client";
import feathers from "@feathersjs/feathers";
import Config from "../../config";
import axios from "axios";

class Incidence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      disableIncidenceButton: false,
      files: [],
      incidenceTypes: [],
      incidence: {
        tipo_incidencia_id: null,
        descripcion: "",
        usuario_id: 0,
        pedido_id: props.pedido || null,
        rodillo_id: props.rodillo || null,
      },
      image: null,
      imageURI: "",
      phase: this.props.phase,
    };

    this.onImageDrop = this.onImageDrop.bind(this);
    this.renderDropContent = this.renderDropContent.bind(this);
    this.getIncidenceTypes = this.getIncidenceTypes.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.createIncidence = this.createIncidence.bind(this);
    this.createIncidence = this.createIncidence.bind(this);
  }
  openFromOutside = async () => {
    await this.getIncidenceTypes();
    await this.setState({
      modalVisible: true,
    });
  };

  async componentWillReceiveProps(nextprops) {
    if (nextprops.desc) {
      this.setState({
        incidence: {
          ...this.state.incidence,
          descripcion: nextprops.desc,
        },
      });
    }
  }

  async componentDidMount() {
    console.log("---", this.state, this.props);
    this.getIncidenceTypes();
  }

  closeModal() {
    this.setState({
      incidence: {
        tipo_incidencia_id: null,
        descripcion: "",
        usuario_id: 0,
        pedido_id: this.props.pedido || null,
        rodillo_id: this.props.rodillo || null,
      },
      modalVisible: false,
    });
  }

  async createIncidence() {
    this.setState({
      disableIncidenceButton: true,
    });

    if (!this.state.incidence.tipo_incidencia_id) {
      return;
    }
    if (!this.state.incidence.descripcion) {
      return;
    }
    // if (!this.state.image) {
    // 	return;
    // }

    let formData = new FormData();
    let form = document.getElementById("documentImage").files[0];
    formData.append("image", form);
    formData.append(
      "tipo_incidencia_id",
      this.state.incidence.tipo_incidencia_id
    );
    formData.append("descripcion", this.state.incidence.descripcion);
    formData.append("fecha_hora", moment().format("YYYY-MM-DD HH:mm:ss"));
    formData.append("usuario_id", this.props.auth.user.id);
    formData.append("rodillo_id", this.state.incidence.rodillo_id);
    formData.append("pedido_id", this.state.incidence.pedido_id);
    formData.append("etapa", this.state.phase);

    // headers: {
    //   Authorization: token,
    //   "Content-Type": "multipart/form-data",
    // },

    const token = localStorage.getItem("bingham-jwt");
    const app = feathers();
    const restClient = feathersRestClient(Config.apiUrl);
    app.configure(restClient.axios(axios));

    app
      .service("incidencia")
      .create(formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        console.log(
          "res",
          res,
          this.state.incidence.rodillo_id,
          this.state.pedido_id
        );

        await Feathers.service("revision")
          .create({
            rodillo_id: this.state.incidence.rodillo_id,
            pedido_id: this.state.incidence.pedido_id,
            incidencia_id: res.id,
          })
          .finally(async () => {
            await Feathers.service("rodillo").patch(
              this.state.incidence.rodillo_id,
              {
                stage: 0,
              }
            );
            await this.props.cancelTaskByIncidence(res.id);
            this.closeModal();
          });
        // this.setState(
        //   {
        //     disableIncidenceButton: false,
        //   },
        //   async () => {
        //     // CREATE CHECK TASK

        //   }
        // );
      })
      .catch((err) => {
        this.setState({
          disableIncidenceButton: false,
        });
      });
  }

  getIncidenceTypes() {
    Feathers.service("tipo_incidencia")
      .find({
        query: {
          activo: 1,
          $or: [{ etapa: this.state.phase }],
        },
      })
      .then((incidenceTypes) => {
        this.setState({
          incidenceTypes: incidenceTypes.map((i) => {
            return {
              text: i.nombre,
              value: i.id,
            };
          }),
        });
      });
  }

  onImageDrop(files, rejectedFiles) {
    this.setState({ files });
  }

  onDropChange = (image) => {
    this.setState({ image });
  };

  renderDropContent() {
    if (!this.state.files.length)
      return <p>Drop an image or click to select a file to upload.</p>;

    return (
      <Image
        src={this.state.files[0].preview}
        style={{ height: 130, width: "auto" }}
      />
    );
  }

  handledFile = (e) => {
    console.log(e, "event");
    this.setState({
      imageURI: e.target.result,
    });
  };

  render() {
    return (
      <div>
        <Button
          floated="right"
          onClick={() => this.setState({ modalVisible: true })}
          style={{ background: "#F41B00", color: "white" }}
        >
          {" "}
          Reportar incidencia{" "}
        </Button>

        <Transition
          visible={this.state.modalVisible}
          animation={"scale"}
          duration={500}
        >
          <Modal open={this.state.modalVisible}>
            <Modal.Header>
              Reportar una nueva incidencia
              <Button
                circular
                size="mini"
                basic
                icon="close"
                floated="right"
                onClick={this.closeModal}
              ></Button>
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Dropdown
                  required
                  value={this.state.incidence.tipo_incidencia_id}
                  label="Tipo de incidencia"
                  options={this.state.incidenceTypes}
                  selection
                  onChange={(event, { name, value }) =>
                    this.setState({
                      incidence: {
                        ...this.state.incidence,
                        tipo_incidencia_id: value,
                      },
                    })
                  }
                ></Form.Dropdown>
                <Form.TextArea
                  required
                  value={this.state.incidence.descripcion}
                  onChange={(event, { name, value }) =>
                    this.setState({
                      incidence: {
                        ...this.state.incidence,
                        descripcion: value,
                      },
                    })
                  }
                  style={{ resize: "none" }}
                  rows={13}
                  label="Descripción"
                ></Form.TextArea>
                <Form.Field required>
                  <label> Imagen </label>
                  <Input
                    style={{ width: "100%" }}
                    accept="image/*"
                    type="file"
                    id="documentImage"
                    name="document"
                    onChange={(event) => {
                      if (event.target.files && event.target.files.length > 0) {
                        this.setState({
                          image: event.target.files[0],
                        });
                        let reader = new FileReader();
                        reader.onloadend = this.handledFile;
                        reader.readAsDataURL(event.target.files[0]);
                      }
                    }}
                  />
                </Form.Field>
              </Form>
              <p style={{ marginTop: "10px", color: "red" }}>
                TODOS LOS CAMPOS SON OBLIGATORIOS
              </p>
            </Modal.Content>

            <Modal.Actions>
              <Button onClick={this.closeModal}>CANCELAR</Button>

              <Button
                disabled={
                  this.state.disableIncidenceButton ||
                  !this.state.incidence.tipo_incidencia_id ||
                  !this.state.incidence.descripcion ||
                  !this.state.image
                }
                loading={this.state.disableIncidenceButton}
                primary
                onClick={() => {
                  this.createIncidence();
                }}
              >
                REPORTAR INCIDENCIA <Icon name="right chevron" />
              </Button>
            </Modal.Actions>
          </Modal>
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(Incidence);
