import React, { Component } from "react";
import Feathers from "../../../redux/FeathersRedux";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../../components/Tasks/Construction/ConstructionTask";
import { notify } from "react-notify-toast";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoaderData from "../../LoaderData";

const SERVICE = "construccion";


class ConstruccionRodilloContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      task: null,
    };

    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.initTask = this.initTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  checkActiveTasks() {
    Feathers.service(SERVICE)
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          if (res.data[0].rodillo.esMancheta === 0) {
            this.setState({
              task: res.data[0],
              ready: true,
            });
          } else {
            this.setState({
              ready: true,
            });
          }
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  viewTask() {
    Feathers.service("construccion")
      .get(this.props.match.params.id)
      .then((task) => this.setState({ task }));
  }

  initTask(task) {
    swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service(SERVICE)
          .patch(task.id, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 1,
            usuario_id: this.props.user.id,
          })
          .then((res) => {
            Feathers.service(SERVICE)
              .get(res.id)
              .then((task) => this.setState({ task }));
          });
      }
    });

    // swal("ver")
  }

  finishTask() {
    swal({
      title: "¿Finalizar tarea de construcción?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service(SERVICE)
          .patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          })
          .then(async (res) => {
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(4);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de constucción rodillos #${this.state.task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }
            this.goBack()
          });
      }
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("construccion")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        this.setState({ task: null });
        notify.show("Incidencia registrada", "success");
      });
  }

  updateTask() {
    Feathers.service(SERVICE)
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  componentDidMount() {
    //this.checkActiveTasks();
    this.viewTask()
  }

  render() {
    if (this.state.task) {
      return (
        <TaskContainer
          goBack={this.goBack}
          initTask={this.initTask}
          finishTask={this.finishTask}
          task={this.state.task}
          updateTask={this.updateTask}
          cancelTaskByIncidence={this.cancelTaskByIncidence}
        />
      );
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ConstruccionRodilloContainer);
