import React, { Component } from "react";
import Feathers from "../redux/FeathersRedux";
import { Tab, Grid, Header } from "semantic-ui-react";
import CatalogEquipmentList from "../components/CatalogEquipment/CatalogEquipmentList";
import CatalogEquipmentForm from "../components/CatalogEquipment/CatalogEquipmentForm";
import { connect } from "react-redux";

class CatalogEquipmentContainer extends Component {
  constructor() {
    super();
    this.state = {
      userProfile: 0,
      isAdmin: 0,
      currIndex: 0,
    };
  }

  componentDidMount() {
      this.fetchUserProfile();
  }


  //Hacemos un peticion para saber si el usuario en sesion es administrador
  fetchUserProfile = () => {
    this.setState({
      userProfile: this.props.auth.user.id,
    });
    this.setState({ loading: true }, () => {
      //Hacemos una consulta que trae un 1 solo si el usuario es admin
      Feathers.service("usuario_perfil")
        .find({
          query: {
            usuario_id: this.state.userProfile,
            perfil_id: 1,
          },
        })
        .then((res) => {
          this.setState({
            isAdmin: res[0].perfil_id,
            loading: false,
          })
        })
        .catch((err) => {
          this.setState({ loading: false, isAdmin: 0 });
        });
    });
  };

  adminView = [
    {
      menuItem: { key: "list", icon: "list", content: "Mis registros" },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={1}
        >
          <CatalogEquipmentList  ref={(ref) => (this.list = ref)} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "add", icon: "add circle", content: "Nuevo registro" },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={2}
        >
          <CatalogEquipmentForm
            onCreate={() => {
              this.list.getWrappedInstance().fetchData();
            }}
          />
        </Tab.Pane>
      ),
    },
  ];

  generalView = [
    {
      menuItem: { key: "list", icon: "list", content: "Registros actuales" },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={1}
        >
          <CatalogEquipmentList ref={(ref) => (this.list = ref)} />
        </Tab.Pane>
      ),
    }
  ];


  accessView() {
      const isAdmin = this.state.isAdmin;
      if(isAdmin === 1){
          return this.adminView
      }else {
          return this.generalView
      }
  }




  setTab = (index) => {
    this.setState({
      currIndex: index || 0,
    });
  }
  
  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="clipboard list"
                content="Catálogo de Maquinarias"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Tab
                menu={{ secondary: true, pointing: true, color: "red" }}
                panes={this.accessView()}
                renderActiveOnly={false}
                activeIndex={this.state.currIndex}
                onTabChange={(event, data) => this.setTab(data.activeIndex)}
                
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(CatalogEquipmentContainer);
