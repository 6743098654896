import Feathers from "../redux/FeathersRedux";
import Config from "../config/index";

export const exportToXls = async (data = [], type = "", filters = {}) => {
  console.log("data", data, filters);
  let xls = await Feathers.service("xls").create({
    data,
    type,
    filters
  });

  let pdfURL = `${
    Config.apiUrl
  }/download_xls?file=${xls}&token=${localStorage.getItem("bingham-jwt")}`;
  window.open(pdfURL, "_blank");
  //window.location.href = `${Config.apiUrl}download_xls?file=${xls}&token=${localStorage.getItem('bingham-jwt')}`
};

export const exportToPdf = async (filename) => {
  //window.location.href = `${Config.apiUrl}/download_pdf?file=${filename}`
  window.open(
    `${
      Config.apiUrl
    }/download_pdf?file=${filename}&token=${localStorage.getItem(
      "bingham-jwt"
    )}`,
    "_blank"
  );
};

String.prototype.capitalizeWords = function() {
  let words = this.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
};
