import React, { Component } from "react";
import {
  Header,
  Segment,
  Grid,
  Icon,
  Form,
  Button,
  Table,
  Modal,
  Label,
  Divider,
  Checkbox,
} from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import Feathers, { services } from "../../../redux/FeathersRedux";
import { TaskStatus } from "../";
import Timer from "../../Timer";
import swal from "sweetalert2";
import moment from "moment";
import VulcanizationDetail from "./VulcanizationDetail";
import VulcanizationTemperature from "./VulcanizationTemperature";
import { connect } from "react-redux";

const MIN_TIME = 6; //25200

class VulcanizationTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishingTask: false,
      selectAll: false,
      pending: [],
      selected: [],
      total: 0,
      page: 0,
      loading: false,
      modalIsOpened: false,
      selectedCount: 0,
      detail: [],
      temperatures: [],
      configuraciones: {
        espera_vulcanizado: null,
      },
      comment: "",
      updatingCommentLoading: false,
      temperatura: "",
    };

    this.nextData = this.nextData.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.renderTaskStatus = this.renderTaskStatus.bind(this);
    this.initVulcanization = this.initVulcanization.bind(this);
    this.renderItemsToChoose = this.renderItemsToChoose.bind(this);
    this.getDetail = this.getDetail.bind(this);
    this.finishTask = this.finishTask.bind(this);
  }

  componentDidMount() {
    if (this.props.task.hora_inicio_vulcanizado) {
      //get detalle vulcanizado
      this.getDetail();
      this.getTemperatures();
    } else {
      this.nextData();
    }

    this.fetchComentario();

    this.fetchConfiguraciones();
  }

  fetchComentario = async (_) => {
    this.setState({
      comment: this.props.task.comentario,
    });
  };

  fetchConfiguraciones = async (_) => {
    let espera_vulcanizado = await this.props.getEspera_Vulcanizado();

    espera_vulcanizado = +espera_vulcanizado.value[0];

    this.setState({
      configuraciones: {
        ...this.state.configuraciones,
        espera_vulcanizado,
      },
    });
  };

  getDetail() {
    Feathers.service("detalle_vulcanizado")
      .find({
        query: {
          vulcanizado_id: this.props.task.id,
        },
      })
      .then((detail) => {
        this.setState({ detail });
      });
  }

  getTemperatures = async () => {
    Feathers.service("detalles_temperatura")
      .find({
        query: {
          tarea_vulcanizado_id: this.props.task.id,
          $limit: "-1",
        },
      })
      .then((res) => {
        console.log(res);
        this.setState({
          temperatures: res.map((e) => {
            return {
              hora: moment(e.hora_captura).format("HH:mm"),
              temperatura: e.temperatura,
              //"presion": e.presion
            };
          }),
        });
      });
  };

  nextData() {
    /**
     *
     * el limit 15 hace que sólo me traiga de 15 en 15 elementos, y como hay mas de 15 y el page no funciona bien
     * entonces me está trayendo nomás 15
     *
     */
    Feathers.service("pendiente_vulcanizado")
      .find({
        query: {
          $limit: "-1",
          // $skip: 15 * this.state.page,
          $sort: {
            rodillo_id: 1,
          },
        },
      })
      .then((res) => {
        // return
        let respuesta = JSON.parse(JSON.stringify(res));

        // si es horno puliuretano, que es la vulcanizadora 3, sólo se podrán elegir poliuretanos para vulcanizar
        if (this.props.task.vulcanizadora_id === 3) {
          respuesta = respuesta.filter((v) => {
            if (
              v.rodillo.partida.producto_id === 3 ||
              v.rodillo.partida.producto_id === 4
            )
              return v;
          });
        } else {
          respuesta = respuesta.filter((v) => {
            if (
              v.rodillo.partida.producto_id !== 3 &&
              v.rodillo.partida.producto_id !== 4
            )
              return v;
          });
        }
        this.setState({
          pending: this.state.pending.concat(respuesta),
          total: res.length,
          loading: false,
          page: this.state.page + 1,
        });
      });
  }

  toggleSelect = (row) => {
    this.setState({
      selectAll: false,
    });
    let pending = [...this.state.pending];
    let count = -1;
    let index = pending.findIndex((p) => p.id === row.id);

    pending[index].selected = !pending[index].selected;

    if (pending[index].selected) count = 1;

    let selected = pending.filter((p) => p.selected);
    let selectedCount = this.state.selectedCount + count;

    this.setState({
      pending,
      selectedCount,
      selected,
      modalIsOpened:
        this.state.modalIsOpened && selectedCount === 0
          ? false
          : this.state.modalIsOpened,
    });
  };

  toggleModal() {
    this.setState({
      modalIsOpened: !this.state.modalIsOpened,
    });
  }

  initVulcanization() {
    swal({
      title: "¿Iniciar proceso de vulcanizado?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (!result.value) return;
      //await this.getTemperature();
      Feathers.service("vulcanizado")
        .patch(this.props.task.id, {
          hora_inicio_vulcanizado: moment().format("YYYY-MM-DD HH:mm:ss"),
          getTemperature: true,
          vulcanizadoraId: this.props.task.vulcanizadora_id,
        })
        .then((res) => {
          Feathers.service("detalle_vulcanizado")
            .create(
              this.state.selected.map((s) => {
                Feathers.service("rodillo").patch(s.rodillo_id, {
                  stage: 4,
                });
                return {
                  vulcanizado_id: this.props.task.id,
                  rodillo_id: s.rodillo_id,
                };
              })
            )
            .then((res) => {
              this.toggleModal();
              this.props.updateTask && this.props.updateTask();
              this.getDetail();
              this.getTemperatures();
            });
        });
    });
  }

  finishTask() {
    if (!this.props.task.hora_inicio_vulcanizado)
      return swal({
        title: "Atención",
        text:
          "Primero debes haber iniciado el proceso de vulcanizado para finalizar la tarea",
        type: "info",
      });

    //7 horas //25200
    if (
      moment().diff(
        moment(this.props.task.hora_inicio_vulcanizado),
        "seconds"
      ) < this.state.configuraciones.espera_vulcanizado
    )
      return swal({
        title: "Atención",
        text: `Debe haber transucurrido al menos ${this.state.configuraciones
          .espera_vulcanizado /
          3600} hora/s del proceso de vulcanización para finalizar la tarea`,
        type: "info",
      });

    this.setState(
      {
        finishingTask: true,
      },
      (_) => {
        this.props.finishTask && this.props.finishTask();
      }
    );
  }

  renderStatus() {
    if (this.props.task.hora_inicio_vulcanizado)
      return (
        <Label color="teal">
          <Icon name="thermometer empty" />
          Vulcanizando
        </Label>
      );

    return (
      <Label color="blue">
        <Icon name="play" /> Iniciada
      </Label>
    );
  }

  renderTaskStatus() {
    return (
      <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
        <Header
          as="h3"
          attached="top"
          inverted
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#393e46",
            borderRadius: "10px",
          }}
        >
          <Icon name="settings" />
          <Header.Content>Estatus de la tarea</Header.Content>
        </Header>
        <Segment size="large">
          <Grid>
            <Grid.Row>
              <Grid.Column computer="4">
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Estatus
                </span>
                {this.renderStatus()}
              </Grid.Column>
              <Grid.Column computer="4">
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Hora inicio
                </span>
                <span>{this.props.task.hora_inicio}</span>
              </Grid.Column>
              <Grid.Column computer="4">
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Hora inicio vulcanizado
                </span>
                <span>{this.props.task.hora_inicio_vulcanizado}</span>
              </Grid.Column>
              <Grid.Column computer="4">
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Contador
                </span>
                <Timer
                  finishTask={this.finishTask}
                  restartTask={() => this.restartTask()}
                  start={this.props.task.hora_inicio_vulcanizado}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }

  handleSelectAll = (checked) => {
    this.setState({
      selectAll: checked,
    });

    let pending = [...this.state.pending];
    let count = 0;

    pending = pending.map((v) => {
      v.selected = checked;
      return v;
    });

    count = checked ? pending.length : 0;

    let selected = pending.filter((p) => p.selected === true);
    let selectedCount = count;

    this.setState({
      pending,
      selectedCount,
      selected,
      modalIsOpened:
        this.state.modalIsOpened && selectedCount === 0
          ? false
          : this.state.modalIsOpened,
    });
  };

  renderItemsToChoose() {
    if (this.props.task.hora_inicio_vulcanizado) return null;

    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <InfiniteScroll
                next={this.nextData}
                hasMore={
                  this.state
                    .loading /* aqui estaba esto this.state.total !== this.state.pending.length pero como ya no se mostrará todo siempre, no funcionará*/
                }
                height={"50vh"}
                loader={<h1>Loading...</h1>}
              >
                <Table selectable striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Checkbox
                          checked={this.state.selectAll}
                          label="Seleccionar todos"
                          onChange={(e, d) => this.handleSelectAll(d.checked)}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>Unidad</Table.HeaderCell>
                      {/* <Table.HeaderCell>SAE</Table.HeaderCell> */}
                      <Table.HeaderCell>Cliente</Table.HeaderCell>
                      <Table.HeaderCell>Tipo</Table.HeaderCell>
                      <Table.HeaderCell>Parte - Mancheta</Table.HeaderCell>
                      <Table.HeaderCell>Material</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.pending.map((p, index) => {
                      return (
                        <Table.Row
                          key={p.id}
                          onClick={() => this.toggleSelect(p, index)}
                        >
                          <Table.Cell>
                            <Icon
                              size="large"
                              color={p.selected ? "green" : ""}
                              name={
                                p.selected ? "check square" : "square outline"
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>{p.rodillo_id}</Table.Cell>
                          {/* <Table.Cell>
                                                {
                                                     p.rodillo.partida.pedido.ref_sae ?  (p.rodillo.partida.pedido.ref_sae) : ("---")
                                                }
                                                </Table.Cell> */}
                          <Table.Cell>
                            {p.rodillo.partida.pedido.cliente.nombre}
                          </Table.Cell>
                          <Table.Cell>
                            {" "}
                            {p.rodillo.partida.producto
                              ? p.rodillo.partida.producto.nombre
                              : "---"}{" "}
                          </Table.Cell>
                          <Table.Cell>
                            {p.rodillo.partida.mancheta
                              ? p.rodillo.partida.mancheta.parte
                              : "---"}
                          </Table.Cell>
                          <Table.Cell>
                            {p.rodillo.partida.material.nombre}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </InfiniteScroll>
            </Grid.Column>
          </Grid.Row>
          {this.state.selectedCount > 0 && (
            <Grid.Row>
              <Grid.Column>
                <Button
                  floated="right"
                  color="green"
                  onClick={this.toggleModal}
                >
                  <div style={{ textAlign: "center" }}>
                    <span>
                      {this.state.selectedCount} rodillos seleccionado(s)
                    </span>
                    <p>Ver rodillos</p>
                  </div>
                </Button>
              </Grid.Column>

              {/* <Grid.Column style={{marginLeft: 15, marginRight: 15, borderRadius: 2}} color={'green'} onClick={this.toggleModal} >
                                    <div style={{textAlign: 'center'}}>
                                        <span>{this.state.selectedCount} rodillos seleccionado(s)</span>
                                        <p>Ver rodillos</p>
                                    </div>
                                </Grid.Column> */}
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  }

  _addComment = async () => {
    await this.setState({
      updatingCommentLoading: true,
    });

    let comentario = await Feathers.service("vulcanizado").patch(
      this.props.task.id,
      {
        comentario: this.state.comment,
      }
    );

    await this.setState({
      updatingCommentLoading: false,
    });
  };

  renderCommentsBox = (_) => {
    return (
      <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
        <Header
          as="h3"
          attached="top"
          inverted
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#393e46",
            borderRadius: "10px",
          }}
        >
          <Icon name="comments" />
          <Header.Content>Área de Comentario</Header.Content>
        </Header>
        <Segment size="large">
          <Form>
            <Form.Field>
              <Form.TextArea
                label={"Comentario"}
                placeholder="Comentario..."
                value={this.state.comment}
                onChange={(_, d) => {
                  this.setState({
                    comment: d.value,
                  });
                }}
              />
            </Form.Field>
            <Form.Field>
              <Form.Button
                icon={"comment outline"}
                loading={this.state.updatingCommentLoading}
                color={"green"}
                content="Actualizar"
                onClick={this._addComment}
              />
            </Form.Field>
          </Form>
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <Segment size={"large"} loading={this.state.finishingTask}>
        <Header as="h2">
          {" "}
          Vulcanizadora:{" "}
          {this.props.task.vulcanizadora
            ? this.props.task.vulcanizadora.nombre
            : ""}{" "}
        </Header>
        {this.renderTaskStatus()}
        {this.renderCommentsBox()}
        {this.renderItemsToChoose()}
        <VulcanizationDetail detail={this.state.detail} />
        <VulcanizationTemperature
          temperatures={this.state.temperatures}
          getTemperature={this.getTemperatures}
        />

        <Modal open={this.state.modalIsOpened} size={"large"}>
          <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
            Rodillos seleccionados
            <Button
              circular
              size="mini"
              basic
              icon="close"
              floated="right"
              onClick={this.toggleModal}
            ></Button>
          </Modal.Header>
          <Modal.Content scrolling>
            <Table striped compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Unidad</Table.HeaderCell>
                  <Table.HeaderCell>SAE</Table.HeaderCell>
                  <Table.HeaderCell>Cliente</Table.HeaderCell>
                  <Table.HeaderCell>Tipo</Table.HeaderCell>
                  <Table.HeaderCell>Parte - Mancheta</Table.HeaderCell>
                  <Table.HeaderCell>Material</Table.HeaderCell>
                  <Table.HeaderCell>Quitar</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.selected.map((p, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{p.rodillo_id}</Table.Cell>
                      <Table.Cell>
                        {p.rodillo.partida.pedido.ref_sae}
                      </Table.Cell>
                      <Table.Cell>
                        {p.rodillo.partida.pedido.cliente.nombre}
                      </Table.Cell>
                      <Table.Cell>
                        {p.rodillo.partida.producto.nombre}
                      </Table.Cell>
                      <Table.Cell>
                        {p.rodillo.partida.mancheta
                          ? p.rodillo.partida.mancheta.parte
                          : "---"}
                      </Table.Cell>
                      <Table.Cell>
                        {p.rodillo.partida.material.nombre}
                      </Table.Cell>

                      <Table.Cell>
                        <Button
                          size="mini"
                          icon="delete"
                          color="red"
                          onClick={() => this.toggleSelect(p)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.toggleModal}>CERRAR</Button>

            <Button primary onClick={this.initVulcanization}>
              PASAR A VULCANIZAR <Icon name="right chevron" />
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    configuraciones: state.services.configuraciones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEspera_Vulcanizado: () =>
      dispatch(
        services.configuraciones.find({
          query: {
            nombre: "espera_vulcanizado",
            $limit: "-1",
          },
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VulcanizationTask);
