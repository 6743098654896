import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Grid, Icon, Search, Label, Form } from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../../redux/FeathersRedux";

import MaterialsInfoModal from "./MaterialsInfoModal";
import MaterialsEditModal from "./MaterialsEditModal";

import { exportToXls } from "../../utils/Utils";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

export default class MaterialsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      materials: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      modalOpened: false,
      editModalOpened: false,
      currentRow: {},
      $sortDirection: 1,
      filterOptions: {
        id: null,
      },

      // Search Bar Results

      nameResults: [],

      // Search Bar Objects

      name: null,
      shouldExport: false,

      // Search Bar Loadings

      loadingName: false,
      columna: "",
      sortDirection: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.openInfoModal = this.openInfoModal.bind(this);
    this.closeInfoModal = this.closeInfoModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.onEditedMaterial = this.onEditedMaterial.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Pegamento":
        array.data.sort((a, b) => {
          const nameA = a.pegamento.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.pegamento.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Densidad":
        array.data.sort((a, b) => {
          let A = a.densidad;
          let B = b.densidad;
          return A - B;
        });
        break;
      case "Costo $USD":
        array.data.sort((a, b) => {
          let A = a.costo;
          let B = b.costo;
          return A - B;
        });
        break;
      case "Precio $USD":
        array.data.sort((a, b) => {
          let A = a.precio ? a.precio : 0;
          let B = b.precio ? b.precio : 0;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData(state = {}) {
    let pageSize = state.pageSize || 100;

    this.setState({ loading: true }, () => {
      let query = {
        $limit: this.state.shouldExport ? "-1" : pageSize,
        $skip: pageSize * state.page,
        $sort: {
          id: -1,
        },
      };

      if (this.state.filterOptions.id !== null) {
        query["id"] = this.state.filterOptions.id;
      }

      Feathers.service("material")
        .find({
          query,
        })
        .then((res) => {
          console.log(res);
          res = this.handleSort(res);
          if (!this.state.shouldExport) {
            this.setState({
              materials: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
          if (this.state.shouldExport) {
            exportToXls(res, "admin_materials");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  }

  openInfoModal(row) {
    this.setState({
      modalOpened: true,
      currentRow: row.original,
    });
  }

  openEditModal(row) {
    this.setState({
      editModalOpened: true,
      currentRow: row.original,
    });
  }

  closeInfoModal() {
    this.setState({
      modalOpened: false,
    });
  }

  closeEditModal() {
    this.setState({
      editModalOpened: false,
    });
  }

  onEditedMaterial() {
    this.fetchData({ page: this.state.page, pageSize: this.state.pageSize });
  }

  handleChange(event, row) {
    Feathers.service("material").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  handleResultSelect = async (e, { result }) => {
    this.setState({ name: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingName: true, name: value });

    setTimeout(() => {
      if (this.state.name.length < 1) return this.resetComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loadingName: false,
            nameResults: res.data,
          });
        });
    }, 500);
  };

  resetComponent = () =>
    this.setState({ loadingName: false, nameResults: [], name: "" });

  handleCleanButton = (_) => {
    this.resetComponent();
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre</label>
                  <Search
                    icon=""
                    loading={this.state.loadingName}
                    value={this.state.name}
                    results={this.state.nameResults}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    resultRenderer={renderSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          //TODO: Agregar a la tabla el precio de venta
          columns={[
            {
              Header: "Nombre",
              id: "nombre",
              accessor: (d) => {
                return d.nombre;
              },
            },
            {
              Header: "Pegamento",
              id: "pegamento",
              accessor: (d) => {
                return d.pegamento.nombre;
              },
            },
            {
              Header: "Densidad",
              id: "densidad",
              accessor: (d) => {
                return d.densidad;
              },
              maxWidth: 100,
            },
            {
              Header: "Costo $USD",
              id: "costo",
              accessor: (d) => {
                return d.costo;
              },
              maxWidth: 100,
            },
            {
              Header: "Precio $USD",
              id: "Precio",
              accessor: (d) => {
                return d.precio ? d.precio : "---";
              },
              maxWidth: 100,
            },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size={"tiny"}
                      basic
                      color="grey"
                      compact
                      icon="eye"
                      onClick={() => this.openInfoModal(row)}
                    />
                    <Button
                      size={"tiny"}
                      basic
                      color="blue"
                      compact
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                  </div>
                );
              },
              maxWidth: 200,
            },
          ]}
          manual
          data={this.state.materials}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState({
              columna: column,
              sortDirection: direction,
            });
            this.fetchData();
          }}
        />
        <MaterialsInfoModal
          isOpened={this.state.modalOpened}
          material={this.state.currentRow}
          onClose={this.closeInfoModal}
        />
        <MaterialsEditModal
          isOpened={this.state.editModalOpened}
          material={this.state.currentRow}
          onClose={this.closeEditModal}
          callback={this.onEditedMaterial}
        />
      </div>
    );
  }
}
