import React, { Component } from "react";
import { Grid, Button, Form } from "semantic-ui-react";
import Notifications, { notify } from "react-notify-toast";
import swal from "sweetalert2";
import Feathers from "../../redux/FeathersRedux";

export default class MaintenanceTypeForm extends Component {
  state = {
    tipo_mantenimiento: {
      nombre: "",
      activo: 1
    },
    loading: false
  };

  handleSubmit = () => {
    if (!this.state.tipo_mantenimiento.nombre) {
      swal({
        title: "El tipo de mantenimiento debe de llevar un nombre",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true
      });
      return;
    }
    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("tipos_mantenimiento")
          .create({
            ...this.state.tipo_mantenimiento
          })
          .then(res => {
            swal({
              title: "Registro éxitoso",
              type: "success",
              confirmButtonText: "ok",
              reverseButtons: true
            });
            this.props.onCreate();
            this.setState({
              loading: false,
              tipo_tarea: {
                nombre: "",
                activo: 1
              }
            });
          })
          .catch(err => {
            notify.show("Se produjo un error, intente de nuevo.", "error");
            this.setState({ loading: false });
          });
      }
    );
  };

  render() {
    return (
      <div>
        <Form size={"large"}>
          <Grid>
            <Grid.Row columns={2} divided>
              <Grid.Column>
                <Form.Input
                  label="Nombre"
                  name="nombre"
                  value={this.state.tipo_mantenimiento.nombre}
                  type="text"
                  placeholder="Nombre"
                  onChange={(e, { name, value }) =>
                    this.setState({
                      tipo_mantenimiento: { ...this.state.tipo_mantenimiento, [name]: value }
                    })
                  }
                  required
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  disabled={this.state.loading}
                  loading={this.state.loading}
                  type="button"
                  onClick={this.handleSubmit}
                  floated="left"
                  positive
                  content="REGISTRAR"
                  icon="send"
                  labelPosition="left"
                  style={{ marginTop: 25 }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}
