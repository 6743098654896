import React, { Component } from "react";
import "react-day-picker/lib/style.css";
import { Header, Grid, Tab } from "semantic-ui-react";
import "moment/locale/es";
import { connect } from "react-redux";
import SolveList from "../components/Incidence/SolveList";
import NotSolveList from "../components/Incidence/NotSolveList";

const panes = [
  {
    menuItem: {
      key: "list",
      icon: "list",
      content: "Incidencias no resueltas",
    },
    pane: (
      <Tab.Pane
        style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
        key={1}
      >
        <NotSolveList />
      </Tab.Pane>
    ),
  },
  {
    menuItem: { key: "list", icon: "list", content: "Incidencias resueltas" },
    pane: (
      <Tab.Pane
        style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
        key={2}
      >
        <SolveList />
      </Tab.Pane>
    ),
  },
];

class IncidencesReportContainer extends Component {
  state = {};
  setTab = (index) => {
    this.setState({
      currIndex: index || 0,
    });
  };
  // renderizado
  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="warning sign"
                content="Reporte de incidencias"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Tab
                menu={{ secondary: true, pointing: true, color: "red" }}
                panes={panes}
                renderActiveOnly={false}
                activeIndex={this.state.currIndex}
                onTabChange={(event, data) => this.setTab(data.activeIndex)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default connect()(IncidencesReportContainer);
