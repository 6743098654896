import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import {
  Button,
  Modal,
  Grid,
  Icon,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import swal from "sweetalert2";
import Toggle from "react-toggle";
import "../react-toggle.css";
import ClientsInfoModal from "./ClientsInfoModal";
import ClientsEditModal from "./ClientsEditModal";
import PropTypes from "prop-types";
import { exportToXls } from "../../utils/Utils";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);
const renderAddressSearchResults = ({ id, direccion }) => (
  <Label key={id} content={direccion} />
);
const renderPhoneSearchResults = ({ id, telefono }) => (
  <Label key={id} content={telefono} />
);
const renderEmailSearchResults = ({ id, email }) => (
  <Label key={id} content={email} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

export default class ClientsList extends Component {
  constructor() {
    super();

    this.state = {
      clients: [],
      total: 0,
      page: 0,
      loading: false,

      currentRow: {},
      // results of the search querys
      results: null,
      resultsAddress: null,
      resultsPhone: null,
      resultsEmail: null,
      // result objects

      client: "",
      address: "",
      phone: "",
      email: "",

      modalOpened: false,
      isEditOpened: false,
      pageSize: 100,
      filterOptions: {
        id: null,
        direccion: null,
        telefono: null,
        email: null,
      },
      // loading of the search bars
      loadingSearch: false,
      loadingAddressSearch: false,
      loadingPhoneSearch: false,
      loadingEmailSearch: false,
      shouldExport: false,

      columna: "",
      sortDirection: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.onClientEdited = this.onClientEdited.bind(this);
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre del Cliente":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Direccion":
        array.data.sort((a, b) => {
          const nameA = a.direccion.toUpperCase(); // ignore upper and lowercase
          const nameB = b.direccion.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Teléfono":
        array.data.sort((a, b) => {
          let nameA = a.telefono ? a.telefono.toUpperCase() : ""; // ignore upper and lowercase
          let nameB = b.telefono ? b.telefono.toUpperCase() : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      // TODO: Email y moneda se ordenan pero no se muestran de manera correcta
      case "Email":
        array.data.sort((a, b) => {
          let nameA = a.email ? a.email.toUpperCase() : ""; // ignore upper and lowercase
          let nameB = b.email ? b.email.toUpperCase() : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Moneda":
        array.data.sort((a, b) => {
          let A = a.moneda;
          let B = b.moneda;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  componentDidMount() {
    // this.fetchData();
  }

  fetchData(state = {}) {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.id) {
      query["id"] = this.state.filterOptions.id;
    }

    if (this.state.filterOptions.email) {
      query["email"] = this.state.filterOptions.email;
    }

    if (this.state.filterOptions.direccion) {
      query["direccion"] = this.state.filterOptions.direccion;
    }

    if (this.state.filterOptions.telefono) {
      query["telefono"] = this.state.filterOptions.telefono;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("cliente")
        .find({
          query,
        })
        .then((res) => {
          res = this.handleSort(res);
          if (!this.state.shouldExport) {
            this.setState({
              clients: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
              shouldExport: false,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "admin_clients");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  }

  onClientEdited() {
    this.fetchData({ page: this.state.page, pageSize: this.state.pageSize });
  }

  handleChange(event, row) {
    Feathers.service("cliente").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  openModal(row) {
    this.setState({
      modalOpened: true,
      currentRow: row.original,
    });
  }

  openEditModal(row) {
    this.setState(
      {
        isEditOpened: true,
        currentRow: row.original,
      },
      () => {}
    );
  }

  closeModal() {
    this.setState({
      modalOpened: false,
    });
  }

  closeEditModal() {
    this.setState({
      isEditOpened: false,
    });
  }

  handleResultSelect = async (e, { result }) => {
    this.setState({ client: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  /* this function is going to be called when an address is selected from the search bar */

  handleAddressResultSelect = async (e, { result }) => {
    this.setState({ address: result.direccion });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        direccion: result.direccion,
      },
    });

    this.fetchData(this.state);
  };

  handlePhoneResultSelect = async (e, { result }) => {
    this.setState({ phone: result.telefono });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        telefono: result.telefono,
      },
    });

    this.fetchData(this.state);
  };

  handleEmailResultSelect = async (e, { result }) => {
    this.setState({ email: result.email });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        email: result.email,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingSearch: true, client: value });

    setTimeout(() => {
      if (this.state.client.length < 1) return this.resetComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingSearch: false,
            results: res.data,
          });
        });
    }, 500);
  };

  handlePhoneSearchChange = (e, { value }) => {
    this.setState({ loadingPhoneSearch: true, phone: value });

    setTimeout(() => {
      if (this.state.phone.length < 1) return this.resetPhoneComponent();

      Feathers.service("cliente")
        .find({
          query: {
            telefono: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingPhoneSearch: false,
            resultsPhone: res.data,
          });
        });
    }, 500);
  };

  handleAddressSearchChange = (e, { value }) => {
    this.setState({ loadingAddressSearch: true, address: value });

    setTimeout(() => {
      if (this.state.address.length < 1) return this.resetAddressComponent();

      Feathers.service("cliente")
        .find({
          query: {
            direccion: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingAddressSearch: false,
            resultsAddress: res.data,
          });
        });
    }, 500);
  };

  handleEmailSearchChange = (e, { value }) => {
    this.setState({ loadingEmailSearch: true, email: value });

    setTimeout(() => {
      if (this.state.email.length < 1) return this.resetEmailComponent();

      Feathers.service("cliente")
        .find({
          query: {
            email: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingEmailSearch: false,
            resultsEmail: res.data,
          });
        });
    }, 500);
  };

  handleCleanButton = async () => {
    this.resetAddressComponent();
    this.resetComponent();
    this.resetPhoneComponent();
    this.resetEmailComponent();

    await this.setState({
      filterOptions: {
        id: null,
      },
    });

    this.fetchData(this.state);
  };

  resetComponent = () =>
    this.setState({ loadingSearch: false, results: [], client: "" });
  resetAddressComponent = () =>
    this.setState({
      loadingAddressSearch: false,
      resultsAddress: [],
      address: "",
    });
  resetPhoneComponent = () =>
    this.setState({ loadingPhoneSearch: false, resultsPhone: [], phone: "" });
  resetEmailComponent = () =>
    this.setState({ loadingEmailSearch: false, resultsEmail: [], email: "" });

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre del Cliente</label>
                  <Search
                    icon=""
                    loading={this.state.loadingSearch}
                    value={this.state.client}
                    results={this.state.results}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    resultRenderer={renderSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Dirección</label>
                  <Search
                    icon=""
                    loading={this.state.loadingAddressSearch}
                    value={this.state.address}
                    results={this.state.resultsAddress}
                    onResultSelect={this.handleAddressResultSelect}
                    onSearchChange={this.handleAddressSearchChange}
                    resultRenderer={renderAddressSearchResults}
                    placeholder="Dirección"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Teléfono</label>
                  <Search
                    icon=""
                    loading={this.state.loadingPhoneSearch}
                    value={this.state.phone}
                    results={this.state.resultsPhone}
                    onResultSelect={this.handlePhoneResultSelect}
                    onSearchChange={this.handlePhoneSearchChange}
                    resultRenderer={renderPhoneSearchResults}
                    placeholder="Teléfono"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Correo</label>
                  <Search
                    icon=""
                    loading={this.state.loadingEmailSearch}
                    value={this.state.email}
                    results={this.state.resultsEmail}
                    onResultSelect={this.handleEmailResultSelect}
                    onSearchChange={this.handleEmailSearchChange}
                    resultRenderer={renderEmailSearchResults}
                    placeholder="Correo"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre del Cliente", accessor: "nombre" },
            { Header: "Direccion", accessor: "direccion" },
            { Header: "Teléfono", accessor: "telefono" },
            { Header: "Email", accessor: "email" },
            {
              Header: "Moneda",
              Cell: (row) => {
                if (row.original.moneda)
                  return (
                    row.original.moneda.nombre + " / " + row.original.moneda.cod
                  );
                return "---";
              },
            },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      basic
                      size={"tiny"}
                      compact
                      color="grey"
                      icon="eye"
                      onClick={() => this.openModal(row)}
                    />
                    <Button
                      basic
                      size={"tiny"}
                      compact
                      color="blue"
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.clients}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state.columna);
              },
              () => {
                console.log(column);
              }
            );
            this.fetchData();
          }}
        />
        <ClientsInfoModal
          isOpened={this.state.modalOpened}
          client={this.state.currentRow}
          onClose={this.closeModal}
        ></ClientsInfoModal>
        <ClientsEditModal
          isOpened={this.state.isEditOpened}
          client={this.state.currentRow}
          onClose={this.closeEditModal}
          callback={this.onClientEdited}
        ></ClientsEditModal>
      </div>
    );
  }
}
