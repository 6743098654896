import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import MainContainer from './containers/MainContainer'
import LoginContainer from './containers/LoginContainer'
import {userIsAuthenticatedRedir, userIsNotAuthenticatedRedir} from './Auth'
import { hot } from 'react-hot-loader'
import Notifications from 'react-notify-toast';

const Login = userIsNotAuthenticatedRedir(LoginContainer);
const Main = userIsAuthenticatedRedir(MainContainer);

class App extends Component {
    render() {
        return (
            <div>
                <Notifications />
                <Switch>
                    <Route exact path="/login" component={Login}/>
                    <Route path="/" component={Main}/>
                </Switch>
            </div>
        )
    }
}


export default hot(module)(App)
