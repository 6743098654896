import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import swal from "sweetalert2";
import Config from "../../config";
import { Label, Button, Icon } from "semantic-ui-react";

export default class ManualView extends Component {
  constructor() {
    super();
    this.state = {
      documents: [],
      total: 0,
      pageSize: 10,
      page: 0,
      loading: false,
    };
  }

  getDocuments = async (state = {}) => {
    const idMaquina = this.props.idMaquina;
    let pageSize = state.pageSize || 5;

    let documents = await Feathers.service("maquinaria_file").find({
      query: {
        maquinaria_id: idMaquina,
        $limit: state.pageSize || 10,
        $skip: pageSize * state.page,
      },
    });
    this.setState({
      total: documents.total,
      documents: documents.data,
      loading: false,
    });
  };

  removeDocument = (file) => {
    swal({
      title: "¿Eliminar este documento?",
      text: "Se eliminará el documento",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        this.setState({ loading: true });
        await Feathers.service("maquinaria_file").remove(file.id);
        swal({
          title: "Exito",
          text: "Se eliminó con éxito el documento",
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        this.getDocuments(this.state);
      }
    });
  };

  render() {
    const isAdmin = this.props.isAdmin;
    return (
      <section style={{fontSize: '16px'}}>
        <Label>
           Total: {this.state.total}
        </Label>
        {isAdmin === 1 ? (
          <ReactTable
            columns={[
              {
                Header: "Manuales",
                id: "document",
                accessor: (d) => {
                  return (
                    <a
                      target="_blank"
                      href={`${Config.apiUrl}/documents/equipments/${d.document}`}
                    >
                      {d.document}
                    </a>
                  );
                },
              },
              {
                Header: "Acciones",
                Cell: (row) => (
                  <Button
                    size="tiny"
                    content="Eliminar"
                    compact
                    basic
                    color="red"
                    icon="delete"
                    onClick={() => this.removeDocument(row.original)}
                  />
                ),
              },
            ]}
            manual
            data={this.state.documents}
            loading={this.state.loading}
            pages={Math.ceil(this.state.total / 5)}
            onFetchData={this.getDocuments}
            defaultPageSize={10}
            style={{ textAlign: "center", marginTop: "1rem" }}
          />
        ) : (
          <ReactTable
            columns={[
              {
                Header: "Manuales",
                id: "document",
                accessor: (d) => {
                  return (
                    <a
                      target="_blank"
                      href={`${Config.apiUrl}/documents/equipments/${d.document}`}
                    >
                      {d.document}
                    </a>
                  );
                },
              },
            ]}
            manual
            data={this.state.documents}
            loading={this.state.loading}
            pages={Math.ceil(this.state.total / 5)}
            onFetchData={this.getDocuments}
            defaultPageSize={10}
            style={{ textAlign: "center", marginTop: "1rem" }}
          />
        )}
      </section>
    );
  }
}
