import React, { Component } from "react";
import {
  Header,
  Segment,
  Grid,
  Icon,
  Button,
  Divider,
} from "semantic-ui-react";
import {
  TaskOrderSegment,
  TaskStatusSegment,
  TaskItemSegment,
  TaskStatus,
} from "../";
import Timer from "../../Timer";
import Incidence from "../../Incidence/Incidence";
import IncidenceViewContainer from "../../../containers/IncidenceViewContainer";

const PHASE = 1;

export default class CheckTask extends Component {
  constructor(props) {
    super(props);
    /* this.state = {
            producto_id: this.props.task.rodillo.partida.producto_id,
        } */
    this.renderItems = this.renderItems.bind(this);
    this.renderInitButton = this.renderInitButton.bind(this);
  }

  renderItems() {
    if (Array.isArray(this.props.task.pedido.partidas)) {
      return this.props.task.pedido.partidas.map((partida) => (
        <TaskItemSegment item={partida} />
      ));
    }

    return <TaskItemSegment item={this.props.task.pedido.partidas} />;
  }

  renderStatus() {
    if (!this.props.task.hora_inicio) return null;

    return (
      <div>
        <Header
          as="h3"
          attached="top"
          inverted
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#393e46",
            borderRadius: "10px",
          }}
        >
          <Icon name="settings" />
          <Header.Content>Estatus de la tarea</Header.Content>
        </Header>
        <Segment size={"large"}>
          <Grid>
            <Grid.Row columns={4}>
              <Grid.Column>
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Estatus
                </span>
                <TaskStatus
                  status={this.props.task.status_tarea_id}
                  text={"Iniciada"}
                />
              </Grid.Column>
              <Grid.Column>
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Hora inicio
                </span>
                <span>{this.props.task.hora_inicio}</span>
              </Grid.Column>
              <Grid.Column>
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Hora Fin
                </span>
                <span>{this.props.task.fin}</span>
              </Grid.Column>
              <Grid.Column>
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Contador
                </span>
                <Timer
                  finishTask={this.props.finishTask}
                  restartTask={() => this.restartTask()}
                  start={this.props.task.hora_inicio}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }

  renderInitButton() {
    if (this.props.task.hora_inicio) return null;

    console.log("res", this.props.task);
    if (this.props.task.pedido.partidas) {
      return (
        <div style={{ marginTop: 20 }}>
          <Button
            onClick={async () => {
              await this.props.finishTask(this.props.task);
              //await this.props.finishTask()
            }}
            icon="play"
            fluid
            color="green"
            content="Iniciar"
          />
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 20 }}>
          <center>
            <p>
              <Icon name="cancel" />
              Se produjó un error al encontrar el producto.
            </p>
          </center>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            {/* <Grid.Column>
              <Header as="h2">
                <Button
                  onClick={this.props.goBack}
                  icon="arrow left"
                  style={{ backgroundColor: "transparent", color: "#000000de" }}
                />
                Tarea de Revisión
              </Header>
            </Grid.Column>
            <Grid.Column> */}
            {/* <IncidenceViewContainer /> */}
            {/* <Incidence
                phase={PHASE}
                task={this.props.task}
                cancelTaskByIncidence={this.props.cancelTaskByIncidence}
                rodillo={this.props.task.id}
                pedido={this.props.task.pedido_id}
              /> */}
            {/* </Grid.Column> */}
          </Grid.Row>
        </Grid>
        <Divider />
        {/* {this.renderStatus()}
        <TaskOrderSegment order={this.props.task.pedido} /> */}

        {/* <Header
          as="h3"
          attached="top"
          inverted
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#393e46",
            borderRadius: "10px",
          }}
        >
          <Icon name="list" />
          <Header.Content>Información de partidas</Header.Content>
        </Header>
        {this.renderItems()}

        {this.renderInitButton()} */}
      </div>
    );
  }
}
