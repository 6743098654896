import React, { Component } from "react";
import { Modal, Grid, Icon, Button, Form, Checkbox, TextArea, Input} from "semantic-ui-react";
import Notifications, { notify } from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";
import swal from "sweetalert2";

export default class RawMaterialsEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      material: {
        orden: "",
        grupo: "",
        quimico: "",
        codigo: "",
        nombre: "",
        costo: null,
        currency: "",
        min: "",
        max: "",
        densidad: 0,
        observaciones: "",
        //url_ficha: ""
      },
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (this.state.material.id !== nextProps.material.id) {
      this.setState({
        material: { ...this.state.material, ...nextProps.material },
      });
    }
  }

  handleSubmit() {
    if (!this.state.material.nombre) {
      notify.show("", "error", 2000);
      swal({
        type: "error",
        title: "Oops...",
        text: "El material debe llevar un nombre",
      });
      return;
    }

    if (!this.state.material.quimico) {
      notify.show("El material debe llevar un quimico", "error", 2000);
      swal({
        type: "error",
        title: "Oops...",
        text: "El material debe llevar un químico",
      });
      return;
    }

    if (!this.state.material.codigo) {
      notify.show("El material debe llevar un codigo", "error", 2000);
      swal({
        type: "error",
        title: "Oops...",
        text: "El material debe llevar un código",
      });
      return;
    }

    if (!this.state.material.costo) {
      notify.show("El material debe llevar un costo", "error", 2000);
      swal({
        type: "error",
        title: "Oops...",
        text: "El material debe llevar un costo",
      });
      return;
    }

    if (!this.state.material.densidad) {
      notify.show("El material debe llevar una densidad", "error", 2000);
      swal({
        type: "error",
        title: "Oops...",
        text: "El material debe llevar una densidad",
      });
      return;
    }

    if (!this.state.material.min) {
      notify.show("El material debe llevar un mínimo", "error", 2000);
      swal({
        type: "error",
        title: "Oops...",
        text: "El material debe llevar un mínimo",
      });
      return;
    }

    if (!this.state.material.max) {
      notify.show("El material debe llevar un máximo", "error", 2000);
      swal({
        type: "error",
        title: "Oops...",
        text: "El material debe llevar un máximo",
      });
      return;
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        if (this.state.material.max > 9999999.999) {
          this.setState({
            loading: false,
          });
          return swal({
            type: "error",
            title: "Oops...",
            text: "El máximo es muy grande",
          });
        }
        Feathers.service("materia_prima")
          .patch(this.state.material.id, {
            orden: +this.state.material.orden || null,
            grupo: this.state.material.grupo,
            quimico: this.state.material.quimico,
            codigo: this.state.material.codigo,
            nombre: this.state.material.nombre,
            costo: this.state.material.costo,
            currency: this.state.material.currency || 'MXN',
            min: this.state.material.min,
            max: this.state.material.max,
            densidad: this.state.material.densidad,
            observaciones: this.state.material.observaciones,
            //url_ficha: this.state.material.url_ficha
          })
          .then((res) => {
            this.props.callback && this.props.callback();

            this.props.onClose && this.props.onClose();

            notify.show("Materia prima actualizada", "success");
            this.setState({ loading: false });
          })
          .catch((err) => {
            notify.show("Se produjo un error", "error");
            this.setState({ loading: false });
          });
      }
    );
  }

  render() {
    return (
      <Modal open={this.props.isOpened} size={"large"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          Editar materia prima
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Form size={"large"}>
            <Grid>
              <Grid.Row columns={2} divided>
                <Grid.Column>
                  <Grid.Row>
                    <Form.Input
                      label="Nombre"
                      name="nombre"
                      value={this.state.material.nombre}
                      type="text"
                      placeholder="Nombre"
                      onChange={(e, { name, value }) =>
                        this.setState({
                          material: { ...this.state.material, [name]: value },
                        })
                      }
                      required
                    />
                    <Form.Input
                      label="Orden"
                      name="orden"
                      value={this.state.material.orden}
                      type="text"
                      placeholder="Orden"
                      onChange={(e, { name, value }) =>
                        this.setState({
                          material: { ...this.state.material, [name]: value },
                        })
                      }
                    />
                    <Form.Input
                      label="Grupo"
                      name="grupo"
                      value={this.state.material.grupo}
                      type="text"
                      placeholder="Grupo"
                      onChange={(e, { name, value }) =>
                        this.setState({
                          material: { ...this.state.material, [name]: value },
                        })
                      }
                    />
                    <Form.Input
                      label="Químico"
                      name="quimico"
                      value={this.state.material.quimico}
                      type="text"
                      placeholder="Químico"
                      onChange={(e, { name, value }) =>
                        this.setState({
                          material: { ...this.state.material, [name]: value },
                        })
                      }
                      required
                    />
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Grid.Row>
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Código"
                        name="codigo"
                        value={this.state.material.codigo}
                        type="text"
                        placeholder="Código"
                        onChange={(e, { name, value }) =>
                          this.setState({
                            material: { ...this.state.material, [name]: value },
                          })
                        }
                        required
                      />
                      <Form.Input
                        label="Densidad"
                        name="densidad"
                        value={this.state.material.densidad ? this.state.material.densidad : 0}
                        type="number"
                        placeholder="densidad"
                        onChange={(e, { name, value }) =>
                          this.setState({
                            material: { ...this.state.material, [name]: value },
                          })
                        }
                        required
                      />
                    </Form.Group>

                    
                    <Form.Group >
                      <Form.Input
                        label="Costo"
                        name="costo"
                        value={this.state.material.costo}
                        type="text"
                        placeholder="Costo"
                        onChange={(e, { name, value }) =>
                          this.setState({
                            material: { ...this.state.material, [name]: value },
                          })
                        }
                        required
                        width={8}
                      />
										<div style={{display: 'flex', marginLeft: '1rem', marginTop: '0'}}>
											<Form.Group inline style={{margin: '0px'}} width={8}>
												<Form.Field >
													<Checkbox
														radio
														label='MXN'
														name='currency'
														value='MXN'
														checked={this.state.material.currency === 'MXN'}
														onChange={(e, {name, value}) => {
															this.setState({material: {...this.state.material, [name]: value} })
														}}
													/>
												</Form.Field>
												<Form.Field>
													<Checkbox
														radio
														label='USD'
														name='currency'
														value='USD'
														checked={this.state.material.currency === 'USD'}
														onChange={(e, {name, value}) => {
															this.setState({material: {...this.state.material, [name]: value} })
														}}
													/>
												</Form.Field>
											</Form.Group>
										</div>
									</Form.Group>
                  
                    
                    
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Mínimo"
                        name="min"
                        value={this.state.material.min}
                        type="number"
                        placeholder="Mínimo"
                        onChange={(e, { name, value }) =>
                          this.setState({
                            material: { ...this.state.material, [name]: value },
                          })
                        }
                        required
                      />
                      <Form.Input
                        label="Máximo"
                        name="max"
                        value={this.state.material.max}
                        type="number"
                        placeholder="Máximo"
                        onChange={(e, { name, value }) =>
                          this.setState({
                            material: { ...this.state.material, [name]: value },
                          })
                        }
                        required
                      />
                    </Form.Group>
                    <Form.Field
                      control={TextArea}
                      label='Observaciones'
                      placeholder='Observaciones sobre esta materia prima'
                      name='observaciones'
                      value={this.state.material.observaciones || ""}
                      onChange={(e, {name, value}) => this.setState({ material: {...this.state.material, [name] : value} })}
                    />
                    {/* <Form.Field
                      control={Input}
                      label='Ficha técnica'
                      placeholder='Adjunte la URL de la ficha técnica de este material'
                      value={this.state.material.url_ficha || ""}
                      name='url_ficha'
                      onChange={(e, {name, value}) => this.setState({ material: {...this.state.material, [name] : value} })}				
                    /> */}
                    <Button
                      disabled={this.state.loading}
                      loading={this.state.loading}
                      type="button"
                      onClick={this.handleSubmit}
                      floated="right"
                      positive
                      content="ACTUALIZAR"
                      icon="send"
                      labelPosition="left"
                    />
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
