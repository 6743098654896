import React, { Component } from 'react'
import {Modal, Grid, Icon, Button, Form, TextArea} from 'semantic-ui-react'
import Notifications, {notify} from 'react-notify-toast';
import Feathers from '../../redux/FeathersRedux'

export default class GluesForm extends Component {
  state = {
    glue: {
      nombre: '',
      descripcion : '',
      activo: 1
    },
    loading: false
  }


  handleSubmit = () =>{
    if (!this.state.glue.nombre) {
      notify.show('El Pegamento debe llevar un nombre', 'error', 2000);
      return;
    }
     this.setState({
            loading: true
        }, () => {
            Feathers.service('pegamento').create({
              ...this.state.glue
            }).then(res => {  
              notify.show('Pegamento Creado', 'success');
              this.props.onCreate()

                this.setState({ loading: false, glue: {
                  nombre: '',
                  descripcion : '',
                  activo: 1
                }});

            }).catch(err => {
                notify.show('Se produjo un error', 'error');
                this.setState({ loading: false })
            })
        })
  }

  render() {
    return (
      <div>
      <Form size={'large'}>
      <Grid>
        <Grid.Row columns={2} divided>
          <Grid.Column>
            <Grid.Row>
              <Form.Input label='Nombre' name='nombre' value={this.state.glue.nombre} type='text' placeholder='Nombre' onChange={(e, {name, value}) => this.setState({ glue: {...this.state.glue, [name] : value} })} required/>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              <Form.Field control={TextArea} label='Descripción' placeholder='Descripción...' name='descripcion' value={this.state.glue.descripcion} onChange={(e, {name, value}) => this.setState({ glue: {...this.state.glue, [name] : value} })}  required/>
              <Button disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='REGISTRAR' icon='send' labelPosition='left' />
            </Grid.Row>
          </Grid.Column>
            
        </Grid.Row>
      </Grid>
    </Form>
      </div>
    )
  }
}
