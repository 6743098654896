import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Button,
  Grid,
  Icon,
  Progress,
  Header,
  Dropdown,
  Form,
  Select,
  Label,
  Input,
  Search,
} from "semantic-ui-react";
import Toggle from "react-toggle";
import Feathers, { feathersAuthentication } from "../redux/FeathersRedux";
import moment from "moment";
import { exportToXls } from "../utils/Utils";

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);
const renderColorSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

export default class CompositeInventoryReportContainer extends Component {
  state = {
    loading: false,
    compuestos: [],
    total: 0,
    page: 0,
    pageSize: 100,
    formulasSelectSettings: {
      value: null,
      options: [],
    },
    $sort: {
      $cantidadSortDirection: null,
    },

    // filter options

    filterOptions: {
      material_id: null,
      color_id: null,
    },

    // search bar object

    material: null,
    color: null,

    // search bar results

    materialResults: null,
    colorResults: null,

    // search bar loadings

    materialLoading: null,
    colorloading: null,
    columna: "",
    sortDirection: false,
  };
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Cantidad":
        array.data.sort((a, b) => {
          return a.cantidad - b.cantidad;
        });
        break;
      case "Color":
        array.data.sort((a, b) => {
          const nameA = a.color.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.color.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fórmula":
        array.data.sort((a, b) => {
          const nameA = a.formula.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.formula.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fórmula - Dureza":
        array.data.sort((a, b) => {
          return a.formula.dureza.valor - b.formula.dureza.valor;
        });
        break;
      case "Fórmula - Material":
        array.data.sort((a, b) => {
          const nameA = a.formula.material.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.formula.material.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  componentDidMount() {
    this.fetchData();
    this.fetchFormulas();
  }

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        material_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleColorResultSelect = async (e, { result }) => {
    this.setState({ color: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        color_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  handleColorSearchChange = (e, { value }) => {
    this.setState({ colorloading: true, color: value });

    setTimeout(() => {
      if (this.state.color.length < 1) return this.resetColorComponent();

      Feathers.service("color")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            colorloading: false,
            colorResults: res.data,
          });
        });
    }, 500);
  };

  resetMaterialComponent = () =>
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  resetColorComponent = () =>
    this.setState({ colorloading: false, colorResults: [], color: "" });

  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      $sort: {
        id: -1,
      },
    };

    if (this.state.formulasSelectSettings.value) {
      query["formula_id"] = this.state.formulasSelectSettings.value;
    }

    if (this.state.$sort.$cantidadSortDirection !== null) {
      query["$sort"] = {
        cantidad: this.state.$sort.$cantidadSortDirection,
      };
    }

    if (this.state.filterOptions.material_id !== null) {
      let formulas_de_ese_material = await Feathers.service("formula").find({
        query: {
          $limit: "-1",
          material_id: this.state.filterOptions.material_id,
        },
      });

      query["formula_id"] = {
        $in: formulas_de_ese_material.map((v) => v.id),
      };
    }

    if (this.state.filterOptions.color_id !== null) {
      query["color_id"] = this.state.filterOptions.color_id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("inventario_compuesto")
        .find({
          query,
        })
        .then((res) => {
          console.log(res.data);
          this.handleSort(res);

          if (!this.state.shouldExport) {
            this.setState({
              compuestos: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "inventory_composite");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };

  fetchFormulas = async () => {
    let formulas = await Feathers.service("formula").find({
      query: {
        $limit: "-1",
      },
    });

    this.setState({
      formulasSelectSettings: {
        ...this.state.formulasSelectSettings,
        options: formulas.map((v) => {
          return {
            text: v.nombre,
            key: v.id,
            value: v.id,
          };
        }),
      },
    });
  };

  _handleFilterClean = async () => {
    this.resetMaterialComponent();
    this.resetColorComponent();
    await this.setState({
      formulasSelectSettings: {
        ...this.state.formulasSelectSettings,
        value: null,
      },
      filterOptions: {
        ...this.state.filterOptions,
        material_id: null,
        color_id: null,
      },
    });

    this.fetchData();
  };

  renderFilterForm = () => {
    return (
      <Form size={"large"}>
        <Form.Group>
          <Form.Field>
            <label>Fórmula</label>
            <Form.Dropdown
              selection
              search
              options={this.state.formulasSelectSettings.options}
              value={this.state.formulasSelectSettings.value}
              onChange={(e, d) => {
                this.setState(
                  {
                    formulasSelectSettings: {
                      ...this.state.formulasSelectSettings,
                      value: d.value,
                    },
                  },
                  (_) => this.fetchData(this.state)
                );
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Materia</label>
            <Search
              icon=""
              loading={this.state.materialLoading}
              value={this.state.material}
              results={this.state.materialResults}
              onResultSelect={this.handleMaterialResultSelect}
              onSearchChange={this.handleMaterialSearchChange}
              resultRenderer={renderMaterialSearchResults}
              placeholder="Materia"
              className="full-width"
            />
          </Form.Field>
          <Form.Field>
            <label>Color</label>
            <Search
              icon=""
              loading={this.state.colorloading}
              value={this.state.color}
              results={this.state.colorResults}
              onResultSelect={this.handleColorResultSelect}
              onSearchChange={this.handleColorSearchChange}
              resultRenderer={renderColorSearchResults}
              placeholder="Color"
              className="full-width"
            />
          </Form.Field>
          <Form.Field>
            <label>Limpiar</label>
            <Button
              basic
              circular
              color="red"
              icon="x"
              onClick={() => {
                this._handleFilterClean();
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Exportar</label>
            <Button
              basic
              color="green"
              circular
              icon="file excel outline"
              onClick={() => {
                this.setState({ shouldExport: true }, () => {
                  this.fetchData(this.state);
                });
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="chart pie" content="Inventario Compuesto" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <this.renderFilterForm />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ReactTable
                columns={[
                  {
                    Header: "Cantidad",
                    id: "cantidad",
                    accessor: (d) => {
                      return String(d.cantidad);
                    },
                  },
                  {
                    Header: "Color",
                    id: "color",
                    accessor: (d) => {
                      if (d.color) return String(d.color.nombre);
                      return "---";
                    },
                  },
                  {
                    Header: "Fórmula",
                    id: "formula",
                    accessor: (d) => {
                      return String(d.formula ? d.formula.nombre : "---");
                    },
                  },
                  {
                    Header: "Fórmula - Dureza",
                    id: "formula-dureza",
                    accessor: (d) => {
                      return String(d.formula ? d.formula.dureza.valor : "---");
                    },
                  },
                  {
                    Header: "Fórmula - Material",
                    id: "formula-material",
                    accessor: (d) => {
                      return String(
                        d.formula ? d.formula.material.nombre : "---"
                      );
                    },
                  },
                ]}
                manual
                data={this.state.compuestos}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState({
                    columna: column,
                    sortDirection: direction,
                  });
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
