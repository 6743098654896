import React from "react";
import { Grid, Button, Form } from "semantic-ui-react";
import swal from "sweetalert2";
import Feathers from "../../redux/FeathersRedux";

export default class ISOCodeForm extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        document: "",
        code: "",
        activo: 1,
      },
      loading: false,
    };
  }

  handleSubmit = () => {
    if (!this.state.data.code || !this.state.data.document) {
      swal({
        title: "Faltan datos por registrar...",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }
    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("codigos_iso")
          .create({
            ...this.state.data,
          })
          .then((res) => {
            swal({
              title: "Registro éxitoso",
              type: "success",
              confirmButtonText: "ok",
              reverseButtons: true,
            });
            this.props.onCreate();
            this.setState({
              loading: false,
              data: {
                document: "",
                code: "",
                activo: 1,
              },
            });
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error.",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true,
            });
            this.setState({ loading: false });
          });
      }
    );
  };

  render() {
    return (
      <>
        <Form size={"large"} onSubmit={this.handleSubmit}>
          <Grid>
            <Grid.Row columns={2} divided>
              <Grid.Column>
                <Form.Input
                  label="Documento"
                  name="document"
                  value={this.state.data.document}
                  type="text"
                  placeholder="Documento"
                  onChange={(e, { name, value }) =>
                    this.setState({
                      data: { ...this.state.data, [name]: value },
                    })
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  label="Código"
                  name="code"
                  value={this.state.data.code}
                  type="text"
                  placeholder="Código"
                  onChange={(e, { name, value }) =>
                    this.setState({
                      data: { ...this.state.data, [name]: value },
                    })
                  }
                />
                <Button
                  disabled={this.state.loading}
                  loading={this.state.loading}
                  type="submit"
                  floated='right'
                  positive
                  content="REGISTRAR"
                  icon="send"
                  labelPosition="left"
                  style={{ marginTop: 25 }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </>
    );
  }
}
