import React, { Component } from "react";
import PropTypes from "prop-types";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import { Button, Checkbox, Grid, Search, Form, Label } from "semantic-ui-react";
import Toggle from "react-toggle";
import FreightEditModal from "./FreightEditModal";
import { exportToXls } from "../../utils/Utils";

const renderTipoFleteSearchResults = ({ id, tipo_flete }) => (
  <Label key={id} content={tipo_flete} />
);

class FreightList extends Component {
  state = {
    freights: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 10,
    currentRow: {},
    isEditOpened: false,
    $sortDirection: 1,
    shouldExport: false,

    filterOptions: {
      id: null,
    },

    // search bar objects

    tipo_flete: null,

    // search bar results

    tipo_fleteResults: null,

    // search Bar loadings

    tipo_fleteLoading: false,
    columna: "",
    sortDirection: false,
  };

  handleTipoFleteResultSelect = async (e, { result }) => {
    this.setState({ tipo_flete: result.tipo_flete });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleTipoFleteSearchChange = (e, { value }) => {
    this.setState({ tipo_fleteLoading: true, tipo_flete: value });

    setTimeout(() => {
      if (this.state.tipo_flete.length < 1)
        return this.resetTipoFleteComponent();

      Feathers.service("flete")
        .find({
          query: {
            tipo_flete: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            tipo_fleteLoading: false,
            tipo_fleteResults: res.data,
          });
        });
    }, 500);
  };

  resetTipoFleteComponent = () =>
    this.setState({
      tipo_fleteLoading: false,
      tipo_fleteResults: [],
      tipo_flete: "",
    });
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Tipo de flete":
        array.data.sort((a, b) => {
          const nameA = a.tipo_flete.toUpperCase(); // ignore upper and lowercase
          const nameB = b.tipo_flete.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Precio":
        array.data.sort((a, b) => {
          const nameA = a.precio.toUpperCase(); // ignore upper and lowercase
          const nameB = b.precio.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 10;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.id !== null) {
      query["id"] = this.state.filterOptions.id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("flete")
        .find({
          query,
        })
        .then((res) => {
          console.log(res.data);
          this.handleSort(res);
          if (!this.state.shouldExport) {
            this.setState({
              freights: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 10,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "admin_freights");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };

  /*     handleChange = (data, row) => {
        Feathers.service('flete').patch(row.original.id, {
            activo: data.checked ? 1: 0
        })
            .then(res => {
                this.fetchData();
            })

    }; */

  handleChange(event, row) {
    Feathers.service("flete").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  openEditModal = (row) => {
    this.setState(
      {
        currentRow: row.original,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isEditOpened: true,
          });
        }, 100);
      }
    );
  };

  closeEditModal = (_) => {
    this.setState({
      isEditOpened: false,
    });
  };

  handleCleanButton = (_) => {
    this.resetTipoFleteComponent();

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Tipo Flete</label>
                  <Search
                    icon=""
                    loading={this.state.tipo_fleteLoading}
                    value={this.state.tipo_flete}
                    results={this.state.tipo_fleteResults}
                    onResultSelect={this.handleTipoFleteResultSelect}
                    onSearchChange={this.handleTipoFleteSearchChange}
                    resultRenderer={renderTipoFleteSearchResults}
                    placeholder="Tipo Flete"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Tipo de flete", accessor: "tipo_flete" },
            {
              Header: "Precio",
              id: "precio",
              accessor: (d) => {
                return "$ " + d.precio;
              },
            },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size={"tiny"}
                      compact
                      basic
                      color="blue"
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                    {/*  <Checkbox toggle onClick={(e, data) => this.handleChange(data, row)} checked={row.original.activo === 1}/> */}
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.freights}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />

        <FreightEditModal
          open={this.state.isEditOpened}
          onEdit={this.fetchData}
          freight={this.state.currentRow}
          onClose={this.closeEditModal}
        />
      </div>
    );
  }
}

FreightList.propTypes = {};

export default FreightList;
