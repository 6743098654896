import React, { Component } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Grid,
  Card,
  Header,
  Segment,
} from "semantic-ui-react";
import Feathers from "../redux/FeathersRedux";
import "react-day-picker/lib/style.css";
import moment from "moment";
import "moment/locale/es";
export default class IndicatorsContainer extends Component {
  state = {
    loading: false,
    dataTimePerTask: [],
    dataTotals: [],
    filter: {
      initDay: "",
      lastDay: "",
    },
  };

  componentDidMount() {
    this.fetchTimePerTask();
    this.fetchTotalsData();
  }


  fetchTotalsData = async () => {
    await this.setState({
      loading: true,
      dataTotals: [],
    });

    let copyData = [...this.state.dataTotals];

    let queryPedido = {};
    let queryUnidad = {};
    let queryIncidencia = {};

   /*  if (this.state.filter.initDay) {
      let fechainicio = moment(this.state.filter.initDay)
        .hour(0)
        .minute(0)
        .second(0);
      fechainicio = fechainicio.format("YYYY-MM-DD HH:mm:ss");
      queryPedido["fecha_entrega"] = {
        $gte: fechainicio,
      };
      queryUnidad["fecha_terminado"] = {
        $gte: fechainicio,
      };

      queryIncidencia["fecha_hora"] = {
        $gte: fechainicio,
      };
    } */

    /* if (this.state.filter.lastDay) {
      let fechafin = moment(this.state.filter.lastDay)
        .hour(22)
        .minute(60)
        .second(60);
      fechafin = fechafin.format("YYYY-MM-DD HH:mm:ss");
      queryPedido["fecha_entrega"] = {
        $lte: fechafin,
      };

      queryUnidad["fecha_terminado"] = {
        $lte: fechafin,
      };

      queryIncidencia["fecha_hora"] = {
        $lte: fechafin,
      };
    } */

    // Pedido

    let pedido_hecho = await Feathers.service("pedido").find({
      query: {
        status_id: 5,
        ...queryPedido,
      },
    });

    /* copyData.push({
      name: "Pedidos Completos",
      uv: pedido_hecho.total,
    }); */

    /*
     * Tenemos que separar los terminados por tipo de producto, asi que vamos a traer todos las partidas de cada uno de los productos
     * */

    // partidas con producto 1 = ahulado de rodillos

    let partidas_de_ahulado_de_rodillos = await Feathers.service(
      "partida"
    ).find({
      query: {
        $limit: "-1",
        producto_id: 1,
      },
    });

    let partidas_de_ahulado_de_rodillos_ids = partidas_de_ahulado_de_rodillos.map(
      (v) => v.id
    );

    // partidas con producto 2 = Rectificado

    let partidas_de_rectificado = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 2,
      },
    });

    let partidas_de_rectificado_ids = partidas_de_rectificado.map((v) => v.id);

    // partidas con producto 3 = poliuretano_pieza

    let partidas_de_poliuretano_pieza = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 3,
      },
    });

    let partidas_de_poliuretano_pieza_ids = partidas_de_poliuretano_pieza.map(
      (v) => v.id
    );

    // partidas con producto 4 = poliuretano rodillo

    let partidas_de_poliuretano_rodillo = await Feathers.service(
      "partida"
    ).find({
      query: {
        $limit: "-1",
        producto_id: 4,
      },
    });

    let partidas_de_poliuretano_rodillo_ids = partidas_de_ahulado_de_rodillos.map(
      (v) => v.id
    );

    // partidas con producto 5 = manchetas

    let partidas_de_manchetas = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 5,
      },
    });

    let partidas_de_manchetas_ids = partidas_de_manchetas.map((v) => v.id);

    // partidas con producto 6 = reparacion espigas

    let partidas_de_reparacion_espigas = await Feathers.service("partida").find(
      {
        query: {
          $limit: "-1",
          producto_id: 6,
        },
      }
    );

    let partidas_de_reparacion_espigas_ids = partidas_de_reparacion_espigas.map(
      (v) => v.id
    );
    // partidas con producto 7 = fabricación de nucleo

    let partidas_de_fabricacion_nucleo = await Feathers.service("partida").find(
      {
        query: {
          $limit: "-1",
          producto_id: 7,
        },
      }
    );

    let partidas_de_fabricacion_nucleo_ids = partidas_de_fabricacion_nucleo.map(
      (v) => v.id
    );
    // partidas con producto 8 = pulido

    let partidas_pulido = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 8,
      },
    });

    let partidas_pulido_ids = partidas_pulido.map((v) => v.id);
    // partidas con producto 9 = ranurado

    let partidas_ranurado = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 9,
      },
    });

    let partidas_ranurado_ids = partidas_ranurado.map((v) => v.id);
    // partidas con producto 10 = corte

    let partidas_corte = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 10,
      },
    });

    let partidas_corte_ids = partidas_corte.map((v) => v.id);
    // partidas con producto 11 = balanceo dinamico

    let partidas_balanceo_dinamico = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 11,
      },
    });

    let partidas_balanceo_dinamico_ids = partidas_balanceo_dinamico.map(
      (v) => v.id
    );
    // partidas con producto 12 = balanceo estático

    let partidas_balanceo_estatico = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 12,
      },
    });

    let partidas_balanceo_estatico_ids = partidas_balanceo_estatico.map(
      (v) => v.id
    );

    // partidas con producto 14 =  fabricacióno embalaje

    let partida_fabricacion_embalaje = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 14,
      },
    });

    let partida_fabricacion_embalaje_ids = partida_fabricacion_embalaje.map(
      (v) => v.id
    );

    // partidas con producto 15 = otros

    let partida_otros = await Feathers.service("partida").find({
      query: {
        $limit: "-1",
        producto_id: 15,
      },
    });

    let partida_otros_ids = partida_otros.map((v) => v.id);

    // vamos a empezar a traer la información y cagarla.

    // AHULADO DE RODILLOs
    let rodillos_hechos = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        ...queryUnidad,
        partida_id: {
          $in: partidas_de_ahulado_de_rodillos_ids,
        },
      },
    });

    copyData.push({
      name: "Rodillo H.",
      uv: rodillos_hechos.total,
    });

    // Rectificado

    let rectificados_hechos = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        ...queryUnidad,
        partida_id: {
          $in: partidas_de_rectificado_ids,
        },
      },
    });

    copyData.push({
      name: "Rectificado",
      uv: rectificados_hechos.total,
    });

    // Poliuretano Pieza

    let poliuretano_pieza_hecho = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        ...queryUnidad,
        partida_id: {
          $in: partidas_de_poliuretano_pieza_ids,
        },
      },
    });

    copyData.push({
      name: "Pol. Pieza.",
      uv: poliuretano_pieza_hecho.total,
    });

    // Poliuretano Rodillo

    let poliuretano_rodillo_hecho = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        ...queryUnidad,
        partida_id: {
          $in: partidas_de_poliuretano_rodillo_ids,
        },
      },
    });

    copyData.push({
      name: "Pol. Rodillo",
      uv: poliuretano_rodillo_hecho.total,
    });

    // MANCHETAS

    let manchetas_hechas = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partidas_de_manchetas_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "Manchetas",
      uv: manchetas_hechas.total,
    });

    // Reparación de Espigas

    let reparacion_espigas_hechas = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partidas_de_reparacion_espigas_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "R. Espigas",
      uv: reparacion_espigas_hechas.total,
    });

    //  Fabricación Nucleo

    let fabricacion_nucleo_hecha = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partidas_de_fabricacion_nucleo_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "F. de Nucleo",
      uv: fabricacion_nucleo_hecha.total,
    });

    // Pulido

    let pulidos_hechos = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partidas_pulido_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "Pulido",
      uv: pulidos_hechos.total,
    });

    // Ranurado

    let ranurados_hechos = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partidas_ranurado_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "Ranurado",
      uv: ranurados_hechos.total,
    });

    // Corte

    let cortes_hechos = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partidas_corte_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "Corte",
      uv: cortes_hechos.total,
    });

    // Balanceo Dinámico

    let balanceo_dinamico_hechos = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partidas_balanceo_dinamico_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "B. Dinámico",
      uv: balanceo_dinamico_hechos.total,
    });

    let balanceo_estatico_hechos = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partidas_balanceo_estatico_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "B. Estático",
      uv: balanceo_estatico_hechos.total,
    });

    // Fabricación embalaje

    let fabricacion_embalaje_hechos = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partida_fabricacion_embalaje_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "F. Embalaje",
      uv: fabricacion_embalaje_hechos.total,
    });

    // otros

    let otros_hechos = await Feathers.service("rodillo").find({
      query: {
        status_tarea_id: 2,
        partida_id: {
          $in: partida_otros_ids,
        },
        ...queryUnidad,
      },
    });

    copyData.push({
      name: "Otros",
      uv: otros_hechos.total,
    });

    // INCIDENCIAS

    let incidencias_hechas = await Feathers.service("incidencia").find({
      query: {
        ...queryIncidencia,
      },
    });

    /* copyData.push({
      name: "Incidencias",
      uv: incidencias_hechas.total,
    }); */

    console.log(copyData, 'totales');
    return this.setState({
      loading: false,
      dataTotals: copyData,
    });

  };

  fetchTimePerTask = async () => {
    await this.setState({
      loading: true,
      dataTimePerTask: [],
    });

    let queryFechas = {};

    if (this.state.filter.initDay) {
      let fechainicio = moment(this.state.filter.initDay)
        .hour(0)
        .minute(0)
        .second(0);
      fechainicio = fechainicio.format("YYYY-MM-DD HH:mm:ss");

      queryFechas["fechainicio"] = fechainicio;
    }

    if (this.state.filter.lastDay) {
      let fechafin = moment(this.state.filter.lastDay)
        .hour(22)
        .minute(60)
        .second(60);
      fechafin = fechafin.format("YYYY-MM-DD HH:mm:ss");
      queryFechas["fechafin"] = fechafin;
    }

    Feathers.service("indicadores")
      .find({
        query: {
          etapa: 1,
          ...queryFechas,
        },
      })
      .then((res) => {
        let copyData = [...this.state.dataTimePerTask];
        copyData.push(res);
        this.setState({
          dataTimePerTask: copyData,
        });
      });

    Feathers.service("indicadores")
      .find({
        query: {
          etapa: 2,
          ...queryFechas,
        },
      })
      .then((res) => {
        let copyData = [...this.state.dataTimePerTask];
        copyData.push(res);
        this.setState({
          dataTimePerTask: copyData,
        });
      });

    Feathers.service("indicadores")
      .find({
        query: {
          etapa: 3,
          ...queryFechas,
        },
      })
      .then((res) => {
        let copyData = [...this.state.dataTimePerTask];
        copyData.push(res);
        this.setState({
          dataTimePerTask: copyData,
        });
      });

    Feathers.service("indicadores")
      .find({
        query: {
          etapa: 4,
          ...queryFechas,
        },
      })
      .then((res) => {
        let copyData = [...this.state.dataTimePerTask];
        copyData.push(res);
        this.setState({
          dataTimePerTask: copyData,
        });
      });

    // Feathers.service('indicadores').find({
    //     query : {
    //         etapa : 5,
    //         ...queryFechas
    //     }
    // }).then(res => {
    //         let copyData = [...this.state.dataTimePerTask]
    //         copyData.push(res)
    //         this.setState({
    //             dataTimePerTask: copyData
    //         })
    // })

    Feathers.service("indicadores")
      .find({
        query: {
          etapa: 6,
          ...queryFechas,
        },
      })
      .then((res) => {
        let copyData = [...this.state.dataTimePerTask];
        copyData.push(res);
        this.setState({
          dataTimePerTask: copyData,
        });
      });

    Feathers.service("indicadores")
      .find({
        query: {
          etapa: 7,
          ...queryFechas,
        },
      })
      .then((res) => {
        let copyData = [...this.state.dataTimePerTask];
        copyData.push(res);
        this.setState({
          dataTimePerTask: copyData,
        });
      });

    Feathers.service("indicadores")
      .find({
        query: {
          etapa: 8,
          ...queryFechas,
        },
      })
      .then((res) => {
        let copyData = [...this.state.dataTimePerTask];
        copyData.push(res);
        this.setState({
          // loading: false,
          dataTimePerTask: copyData,
        });
      });
    Feathers.service("indicadores")
      .find({
        query: {
          etapa: 9,
          ...queryFechas,
        },
      })
      .then((res) => {
        let copyData = [...this.state.dataTimePerTask];
        copyData.push(res);
        this.setState({
          // loading: false,
          dataTimePerTask: copyData,
        });
      });
    Feathers.service("indicadores")
      .find({
        query: {
          etapa: 10,
          ...queryFechas,
        },
      })
      .then((res) => {
        let copyData = [...this.state.dataTimePerTask];
        copyData.push(res);
        this.setState({
          // loading: false,
          dataTimePerTask: copyData,
        });
      });
  };

  renderCardsTimePerTask = () => {
    return this.state.dataTimePerTask.map((v, i) => {
      return (
        <Card key={i} color="red">
          <Card.Content>
            <Card.Header>{v.name}</Card.Header>
            <Card.Meta>Tiempo en horas</Card.Meta>
            <Card.Description>{v.minutos} min</Card.Description>
          </Card.Content>
        </Card>
      );
    });
  };

  renderChartTimePerTask = () => {
    return (
      <div
        style={{
          width: "100%",
          height: 250,
          marginBottom: '2rem'
        }}
      >
        <h3>Promedios por tarea</h3>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={this.state.dataTimePerTask}>
            <Tooltip />
            <Line type="monotone" dataKey="minutos" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" scaleToFit="true" textAnchor="middle" />
            <YAxis />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  renderChartTotals = () => {
    return (
      <div
        style={{
          width: "100%",
          height: 250,
          marginBottom: '2rem'
        }}
      >
        <h3>Partidas totales por productos</h3>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={this.state.dataTotals}>
            <Bar dataKey="uv" fill="#ef9a9a" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" scaleToFit />
            <YAxis />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  renderTotalsCards = () => {
    return this.state.dataTotals.map((v, i) => {
      return (
        <Card key={i} color="red">
          <Card.Content>
            <Card.Header>{v.name}</Card.Header>
            <Card.Meta>Totales existentes</Card.Meta>
            <Card.Description>{v.uv}</Card.Description>
          </Card.Content>
        </Card>
      );
    });
  };
  
  _handleFilter = () => {
    this.fetchTotalsData();
    this.fetchTimePerTask();
  };

  _handleCleanFilter = () => {
    this.setState({
      filter: {
        initDay: "",
        lastDay: "",
      },
    });
    this.fetchTotalsData();
    this.fetchTimePerTask();
  };

  render() {
    return (
      <Segment loading={this.state.loading}>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="pin" content="Indicadores" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderChartTotals()}</Grid.Column>
          </Grid.Row>
          {/* <Grid.Row>
            <Grid.Column>{this.renderChartTimePerTask()}</Grid.Column>
          </Grid.Row> */}
          <Grid.Row>
            <Grid.Column>
              <Card.Group itemsPerRow={6}>{this.renderTotalsCards()}</Card.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
