import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import configureStore, {history} from './redux'
import {feathersAuthentication, feathersServices} from './redux/FeathersRedux'
import Config from './config'
import 'react-dates/initialize';

const store = configureStore();

if (localStorage[Config.jwtToken]) {
	store.dispatch(feathersAuthentication.authenticate())
		.catch(err => {
			console.log('authenticate catch', err); // eslint-disable-line no-console
			return err;
	  	});
}

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App services={feathersServices}/>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
);
registerServiceWorker();
