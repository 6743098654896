import React, { Component } from "react";
import { Modal, Form, Grid, Table, Button, Dropdown } from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import AddRawMaterial from "./AddRawMaterial";
import swal from "sweetalert2";
import Notifications, { notify } from "react-notify-toast";

export default class FormulasEditModal extends Component {
  constructor() {
    super();

    this.state = {
      //colorsOptions: [],
      rawMaterials: [],
      nombre: "",
      densidad: 0,
      kilosMolienda: 0,
      color_id: "",
      product_id: "",
      modalVisible: false,
      hasChanges: false,
      formulaHasChanges: false,
      colorHasChanges: false,
      productHasChanges: false,
      colorsOptions: {},
      materials: [],
      products: [],
      productsFormula: [],

      hardnesses: [],
      dureza_id: "",
      dureza: "",
    };

    this.getRawMaterials = this.getRawMaterials.bind(this);
    this.removeRawMaterial = this.removeRawMaterial.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.addRawMaterial = this.addRawMaterial.bind(this);
    this.patch = this.patch.bind(this);

    //this.fetchColor = this.fetchColor.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.formula) {
      Feathers.service('formula_productos').find({
        query: {
          formula_id: nextProps.formula.id
        }
      })
      .then(res => {
        const products = res.data.map((v, i) => {
          return v.producto_id
        })

        this.setState({
          productsFormula: products
        })
      })

      this.setState({
        nombre: nextProps.formula.nombre,
        densidad: nextProps.formula.densidad,
        kilosMolienda: nextProps.formula.kilos_molienda,
        color_id: nextProps.formula.color_id,
        product_id: nextProps.formula.product_id,
        dureza_id: nextProps.formula.dureza_id,
        formulaHasChanges: false,
        colorHasChanges: false,
        productHasChanges: false,
        hasChanges: false,
      });

      this.getRawMaterials(nextProps.formula.id);
    }
  }

  getRawMaterials(formula_id) {
    Feathers.service("formula_materia_prima")
      .find({
        query: { formula_id },
      })
      .then((rawMaterials) => {
        this.setState({ rawMaterials });
      });
  }

  removeRawMaterial(index) {
    let rawMaterials = [...this.state.rawMaterials];

    rawMaterials.splice(index, 1);

    this.setState({ rawMaterials, hasChanges: true });
  }

  fetchColor = async () => {
    let colors = await Feathers.service("color").find({
      query: {
        activo: 1,
        $limit: "-1",
      },
    });

    let colorsOptions = colors.map((c) => {
      return {
        key: c.id,
        text: c.nombre,
        value: c.id,
      };
    });

    this.setState({
      colorsOptions,
    });
  };

  fetchProducts = async () => {
    await Feathers.service("producto")
      .find({
        query: {
          $limit: "-1",
        },
      })
      .then((products) =>
        this.setState({
          products: products.map((p) => {
            return {
              value: p.id,
              text: p.nombre,
            };
          }),
        })
      );
  };

  fetchHardnesses() {
    Feathers.service("dureza")
      .find({
        query: {
          $limit: "-1",
        },
      })
      .then((hardnesses) =>
        this.setState({
          hardnesses: hardnesses.map((h) => {
            return {
              value: h.id,
              text: h.valor,
            };
          }),
        })
      );
  }


  toggleModal() {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  }

  addRawMaterial(result) {
    let rawMaterials = [...this.state.rawMaterials];

    let exists = rawMaterials.find(
      (rm) => rm.materia_prima_id === result.materia_prima.id
    );

    if (exists) {
      exists.partes += result.partes;
    } else {
      rawMaterials.push({
        materiaPrima: result.materia_prima,
        materia_prima_id: result.materia_prima.id,
        partes: result.partes,
      });
    }

    this.setState({ rawMaterials, hasChanges: true });
  }

  patch() {
    swal({
      title: "¿Actualizar fórmula?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (!result.value) return;

      try {
        let formula = await Feathers.service("formula").patch(
          this.props.formula.id,
          {
            nombre: this.state.nombre,
            color_id: this.state.color_id,
            densidad: this.state.densidad,
            kilos_molienda: this.state.kilosMolienda,
            //product_id: this.state.product_id,
            products: this.state.productsFormula,
            dureza_id: this.state.dureza_id
          }
        );

        if (this.state.hasChanges) {
          await Feathers.service("formula_materia_prima").remove(null, {
            query: {
              formula_id: formula.id,
            },
          });

          await Feathers.service("formula_materia_prima").create(
            this.state.rawMaterials.map((rm) => {
              return {
                formula_id: formula.id,
                materia_prima_id: rm.materia_prima_id,
                partes: rm.partes,
              };
            })
          );
        }

        this.setState({
          hasChanges: false,
          formulaHasChanges: false,
          colorHasChanges: false,
          productHasChanges: false,
        });

        swal({
          type: "success",
          title: "Formula actualizada",
          text: "¡La formula se actualizó correctamente!",
        });

        this.props.callback();
        this.props.onClose();
      } catch (error) {
        //MENSAJE DE ERROR
        //notify.show("Se produjo un error al actualizar la fórmula", "error");
        swal({
          type: "error",
          title: "Ha ocurrido un error",
          text: "¡La formula no se pudo actualizar correctamente!",
        });
      }
    });
  }

  componentDidMount() {
    this.fetchColor();
    this.fetchProducts();
    this.fetchHardnesses();
  }

  setDureza(value) {
    let hardness = this.state.hardnesses.find((h) => h.value === value);

    this.setState({
      dureza_id: value,
      nombre: this.props.formula.material.nombre + " " + hardness.text,
      dureza: hardness.text,
      formulaHasChanges: true,
    });
  }

  render() {
    if (!this.props.formula) return null;

    return (
      <Modal open={this.props.open} size={"large"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          {this.props.formula.nombre}
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content scrolling>
          <div>
            <Form size={"large"}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4} largeScreen={4} mobile={16}>
                    <Form.Input
                      required
                      onChange={(e, { name, value }) =>
                        this.setState({
                          nombre: value,
                          formulaHasChanges: true,
                        })
                      }
                      label="Nombre"
                      value={this.state.nombre}
                      placeholder="Nombre de la fórmula"
                    />
                  </Grid.Column>
                  <Grid.Column width={4} largeScreen={4} mobile={16}>
                    <Form.Input
                      required
                      onChange={(e, { name, value }) =>
                        this.setState({
                          densidad: value,
                          formulaHasChanges: true,
                        })
                      }
                      label="Densidad"
                      value={this.state.densidad ? this.state.densidad : 0}
                      placeholder="Densidad de la fórmula"
                    />
                  </Grid.Column>
                  <Grid.Column width={4} largeScreen={4} mobile={16}>
                    <Form.Input
                      required
                      onChange={(e, { name, value }) =>
                        this.setState({
                          kilosMolienda: value,
                          formulaHasChanges: true,
                        })
                      }
                      label="KG molienda"
                      value={this.state.kilosMolienda ? this.state.kilosMolienda : 0}
                      placeholder="KG molienda"
                    />
                  </Grid.Column>
                  
                  <Grid.Column width={4} largeScreen={4} mobile={16}>
                      <Form.Dropdown
                        required
                        onChange={(e, { name, value }) => this.setDureza(value)}
                        label="Dureza"
                        placeholder="Dureza"
                        options={this.state.hardnesses}
                        selection
                        fluid
                        value={this.state.dureza_id}
                      />
                    {/* <Form.Input
                      className="input-readOnly"
                      readOnly
                      label="Dureza"
                      value={this.props.formula.dureza.valor}
                    /> */}
                  </Grid.Column>
                  
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4} largeScreen={4} mobile={16}>
                    <Form.Input
                      className="input-readOnly"
                      readOnly
                      label="Material"
                      value={this.props.formula.material.nombre}
                    />
                  </Grid.Column>
                  <Grid.Column width={4} largeScreen={4} mobile={16}>
                    <Form.Dropdown
                      required
                      onChange={(e, { name, value }) =>
                        this.setState({
                          color_id: value,
                          formulaHasChanges: true,
                        })
                      }
                      label="Color de la formula"
                      placeholder="Color"
                      options={this.state.colorsOptions}
                      selection
                      fluid
                      value={this.state.color_id}
                    />
                  </Grid.Column>
                  <Grid.Column width={6} largeScreen={8} mobile={16}>
                    <Form.Input label="Productos para esta formula">
                      <Dropdown
                        required
                        placeholder="Seleccione los productos de esta formula"
                        fluid
                        multiple
                        selection
                        options={this.state.products}
                        value={this.state.productsFormula}
                        onChange={(e, { value }) =>
                          this.setState({
                            error: false,
                            productsFormula: [...value],
                            formulaHasChanges: true, 
                          })
                        }
                      />
                    </Form.Input>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>

            <Button
              style={{ marginTop: 15 }}
              color="blue"
              icon="add"
              fluid
              onClick={this.toggleModal}
              content="Agregar materia prima"
            />

            <Table compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Materia prima</Table.HeaderCell>
                  <Table.HeaderCell>Partes</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.rawMaterials.map((rm, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {rm.materiaPrima.nombre +
                          " - " +
                          rm.materiaPrima.quimico}
                      </Table.Cell>
                      <Table.Cell>{rm.partes}</Table.Cell>
                      <Table.Cell>
                        <Button
                          onClick={() => this.removeRawMaterial(i)}
                          compact={true}
                          negative
                          size={"tiny"}
                          icon={"trash"}
                        ></Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button
            onClick={this.patch}
            color="green"
            icon="refresh"
            content="GUARDAR CAMBIOS"
            disabled={!this.state.hasChanges && !this.state.formulaHasChanges}
          />
        </Modal.Actions>

        <AddRawMaterial
          closeOnAdd={true}
          open={this.state.modalVisible}
          toggleModal={this.toggleModal}
          add={this.addRawMaterial}
        />
      </Modal>
    );
  }
}
