import React, {Component} from 'react';
import {Button, Form, Grid, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {notify} from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";
import swal from 'sweetalert2';


class ColorEditModal extends Component {


    state = {
        color: {
            nombre:"",
        },
        toEdit: 0,
        loading: false
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.color) {
            this.setState({
                color: {
                    nombre: nextProps.color.nombre,
                },
                toEdit: nextProps.color.id
            })
        }

    }

    handleSubmit = _ =>{
        if (!this.state.color.nombre) {
            notify.show('Hace falta el nombre', 'error', 2000);
            swal({
                type: 'error',
                title: 'Oops...',
                text: 'Hace falta el nombre',
            })
            return;
        }

        this.setState({
            loading: true
        }, () => {
            Feathers.service('color').patch( this.state.toEdit , {
                ...this.state.color
            }).then(async res => {
                await this.setState({
                    loading: false
                });
                await this.props.onEdit();
                await this.props.onClose();
            }).catch(err => {
                notify.show('Se produjo un error', 'error');
                this.setState({ loading: false })
            })
        })
    };

    render() {
        return (
            <Modal  open={this.props.open}  size={'small'}>
                <Modal.Header style={{backgroundColor: "#f3f3f3"}}>Editar
                <Button circular size='mini' basic icon='close' floated='right' onClick={this.props.onClose}></Button>
                </Modal.Header>
                <Modal.Content scrolling>
                    <Form size={'large'}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Grid.Row>
                                        <Form.Input label='Nombre' name='nombre' value={this.state.color.nombre} type='text' placeholder='Nombre' onChange={(e, {name, value}) => this.setState({ color: {...this.state.color, [name] : value} })} required/>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Button style={{
                                            marginTop: 10,
                                        }} disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='ACTUALIZAR' icon='send' labelPosition='left' />
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}

ColorEditModal.propTypes = {};

export default ColorEditModal;
