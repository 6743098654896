import React, { Component } from "react";
import GridColumn, {
  Segment,
  Icon,
  Grid,
  Button,
  Header,
  Label,
  Form,
  Table,
  Input,
  Divider,
  Loader,
} from "semantic-ui-react";
import Timer from "../../Timer";
import Feathers, { services } from "../../../redux/FeathersRedux";
// import './WindmillTask.css'
import swal from "sweetalert2";
import moment from "moment";
import Incidence from "../../Incidence/Incidence";
import { TaskOrderSegment, TaskStatusSegment, TaskItemSegment } from "../";
import { connect } from "react-redux";
import { serial_connectPort } from "../../../utils/serial";
import Toggle from "react-toggle";

const MIN_TIME = 60; //7200
const PHASE = 2;
const TOL1 = 1;
const TOL2 = -1;
class WindmillTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingP1: false,
      task: props.task,
      rawMaterials: [],
      hardness: null,
      manualMeasure: false,
      sumPartes: 0,
      configuraciones: {
        espera_molino: null,
        tolerance: null,
      },
      serialPort: null,
      buttonIndex: null,
      rawMaterialsTotal: 0,
      weighedButtons: [],
      rodillo: null,
    };

    this.renderItems = this.renderItems.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.renderPhase1 = this.renderPhase1.bind(this);
    this.renderPhase3 = this.renderPhase3.bind(this);
    this.calculateKilos = this.calculateKilos.bind(this);
    this.updateDetail = this.updateDetail.bind(this);
    this.checkDisabled = this.checkDisabled.bind(this);
    this.renderTimer = this.renderTimer.bind(this);
    this.endPhase1 = this.endPhase1.bind(this);
    this.endPhase2 = this.endPhase2.bind(this);
    this.endPhase3 = this.endPhase3.bind(this);
    this.saveHardness = this.saveHardness.bind(this);
    this.connectScale = this.connectScale.bind(this);
    this.closeScaleConnection = this.closeScaleConnection.bind(this);
    this.weighScale = this.weighScale.bind(this);
    this.updateDetailManual = this.updateDetailManual.bind(this);
    this.cleanScaleResponse = this.cleanScaleResponse.bind(this);
  }

  componentWillUnmount() {
    if (this.state.serialPort) {
      this.closeScaleConnection();
    }
  }

  cleanScaleResponse = (value) => {
    console.log("res", value)
    let match = value.replace(/ /g, '')
    console.log("return", match)
    return match;
  };

  getToleranceWeigh = (tolerance, toBeWeigh) => {
    return toBeWeigh * (tolerance / 100)
  }

  // connect scale
  async connectScale() {
    let serial = await navigator.serial.requestPort();
    let baud = 9600; // Change this value according to weighing scale specifications
    const port = await serial_connectPort(serial, baud);

    // port.open({
    //   baudRate: 9600,
    //   dataBits: 8,
    //   stopBits: 1,
    //   parity: `none`,
    //   flowControl: `none`,
    // });

    this.setState({
      serialPort: port,
    });
  }

  async weighScale(index) {
    let port = this.state.serialPort;
    let response = await port.send("P");
    let value = this.cleanScaleResponse(response[0]);
    console.log("weight", value)
    this.updateDetail(index, value);
  }

  // close scale connection
  async closeScaleConnection() {
    let port = this.state.serialPort;
    port.close();
    this.setState({ serialPort: null });
  }

  async componentDidMount() {
    // console.log("task", this.props.task);
    // Get rodillo info since molino table doesn't store its id
    await Feathers.service("rodillo")
      .find({
        query: {
          partida_id: this.props.task.partida_id,
        },
      })
      .then((res) => {
        console.log("res", res.data[0]);
        // check if found rodillo exists
        if (res.data) {
          this.setState({
            rodillo: res.data[0],
          });
        }
      });
    //Si se esta en fase 1, ir por las materias primas
    if (!this.props.task.hora_fin_fase1 && !this.props.task.hora_fin_fase2) {
      this.getRawMaterials();
    }

    this.fetchConfiguraciones();
    //console.log(this.props.task, 'info de esta tarea')
  }

  fetchConfiguraciones = async (_) => {
    let espera_molino = await this.props.getEspera_Molino();
    let tolerance =
      this.props.getTolerance && (await this.props.getTolerance());

    espera_molino = espera_molino.value[0];

    this.setState({
      configuraciones: {
        ...this.state.configuraciones,
        espera_molino,
        tolerance: tolerance,
      },
    });
  };

  componentWillReceiveProps(nextprops) {
    if (nextprops.task) {
      this.setState({
        task: nextprops.task,
      });
      this.getRawMaterials();
    }
  }

  renderInitButton = () => {
    if (this.props.task.hora_inicio) return null;

    return (
      <div style={{ marginTop: 20 }}>
        <Button
          onClick={() => this.props.initTask(this.props.task)}
          icon="play"
          fluid
          color="blue"
          content="Iniciar"
          style={{ backgroundColor: "#0098f0" }}
        />
      </div>
    );
  };

  async getRawMaterials() {
    let item = this.props.task.partida;

    try {
      let res = await Feathers.service("formula").find({
        query: {
          dureza_id: item.dureza_id,
          material_id: item.material_id,
        },
      });

      let formula = res.data[0];

      if (formula) {
        let rawMaterials = await Feathers.service("formula_materia_prima").find(
          {
            query: { formula_id: formula.id },
          }
        );
        const partes = rawMaterials.map((i) => +i.partes);
        const reducer = (accumulator, currentValue) =>
          accumulator + currentValue;
        const sum = partes.reduce(reducer).toFixed(2);
        this.setState({
          rawMaterials,
          sumPartes: sum,
          rawMaterialsTotal: rawMaterials.length,
        });
      }
    } catch (e) { }
  }

  renderStatus() {
    if (!this.state.task.hora_fin_fase1)
      return <Label color="violet">Fase 1</Label>;
    if (!this.state.task.hora_fin_fase2)
      return <Label color="blue">Fase 2</Label>;

    return <Label color="teal">Fase 3</Label>;
  }

  calculateKilos(part) {
    //return ((part / 230.7) * this.props.task.kilos).toFixed(3);
    return ((this.props.task.kilos / this.state.sumPartes) * part).toFixed(2);
  }



  updateDetail(index, value) {
    let rawMaterials = [...this.state.rawMaterials];
    rawMaterials[index].peso = parseFloat(value);
    console.log("value", value)
    let kilos = parseFloat(value);
    console.log("kilos", kilos)
    let toBeWeigh = this.calculateKilos(rawMaterials[index].partes);
    let buttonsArray = this.state.weighedButtons;
    let tolerance = parseFloat(
      this.state.configuraciones.tolerance.value.data[0].valor
    );

    // USE PERCENT CONDITION
    // let tolerancePercent = this.getToleranceWeigh(parseFloat(toBeWeigh), tolerance)

    // check if weight is between tolerance
    if (
      kilos > parseFloat(toBeWeigh) - tolerance &&
      kilos < parseFloat(toBeWeigh) + tolerance
    ) {
      buttonsArray.push(index);
      this.setState({
        rawMaterials,
        buttonIndex: null,
        weighedButtons: buttonsArray,
      });
    } else {
      this.setState({
        buttonIndex: index,
      });
    }
  }

  updateDetailManual(index, value) {
    let buttonsArray = this.state.weighedButtons;
    buttonsArray.push(index);

    let rawMaterials = [...this.state.rawMaterials];
    rawMaterials[index].peso = value;
    let weights = rawMaterials.map((material) => {
      return material.peso;
    });

    this.setState(
      {
        rawMaterials,
        weighedButtons: buttonsArray,
      },
      () => { }
    );
  }

  checkDisabled() {
    return !this.state.rawMaterials.every(
      (material) => material.peso && material.peso != 0
    );
  }

  endPhase1() {
    swal({
      title: "¿Registrar pesos?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        if (this.state.serialPort) {
          this.closeScaleConnection();
        }
        this.setState(
          {
            loadingP1: true,
          },
          (_) => {
            Feathers.service("detalle_molino")
              .create(
                this.state.rawMaterials.map((detail) => {
                  return {
                    molino_id: this.props.task.id,
                    materia_prima_id: detail.materia_prima_id,
                    peso: detail.peso,
                  };
                })
              )
              .then((res) => {
                Feathers.service("molino")
                  .patch(this.props.task.id, {
                    hora_fin_fase1: moment().format("YYYY-MM-DD HH:mm:ss"),
                  })
                  .then((molino) => {
                    this.setState({
                      loadingP1: false,
                      task: {
                        ...this.state.task,
                        hora_fin_fase1: molino.hora_fin_fase1,
                      },
                    });
                  });
              });
          }
        );
      }
    });
  }

  endPhase2() {
    swal({
      title: "¿Terminar fase de molienda?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (!res.value) return;

      Feathers.service("molino")
        .patch(this.props.task.id, {
          hora_fin_fase2: moment().format("YYYY-MM-DD HH:mm:ss"),
        })
        .then((molino) => {
          this.setState({
            task: { ...this.state.task, hora_fin_fase2: molino.hora_fin_fase2 },
          });
        });
    });
  }

  async saveHardness() {
    const dureza_esperada = +this.state.task.partida.dureza.valor;

    const dureza_introducida = +this.state.hardness;

    let holgura = await Feathers.service("configuraciones").find({
      query: {
        nombre: "holgura_dureza",
      },
    });

    holgura = +holgura.data[0].valor;

    swal({
      title: "¿Capturar dureza de tapones?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (!res.value) return false;

      if (
        dureza_introducida < dureza_esperada - holgura ||
        dureza_introducida > dureza_esperada + holgura
      ) {
        //console.log("esta pasando los limites");
        await swal({
          type: "error",
          title: "Oops...",
          text:
            "Los valores no coninciden. Se tendrá que levantar una incidencia",
        });
        await this.incidence.getWrappedInstance().openFromOutside();
        return; //console.log("Houston, tenemos un problema");
      } else {
        Feathers.service("molino")
          .patch(this.props.task.id, {
            dureza_tapones: this.state.hardness,
          })
          .then((molino) => {
            this.setState({
              task: {
                ...this.state.task,
                dureza_tapones: molino.dureza_tapones,
              },
            });
            //Acabar por completo la tarea
            this.props.finishTask();
          });
      }
    });
  }

  endPhase3() {
    if (!this.state.task.dureza_tapones)
      return swal({
        title: "Atención",
        text:
          "Primero debes capturar la dureza de tapones para finalizar la tarea",
        type: "info",
      });

    //2 horas
    if (
      moment().diff(moment(this.state.task.hora_fin_fase2), "seconds") <
      this.state.configuraciones.espera_molino
    )
      return swal({
        title: "Atención",
        text: `Deben haber transucurrido al menos ${(
          this.state.configuraciones.espera_molino / 3600
        ).toFixed(
          2
        )} hora/s de la terminación de la fase 2 para finalizar la tarea`,
        type: "info",
      });

    this.props.finishTask && this.props.finishTask();
  }

  renderTimer() {
    if (!this.state.task.hora_fin_fase1) {
      return (
        <Timer
          renderFinish="false"
          finishTask={() => this.props.finishTask()}
          restartTask={() => this.restartTask()}
          start={this.state.task.hora_inicio}
        />
      );
    } else if (!this.state.task.hora_fin_fase2) {
      return (
        <Timer
          finishTask={() => this.endPhase2()}
          restartTask={() => this.restartTask()}
          start={this.state.task.hora_fin_fase1}
        />
      );
    }

    return (
      <Timer
        finishTask={() => this.endPhase3()}
        restartTask={() => this.restartTask()}
        start={this.state.task.hora_fin_fase2}
      />
    );
  }

  renderItems() {
    return (
      <Segment attached>
        <Grid>
          <Grid.Row
            columns={4}
            style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
          >
            <Grid.Column>
              <span style={{ display: "block", fontWeight: "bold" }}>
                Material
              </span>
              <span>{this.props.task.partida.material.nombre}</span>
            </Grid.Column>
            <Grid.Column>
              <span style={{ display: "block", fontWeight: "bold" }}>
                Dureza
              </span>
              <span>{this.props.task.partida.dureza.valor}</span>
            </Grid.Column>
            <Grid.Column>
              <span style={{ display: "block", fontWeight: "bold" }}>
                Color
              </span>
              <span>{this.props.task.partida.color.nombre}</span>
            </Grid.Column>
            <Grid.Column>
              <span style={{ display: "block", fontWeight: "bold" }}>
                Kilos
              </span>
              <span>{this.props.task.kilos}</span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            columns={4}
            style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
          >
            <Grid.Column>
              <span style={{ display: "block", fontWeight: "bold" }}>LOTE</span>
              <span>{`${this.props.task.partida.material.nombre}-${moment(
                this.state.task.partida.pedido.fecha_alta
              ).format("DDMMYYYY")}-${this.props.task.kilos}`}</span>
            </Grid.Column>
            <Grid.Column>
              <span style={{ display: "block", fontWeight: "bold" }}>
                Observaciones
              </span>
              <span>
                {this.props.task.partida.observaciones
                  ? this.props.task.partida.observaciones
                  : "N/A"}
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  renderPhase1() {
    if (
      !this.state.task.hora_inicio ||
      !this.state.rawMaterials.length ||
      this.state.task.hora_fin_fase1
    )
      return;

    return (
      <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
        <Header
          as="h4"
          attached="top"
          inverted
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#212121",
          }}
        >
          <Icon name="settings" />
          <Header.Content>Fase 1</Header.Content>
        </Header>
        <Segment attached>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label key={1} style={{ paddingLeft: 2 }}>
              Medición con báscula:{" "}
            </label>
            <></>
            <Toggle
              value={this.state.manualMeasure}
              icons={false}
              onClick={() => {
                this.setState({ manualMeasure: !this.state.manualMeasure });
              }}
            ></Toggle>
          </div>
          <Divider />
          {this.state.manualMeasure && (
            <>
              {!this.state.serialPort && (
                <Button
                  onClick={this.connectScale}
                  loading={this.state.loadingP1}
                  icon="play"
                  positive
                  content="Conectar báscula"
                />
              )}
              {this.state.serialPort && (
                <Button
                  onClick={this.closeScaleConnection}
                  loading={this.state.loadingP1}
                  icon="cancel"
                  negative
                  content="Desconectar"
                />
              )}
            </>
          )}
          {this.state.manualMeasure && (
            <Grid columns={2} divided style={{ paddingTop: 15 }}>
              <Grid.Row>
                <Grid.Column>
                  {this.state.serialPort && (
                    <p>
                      <Icon name="check" />
                      Báscula conectada
                    </p>
                  )}
                  {!this.state.serialPort && (
                    <p>
                      <Icon name="cancel" />
                      Báscula no conectada
                    </p>
                  )}
                </Grid.Column>
                <Grid.Column>
                  <span>
                    {" "}
                    <p>
                      {" "}
                      Tolerancia de báscula:{" "}
                      {parseFloat(
                        this.state.configuraciones.tolerance.value.data[0].valor
                      )} &#37;
                    </p>
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          <Table singleLine compact={true}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Materia prima</Table.HeaderCell>
                <Table.HeaderCell>Partes</Table.HeaderCell>
                <Table.HeaderCell>Kilos a pesar</Table.HeaderCell>
                <Table.HeaderCell>Kilos pesados</Table.HeaderCell>
                {this.state.manualMeasure && (
                  <Table.HeaderCell>Acciones</Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.rawMaterials.map((rm, index) => {
                return !this.state.manualMeasure ? (
                  <Table.Row key={index}>
                    <Table.Cell>{rm.materiaPrima.nombre}</Table.Cell>
                    <Table.Cell>{rm.partes}</Table.Cell>
                    <Table.Cell>{this.calculateKilos(rm.partes)}</Table.Cell>
                    <Table.Cell>
                      <Form.Input
                        type="number"
                        onChange={(e, { name, value }) =>
                          this.updateDetailManual(index, value)
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <Table.Row key={index}>
                    <Table.Cell>{rm.materiaPrima.nombre}</Table.Cell>
                    <Table.Cell>{rm.partes}</Table.Cell>
                    <Table.Cell>{this.calculateKilos(rm.partes)}</Table.Cell>
                    <Table.Cell>
                      {/* <Form.Input
                        type="number"
                        onChange={(e, { name, value }) =>
                          this.updateDetail(index, value)
                        }
                      /> */}
                      {this.state.rawMaterials[index].peso
                        ? this.state.rawMaterials[index].peso
                        : "N/A"}
                    </Table.Cell>
                    <Table.Cell>
                      {this.state.serialPort && (
                        <Button
                          onClick={() => this.weighScale(index)}
                          loading={this.state.loadingP1}
                          icon="balance scale"
                          positive
                          disabled={this.state.weighedButtons.includes(index)}
                          content="Pesar"
                        />
                      )}
                      {!this.state.serialPort && (
                        <Button
                          onClick={() => this.weighScale(index)}
                          loading={this.state.loadingP1}
                          icon="balance scale"
                          positive
                          disabled
                          content="Pesar"
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={this.endPhase1}
              loading={this.state.loadingP1}
              disabled={this.checkDisabled()}
              icon="check"
              positive
              content="REGISTRAR PESOS"
            />
          </div>
        </Segment>
      </div>
    );
  }

  renderPhase3() {
    if (
      !(this.state.task.hora_fin_fase1 && this.state.task.hora_fin_fase2) ||
      this.state.task.dureza_tapones
    )
      return;

    return (
      <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
        <Header
          as="h4"
          attached="top"
          inverted
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#212121",
          }}
        >
          <Icon name="settings" />
          <Header.Content>Fase 3</Header.Content>
        </Header>
        <Segment attached>
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Form.Input
                    onChange={(e, { name, value }) =>
                      this.setState({ hardness: value })
                    }
                    type="number"
                    label="Dureza de tapones"
                    placeholder="Dureza de tapones"
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Button
                    type="button"
                    onClick={this.saveHardness}
                    disabled={!this.state.hardness}
                    fluid
                    style={{ marginTop: "25px" }}
                    icon="check"
                    positive
                    content="REGISTRAR DUREZA"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h2">
                <Button
                  onClick={this.props.goBack}
                  icon="arrow left"
                  style={{ backgroundColor: "transparent", color: "#000000" }}
                />
                Tareas de Molino
              </Header>
            </Grid.Column>
            <Grid.Column>
              {this.state.rodillo ? (
                <Incidence
                  ref={(ref) => (this.incidence = ref)}
                  phase={PHASE}
                  task={this.props.task}
                  cancelTaskByIncidence={this.props.cancelTaskByIncidence}
                  rodillo={this.state.rodillo ? this.state.rodillo.id : 0}
                  pedido={this.props.task.partida.pedido_id}
                />
              ) : <Loader active inline />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        {this.state.task.hora_inicio && (
          <div>
            <Header
              as="h3"
              attached="top"
              inverted
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                backgroundColor: "#212121",
              }}
            >
              <Icon name="settings" />
              <Header.Content>Estatus de la tarea</Header.Content>
            </Header>
            <Segment attached>
              <Grid>
                <Grid.Row columns={5}>
                  <Grid.Column>
                    <span style={{ display: "block", fontWeight: "bold" }}>
                      Estatus
                    </span>
                    {this.renderStatus()}
                  </Grid.Column>
                  <Grid.Column>
                    <span style={{ display: "block", fontWeight: "bold" }}>
                      Hora inicio
                    </span>
                    <span>{this.props.task.hora_inicio}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span style={{ display: "block", fontWeight: "bold" }}>
                      Hora fin fase 1
                    </span>
                    <span>{this.state.task.hora_fin_fase1}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span style={{ display: "block", fontWeight: "bold" }}>
                      Hora fin fase 2
                    </span>
                    <span>{this.state.task.hora_fin_fase2}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span style={{ display: "block", fontWeight: "bold" }}>
                      Contador
                    </span>
                    {this.renderTimer()}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </div>
        )}

        <TaskOrderSegment
          order={this.state.task.partida.pedido}
          kilos={this.state.task.partida.kilos}
        />
        <Header
          as="h3"
          attached="top"
          inverted
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#212121",
          }}
        >
          <Icon name="list" />
          <Header.Content>Información de partidas</Header.Content>
        </Header>
        <this.renderItems />

        {this.renderInitButton()}
        {this.renderPhase1()}
        {this.renderPhase3()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    configuraciones: state.services.configuraciones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEspera_Molino: () =>
      dispatch(
        services.configuraciones.find({
          query: {
            nombre: "espera_molino",
            $limit: "-1",
          },
        })
      ),
    getTolerance: () =>
      dispatch(
        services.configuraciones.find({
          query: {
            nombre: "Tolerancia en báscula",
            $limit: "-1",
          },
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WindmillTask);
