import React, { Component } from "react";
import {
  Header,
  Segment,
  Grid,
  Icon,
  Form,
  Button,
  Table,
  Modal,
  Label,
  Divider,
  Checkbox,
} from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import Feathers, { services } from "../../../redux/FeathersRedux";
import { TaskStatus } from "../../../components/Tasks";
import Timer from "../../../components/Timer";
import swal from "sweetalert2";
import moment from "moment";
import VulcanizationDetail from "../../../components/Tasks/Vulcanization/VulcanizationDetail";
import VulcanizationTemperature from "../../../components/Tasks/Vulcanization/VulcanizationTemperature";
import { connect } from "react-redux";

const MIN_TIME = 6; //25200

class VulcanizationTaskInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishingTask: false,
      selectAll: false,
      pending: [],
      selected: [],
      total: 0,
      page: 0,
      loading: true,
      modalIsOpened: false,
      selectedCount: 0,
      detail: [],
      temperatures: [],
      configuraciones: {
        espera_vulcanizado: null,
      },
      comment: "",
      updatingCommentLoading: false,
      temperatura: "",
      task: null,
      rodillo: null,
      partida: null,
      pedido: null,
      tasksVulcanization: [],
    };

    this.getTemperatures = this.getTemperatures.bind(this);
  }

  async componentDidMount() {
    const vulcanizationId = parseInt(this.props.match.params.vulcanizationId);
    const rodilloId = parseInt(this.props.match.params.rodilloId);

    Feathers.service("historial-vulcanizado")
      .get(vulcanizationId, {
        query: {
          rodilloId,
        },
      })
      .then((res) => {
        console.log("res", res.data[0]);
        this.getTemperatures();
        this.setState({
          loading: false,
          tasksVulcanization: res.data[0],
        });
      });
  }

  getTemperatures = async () => {
    Feathers.service("detalles_temperatura")
      .find({
        query: {
          tarea_vulcanizado_id: this.props.match.params.vulcanizationId,
          $limit: "-1",
        },
      })
      .then((res) => {
        this.setState({
          temperatures: res.map((e) => {
            return {
              hora: moment(e.hora_captura).format("HH:mm"),
              temperatura: e.temperatura,
              //"presion": e.presion
            };
          }),
        });
      });
  };

  renderStatusTask(task) {
    if (task.hora_inicio_vulcanizado)
      return (
        <Label color="teal">
          <Icon name="thermometer empty" />
          Vulcanizando
        </Label>
      );

    return (
      <Label color="blue">
        <Icon name="play" /> Iniciada
      </Label>
    );
  }

  render() {
    return !this.state.loading ? (
      <Segment size={"large"} loading={this.state.finishingTask}>
        <Header as="h2">
          <Button
            onClick={this.props.history.goBack}
            icon="arrow left"
            style={{ backgroundColor: "transparent", color: "#000000de" }}
          />
          Tareas de vulcanizado
        </Header>
        {this.state.tasksVulcanization && (
          <>
            <Header as="h2">
              Vulcanizadora:{" "}
              {this.state.tasksVulcanization.vulcanizadora_nombre}
            </Header>
            <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
              <Header
                as="h3"
                attached="top"
                inverted
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: "#393e46",
                  borderRadius: "10px",
                }}
              >
                <Icon name="settings" />
                <Header.Content>Estatus de la tarea</Header.Content>
              </Header>
              <Segment size="large">
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer="4">
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        Estatus
                      </span>
                      {this.renderStatusTask(this.state.tasksVulcanization)}
                    </Grid.Column>
                    <Grid.Column computer="4">
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        Hora inicio
                      </span>
                      <span>{this.state.tasksVulcanization.hora_inicio}</span>
                    </Grid.Column>
                    <Grid.Column computer="4">
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        Hora inicio vulcanizado
                      </span>
                      <span>
                        {this.state.tasksVulcanization.hora_inicio_vulcanizado}
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>
          </>
        )}
        {this.state.tasksVulcanization && (
          <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
            <Header
              as="h3"
              attached="top"
              inverted
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                backgroundColor: "#393e46",
                borderRadius: "10px",
              }}
            >
              <Icon name="comments" />
              <Header.Content>Área de Comentario</Header.Content>
            </Header>
            <Segment size="large">
              <Form>
                <Form.Field>
                  <Form.TextArea
                    readOnly
                    label={"Comentario"}
                    placeholder="Comentario..."
                    value={
                      this.state.tasksVulcanization.comentario ||
                      "Sin comentarios."
                    }
                  />
                </Form.Field>
              </Form>
            </Segment>
          </div>
        )}
        {this.state.tasksVulcanization && (
          <div>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Table selectable striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Unidad</Table.HeaderCell>
                        <Table.HeaderCell>OT</Table.HeaderCell>
                        <Table.HeaderCell>Cliente</Table.HeaderCell>
                        <Table.HeaderCell>Tipo</Table.HeaderCell>
                        <Table.HeaderCell>Parte - Mancheta</Table.HeaderCell>
                        <Table.HeaderCell>Material</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row key={this.state.tasksVulcanization.rodillo_id}>
                        <Table.Cell>
                          {this.state.tasksVulcanization.rodillo_id || "---"}
                        </Table.Cell>
                        <Table.Cell>
                          {this.state.tasksVulcanization.pedido_id || "---"}
                        </Table.Cell>
                        <Table.Cell>
                          {this.state.tasksVulcanization.cliente_nombre ||
                            "---"}
                        </Table.Cell>
                        <Table.Cell>
                          {this.state.tasksVulcanization.producto_nombre ||
                            "---"}
                        </Table.Cell>
                        <Table.Cell>
                          {this.state.tasksVulcanization.mancheta_parte ||
                            "---"}
                        </Table.Cell>
                        <Table.Cell>
                          {this.state.tasksVulcanization.material_nombre ||
                            "---"}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
              {this.state.selectedCount > 0 && (
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      floated="right"
                      color="green"
                      onClick={this.toggleModal}
                    >
                      <div style={{ textAlign: "center" }}>
                        <span>
                          {this.state.selectedCount} rodillos seleccionado(s)
                        </span>
                        <p>Ver rodillos</p>
                      </div>
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            <VulcanizationTemperature
              temperatures={this.state.temperatures}
              getTemperature={this.getTemperatures}
            />
          </div>
        )}
      </Segment>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state) => {
  return {
    configuraciones: state.services.configuraciones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEspera_Vulcanizado: () =>
      dispatch(
        services.configuraciones.find({
          query: {
            nombre: "espera_vulcanizado",
            $limit: "-1",
          },
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VulcanizationTaskInfo);
