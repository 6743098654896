import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Button,
  Grid,
  Icon,
  Progress,
  Header,
  Dropdown,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../redux/FeathersRedux";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import "moment/locale/es";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { exportToXls } from "../utils/Utils";
import ReportFilter from "../components/FIlters/ReportFilter";

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

export default class RawMaterialsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 50,
      modalOpened: false,
      editModalOpened: false,
      currentRow: {},
      options: [],
      clienteAFiltrar: "",
      filter: {
        initDay: "",
        lastDay: "",
        cliente_id: null,
        rodilloValue: "",
        partidaValue: "",
        pedidoValue: "",
        statusValue: "",
      },
      status_options: [
        {
          key: 1,
          text: "Cotización",
          value: 1,
        },
        {
          key: 2,
          text: "Iniciado",
          value: 2,
        },
        {
          key: 3,
          text: "Finalizado",
          value: 3,
        },
        {
          key: 4,
          text: "Enviado",
          value: 4,
        },
        {
          key: 5,
          text: "Entregado",
          value: 5,
        },
        {
          key: 6,
          text: "Cancelado",
          value: 6,
        },
        {
          key: 7,
          text: "Todos",
          value: 7,
        },
      ],
      filters: ["range", "costumer", "rodillo", "pedido", "partida", "status"],
      $sort: {
        $saeSortDirection: null,
        $fechaAltaSortDirection: null,
        $fechaEntregaSortDirection: null,
      },

      // search bar objects

      cliente: "",

      // search bar results

      clienteResults: null,

      // search bar loading

      clienteLoading: null,
      columna: "",
      sortDirection: true,
      showSae: false,
      tableColumns: [
        {
          Header: "SAE",
          id: "ref_sae",
          accessor: (d) => {
            if (d.ref_sae) return d.ref_sae;
            return "---";
          },
        },
        {
          Header: "Orden de Trabajo",
          headerStyle: { fontSize: 16 },
          id: "pedidoid",
          accessor: (d) => {
            if (d.pedidoid) return d.pedidoid;
            return "---";
          },
        },
        {
          Header: "Rodillo",
          headerStyle: { fontSize: 16 },
          id: "rodillo_id",
          accessor: (d) => {
            if (d.rodilloid) return d.rodilloid;
            if (d.partidas) {
              if (d.partidas.length) {
                if (d.partidas[0].rodillo.length)
                  return d.partidas[0].rodillo[0].id;
                else return d.partidas[0].rodillo.id;
              } else {
                if (d.partidas.rodillo.length) return d.partidas.rodillo[0].id;
                else return d.partidas.rodillo.id;
              }
            }
            return "---";
          },
        },
        {
          Header: "Partida",
          headerStyle: { fontSize: 16 },
          id: "partida_id",
          accessor: (d) => {
            if (d.partidaid) return d.partidaid;
            // if (d.partidas) return d.partidas.id;
            return "---";
          },
        },
        {
          Header: "Cliente",
          id: "nombre",
          accessor: (d) => d.nombre,
        },
        {
          Header: "Vendedor",
          id: "vendedor",
          accessor: (d) =>
            d.vendedor_nombre && d.vendedor_apellido
              ? d.vendedor_nombre + " " + d.vendedor_apellido
              : "N/A",
        },
        {
          Header: "Fecha Alta",
          id: "fecha_alta",
          accessor: (d) => moment(d.fecha_alta).format("YYYY-MM-DD"),
        },
        {
          Header: "Fecha Entrega",
          id: "fecha_entrega",
          accessor: (d) => {
            return moment(d.fecha_entrega).format("YYYY-MM-DD");
          },
        },
        {
          Header: "Unidades totales",
          id: "cantidad",
          accessor: (d) => d.cantidad,
        },
        {
          Header: "Unidades pendientes",
          id: "pendientes",
          accessor: (d) => d.pendientes,
        },
        {
          Header: "Unidades Completados",
          id: "completados",
          accessor: (d) => d.completados,
        },
        {
          Header: "Porcentaje",
          id: "porcentaje",
          accessor: (d) => {
            return <Progress percent={+d.porcentaje} indicating progress />;
          },
        },
        {
          Header: "Estado",
          id: "estado",
          accessor: (d) => {
            if (d.status_id === 1) return <Label tag> Cotización </Label>;
            if (d.status_id === 2)
              return (
                <Label tag color="blue">
                  {" "}
                  Iniciado{" "}
                </Label>
              );
            if (d.status_id === 3)
              return (
                <Label tag color="green">
                  {" "}
                  Finalizado{" "}
                </Label>
              );
            if (d.status_id === 4) return <Label tag> Enviado </Label>;
            if (d.status_id === 5) return <Label tag> Entregado </Label>;
            if (d.status_id === 6)
              return (
                <Label tag color="red">
                  {" "}
                  Cancelado{" "}
                </Label>
              );
          },
        },
      ],
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
  }
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "SAE":
        array.data.sort((a, b) => {
          return a.ref_sae - b.ref_sae;
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha Entrega":
        array.data.sort((a, b) => {
          const nameA = a.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          const nameB = b.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Unidades totales":
        array.data.sort((a, b) => {
          return parseInt(a.cantidad) - parseInt(b.cantidad);
        });
        break;
      case "Partida":
        array.data.sort((a, b) => {
          return parseInt(a.cantidad) - parseInt(b.cantidad);
        });
        break;
      case "Rodillo":
        array.data.sort((a, b) => {
          return parseInt(a.cantidad) - parseInt(b.cantidad);
        });
        break;
      case "Orden de Trabajo":
        array.data.sort((a, b) => {
          return parseInt(a.cantidad) - parseInt(b.cantidad);
        });
        break;
      case "Unidades pendientes":
        array.data.sort((a, b) => {
          return parseInt(a.pendientes) - parseInt(b.pendientes);
        });
        break;
      case "Unidades Completadas":
        array.data.sort((a, b) => {
          return parseInt(a.completados) - parseInt(b.completados);
        });
        break;
      case "Porcentaje":
        array.data.sort((a, b) => {
          return parseInt(a.porcentaje) - parseInt(b.porcentaje);
        });
        break;
      case "Estado":
        array.data.sort((a, b) => {
          return parseInt(a.status_id) - parseInt(b.status_id);
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  getClients = async () => {
    Feathers.service("cliente")
      .find({
        query: {
          dopaginate: false,
        },
      })
      .then((res) => {
        this.setState({
          options: res.map((v, i) => {
            return {
              text: v.nombre,
              value: v.id,
              key: v.id,
            };
          }),
        });
      });
  };

  componentDidMount() {
    this.getClients();
  }

  handleClientResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    await this.setState({
      filter: {
        ...this.state.filter,
        cliente_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleClientSearchChange = async (e, { value }) => {
    await this.setState({ clienteLoading: true, cliente: value });

    setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  resetClienteComponent = () =>
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });

  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
      report: true,
      $sort: {
        id: -1,
        fecha_alta: -1,
      },
    };
    /*
    if (this.state.$sort.$saeSortDirection !== null) {
      query.$sort = {
        ref_sae: this.state.$sort.$saeSortDirection,
      };
    }*/

    if (this.state.filter.initDay) {
      let fechainicio = moment(this.state.filter.initDay)
        .hour(0)
        .minute(0)
        .second(0);
      fechainicio = fechainicio.format("YYYY-MM-DD HH:mm:ss");
      query["fechainicio"] = fechainicio;
    }

    if (this.state.filter.lastDay) {
      let fechafin = moment(this.state.filter.lastDay)
        .hour(22)
        .minute(60)
        .second(60);
      fechafin = fechafin.format("YYYY-MM-DD HH:mm:ss");
      query["fechafin"] = fechafin;
    }

    if (this.state.filter.cliente_id !== null) {
      query["cliente_id"] = this.state.filter.cliente_id;
    }

    if (this.state.filter.rodilloValue !== null) {
      query["rodillo"] = this.state.filter.rodilloValue;
    }

    if (this.state.filter.partidaValue !== null) {
      query["partida"] = this.state.filter.partidaValue;
    }

    if (this.state.filter.pedidoValue !== null) {
      query["pedido"] = this.state.filter.pedidoValue;
    }

    if (this.state.filter.statusValue !== null) {
      query["status"] = this.state.filter.statusValue;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("pedido")
        .find({
          query,
        })
        .then((res) => {
          console.log("res reporte", res);
          if (this.state.columna) {
            this.handleSort(res);
          }
          this.setState({
            orders: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        });
    });
  };

  _handleFilterButton = () => {
    this.fetchData(this.state);
  };

  _handleCleanButton = async () => {
    this.resetClienteComponent();
    await this.setState({
      filter: {
        initDay: "",
        lastDay: "",
        cliente_id: null,
      },
    });
    this.fetchData(this.state);
  };

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  rodilloExists = (d) => {
    if (d.partidas) {
      if (d.partidas.length) {
        if (d.partidas[0].rodillo.length) return d.partidas[0].rodillo[0].id;
        else return d.partidas[0].rodillo.id;
      } else {
        if (d.partidas.rodillo.length) return d.partidas.rodillo[0].id;
        else return d.partidas.rodillo.id;
      }
    }

    return null;
  };

  onRodilloChange = (rodillo) => {
    this.setState({
      filter: {
        ...this.state.filter,
        rodilloValue: rodillo,
      },
    });
  };

  onPartidaChange = (partida) => {
    this.setState({
      filter: {
        ...this.state.filter,
        partidaValue: partida,
      },
    });
  };

  onPedidoChange = (pedido) => {
    this.setState({
      filter: {
        ...this.state.filter,
        pedidoValue: pedido,
      },
    });
  };

  onStatusChange = (status) => {
    this.setState({
      filter: {
        ...this.state.filter,
        statusValue: status,
      },
    });
  };

  changeInitDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        initDay: date,
      },
    });
  };

  changeLastDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        lastDay: date,
      },
    });
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="ordered list"
                content="Reporte de Pedidos"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form size={"small"}>
                <Form.Group>
                  <ReportFilter
                    loading={this.state.loading}
                    filters={this.state.filters}
                    initDay={this.state.filter.initDay}
                    lastDay={this.state.filter.lastDay}
                    changeInitDay={this.changeInitDay}
                    changeLastDay={this.changeLastDay}
                    costumer={this.state.cliente}
                    costumers={this.state.clienteResults}
                    onSelectCostumer={this.handleClientResultSelect}
                    onSearchCostumerChange={this.handleClientSearchChange}
                    costumerResultRenderer={renderClientSearchResults}
                    rodilloValue={this.state.rodilloValue}
                    onRodilloChange={this.onRodilloChange}
                    partidaValue={this.state.partidaValue}
                    onChangePartida={this.onPartidaChange}
                    pedidoValue={this.state.pedidoValue}
                    onPedidoChange={this.onPedidoChange}
                    statusFilterOptions={this.state.status_options}
                    statusValue={this.state.statusValue}
                    onStatusChange={this.onStatusChange}
                  />
                  <Form.Field>
                    <label>Filtrar</label>
                    <Button
                      basic
                      circular
                      icon="filter"
                      onClick={this._handleFilterButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this._handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        exportToXls(this.state.orders, "report_orders");
                      }}
                    />
                  </Form.Field>
                  {/* <Form.Field>
                    <label>Mostrar SAE</label>
                    <Toggle
                      icons={false}
                      onClick={() => {
                        this.handleSaeToggle();
                      }}
                    ></Toggle>
                  </Form.Field> */}
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ReactTable
                columns={
                  this.state.showSae
                    ? this.state.tableColumns
                    : this.state.tableColumns.slice(1)
                }
                manual
                data={this.state.orders}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={50}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  console.log("column", this.state.columna.Header);
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState({
                    columna: column,
                    sortDirection: direction,
                  });
                  this.fetchData();
                }}
                style={{ textAlign: "center" }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
