import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import { Button, Checkbox, Grid, Form, Label, Search } from "semantic-ui-react";
import Toggle from "react-toggle";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { exportToXls } from "../../utils/Utils";

const renderDurezaSearchResults = ({ id, dureza }) => (
  <Label key={id} content={dureza} />
);
const renderParteSearchResults = ({ id, parte }) => (
  <Label key={id} content={parte} />
);

class ManchetasList extends Component {
  state = {
    manchetas: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 100,
    currentRow: {},
    isEditOpened: false,
    $sort: {
      $parteSortDirection: null,
    },
    filterOptions: {
      dureza: null,
      forma_mancheta_id: null,
      tipo_mancheta_id: null,
      parte: null,
    },

    // options for filtes

    forma_mancheta_id_options: [],
    tipo_mancheta_id_options: [],

    // Search bar Results

    durezaResults: null,
    dureza: null,
    durezaLoading: false,

    parteResults: null,
    parte: null,
    parteLoading: false,
    columna: "",
    sortDirection: false,
    shouldExport: false,
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Parte":
        array.data.sort((a, b) => {
          return a.parte - b.parte;
        });
        break;
      case "Forma Mancheta":
        array.data.sort((a, b) => {
          const nameA = a.formamancheta.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.formamancheta.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Tipo Mancheta":
        array.data.sort((a, b) => {
          const nameA = a.tipomancheta.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.tipomancheta.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Perímetro Específico":
        array.data.sort((a, b) => {
          return a.perimetro_espec - b.perimetro_espec;
        });
        break;
      case "Perímetro Mínimo":
        array.data.sort((a, b) => {
          return a.perimetro_min - b.perimetro_min;
        });
        break;
      case "Perímetro Máximo":
        array.data.sort((a, b) => {
          return a.perimetro_max - b.perimetro_max;
        });
        break;
      case "Espesor Específico":
        array.data.sort((a, b) => {
          return a.espesor_espec - b.espesor_espec;
        });
        break;
      case "Espesor Mínimo":
        array.data.sort((a, b) => {
          return a.espesor_min - b.espesor_min;
        });
        break;
      case "Espesor Máximo":
        array.data.sort((a, b) => {
          return a.espesor_max - b.espesor_max;
        });
        break;
      case "Altura Específica":
        array.data.sort((a, b) => {
          return a.altura_espec - b.altura_espec;
        });
        break;
      case "Altura Mínima":
        array.data.sort((a, b) => {
          return a.altura_min - b.altura_min;
        });
        break;
      case "Altura Máxima":
        array.data.sort((a, b) => {
          return a.altura_max - b.altura_max;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          return a.dureza - b.dureza;
        });
        break;
      case "USD":
        array.data.sort((a, b) => {
          return a.usd - b.usd;
        });
        break;
      case "Kilos":
        array.data.sort((a, b) => {
          return a.kilos - b.kilos;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  componentDidMount() {
    this.fetch_options();
  }

  handleDurezaResultSelect = async (e, { result }) => {
    this.setState({ dureza: result.dureza });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        dureza: result.dureza,
      },
    });

    this.fetchData(this.state);
  };

  handleParteResultSelect = async (e, { result }) => {
    this.setState({ parte: result.parte });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        parte: result.parte,
      },
    });

    this.fetchData(this.state);
  };

  removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  };

  handleDurezaSearchChange = (e, { value }) => {
    this.setState({ durezaLoading: true, dureza: value });

    setTimeout(() => {
      if (this.state.dureza.length < 1) return this.resetDurezaComponent();

      Feathers.service("manchetas")
        .find({
          query: {
            dureza: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          let dataRes = JSON.parse(JSON.stringify(res.data));
          let newDataRes = this.removeDuplicates(dataRes, "dureza");
          this.setState({
            durezaLoading: false,
            durezaResults: newDataRes,
          });
        });
    }, 500);
  };

  handleParteSearchChange = (e, { value }) => {
    this.setState({ parteLoading: true, parte: value });

    setTimeout(() => {
      if (this.state.parte.length < 1) return this.resetParteComponent();

      Feathers.service("manchetas")
        .find({
          query: {
            parte: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          let dataRes = JSON.parse(JSON.stringify(res.data));
          let newDataRes = this.removeDuplicates(dataRes, "parte");
          this.setState({
            parteLoading: false,
            parteResults: newDataRes,
          });
        });
    }, 500);
  };

  resetDurezaComponent = () =>
    this.setState({ durezaLoading: false, durezaResults: [], dureza: "" });
  resetParteComponent = () =>
    this.setState({ parteLoading: false, parteResults: [], parte: "" });

  fetch_options = async (_) => {
    let forma_mancheta = await Feathers.service("forma_mancheta").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    // key, text, value. It is the format that the semantic dropdowns accepts
    let parsed_formas = forma_mancheta.map((v) => {
      return {
        text: v.nombre,
        key: v.id,
        value: v.id,
      };
    });

    let tipo_mancheta = await Feathers.service("tipo_mancheta").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    let parsed_tipos = tipo_mancheta.map((v) => {
      return {
        text: v.nombre,
        key: v.id,
        value: v.id,
      };
    });

    this.setState({
      forma_mancheta_id_options: parsed_formas,
      tipo_mancheta_id_options: parsed_tipos,
    });
  };

  /* handleChange = (data, row) => {
    Feathers.service('manchetas').patch(row.original.id, {
      activo: data.checked ? 1: 0
    })
      .then(res => {
        this.fetchData(this.state);
      })

  }; */

  handleChange(event, row) {
    Feathers.service("manchetas").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  closeEditModal = (_) => {
    this.setState({
      isEditOpened: false,
    });
  };

  openEditModal = (row) => {
    this.props.dispatch(push("/manchetas/edit/" + row.original.id));
  };

  handleCleanButton = (_) => {
    this.resetDurezaComponent();
    this.resetParteComponent();
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          dureza: null,
          parte: null,
          forma_mancheta_id: null,
          tipo_mancheta_id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.$sort.$parteSortDirection !== null) {
      query["$sort"] = {
        parte: this.state.$sort.$parteSortDirection,
      };
    }

    if (this.state.filterOptions.forma_mancheta_id !== null) {
      query["forma_mancheta_id"] = this.state.filterOptions.forma_mancheta_id;
    }

    if (this.state.filterOptions.tipo_mancheta_id !== null) {
      query["tipo_mancheta_id"] = this.state.filterOptions.tipo_mancheta_id;
    }

    if (this.state.filterOptions.dureza !== null) {
      query["dureza"] = this.state.filterOptions.dureza;
    }

    if (this.state.filterOptions.parte !== null) {
      query["parte"] = this.state.filterOptions.parte;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("manchetas")
        .find({
          query,
        })
        .then((res) => {
          res = this.handleSort(res);
          if (!this.state.shouldExport) {
            this.setState({
              manchetas: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "admin_manchetas");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <Form.Field>
                    <label>Parte</label>
                    <Search
                      icon=""
                      loading={this.state.parteLoading}
                      value={this.state.parte}
                      results={this.state.parteResults}
                      onResultSelect={this.handleParteResultSelect}
                      onSearchChange={this.handleParteSearchChange}
                      resultRenderer={renderParteSearchResults}
                      placeholder="Parte"
                      className="full-width"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Forma Mancheta</label>
                    <Form.Dropdown
                      selection
                      options={this.state.forma_mancheta_id_options}
                      value={this.state.filterOptions.forma_mancheta_id}
                      onChange={(e, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              forma_mancheta_id: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Tipo Mancheta</label>
                    <Form.Dropdown
                      selection
                      options={this.state.tipo_mancheta_id_options}
                      value={this.state.filterOptions.tipo_mancheta_id}
                      onChange={(e, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              tipo_mancheta_id: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Dureza</label>
                    <Search
                      icon=""
                      loading={this.state.durezaLoading}
                      value={this.state.dureza}
                      results={this.state.durezaResults}
                      onResultSelect={this.handleDurezaResultSelect}
                      onSearchChange={this.handleDurezaSearchChange}
                      resultRenderer={renderDurezaSearchResults}
                      placeholder="Dureza"
                      className="full-width"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        this.setState({ shouldExport: true }, () => {
                          this.fetchData(this.state);
                        });
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ReactTable
          columns={[
            { Header: "Parte", accessor: "parte" },
            {
              Header: "Forma Mancheta",
              id: "forma_mancheta",
              accessor: (d) => {
                if (d.formamancheta) return d.formamancheta.nombre;
                return "---";
              },
            },
            {
              Header: "Tipo Mancheta",
              id: "tipo_mancheta",
              accessor: (d) => {
                if (d.tipomancheta) return d.tipomancheta.nombre;
                return "---";
              },
            },
            { Header: "Perímetro Específico", accessor: "perimetro_espec" },
            { Header: "Perímetro Mínimo", accessor: "perimetro_min" },
            { Header: "Perímetro Máximo", accessor: "perimetro_max" },
            { Header: "Espesor Específico", accessor: "espesor_espec" },
            { Header: "Espesor Mínimo", accessor: "espesor_min" },
            { Header: "Espesor Máximo", accessor: "espesor_max" },
            { Header: "Altura Específica", accessor: "altura_espec" },
            { Header: "Altura Mínima", accessor: "altura_min" },
            { Header: "Altura Máxima", accessor: "altura_max" },
            { Header: "Dureza", accessor: "dureza" },
            { Header: "USD", accessor: "usd" },
            { Header: "Kilos", accessor: "kilos" },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size={"tiny"}
                      compact
                      basic
                      color="blue"
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                    {/* <Checkbox toggle onClick={(e, data) => this.handleChange(data, row)} checked={row.original.activo === 1}/> */}
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.manchetas}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState({
              columna: column,
              sortDirection: direction,
            });
            this.fetchData();
          }}
        />
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(ManchetasList);
