import React, { Component } from "react";
import { Modal, Grid, List, Button } from "semantic-ui-react";
import Config from "../../config";

export default class RawMaterialsTechnicalSheetsModal extends Component {
  render() {
    return (
      <Modal open={this.props.isOpened} size={"small"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          {this.props.material.nombre}
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Grid centered style={{ fontSize: "16px" }}>
            <Grid.Row>
              <Grid.Column>
                <div style={{ width: "80%", margin: "auto", textAlign: "center" }}>
                  {this.props.technicalSheets.length > 0 ? (
                      <List divided relaxed>
                        {this.props.technicalSheets.map((e) => (
                          <List.Item key={e.id}>
                            <List.Icon
                              name="file text"
                              size="large"
                              verticalAlign="middle"
                            />
                            <List.Content>
                              <List.Header>
                                <a
                                  target="_blank"
                                  href={`${Config.apiUrl}/documents/technicalSheets/${e.document}`}
                                >
                                  {e.document}
                                </a>
                              </List.Header>
                            </List.Content>
                          </List.Item>
                        ))}
                      </List>
                  
                  ) : (
                    <strong>
                      No se encontraron fichas tecnicas de esta materia prima...
                    </strong>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
