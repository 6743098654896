import React, { Component } from "react";
import Feathers from "../redux/FeathersRedux";
import { Grid, Header, Tab, Button } from "semantic-ui-react";
import TaskTypeReportList from "../components/Reports/TaskTypeReportList";
import TaskReportForm from "../components/Reports/TaskReportForm";
import { push } from "react-router-redux";
import { connect } from "react-redux";


class CalendarTaskTypeReportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currIndex: 0,
      nameFolder: '',

    }
  }

  fetchTypeTask =  async () => {
    const task_type_id = this.props.match.params.id
    await Feathers.service('tipo_tarea').find({
      query: {
        id: task_type_id
      }
    })
    .then((res) => {
      this.setState({
        nameFolder: res.data[0].nombre
      });
    })
    .catch((err) => {
      console.log(err);
    })

  }

  componentDidMount = () => {
    this.fetchTypeTask();
  }

  panes = [
    {
      menuItem: { key: "list", icon: "list", content: "Entregables por tareas del calendario" },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={1}
        >
          <TaskTypeReportList id={this.props.match.params.id} ref={(ref) => (this.list = ref)} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "add", icon: "add circle", content: "Subir documento" },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={2}
        >
          <TaskReportForm id={this.props.match.params.id}/>
        </Tab.Pane>
      ),
    },
  ];

  setTab = (index) => {
    this.setState({
        currIndex: index || 0 
    });
}

  render() {
    return (
      <>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as={"h2"}>
                <Button
                  onClick={() =>
                    this.props.dispatch(push("/reports/calendartaskreport"))
                  }
                  icon="arrow left"
                  style={{ backgroundColor: "transparent", color: "#000000" }}
                />
                Documentos de {`${this.state.nameFolder}`}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Tab
                menu={{ secondary: true, pointing: true, color: "red" }}
                panes={this.panes}
                renderActiveOnly={false}
                activeIndex={this.state.currIndex}
                onTabChange={(event, data) => this.setTab(data.activeIndex)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}


export default connect(null, null, null, {
  withRef: true,
})(CalendarTaskTypeReportContainer);