import React from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import { Button, Form, Search, Grid, Label } from "semantic-ui-react";
import Toggle from "react-toggle";
import ProductEditModal from "./ProductEditModal";

const renderNombreSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

export default class ProductsList extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 10,
      currentRow: {},
      isEditOpened: false,
      $sortDirection: 1,
      filterOptions: {
        id: null,
      },
      // search bar objects
      nombre: "",
      // search bar results
      nombreResults: "",
      // search bar loadings
      nombreLoadings: false,
      columna: "",
      sortDirection: false,
    };
  }
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Precio":
        array.data.sort((a, b) => {
          return parseInt(a.precio) - parseInt(b.precio)
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  handleNombreResultSelect = async (e, { result }) => {
    this.setState({ nombre: result.nombre });

    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleNombreSearchChange = async (e, { value }) => {
    this.setState({ nombreLoadings: true, nombre: value });

    setTimeout(async () => {
      if (this.state.nombre.length < 1) return this.resetNombreComponent();

      await Feathers.service("producto")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            nombreLoadings: false,
            nombreResults: res.data,
          });
        });
    }, 500);
  };

  closeEditModal = () => {
    this.setState({
      isEditOpened: false,
    });
  };

  openEditModal = (row) => {
    this.setState({
      currentRow: row.original,
      isEditOpened: true,
    });
  };

  handleCleanButton = (_) => {
    this.resetNombreComponent();
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  resetNombreComponent = () =>
    this.setState({ nombreLoadings: false, nombreResults: [], nombre: "" });

  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.id != null) {
      query["id"] = this.state.filterOptions.id;
    }

    this.setState({ loading: true }, async () => {
      await Feathers.service("producto")
        .find({
          query,
        })
        .then((res) => {
          console.log(res)
          this.handleSort(res)
          this.setState({
            data: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        });
    });
  };

  render() {
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre:</label>
                  <Search
                    loading={this.state.nombreLoadings}
                    value={this.state.nombre}
                    results={this.state.nombreResults}
                    onResultSelect={this.handleNombreResultSelect}
                    onSearchChange={this.handleNombreSearchChange}
                    resultRenderer={renderNombreSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre", accessor: "nombre" },
            {
              Header: "Precio",
              Cell: (row) => {
                return `$ ${Intl.NumberFormat("en-MX").format(
                  row.original.precio
                )}`;
              },
            },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size={"tiny"}
                      compact
                      basic
                      color="blue"
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.data}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPagesize={10}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState({
              columna: column,
              sortDirection: direction,
            });
            this.fetchData();
          }}
        />
        <ProductEditModal
          open={this.state.isEditOpened}
          onEdit={this.fetchData}
          data={this.state.currentRow}
          onClose={this.closeEditModal}
        />
      </>
    );
  }
}
