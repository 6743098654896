import React from "react";
import { Tab, Grid, Header } from "semantic-ui-react";
import ContactsCustomerList from "../components/Contacts/ContactsCustomerList";
import ContactsProviderList from "../components/Contacts/ContactsProviderList";

export default class ContactsContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      currIndex: 0,
    };

    this.panes = [
      {
        menuItem: { key: "listCustomer", icon: "list", content: "Contactos de clientes" },
        pane: (
          <Tab.Pane
            style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
            key={1}
          >
            <ContactsCustomerList/>
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: "listProvider", icon: "List", content: "Contactos de proveedores" },
        pane: (
          <Tab.Pane
            style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
            key={2}
          >
            <ContactsProviderList/>
          </Tab.Pane>
        ),
      },
    ];
  }

  setTab = (index) => {
    this.setState({
      currIndex: index || 0,
    });
  };


  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="phone" content="Lista de Contactos" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Tab
                menu={{ secondary: true, pointing: true, color: "red" }}
                panes={this.panes}
                renderActiveOnly={false}
                activeIndex={this.state.currIndex}
                onTabChange={(event, data) => this.setTab(data.activeIndex)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
