import React, { Component } from "react";
import UsersList from "../components/Users/UsersList";
import FiredList from "../components/Users/FiredList";
import UsersForm from "../components/Users/UsersForm";
import { Tab, Grid, Header } from "semantic-ui-react";

export default class UserContainer extends Component {
  state = {
    ccurrIndex: 0,
  };

  panes = [
    {
      menuItem: { key: "list", icon: "list", content: "Usuarios Activos" },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={1}
        >
          <UsersList
            ref={(ref) => (this.listActive = ref)}
            onFetchFired={() => {
              this.listFired.getWrappedInstance().fetchData();
            }}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "listFired", icon: "list", content: "Despedidos" },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={1}
        >
          <FiredList
            ref={(ref) => (this.listFired = ref)}
            onFetchActive={() => {
              this.listActive.getWrappedInstance().fetchData();
            }}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "add", icon: "add circle", content: "Nuevo" },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={2}
        >
          <UsersForm
            onCreate={() => {
              this.listActive.getWrappedInstance().fetchData();
            }}
          />
        </Tab.Pane>
      ),
    },
  ];

  setTab = (index) => {
    this.setState({
      currIndex: index || 0,
    });
  };

  onFetchFired = () => {
    this.listFired.fetchData();
  };

  onFetchActive = () => {
    this.listActive.fetchData();
  };

  render() {
    return (
      <div>
        <div>
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Header as="h2" icon="users" content="Usuarios" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Tab
                  menu={{ secondary: true, pointing: true, color: "red" }}
                  panes={this.panes}
                  renderActiveOnly={false}
                  activeIndex={this.state.currIndex}
                  onTabChange={(event, data) => this.setTab(data.activeIndex)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}
