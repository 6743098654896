import React, { Component } from "react";
import Feathers from "../redux/FeathersRedux";
import { Grid, Header, Tab, Button } from "semantic-ui-react";
import TechnicalSheetsForm from "../components/TechnicalSheets/TechnicalSheetsForm";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import DrawImages from "../components/Draw/DrawImages";
import DrawPdf from "../components/Draw/DrawPdf";

class DrawFilesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currIndex: 0,
      nameFolder: "",
    };
  }

  panes = [
    {
      menuItem: {
        key: "listImages",
        icon: "list",
        content: "Imagenes",
      },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={1}
        >
          <DrawImages
            id={this.props.match.params.id}
            ref={(ref) => (this.list = ref)}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "listPDF",
        icon: "list",
        content: "PDF",
      },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={2}
        >
          <DrawPdf
            id={this.props.match.params.id}
            onSave={() => {
              this.list.fetchData();
            }}
          />
        </Tab.Pane>
      ),
    },
  ];

  setTab = (index) => {
    this.setState({
      currIndex: index || 0,
    });
  };

  render() {
    return (
      <>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as={"h2"}>
                <Button
                  onClick={() =>
                    this.props.dispatch(push("/draw"))
                  }
                  icon="arrow left"
                  style={{ backgroundColor: "transparent", color: "#000000" }}
                />
                Archivos de este dibujo
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Tab
                menu={{ secondary: true, pointing: true, color: "red" }}
                panes={this.panes}
                renderActiveOnly={false}
                activeIndex={this.state.currIndex}
                onTabChange={(event, data) => this.setTab(data.activeIndex)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(DrawFilesContainer);
