import React, { Component } from "react";
import { Grid, Button, Form } from "semantic-ui-react";
import { notify } from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";
import moment from "moment";

export default class MaterialsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      material: {
        nombre: "",
        pegamento_id: null,
        densidad: 1.0,
        costo: 0,
        precio: 0,
      },
      pegamento_options: [],
      loading: false,
      valueDollar: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  fetchApiDollar = async () => {
    await Feathers.service("configuraciones")
        .find({
            query: {
                nombre: "cambio_dolar"
            }
        })
        .then((res) => {
            const currentDate =  moment().format("YYYY-MM-DD");
            const isCurrent = moment(currentDate).isSame(res.data[0].fecha_cambio);
            if(isCurrent) {
                console.log('Ya se hizo una peticion del valor del dolar hoy');
                this.setState({
                  valueDollar: res.data[0].valor
                })  
            } else {
                const URl_API = "https://openexchangerates.org/api/latest.json?app_id=cc049e4fed0747f79ce473bdf6c3ea83";
                fetch(URl_API)
                .then((response) => response.json())
                .then((data) => {
                    Feathers.service("configuraciones").patch(
                        res.data[0].id, {
                            valor: data.rates.MXN,
                            fecha_cambio: currentDate,
                        }
                    )
                    .then((res) => {
                      this.setState({
                        valueDollar: res.valor
                      }) 
                    })
                });
            }
        })

/*      */
  };



  fecthPegamentos = async () => {
    let pegamentos = await Feathers.service("pegamento").find({
      query: {
        $limit: "-1",
        activo: 1
      },
    });

    let pegamento_options = pegamentos.map((v) => {
      return {
        key: v.id,
        text: v.nombre,
        value: v.id,
      };
    });

    this.setState({
      pegamento_options,
    });
  };

  componentDidMount() {
    this.fecthPegamentos();
    //this.fetchDollarValue();
    this.fetchApiDollar();
  }

  handleSubmit() {
    if (!this.state.material.nombre) {
      notify.show("Debe llevar un nombre", "error", 2000);
      return;
    }
    if (!this.state.material.pegamento_id) {
      notify.show("Debe llevar un pegamento", "error", 2000);
      return;
    }

    if (!this.state.material.densidad) {
      notify.show("Debe llevar un densidad", "error", 2000);
      return;
    }

    if (!this.state.material.costo) {
      notify.show("Debe llevar un costo en dolares", "error", 2000);
      return;
    }

    if (!this.state.material.precio) {
      notify.show("Debe de llevar un precio", "error", 2000);
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("material")
          .create({
            nombre: this.state.material.nombre,
            pegamento_id: this.state.material.pegamento_id,
            densidad: this.state.material.densidad,
            costo: this.state.material.costo,
            precio: this.state.material.precio,
            activo: 1,
          })
          .then((res) => {
            this.props.callback && this.props.callback();

            this.props.refreshList && this.props.refreshList();

            notify.show("Material Compuesto creado", "success");
            this.setState({
              loading: false,
              material: {
                pegamento_id: null,
                nombre: "",
                densidad: "",
                costo: "",
                precio: "",
              },
            });
          })
          .catch((err) => {
            notify.show("Se produjo un error", "error");
            this.setState({ loading: false });
          });
      }
    );
  }

  render() {
    return (
      <div>
        <Form size={"large"}>
          <Grid>
            <Grid.Row columns={2} divided>
              <Grid.Column>
                <Grid.Row>
                  <Form.Input
                    label="Nombre"
                    name="nombre"
                    value={this.state.material.nombre}
                    type="text"
                    placeholder="Nombre"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        material: { ...this.state.material, [name]: value },
                      })
                    }
                    required
                  />
                  <Form.Dropdown
                    label="Pegamento"
                    value={this.state.material.pegamento_id}
                    required
                    placeholder="Pegamento"
                    fluid
                    selection
                    search
                    options={this.state.pegamento_options}
                    onChange={(e, d) => {
                      this.setState({
                        material: {
                          ...this.state.material,
                          pegamento_id: d.value,
                        },
                      });
                    }}
                  />
                </Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row>
                  <Form.Input
                    label="Densidad"
                    name="densidad"
                    value={this.state.material.densidad}
                    type="number"
                    placeholder="Densidad"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        material: { ...this.state.material, [name]: value },
                      })
                    }
                    required
                  />
                  <Form.Group widths="equal">
                    <Form.Input
                      label="Costo USD"
                      name="costo"
                      value={this.state.material.costo}
                      type="number"
                      placeholder="$USD"
                      onChange={(e, { name, value }) =>
                        this.setState({
                          material: { ...this.state.material, [name]: value },
                        })
                      }
                      required
                    />
                    <Form.Input
                      label="Precio USD"
                      name="precio"
                      value={this.state.material.precio}
                      type="number"
                      placeholder="$USD"
                      onChange={(e, { name, value }) =>
                        this.setState({
                          material: { ...this.state.material, [name]: value },
                        })
                      }
                      required
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Input
                      label="Costo MXN"
                      name="costoMX"
                      readOnly
                      value={this.state.material.costo * this.state.valueDollar}
                    />
                    <Form.Input
                      label="Precio MXN"
                      name="precioMX"
                      readOnly
                      value={this.state.material.precio * this.state.valueDollar}
                    />
                  </Form.Group>

                </Grid.Row>
                <Button
                  disabled={this.state.loading}
                  loading={this.state.loading}
                  type="button"
                  onClick={this.handleSubmit}
                  floated="right"
                  positive
                  content="REGISTRAR"
                  icon="send"
                  labelPosition="left"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}
