import React from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import { Button, Form, Search, Grid, Label } from "semantic-ui-react";
import Toggle from "react-toggle";
import ISOCodeEditModal from "./ISOCodeEditModal";

const renderCodeSearchResults = ({ id, code }) => (
  <Label key={id} content={code} />
);

const renderDocumentSearchResults = ({ id, document }) => (
  <Label key={id} content={document} />
);

export default class ISOCodeList extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 10,
      currentRow: {},
      isEditOpened: false,
      $sortDirection: 1,
      filterOptions: {
        id: null,
      },
      // search bar objects
      document: null,
      code: null,
      // search bar results
      documentResults: null,
      codeResults: null,
      // search bar loadings
      documentLoadings: false,
      codeLoadings: false,
      columna: "",
      sortDirection: false,
    };
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Documento":
        array.data.sort((a, b) => {
          const nameA = a.document.toUpperCase(); // ignore upper and lowercase
          const nameB = b.document.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Código":
        array.data.sort((a, b) => {
          const nameA = a.code.toUpperCase(); // ignore upper and lowercase
          const nameB = b.code.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  handleCodeResultSelect = async (e, { result }) => {
    this.setState({ code: result.code });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleDocumentResultSelect = async (e, { result }) => {
    this.setState({ document: result.document });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleCodeSearchChange = (e, { value }) => {
    this.setState({ codeLoadings: true, code: value });

    setTimeout(() => {
      if (this.state.code.length < 1) return this.resetCodeComponent();

      Feathers.service("codigos_iso")
        .find({
          query: {
            code: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            codeLoadings: false,
            codeResults: res.data,
          });
        });
    }, 500);
  };

  handleDocumentSearchChange = async (e, { value }) => {
    this.setState({ documentLoadings: true, document: value });

    setTimeout(() => {
      if (this.state.document.length < 1) return this.resetDocumentComponent();

      Feathers.service("codigos_iso")
        .find({
          query: {
            document: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            documentLoadings: false,
            documentResults: res.data,
          });
        });
    }, 500);
  };

  handleChange = (event, row) => {
    Feathers.service("codigos_iso").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  };

  closeEditModal = () => {
    this.setState({
      isEditOpened: false,
    });
  };

  openEditModal = (row) => {
    this.setState({
      currentRow: row.original,
      isEditOpened: true,
    });
  };

  handleCleanButton = (_) => {
    this.resetCodeComponent();
    this.resetDocumentComponent();

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  resetCodeComponent = () =>
    this.setState({ codeLoadings: false, codeResults: [], code: "" });
  resetDocumentComponent = () =>
    this.setState({
      documentLoadings: false,
      documentResults: [],
      document: "",
    });

  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.id != null) {
      query["id"] = this.state.filterOptions.id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("codigos_iso")
        .find({
          query,
        })
        .then((res) => {
          res = this.handleSort(res);
          this.setState({
            data: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        });
    });
  };

  render() {
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Documento:</label>
                  <Search
                    loading={this.state.documentLoadings}
                    value={this.state.document}
                    results={this.state.documentResults}
                    onResultSelect={this.handleDocumentResultSelect}
                    onSearchChange={this.handleDocumentSearchChange}
                    resultRenderer={renderDocumentSearchResults}
                    placeholder="Documento"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Codigo:</label>
                  <Search
                    loading={this.state.codeLoadings}
                    value={this.state.code}
                    results={this.state.codeResults}
                    onResultSelect={this.handleCodeResultSelect}
                    onSearchChange={this.handleCodeSearchChange}
                    resultRenderer={renderCodeSearchResults}
                    placeholder="Código"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Documento", accessor: "document" },
            { Header: "Código", accessor: "code" },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size={"tiny"}
                      compact
                      basic
                      color="blue"
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.data}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPagesize={5}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftkey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }
            this.setState({
              columna: column,
              sortDirection: direction,
            });
            this.fetchData();
          }}
        />
        <ISOCodeEditModal
          open={this.state.isEditOpened}
          onEdit={this.fetchData}
          data={this.state.currentRow}
          onClose={this.closeEditModal}
        />
      </>
    );
  }
}