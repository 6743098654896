import React, { Component } from "react";
import { Modal, Form, Grid, Table, Button, Icon, Dropdown } from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import NumberFormat from "react-number-format";

export default class FormulasInfoModal extends Component {
  constructor() {
    super();

    this.state = {
      rawMaterials: [],
      productsFormula: [],
      products: [],
    };

    this.getRawMaterials = this.getRawMaterials.bind(this);
  }

  componentWillReceiveProps(nextProps) {

    
    if (nextProps.formula) {
      this.fetchProducts();
      this.fetchFormulasProducts(nextProps.formula.id);
      this.getRawMaterials(nextProps.formula.id);
    }
  }

  fetchFormulasProducts = async (formula_id) => {
    Feathers.service('formula_productos').find({
      query: {
        formula_id: formula_id
      }
    })
    .then(res => {
      const products = res.data.map((v, i) => {
        return v.producto_id
      })

      this.setState({
        productsFormula: products
      })
    })
  }

  fetchProducts = async () => {
    await Feathers.service("producto")
      .find({
        query: {
          $limit: "-1",
        },
      })
      .then((products) =>
        this.setState({
          products: products.map((p) => {
            return {
              value: p.id,
              text: p.nombre,
            };
          }),
        })
      );
  };

  getRawMaterials(formula_id) {
    Feathers.service("formula_materia_prima")
      .find({
        query: { formula_id },
      })
      .then((rawMaterials) => {
        this.setState({ rawMaterials });
      });
  }

  render() {
    if (!this.props.formula) return null;

    return (
      <Modal open={this.props.open} size={"large"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          {this.props.formula.nombre}
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content scrolling>
          <div>
            <Form>
              <Grid divided style={{ fontSize: "16px" }}>
                {this.props.formula.fecha_baja || this.props.formula.motivo_baja  ? (
                <Grid.Row  columns={2}>
                  <Grid.Column>
                    <Grid.Row>
                      <Icon name="info" />
                      <span style={{ fontWeight: "bold" }}>Motivo de baja</span>
                    </Grid.Row>
                    <Grid.Row>
                      <span>
                        {this.props.formula.motivo_baja
                          ? this.props.formula.motivo_baja
                          : "---"}
                      </span>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column>
                    <Grid.Row>
                      <Icon name="calendar" />
                      <span style={{ fontWeight: "bold" }}>Fecha de baja</span>
                    </Grid.Row>
                    <Grid.Row>
                      <span>
                        {this.props.formula.fecha_baja
                          ? this.props.formula.fecha_baja
                          : "---"}
                      </span>
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
                ) : (
                  <></> 
                )}
                
                <Grid.Row  columns={5}>
                  <Grid.Column>
                    {/*  <Form.Input className="input-readOnly" readOnly label="Material" value={this.props.formula.material.nombre} /> */}
                    <Grid.Row>
                      <Icon name="circle thin" />
                      <span style={{ fontWeight: "bold" }}>Material</span>
                    </Grid.Row>
                    <Grid.Row>
                      <span>{this.props.formula.material.nombre}</span>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column>
                    <Grid.Row>
                      <Icon name="circle thin" />
                      <span style={{ fontWeight: "bold" }}>Densidad</span>
                    </Grid.Row>
                    <Grid.Row>
                      <span>
                        {this.props.formula.densidad
                          ? this.props.formula.densidad
                          : "---"}
                      </span>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column>
                    <Grid.Row>
                      <Icon name="circle thin" />
                      <span style={{ fontWeight: "bold" }}>Kilos molienda</span>
                    </Grid.Row>
                    <Grid.Row>
                      <span>
                        {this.props.formula.kilos_molienda
                          ? this.props.formula.kilos_molienda
                          : "---"}
                      </span>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column>
                    {/* <Form.Input className="input-readOnly" readOnly label="Dureza" value={this.props.formula.dureza.valor}/> */}
                    <Grid.Row>
                      <Icon name="legal" />
                      <span style={{ fontWeight: "bold" }}>Dureza</span>
                    </Grid.Row>
                    <Grid.Row>
                      <span>{this.props.formula.dureza.valor}</span>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column>
                    {/* <Form.Input className="input-readOnly" readOnly label="Color" value={this.props.formula.color ? this.props.formula.color.nombre : "---"} /> */}
                    <Grid.Row>
                      <Icon name="paint brush" />
                      <span style={{ fontWeight: "bold" }}>Color</span>
                    </Grid.Row>
                    <Grid.Row>
                      <span>
                        {this.props.formula.color
                          ? this.props.formula.color.nombre
                          : "---"}
                      </span>
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={16}>
                    <Grid.Row>
                      <Icon name="circle thin" />
                      <span style={{ fontWeight: "bold" }}>
                        Productos para esta formula
                      </span>
                    </Grid.Row>
                    <Grid.Row>
                      <Form.Input className="input-readOnly">
                        <Dropdown
                          fluid
                          multiple
                          selection
                          options={this.state.products}
                          value={this.state.productsFormula}
                        />
                      </Form.Input>
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>

            <Table compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Materia prima</Table.HeaderCell>
                  <Table.HeaderCell>Partes</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.rawMaterials.map((rm, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {rm.materiaPrima.nombre +
                          " - " +
                          rm.materiaPrima.quimico}
                      </Table.Cell>
                      <Table.Cell>
                        <NumberFormat
                          value={rm.partes}
                          displayType={"text"}
                          allowEmptyFormatting={true}
                          decimalScale={3}
                          fixedDecimalScale={true}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
