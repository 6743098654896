import React, { Component } from 'react'
import {Modal, Grid, Icon, Button, Form} from 'semantic-ui-react'
import Notifications, {notify} from 'react-notify-toast';
import Feathers from '../../redux/FeathersRedux'


export default class ProfilesForm extends Component {

  state = {
    perfil: {
      nombre: '',
      activo: 1
    },
    loading: false
  }


  handleSubmit = () =>{
    if (!this.state.perfil.nombre) {
      notify.show('El perfil debe llevar un nombre', 'error', 2000);
      return;
    }
     this.setState({
            loading: true
        }, () => {
            Feathers.service('perfil').create({
              ...this.state.perfil
            }).then(res => {  
              notify.show('Perfil Creado', 'success');
              this.props.onCreate()

                this.setState({ loading: false, perfil: {
                  nombre: '',
                  activo: 1
                }});

            }).catch(err => {
                notify.show('Se produjo un error', 'error');
                this.setState({ loading: false })
            })
        })
  }

  render() {
    return (
      <div>
      <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Grid.Row>
              <Form.Input label='Nombre' name='nombre' value={this.state.perfil.nombre} type='text' placeholder='Nombre' onChange={(e, {name, value}) => this.setState({ perfil: {...this.state.perfil, [name] : value} })} required/>
              <Button disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='REGISTRAR' icon='send' labelPosition='left' />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
      </div>
    )
  }
}
