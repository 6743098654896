import React, { Component } from 'react';
import { Header, Segment, Grid, Icon, Button, Divider, Form } from 'semantic-ui-react'
import { TaskOrderSegment, TaskStatusSegment, TaskItemSegment} from '../'
import Timer from '../../Timer'
import Incidence from '../../Incidence/Incidence'
import Feathers from '../../../redux/FeathersRedux';
import swal from 'sweetalert2'

const PHASE = 7;
export default class RectifiedTask extends Component {


    constructor(props){
      super(props);
      this.state = {
        producto_id: this.props.task.rodillo.partida.producto_id,
        lc_final: null,
        de_final: null,
        dureza: null,
      }
    }


    componentDidMount(){
        console.log("task")
        console.log(this.props)
    }

  renderInitButton = () => {
        if (this.props.task.hora_inicio) return null;
        return (
            <div style={{marginTop: 20}}>
                <Button onClick={() => this.props.initTask(this.props.task)}  icon='play' fluid color="green" content='Iniciar'  />
             </div>
        )
    };


    finishTask = () => {
        if([1, 4].indexOf(this.state.producto_id) !== -1) {
          if (!this.props.task.lc_final || !this.props.task.de_final || !this.props.task.dureza) return swal({
              title: 'Atención',
              text: 'Primero debes capturar la información pendiente para finalizar la tarea',
              type: 'info',
          });
        }
        
        if([2].indexOf(this.state.producto_id) !== -1){
          this.props.finishTask && this.props.finishTask();
        }

        if([8].indexOf(this.state.producto_id) !== -1){
            this.props.finishTask && this.props.finishTask();
        }

        this.props.finishTask && this.props.finishTask();

    };


    saveInfo = async () => {


        
       

        const lc_esperado = +(this.props.task.rodillo.partida.lc)
        const de_esperado = +(this.props.task.rodillo.partida.de)
        const dureza_esperada = this.props.task.rodillo.partida.dureza ? +(this.props.task.rodillo.partida.dureza.valor) : 0

        let holgura_de = await Feathers.service('configuraciones').find({
            query: {
              nombre: 'holgura_de'
            }
          })
        
        holgura_de = +(holgura_de.data[0].valor)

        let holgura_lc = await Feathers.service('configuraciones').find({
            query: {
                nombre: 'holgura_lc'
            }
        })
  
        holgura_lc = +(holgura_lc.data[0].valor)


        let holgura_dureza = await Feathers.service('configuraciones').find({
            query: {
                nombre: 'holgura_dureza'
            }
        })

        holgura_dureza = +(holgura_dureza.data[0].valor)

        if([1, 4].indexOf(this.state.producto_id) !== -1){
            const { lc_final, de_final, dureza } = this.state;

            if(+(de_final) <  +(de_esperado - holgura_de) || +(de_final) >  de_esperado ){
                await swal({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
                })
                await this.incidence.getWrappedInstance().openFromOutside()
                return
            }

            if(+(lc_final) <  +(lc_esperado - holgura_lc) || +(lc_final) >  lc_esperado ){
                await swal({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
                })
                  await this.incidence.getWrappedInstance().openFromOutside()
                  return
            } 

            if( +(dureza) <  +(dureza_esperada - holgura_dureza) || +(dureza) > +(dureza_esperada + holgura_dureza) ){
                await swal({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
                })
                  await this.incidence.getWrappedInstance().openFromOutside()
                  return
            }
          
            Feathers.service('rectificado').patch(this.props.task.id, {
                lc_final,
                de_final,
                dureza
            })
            .then(async res => {
                    this.props.finishTask && this.props.finishTask();
            })
        }

        if([2].indexOf(this.state.producto_id) !== -1){
          let { de_final } = this.state;

          if(de_final === null) de_final = -1
          Feathers.service('rectificado').patch(this.props.task.id, {
                de_final,
            })
            .then(async res => {
                    this.props.finishTask && this.props.finishTask();
            })
        }

        if([8].indexOf(this.state.producto_id) !== -1){
            this.props.finishTask && this.props.finishTask();
        }


    };


    renderForm = () => {
        if(!this.props.task.hora_inicio) return null;
        if([1, 4].indexOf(this.state.producto_id) !== -1){
        if (!this.props.task.lc_final) {

            return (
                <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                    <Segment>
                        <Form>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column computer={3} tablet={3} mobile={8}>
                                        <Form.Input name="lc_final" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='LC final' placeholder='Largo de cara final'/>
                                    </Grid.Column>
                                    <Grid.Column computer={3} tablet={3} mobile={8}>
                                        <Form.Input name="de_final" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DE final' placeholder='Diámetro externo final'/>
                                    </Grid.Column>
                                    <Grid.Column computer={3} tablet={3} mobile={8}>
                                        <Form.Input name="dureza" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='Dureza' placeholder='Dureza'/>
                                    </Grid.Column>
                                    <Grid.Column computer={6} tablet={6} mobile={16}>
                                        <Button type="button" onClick={this.saveInfo} disabled={!this.state.lc_final || !this.state.de_final || !this.state.dureza} fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR' />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Segment>
                </div>
            )
        }

        return (
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Segment>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column computer={4} tablet={4} mobile={8}>
                                    <span style={{display: 'block', fontWeight: 'bold'}}>LC Final</span>
                                    <span>{this.props.task.lc_final}</span>
                                </Grid.Column>
                                <Grid.Column computer={4} tablet={4} mobile={8}>
                                    <span style={{display: 'block', fontWeight: 'bold'}}>DE Final</span>
                                    <span>{this.props.task.de_final}</span>
                                </Grid.Column>
                                <Grid.Column computer={4} tablet={4} mobile={8}>
                                    <span style={{display: 'block', fontWeight: 'bold'}}>Dureza</span>
                                    <span>{this.props.task.dureza}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </Segment>
            </div>
        )

      }
      if([2].indexOf(this.state.producto_id) !== -1){
        if (!this.props.task.de_final) {

            return (
                <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                    <Segment>
                        <Form>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column computer={4} tablet={8} mobile={8}>
                                        <Form.Input name="de_final" onChange={(e , {name, value}) => this.setState({ [name]: value })} type="text" label='DE final' value={this.state.de_final === null ? "" : this.state.de_final} placeholder='Diámetro externo final'/>
                                    </Grid.Column>
                                    <Grid.Column computer={6} tablet={8} mobile={16}>
                                        <Button type="button" onClick={this.saveInfo} fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR' />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Segment>
                </div>
            )
        }
        return (
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Segment>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column computer={4} tablet={4} mobile={8}>
                                    <span style={{display: 'block', fontWeight: 'bold'}}>DE Final</span>
                                    <span>{this.props.task.de_final}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </Segment>
            </div>
        )
      }
      return null;
    };
  
  
  render() {
        return (
            <div>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Header as='h2'>
                            <Button onClick={this.props.goBack} icon="arrow left" style={{backgroundColor: 'transparent', color: '#000000de'}}/>
                            Tarea de Rectificado
                            </Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Incidence ref={ref => this.incidence = ref} phase={PHASE} task={this.props.task} cancelTaskByIncidence={this.props.cancelTaskByIncidence} rodillo={this.props.task.rodillo_id} pedido={this.props.task.rodillo.partida.pedido_id} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />

                <TaskStatusSegment task={this.props.task} finishTask={this.finishTask}></TaskStatusSegment>
                <TaskOrderSegment order={this.props.task.rodillo.partida.pedido} kilos={this.props.task.rodillo.partida.kilos} />

                <Header as='h3' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px', backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='list' />
                    <Header.Content>Información de la partida</Header.Content>
                </Header>
                <TaskItemSegment roller={this.props.task.rodillo.id} item={this.props.task.rodillo.partida} />
          <this.renderForm/>
          {this.renderInitButton()}

          </div>
        )
    }
}
