import React, { Component } from "react";
import Feathers from "../redux/FeathersRedux";
import { Grid, Header, Image, Button } from "semantic-ui-react";
import { push } from "react-router-redux";
import { connect } from "react-redux";

class FoldersBinghamReportContainer extends Component {
  constructor() {
    super();
    this.state = {
      folders: [],
      folderTS: [],
      loading: false,
    };
  }

  //Traer todos los tipos de tareas excluyendo la norma ISO 9000
  fetchFolders = async () => {
    await Feathers.service("carpetas_bingham")
      .find({
        query: {
          id: {
            $ne: 5,
          }
        },
      })
      .then((res) => {
        this.setState({
          folders: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchFoldersTC = async () => {
    await Feathers.service("carpetas_bingham")
      .find({
        query: {
          id: 5
        },
      })
      .then((res) => {
        this.setState({
          folderTS: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }


  handleFolderData = (id) => {
      this.props.dispatch(push(`/reports/foldersbingham/${id}`));
  };

  handleFolderTS = () => {
    this.props.dispatch(push('/reports/foldersbinghamTS'));
  };

  componentDidMount() {
    this.fetchFolders();
    this.fetchFoldersTC();
  }

  render() {
    //const { nombre, id } = this.state.task_iso
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header
                as="h2"
                icon="calendar check outline"
                content="Carpetas generales de bingham"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            {this.state.folders.map((item) => (
              <Grid.Column key={item.id}>
                <section  style={{ marginBottom: "3rem" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "1rem" }}>
                      <Image
                        src={require("../resources/images/carpeta.svg")}
                        size="tiny"
                      />
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <h4 style={{ marginBottom: "5px" }}>{item.nombre}</h4>
                      <Button
                        basic
                        size="tiny"
                        color="grey"
                        onClick={() => this.handleFolderData(item.id)}
                      >
                        Abrir carpeta
                      </Button>
                    </div>
                  </div>
                </section>
              </Grid.Column>
            ))}
            {this.state.folderTS ? (
            <Grid.Column>
              <section  style={{ marginBottom: "3rem" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "1rem" }}>
                    <Image
                      src={require("../resources/images/carpeta.svg")}
                      size="tiny"
                    />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <h4 style={{ marginBottom: "5px" }}>{this.state.folderTS.nombre}</h4>
                    <Button
                      basic
                      size="tiny"
                      color="grey"
                      onClick={() => this.handleFolderTS()}
                    >
                      Abrir carpeta
                    </Button>
                  </div>
                </div>
              </section>
            </Grid.Column>
            ) : ( 
              <></>
            )}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(FoldersBinghamReportContainer);
