import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Label,
  Grid,
  Search,
} from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../../redux/FeathersRedux";
import GluesEditModal from "./GluesEditModal";
import FreightEditModal from "../Freight/FreightEditModal";
import { exportToXls } from "../../utils/Utils";

const renderNameSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

export default class GluesList extends Component {
  state = {
    glues: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 100,
    currentRow: {},
    isEditOpened: false,
    $sortDirection: 1,

    filterOptions: {
      id: null,
    },

    // Search bar Results

    glueResults: null,

    // Search bar objects

    glue: null,

    // Search bar loading

    glueLoading: false,
    columna: "",
    sortDirection: false,
    shouldExport: false,
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Descripción":
        array.data.sort((a, b) => {
          const nameA = a.descripcion.toUpperCase(); // ignore upper and lowercase
          const nameB = b.descripcion.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  handleNameResultSelect = async (e, { result }) => {
    this.setState({ glue: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleNameSearchChange = (e, { value }) => {
    this.setState({ glueLoading: true, glue: value });

    setTimeout(() => {
      if (this.state.glue.length < 1) return this.resetNameComponent();

      Feathers.service("pegamento")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          console.log(res);
          res = this.handleSort(res);
          this.setState({
            glueLoading: false,
            glueResults: res.data,
          });
        });
    }, 500);
  };

  resetNameComponent = () =>
    this.setState({ glueLoading: false, glueResults: [], glue: "" });

  /* handleChange = (data, row) => {
      Feathers.service('pegamento').patch(row.original.id, {
        activo: data.checked ? 1: 0
      })
        .then(res => {
          this.fetchData();
        })

    }; */

  handleChange(event, row) {
    Feathers.service("pegamento").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  closeEditModal = (_) => {
    this.setState({
      isEditOpened: false,
    });
  };

  openEditModal = (row) => {
    this.setState(
      {
        currentRow: row.original,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isEditOpened: true,
          });
        }, 100);
      }
    );
  };

  handleCleanButton = (_) => {
    this.resetNameComponent();

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.id != null) {
      query["id"] = this.state.filterOptions.id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("pegamento")
        .find({
          query,
        })
        .then((res) => {
          console.log(res.data);
          this.handleSort(res);

          if (!this.state.shouldExport) {
            this.setState({
              glues: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "admin_glues");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };
  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre</label>
                  <Search
                    icon=""
                    loading={this.state.glueLoading}
                    value={this.state.glue}
                    results={this.state.glueResults}
                    onResultSelect={this.handleNameResultSelect}
                    onSearchChange={this.handleNameSearchChange}
                    resultRenderer={renderNameSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre", accessor: "nombre" },
            { Header: "Descripción", accessor: "descripcion" },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size={"tiny"}
                      compact
                      basic
                      color="blue"
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                    {/* <Checkbox toggle onClick={(e, data) => this.handleChange(data, row)} checked={row.original.activo === 1}/> */}
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.glues}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />
        <GluesEditModal
          open={this.state.isEditOpened}
          onEdit={this.fetchData}
          pegamento={this.state.currentRow}
          onClose={this.closeEditModal}
        />
      </div>
    );
  }
}
