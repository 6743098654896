import React, {Component} from 'react';
import { Button } from 'semantic-ui-react'
import PropTypes from 'prop-types';
import moment from 'moment'

export default class Timer extends Component {
    constructor() {
        super()

        this.state = {
            elapsed: 0
        }

        this.update = this.update.bind(this)
        this.humanizeUnit = this.humanizeUnit.bind(this)
    }

    componentDidMount() {
        this.update();
        this.timer = setInterval(this.update, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    update() {
        this.setState({
            elapsed: moment().diff(moment(this.props.start), 'seconds')
        })
    }

    humanizeUnit(unit) {
        return unit > 9 ? unit : '0' + unit;
    }

    render() {
        if (this.state.elapsed) {
            let secondsLeft = this.state.elapsed % 3600;
            let hours = Math.floor(this.state.elapsed / 3600);
            let minutes = Math.floor(secondsLeft / 60)
            secondsLeft %= 60;

            return (
                <Button.Group>
                    {(this.props.renderFinish === false || this.props.renderFinish === undefined) && (
                        <Button onClick={() => this.props.finishTask && this.props.finishTask()} style={{backgroundColor: '#6f0000'}} color="red" icon='check'/>
                    )} 
                    <Button style={{backgroundColor: '#880000'}} color="red" content={`${this.humanizeUnit(hours)}:${this.humanizeUnit(minutes)}:${this.humanizeUnit(secondsLeft)}`} />
                </Button.Group>
            )
        }

        return <span>Cargando...</span>
        
    }
}