import React, { Component } from "react";
import {
  Grid,
  Button,
  Form,
  Dropdown,
  Divider,
  Header
} from "semantic-ui-react";
import {push} from "react-router-redux";
import {connect} from "react-redux";
import Feathers from "../redux/FeathersRedux";
import Notifications, { notify } from "react-notify-toast";
class UsersEditContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      usuario: {
        nombre: "",
        apellido: "",
        email: "",
        login: "",
        celular: "",
        perfiles: [],
        password: ""
      },
      loading: false,
      options: [],
      error: false
    };
  }
 

  componentDidMount = () => {
    this.fetchUserData();
    this.fetchData();
  };

  fetchUserData = () => {
    
    let perfiles_usuario;
    this.setState(
      {
        loading: false
      },
      () => {
        Feathers.service("usuario")
          .find({
            query: {
              id: this.props.match.params.id,
              activo: 1
            }
          })
          .then(res => {
            this.setState({
              usuario: {
                ...this.state.usuario,
                nombre: res.data[0].nombre,
                apellido: res.data[0].apellido,
                email: res.data[0].email,
                login: res.data[0].login,
                celular: res.data[0].celular
              }
            });
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    );

    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("usuario_perfil")
          .find({
            query: {
              usuario_id: this.props.match.params.id
            }
          })
          .then(res => {
            perfiles_usuario = res.map((v, i) => {
              return v.perfil_id;
            });

            this.setState({
              usuario: {
                ...this.state.usuario,
                perfiles: perfiles_usuario
              }
            });
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    );
  };

  fetchData = () => {
    
    let options;
    this.setState(
      {
        loading: false
      },
      () => {
        Feathers.service("perfil")
          .find({
            query: {
              activo: 1,
              doPagination: false
            }
          })
          .then(res => {
            options = res.map((v, i) => {
              return {
                key: i,
                text: v.nombre,
                value: v.id
              };
            });

            this.setState({
              options
            });
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    );
  };

  handleSubmit = () => {
    if (this.state.usuario.perfiles.length < 1) {
      this.setState({
        error: true
      });
      return;
    }

    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("usuario")
          .patch(this.props.match.params.id, {
            ...this.state.usuario
          })
          .then(res => {
            notify.show("Usuario Editado", "success");
            this.props.onCreate();
            this.setState({ loading: false });

          })
          .catch(err => {
            notify.show("Se produjo un error", "error");
            this.setState({ loading: false });
          });
      }
    );
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
            <Header as={'h2'}>
                <Button onClick={() => this.props.dispatch(push("/users"))} icon="arrow left" style={{backgroundColor: 'transparent', color: '#000000'}}/>
                  Editar Usuario
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Form onSubmit={this.handleSubmit}  size={'large'} style={{boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)", padding: "1rem"}}>
          <Form.Group widths="equal">
            <Form.Input
              label="Nombre"
              name="nombre"
              value={this.state.usuario.nombre}
              type="text"
              placeholder="Nombre"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              required
            />
            <Form.Input
              label="Apellido"
              name="apellido"
              value={this.state.usuario.apellido}
              type="text"
              placeholder="Apellido"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              required
            />
            <Form.Input
              label="Celular"
              name="celular"
              value={this.state.usuario.celular}
              type="number"
              placeholder="Celular"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Usuario"
              name="login"
              value={this.state.usuario.login}
              type="text"
              placeholder="Login"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              required
            />
            <Form.Input
              label="Contraseña"
              name="password"
              value={this.state.usuario.password}
              type="password"
              placeholder="Enter New Password"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
            />
          </Form.Group>
          <Form.Group widths="equal">
          <Form.Input
              label="Email"
              name="email"
              value={this.state.usuario.email}
              type="email"
              placeholder="Email"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              required
            />
            
            <Form.Input error={this.state.error} label="Perfil">
              <Dropdown
                required
                placeholder="Perfil"
                fluid
                multiple
                selection
                options={this.state.options}
                value={this.state.usuario.perfiles}
                onChange={(e, { value }) =>
                  this.setState({
                    error: false,
                    usuario: { ...this.state.usuario, perfiles: [...value] }
                  })
                }
              />
            </Form.Input>
          </Form.Group>

          <Button
            type="submit"
            floated="right"
            positive
            content="Editar"
            icon="send"
            labelPosition="left"
            loading={this.state.loading}
          />
        </Form>
      </div>
    );
  }
}
export default connect(null, null, null, {
  withRef: true
})(UsersEditContainer)