import React, { Component } from "react";
import {
  Grid,
  Segment,
  Icon,
  Header,
  Container,
  Button,
  Input,
  Image
} from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import axios from "axios";
import feathersRestClient from "@feathersjs/rest-client";
import feathers from "@feathersjs/feathers";
import Config from "../../config";
import swal from "sweetalert2";
import ReactTable from "react-table";
import { Dropzone } from "../Dropzone";

export default class DrawImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      image: null,
      nameDraw: "",
      total: 0,
      pageSize: 10,
      page: 0,
      loading: false,
    };
  }

  fetchDraw = async () => {
    await Feathers.service("dibujo")
      .get(this.props.id, {
        query: {
          $select: ["nombre"],
        },
      })
      .then((res) => {
        this.setState({
          nameDraw: res.nombre,
        });
      });
  };

  fetchData = async () => {
    await Feathers.service("draw_files")
      .find({
        query: {
          dibujo_id: this.props.id,
          type: "IMG",
        },
      })
      .then((res) => {
        this.setState({
          images: res.data,
        });
      });
  };

  uploadFile = () => {
    let newDataToSend = new FormData();
    if (
      document.getElementById("documentImage") &&
      document.getElementById("documentImage").files.length > 0 &&
      document.getElementById("documentImage").files[0]
    ) {
      let form = document.getElementById("documentImage").files[0];
      newDataToSend.set("documentFile", form);
    } else {
      swal({
        title: "No ha seleccionado ningún archivo",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }
    newDataToSend.set("dibujo_id", this.props.id);
    newDataToSend.set("type", "IMG");
    const token = localStorage.getItem("bingham-jwt");
    const app = feathers();
    const restClient = feathersRestClient(Config.apiUrl);
    app.configure(restClient.axios(axios));

    app
      .service("draw_files")
      .create(newDataToSend, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        swal({
          title: "¡Subida Exitosa!",
          type: "success",
          confirmButtonText: "OK",
        }).then((res) => {
          //this.props.onSave();
          this.fetchData();
          this.setState({ loading: false });
        });
      })
      .catch((err) => {
        //alert("Se produjo un error");
        swal({
          title: "¡Ocurrió un error!",
          type: "error",
          confirmButtonText: "OK",
        });
        this.setState({
          loading: false,
        });
      });
  };

  removeFile = (document) => {
    swal({
      title: "¿Eliminar esta imagen?",
      text: "Se eliminará la imagen de este listado",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        this.setState({ loading: true });
        await Feathers.service("draw_files").remove(document.id);
        swal({
          title: "Exito",
          text: "Se eliminó con éxito la imagen",
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        this.fetchData();
        this.setState({ loading: false });
      }
    });
  };

  componentDidMount = () => {
    this.fetchDraw();
  };

  onDropChange = (image) => {
    this.setState({ image });
  };

  render() {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Container style={{ margin: 20 }}>
                <Segment placeholder padded>
                  <Grid style={{ width: "70%", margin: "0 auto" }}>
                    <Grid.Row>
                      <Grid.Column textAlign="center" width={16}>
                        <Header icon as="h3">
                          <Icon name="pdf file outline" />
                          Subir imagen
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid style={{ width: "50%", margin: "0 auto" }}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Input
                          style={{ width: "100%" }}
                          accept="image/*"
                          type="file"
                          id="documentImage"
                          name="document"
                          onChange={(event) => {
                            if (
                              event.target.files &&
                              event.target.files.length > 0
                            ) {
                              this.setState({
                                document: event.target.files[0],
                              });
                            }
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16} textAlign="right">
                        <Button
                          loading={this.state.loading}
                          onClick={this.uploadFile}
                          floated="right"
                          color="blue"
                          labelPosition="left"
                          icon="upload"
                          content="Subir"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Container style={{ margin: 20 }}>
                <Segment>
                  <Header as="h2">
                    <Icon name="pdf file outline" />
                    <Header.Content>
                      Imagenes del dibujo: {this.state.nameDraw}
                      <Header.Subheader>
                        Imagenes que pertenecen a este dibujo
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <ReactTable
                    columns={[
                      {
                        Header: "Dibujo",
                        id: "file",
                        accessor: (row) => {
                          return (
                            <div>
                              <Image
                                src={`${Config.apiUrl}/documents/drawFiles/${row.document}`}
                                style={{ height: "100px", margin: "0 auto" }}
                              />
                            </div>
                          );
                        },
                      },
                      {
                        Header: "Acciones",
                        Cell: (row) => (
                          <Button
                            size="tiny"
                            content="Eliminar"
                            compact
                            basic
                            color="red"
                            icon="delete"
                            onClick={() => this.removeFile(row.original)}
                          />
                        ),
                      },
                    ]}
                    manual
                    data={this.state.images}
                    loading={this.state.loading}
                    pages={Math.ceil(this.state.total / 5)}
                    onFetchData={this.fetchData}
                    defaultPageSize={10}
                    style={{ textAlign: "center", marginTop: "1rem" }}
                  />
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}
