import React, { Component } from "react";
import {
  Modal,
  Grid,
  Icon,
  Button,
  Form,
  Dropdown,
  Divider,
  Header
} from "semantic-ui-react";
import Notifications, { notify } from "react-notify-toast";
import {push} from "react-router-redux";
import {connect} from "react-redux";
import Feathers from "../redux/FeathersRedux";

 class RolEditContainer extends Component {
  constructor(props) {
    super(props);
    this.rolId = props.match.params.id;
    this.state = {
      loading: false,
      rolEdit: {},
      permission: [],
      currentPermission: [],
      currentRolPermission: [],
      defaultCurrentRolPermission: [],
      data: [],
      error: false,
      exist: false
    };
  }

  componentDidMount = () => {
    //this.fetchUserData();
    this.getRole();
    this.getPermissions();
    this.getRolePermission();
  };

  /**
   * Obtiene la información del rol
   * @memberof RolEditContainer
   */
  getRole = () => {
    // console.log("FETCH-DATA");
    let rol;
    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("perfil")
          .find({
            query: {
              id: this.rolId,
              activo: 1,
              doPagination: false
            }
          })
          .then(res => {
            this.setState(
              {
                rolEdit: { ...res[0] }
              },
              () => console.log("this.state:", this.state.rolEdit)
            );
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    );
  };

  /**
   * Obtiene todos los permisos
   * @memberof RolEditContainer
   */
  getPermissions = () => {
    console.log("FETCH-DATA");
    let rol;
    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("permiso")
          .find({
            query: {
              doPagination: false
            }
          })
          .then(res => {
            this.setState(
              {
                permission: [...res]
              },
              () => console.log("tttttt:", this.state.permission)
            );
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    );
  };

  /**
   * Obtiene los permisos asignado al perfil/rol
   * @memberof RolEditContainer
   */
  getRolePermission = () => {
    console.log("FETCH-DATA");
    let rol;
    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("perfil-permiso")
          .find({
            query: {
              perfil_id: this.rolId,
              doPagination: false
            }
          })
          .then(res => {
            const ids = res.map(item => {
              return item.permiso_id;
            });
            this.setState(
              {
                currentRolPermission: [...res],
                defaultCurrentRolPermission: ids
              },
              () =>
                console.log(
                  "Permisos de rol asignados actualmente:",
                  this.state.defaultCurrentRolPermission
                )
            );
            this.setState({ loading: false });
          })
          .catch(err => {
            console.log("eroor PErmisos de rol asignados actualmente:", err);
            this.setState({ loading: false });
          });
      }
    );
  };

    /**
   * Actualiza el nombre del rol
   * @memberof RolEditContainer
   */
    updateRol = (perfil_id, nombreUpdate, result) => {
      console.log("Entro en update rol");
      Feathers.service("perfil")
        .patch(perfil_id, {
          nombre: nombreUpdate,
          permissions: result
        })
        .then(res => {        
          this.setState({
            loading: false,
            error: false
          });
        })
        .catch(err => {      
          this.setState({
            loading: false,
            error: true
          });
        });
    };
    /**
     * Elimina los permisos asignado al perfil
     * @memberof RolEditContainer
     */
    deletePermissionRole = perfil_id_delete => {
      console.log("Entro en delete");
      Feathers.service("perfil-permiso")
        .remove(null, {
          query: {
            perfil_id: perfil_id_delete
          }
        })
        .then(res => {       
          this.setState({
            loading: false,
            error: false
          });
        })
        .catch(err => {       
          this.setState({
            loading: false,
            error: true
          });
        });
    };
  
    /**
     * Crea los permisos asignado al perfil
     * @memberof RolEditContainer
     */
    createPermissionRole = arrayPermisos => {
      console.log(arrayPermisos,"Entro en crear");
      let result = [...arrayPermisos];
      Feathers.service("perfil-permiso")
        .create([...result])
        .then(res => {       
          this.setState({
            loading: false,
            error: true
          });
        })
        .catch(err => {        
          this.setState({
            loading: false,
            error: true
          });
        });
    };
  
    /**
     * Obtiene el arreglo de permisos cuando este cambia
     * @memberof RolEditContainer
     */
    onChangePermission = (name, value) => {
      this.setState({
        defaultCurrentRolPermission: [...value]
      });
    };

  /**
   * Guarda la información
   * @memberof RolEditContainer
   */
  handleSubmit = async () => {
    const {
      defaultCurrentRolPermission,
      currentRolPermission,
      error
    } = this.state;
    let objectPermissions = {};

    const result = defaultCurrentRolPermission.map(item => {
      return (objectPermissions = {
        permiso_id: item,
        perfil_id: +this.rolId
      });
    });

    console.log(result, 'resultados');

    //this.deletePermissionRole(this.rolId);
    this.updateRol(this.rolId, this.state.rolEdit.nombre, result);
    //this.createPermissionRole(result);

    if (!error) {
      notify.show("Rol actualizado.", "success");
    } else {
      notify.show("Ocurrió un error, intente de nuevo", "error");
    }
  };



  render() {
    const options = [
      { key: "1", text: "Si", value: 1 },
      { key: "0", text: "No", value: 0 }
    ];
    const stateOptions = this.state.permission.map(item => ({
      key: item.id,
      text: item.nombre,
      value: item.id
    }));
    // const defaultStateOptions = this.state.currentRolPermission.map(item => {
    //   return item.permiso_id;
    // });

    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as={'h2'}>
                <Button onClick={() => this.props.dispatch(push("/role_permission"))} icon="arrow left" style={{backgroundColor: 'transparent', color: '#000000'}}/>
                  Editar Rol
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Form onSubmit={this.handleSubmit}  size={'large'} style={{boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)", padding: "1rem"}}>
          <Form.Group widths="equal">
            <Form.Input
              label="Nombre"
              name="nombre"
              value={this.state.rolEdit && this.state.rolEdit.nombre}
              type="text"
              placeholder="Nombre"
              onChange={(e, { name, value }) =>
                this.setState({
                  rolEdit: {
                    ...this.state.rolEdit,
                    nombre: value
                  }
                })
              }
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Dropdown
              placeholder="Permisos"
              fluid
              multiple
              search
              selection
              value={this.state.defaultCurrentRolPermission}
              //   defaultValue={this.state.defaultCurrentRolPermission}
              options={stateOptions}
              onChange={(e, { name, value }) =>
                this.onChangePermission(name, value)
              }
            />
          </Form.Group>
          <Button
            type="submit"
            floated="right"
            positive
            content="Editar"
            icon="send"
            labelPosition="left"
          />
        </Form>
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true
})(RolEditContainer)