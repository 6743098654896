import React from "react";
import { Tab, Grid, Header } from "semantic-ui-react";
import PartidasList from "../components/Partidas/PartidasList";

export default class PartidasContainer extends React.Component {
  state = {
    currIndex: 0,
  };

  panes = [
    {
      menuItem: { key: "list", icon: "list", content: "Lista" },
      pane: (
        <Tab.Pane
          style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
          key={1}
        >
          <PartidasList />
        </Tab.Pane>
      ),
    },
  ];

  setTab = (index) => {
    this.setState({
      currIndex: index || 0,
    });
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="file text" content="Partidas" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Tab
                menu={{ secondary: true, pointing: true, color: "red" }}
                panes={this.panes}
                renderActiveOnly={false}
                activeIndex={this.state.currIndex}
                onTabChange={(event, data) => this.setTab(data.activeIndex)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
