import React, { Component } from "react";
import Feathers from "../../../redux/FeathersRedux";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../../components/Tasks/Cutting/CuttingTask";
import { notify } from "react-notify-toast";
import { connect } from "react-redux";
import LoaderData from "../../LoaderData";

class CuttingContainerTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      task: undefined,
    };

    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.initTask = this.initTask.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.goBack = this.goBack.bind(this);
    this.updateTask = this.updateTask.bind(this);
  }

  updateTask() {
    Feathers.service("corte")
      .get(this.props.match.params.id)
      .then((task) => this.setState({ task }));
  }

  goBack() {
    this.props.history.goBack();
  }

  checkActiveTasks() {
    Feathers.service("corte")
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          this.setState({
            task: res.data[0],
            ready: true,
          });
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  viewTask(task) {
    Feathers.service("corte")
      .get(this.props.match.params.id)
      .then((task) => {
        const userId = this.props.user.id;
        const taskUserId = task.usuario ? task.usuario.id : null;
        console.log(userId, taskUserId, "ids");
        if (taskUserId && taskUserId !== userId) {
          swal({
            title:
              "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
            type: "warning",
            confirmButtonText: "Aceptar",
          });
          this.goBack();
        } else {
          this.setState({ task });
        }
      });
  }

  initTask(task) {
    swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (res.value) {
        const validate = await Feathers.service("corte").get(
          this.state.task.id
        );
        if (validate.hora_inicio) {
          swal({
            title:
              "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
            type: "warning",
            confirmButtonText: "Aceptar",
          });
          this.goBack();
        } else {
          Feathers.service("corte")
            .patch(task.id, {
              hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
              status_tarea_id: 1,
              usuario_id: this.props.user.id,
            })
            .then((res) => {
              Feathers.service("corte")
                .get(res.id)
                .then((task) => this.setState({ task }));
            });
        }
      }
    });
  }

  finishTask() {
    swal({
      title: "¿Finalizar tarea de corte?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("corte")
          .patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          })
          .then(async (res) => {
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(11);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de corte #${this.state.task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }
            this.goBack();
          });
      }
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("corte")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        this.setState({
          task: null,
        });
        notify.show("Incidencia registrada.", "success");
      });
  }

  componentDidMount() {
    //this.checkActiveTasks();
    this.viewTask();
  }

  render() {
    if (this.state.task) {
      return (
        <TaskContainer
          goBack={this.goBack}
          initTask={this.initTask}
          finishTask={this.finishTask}
          task={this.state.task}
          updateTask={this.updateTask}
          cancelTaskByIncidence={this.cancelTaskByIncidence}
        />
      );
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CuttingContainerTask);
