import React, { Component } from "react";
import Feathers from "../../../redux/FeathersRedux";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../../components/Tasks/Windmill/WindmillTask";
import { notify } from "react-notify-toast";
import { connect } from "react-redux";
import LoaderData from "../../LoaderData";

class WindmillContainerTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      task: null,
      saeResults: null,
    };

    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.goBack = this.goBack.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.initTask = this.initTask.bind(this);
  }

  checkActiveTasks() {
    Feathers.service("molino")
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          this.setState({
            task: res.data[0],
            ready: true,
          });
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  initTask(task) {
    swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (res.value) {
        const validate = await Feathers.service("molino").get(
          this.state.task.id
        );
        if (validate.hora_inicio) {
          swal({
            title:
              "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
            type: "warning",
            confirmButtonText: "Aceptar",
          });
          this.goBack();
        } else {
          Feathers.service("molino")
            .patch(task.id, {
              hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
              status_tarea_id: 1,
              usuario_id: this.props.user.id,
            })
            .then((res) => {
              Feathers.service("molino")
                .get(res.id)
                .then((task) => {
                  this.setState({ task });
                });
            });
        }
      }
    });
  }

  viewTask() {
    Feathers.service("molino")
      .get(this.props.match.params.id)
      .then((task) => {
        const userId = this.props.user.id;
        const taskUserId = task.usuario ? task.usuario.id : null;
        console.log(userId, taskUserId, "ids");
        this.setState({ task });
        // if (taskUserId && taskUserId !== userId) {
        //   swal({
        //     title:
        //       "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
        //     type: "warning",
        //     confirmButtonText: "Aceptar",
        //   });
        //   this.goBack();
        // } else {
        // }
      });
  }

  goBack() {
    this.props.history.goBack();
  }

  finishTask() {
    swal({
      title: "¿Finalizar tarea de molino?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (!res.value) return;

      Feathers.service("molino")
        .patch(this.state.task.id, {
          hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
          status_tarea_id: 2,
        })
        .then(async (res) => {
          //!Registrar notis si esta activa para esta tarea
          const checkNotification = await Feathers.service(
            "notification_tasks"
          ).get(2);
          if (checkNotification.activo) {
            Feathers.service("notificacion").create({
              mensaje: `Se ha finalizado una tarea de molino #${this.state.task.id}`,
              perfil_id: "1",
              fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
              tipo: 4,
            });
          }
          this.goBack();
        });
    });
  }

  cancelTaskByIncidence() {
    this.setState({ task: null });
    notify.show("Incidencia registrada. Tarea reiniciada", "success");
  }

  componentDidMount() {
    this.viewTask();
  }

  render() {
    if (this.state.task) {
      return (
        <TaskContainer
          task={this.state.task}
          initTask={this.initTask}
          goBack={this.goBack}
          finishTask={this.finishTask}
          cancelTaskByIncidence={this.cancelTaskByIncidence}
        />
      );
    } else if (this.state.reloading) {
      return <span>Reiniciando tarea...</span>;
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(WindmillContainerTask);
