import React, {Component} from 'react';
import {Nodal, Grid, Icon, Button, Form} from 'semantic-ui-react';
import Notifications, {notify} from 'react-notify-toast';
import Feathers from '../../redux/FeathersRedux';

export default class TypeMachineForm extends Component {
    state={
        tipo_maquinaria: {
            nombre: '',
            activo: 1
        },
        loading: false
    }


    handleSubmit = () => {
        if(!this.state.tipo_maquinaria.nombre){
            notify.show('El tipo de maquina debe de llevar un nombre', 'error', 2000);
            return;
        }
        this.setState({
            loading: true
        }, () => {
            Feathers.service('tipo_maquinaria').create({
                ...this.state.tipo_maquinaria
            }).then(res => {
                notify.show('Tipo de maquinaria creado', 'success');
                this.props.onCreate();
                this.setState({loading: false, tipo_maquinaria: { 
                    nombre: '', 
                    activo: 1
                }});
            }).catch(err => {
                notify.show('Se produjo un error, intente de nuevo.', 'error');
                this.setState({loading: false})
            })
        })
    }

    render() {
        return (
            <div>
                <Form size={'large'} >
                    <Grid >
                        <Grid.Row columns={2} divided>
                            <Grid.Column>
                                <Form.Input label='Nombre' name='nombre' value={this.state.tipo_maquinaria.nombre} type='text' placeholder='Nombre' onChange={(e, {name, value}) => this.setState({ tipo_maquinaria: {...this.state.tipo_maquinaria, [name] : value} })} required/>
                            </Grid.Column>
                            <Grid.Column>
                                <Button disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='left' positive content='REGISTRAR' icon='send' labelPosition='left' style={{marginTop: 25}} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
        )
    }
}
