import React, { Component } from 'react'
import TableCell, { Divider, Grid, Form, Input, Button, Dropdown, Table, Header } from 'semantic-ui-react'
import swal from 'sweetalert2';
import Feathers from '../redux/FeathersRedux';
import moment from 'moment'
import {connect} from 'react-redux';

// este es el estado inicial del compuesto, para que podamos reiniciarlo facilmente
const INITIAL_STATE = {
    formula_id: null,
    cantidad: null,
    text: null,
    color_id: null,
    color_text: null
}

class CompoundDiscountStockContainer extends Component {

    state = {
        loading: false,
        compuesto: INITIAL_STATE,
        compuestos: [],

        // options
        formulas: [],
        colores: []
    }


    componentDidMount(){
        this.fetchColors()
        this.fetchFormulas()
    }

    // #region fetching data

    fetchFormulas = async () => {
        let formulas = await Feathers.service('formula').find({
            query: {
                $limit: '-1'
            }
        })

        formulas = formulas.map( v => {
            return {
                key: v.id,
                text: v.nombre,
                value: v.id
            }
        })

        this.setState({
            formulas
        })

    }

    fetchColors = async () => {
        let colores = await Feathers.service('color').find({
            query: {
                $limit: '-1'
            }
        })

        console.log(colores)

        colores = colores.map( v => {
            return {
                key: v.id,
                text: v.nombre,
                value: v.id
            }
        })


        this.setState({
            colores
        })
    }

    // #endregion fetching data


    // #region handlebutton funcions

    handleDeleteButton = (indice) => {

        let compuestos = [...this.state.compuestos];
        compuestos.splice(indice, 1)

        this.setState({
            compuestos,
        })


    }


    _handleSendButton = async () => {

        await this.setState({
            loading: true
        })

        let compuestosASubir = await Promise.all( this.state.compuestos.map( async comp => {

            let formula_tabla = await Feathers.service('formula').get(comp.formula_id)


            let material_id = formula_tabla.material_id;

            let pruebaSiExiste = await Feathers.service('inventario_compuesto').find({
                query : {
                    $limit: '-1',
                    formula_id: comp.formula_id,
                    color_id: comp.color_id
                }
            })


            if(pruebaSiExiste.length == 0){
                let cargaNuevoCompuesto = await Feathers.service('inventario_compuesto').create({
                    formula_id: comp.formula_id,
                    color_id: comp.color_id,
                    cantidad: 0 - comp.cantidad
                })
            }else{
                let cantidad = +(pruebaSiExiste[0].cantidad)

                cantidad -= +(comp.cantidad);

                let patcheaCompuestoExistente = await Feathers.service('inventario_compuesto').patch(pruebaSiExiste[0].id, {
                    cantidad
                })

            }

            let movimiento_inventario = await Feathers.service('movimientos_inventario').create({
                almacen_origen_id: 3,
                almacen_destino_id: 3,
                tipo_movimiento_id: 3,
                clave_id: material_id,
                cantidad: comp.cantidad,
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                usuario_id: this.props.user.id,
                formula_id: comp.formula_id
            })


        }))

        await swal(
            '¡Buen trabajo!',
            'Se descargaron correctamente los compuestos',
            'success'
          )


        this.setState({
            loading: false,
            compuestos: []
        })

    }

    _handleAddButton = () => {

        if(!this.state.compuesto.formula_id) return
        if(!this.state.compuesto.cantidad) return

        let compuesto = JSON.parse(JSON.stringify(this.state.compuesto))
        
        let compuestos = JSON.parse(JSON.stringify(this.state.compuestos))

        compuestos.push(compuesto)

        this.setState({
            compuesto: INITIAL_STATE,
            compuestos
        })

    }
    // #endregion handlebutton funcions
 
    renderTable = () => {
        
        return (
            <Table celled size={"large"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Fórmula</Table.HeaderCell>
                        <Table.HeaderCell>Color</Table.HeaderCell>
                        <Table.HeaderCell>Cantidad</Table.HeaderCell>
                        <Table.HeaderCell>Acción</Table.HeaderCell>
                        
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        this.state.compuestos.map( (v, i) => {
                            return(
                                <Table.Row key={i}>
                                    <Table.Cell>{v.text}</Table.Cell>
                                    <Table.Cell>{v.color_text}</Table.Cell>
                                    <Table.Cell>{v.cantidad}</Table.Cell>
                                    <Table.Cell><Button style={{background:"#F41B00", color: "white"}}  fluid icon="trash" content="Eliminar" onClick={() => this.handleDeleteButton(i)} /></Table.Cell>
                                </Table.Row>
                            )
                        })
                    }
                </Table.Body>
            </Table>
        )
        
    }

    _handleFormulaSelection = (e, d) => {


        let options = JSON.parse(JSON.stringify(this.state.formulas))

        let selected = options.find( v => {
            if(v.value == d.value) return v
        })



        this.setState({
            compuesto: {
                ...this.state.compuesto,
                text: selected.text,
                formula_id: d.value
            }
        })
    }

    _handleColorSelection = (e, d) => {
        let options = JSON.parse(JSON.stringify(this.state.colores))
        let selected = options.find( v => {
            if(v.value == d.value) return v
        })
        this.setState({
            compuesto: {
                ...this.state.compuesto,
                color_id: d.value,
                color_text: selected.text
            }
        }, _ => console.log(this.state.compuesto))
    }

    renderform = () => {
        return (
            <Form size={"large"}>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Fórmula</label>
                        <Dropdown search fluid selection placeholder="Fórmula" value={this.state.compuesto.formula_id} options={this.state.formulas} onChange={this._handleFormulaSelection } />
                    </Form.Field>
                    <Form.Field>
                        <label>Color</label>
                        <Dropdown search fluid selection placeholder="Color" value={this.state.compuesto.color_id} options={this.state.colores} onChange={this._handleColorSelection } />
                    </Form.Field>
                    <Form.Field>
                        <label>Cantidad</label>
                        <Input placeholder="Cantidad" onChange={(e ,d) => {
                            this.setState({
                                compuesto: {
                                    ...this.state.compuesto,
                                    cantidad: d.value
                                }
                            })
                        }} value={this.state.compuesto.cantidad || ""} />
                    </Form.Field>
                    <Form.Field>
                        <Button fluid content="Registrar Retiro" icon="minus"  onClick={this._handleAddButton} style={{marginTop: 23, background:"#F41B00", color: "white"}}/>
                    </Form.Field>
                </Form.Group>
                

            </Form>       
        )
    }

    render() {
        return (
            <div>
                <Header as="h1" icon="lab" content="Descarga de Compuestos"/>
                <Divider />
                <this.renderform />
                <Divider />
                <this.renderTable />
                <Button content="Descontar" loading={this.state.loading} disabled={this.state.loading || this.state.compuestos.length === 0} icon="send" style={{background:"#F41B00", color: "white"}} floated="right" onClick={this._handleSendButton} />
            </div>
        )
    }
}



const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(CompoundDiscountStockContainer)