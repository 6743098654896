import React, {Component} from 'react';
import {Button, Form, Grid, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {notify} from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";
import swal from 'sweetalert2';


class FreightEditModal extends Component {


    state = {
        flete: {
            tipo_flete:"",
            precio : "",
        },
        toEdit: 0,
        loading: false
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.freight) {
            this.setState({
                flete: {
                    tipo_flete: nextProps.freight.tipo_flete,
                    precio : nextProps.freight.precio,
                },
                toEdit: nextProps.freight.id
            })
        }

    }

    handleSubmit = _ =>{
        if (!this.state.flete.tipo_flete) {
            notify.show('Hace falta el tipo de flete', 'error', 2000);
            swal({
                type: 'error',
                title: 'Oops...',
                text: 'Hace falta el tipo de flete',
            })
            return;
        }
        if (!this.state.flete.precio) {
            notify.show('Hace falta el precio', 'error', 2000);
            swal({
                type: 'error',
                title: 'Oops...',
                text: 'Hace falta el precio',
            })
            return;
        }
        this.setState({
            loading: true
        }, () => {
            Feathers.service('flete').patch( this.state.toEdit , {
                ...this.state.flete
            }).then(async res => {
                await this.setState({
                    loading: false
                });
                await this.props.onEdit();
                await this.props.onClose();
            }).catch(err => {
                notify.show('Se produjo un error', 'error');
                this.setState({ loading: false })
            })
        })
    };

    render() {
        return (
            <Modal  open={this.props.open} size={'large'}>
                <Modal.Header style={{backgroundColor: "#f3f3f3"}} >Editar
                <Button circular size='mini' basic icon='close' floated='right' onClick={this.props.onClose}></Button>
                </Modal.Header>
                <Modal.Content scrolling>
                    <Form size={'large'}>
                        <Grid>
                            <Grid.Row columns={2} divided>
                                <Grid.Column>
                                    <Grid.Row>
                                        <Form.Input label='Tipo de flete' name='tipo_flete' value={this.state.flete.tipo_flete} type='text' placeholder='Tipo de flete' onChange={(e, {name, value}) => this.setState({ flete: {...this.state.flete, [name] : value} })} required/>
                                    </Grid.Row>
                                </Grid.Column>
                                <Grid.Column>
                                    <Grid.Row>
                                        <Form.Input label='Precio' placeholder='Precio' type="number" name='precio' value={this.state.flete.precio} onChange={(e, {name, value}) => this.setState({ flete: {...this.state.flete, [name] : value} })}  required/>
                                        <Button disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='ACTUALIZAR' icon='send' labelPosition='left' />
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}

FreightEditModal.propTypes = {};

export default FreightEditModal;
