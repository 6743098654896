import React from 'react';
import { Segment, Loader, Dimmer, Image } from 'semantic-ui-react';


const LoaderData = () => {
    return (
        <Segment style={{marginTop:"10rem"}}>
            <Dimmer active inverted>
                <Loader inverted>Cargando</Loader>
            </Dimmer>
    
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
        )
}

export default LoaderData;
