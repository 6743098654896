import React, { Component } from "react";
import feathersRestClient from "@feathersjs/rest-client";
import feathers from "@feathersjs/feathers";
import axios from "axios";
import {
  Modal,
  Form,
  Grid,
  Button,
  Icon,
  Segment,
  Message,
  Header
} from "semantic-ui-react";
import Config from "../../config";
import swal from "sweetalert2";
import VideoForm from "./VideoForm";

export default class CatalogEquipmentFile extends Component {
  constructor() {
    super();

    this.state = {
      files: [],
      maquinaria_file: {
        document: null,
        maquinaria_id: null,
      },
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.maquinaria !== nextProps.maquinaria) {
      this.setState({
        maquinaria: { ...this.state.maquinaria, ...nextProps.maquinaria },
      });
    }
  }

  uploadDocument = async () => {
    let newDataToSend = new FormData();

    if (
      document.getElementById("documentFile") &&
      document.getElementById("documentFile").files.length > 0 &&
      document.getElementById("documentFile").files[0]
    ) {
      let form = document.getElementById("documentFile").files[0];
      newDataToSend.set("documentFile", form);
    } else {
      swal({
        title: "No ha seleccionado ningún archivo",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }

    newDataToSend.set("maquinaria_id", this.state.maquinaria.id);

    const token = localStorage.getItem("bingham-jwt");
    const app = feathers();
    const restClient = feathersRestClient(Config.apiUrl);
    app.configure(restClient.axios(axios));

    app
      .service("maquinaria_file")
      .create(newDataToSend, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        if (document.getElementById("documentFile")) {
          document.getElementById("documentFile").value = null;
        }
        //this.props.onClose && this.props.onClose();
        this.props.callback && this.props.callback();
        swal({
          title: "¡Subida Exitosa!",
          type: "success",
          confirmButtonText: "OK",
        }).then((res) => {
          this.setState({ loading: false });
        });
      })
      .catch((err) => {
        swal({
          title: "¡Se produjo un error, intente de nuevo!",
          type: "error",
          confirmButtonText: "OK",
        }).then((err) =>
          this.setState({
            loading: false,
          })
        );
      });
  };

  render = () => {
    return (
      <Modal open={this.props.open} size={"small"}>
        <Modal.Header>
           Recursos de este equipo
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Message info>
            <Message.Header>
              Registre los recursos necesarios para este equipo
            </Message.Header>
            <p>Para los videos de YouTube solo copie en enlace al cual quiere hacer referencia</p>
          </Message>
          <Segment placeholder padded>
            <Header>
              <h4>Documentos PDF</h4>
            </Header>
            <Form id="form" style={{ marginTop: "1rem" }}>
              <Grid columns={3}>
                <Grid.Column
                  width={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon name="pdf file outline" color="red" size="big" />
                </Grid.Column>
                <Grid.Column width={11}>
                  <Form.Field required>
                    <input
                      accept="application/pdf"
                      type="file"
                      id="documentFile"
                      name="document"
                      //value={this.state.document}
                      onChange={(event) => {
                        if (
                          event.target.files &&
                          event.target.files.length > 0
                        ) {
                          this.setState({ document: event.target.files[0] });
                        }
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column
                  width={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    loading={this.state.loading}
                    onClick={this.uploadDocument}
                    floated="right"
                    positive
                    labelPosition="left"
                    icon="upload"
                    content="Guardar"
                    basic
                  />
                </Grid.Column>
              </Grid>
            </Form>
          </Segment>
          {/* Formulario para registrar link de YT */}
          <Segment placeholder padded>
            <Header>
              <h4>Enlace de YouTube</h4>
            </Header>
            <VideoForm idMaquinaria={this.props.maquinaria.id} />
          </Segment>
        </Modal.Content>
      </Modal>
    );
  };
}
