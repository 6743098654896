import React, { Component } from 'react';
import { Header, Segment, Grid, Icon, Button, Divider, Form } from 'semantic-ui-react'
import { TaskOrderSegment, TaskStatusSegment, TaskItemSegment} from '../'
import Timer from '../../Timer'
import Incidence from '../../Incidence/Incidence'
import swal from 'sweetalert2'
import Feathers from '../../../redux/FeathersRedux';

const PHASE = 3;

export default class UndressTask extends Component {    
    constructor(props) {
        super(props);

        this.state = {       
            dn_final: null,    
            rawMaterials: [],
        }
    }

   

    renderInitButton = () => {
        if (this.props.task.hora_inicio) return null;
        
        return (
            <div style={{marginTop: 20}}>
                <Button onClick={() => this.props.initTask(this.props.task)} icon='play' fluid color="green" content='Iniciar' />
            </div>
        )
    }

    saveInfo = async () => {

        let holgura = await Feathers.service('configuraciones').find({
            query: {
                nombre: 'holgura_dn'
            }
        })

        holgura = +(holgura.data[0].valor)



        
        const dn_introducido = +(this.state.dn_final)
        const dn_esperado = +(this.props.task.rodillo.partida.dn)



        if(dn_introducido < (dn_esperado - holgura) || dn_introducido >  dn_esperado ){
            console.log("Algo esta mal aqui")
            await swal({
                type: 'error',
                title: 'Oops...',
                text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
            })
            await this.incidence.getWrappedInstance().openFromOutside()
            return
        }else{
            await Feathers.service('desviste').patch(this.props.task.id, {
                dn_final: this.state.dn_final,
              });
              await this.props.updateTask &&  await this.props.updateTask();
              await this.props.finishTask && await this.props.finishTask();
        }
        


        // swal({
        //     title: '¿Registar información?',
        //     type: 'warning',
        //     showCancelButton: true,
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Si',
        //     cancelButtonText: 'No',
        //     reverseButtons: true
        // })
        //     .then(async res => {
        //         if (!res.value) return;
        //
        //         await Feathers.service('desviste').patch(this.props.task.id, {
        //             dn_final: this.state.dn_final,
        //         });
        //         await this.props.updateTask &&  await this.props.updateTask();
        //         await this.props.finishTask && await this.props.finishTask();
        //
        //     })
    }

    finishTask = () => {
        if (!this.props.task.dn_final) return swal({
            title: 'Atención',
            text: 'Primero debes capturar la información pendiente para finalizar la tarea',
            type: 'info',
        })

        this.props.finishTask && this.props.finishTask();
    }

    renderForm = () => {
        if (this.props.task.hora_inicio == null ) return null;
        if (!this.props.task.dn_final){
            return (
                <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                    <Segment>
                        <Form>
                            <Grid>
                                <Grid.Row>
                                  
                                    <Grid.Column computer={4} tablet={4} mobile={16}>
                                        <Form.Input name="dn_final" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DN final' placeholder='Diámetro de nucleo final'/>
                                    </Grid.Column>
                                    
                                    <Grid.Column computer={4} tablet={4} mobile={16}>
                                        <Button type="button" onClick={this.saveInfo} disabled={ !this.state.dn_final } fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR' />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Segment>
                </div>

            )
        }
        return (
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Segment>
                        <Grid>
                            <Grid.Row>
                                
                                <Grid.Column computer={5} tablet={5} mobile={16}>
                                    <span style={{display: 'block', fontWeight: 'bold'}}>DN Final test</span>
                                    <span>{this.props.task.dn_final}</span>
                                </Grid.Column>
                                
                            </Grid.Row>

                        </Grid>
                </Segment>
            </div>
        )
    }

    render() {
        return (
            <div>
              <Grid>
                 <Grid.Row columns={2}>
                    <Grid.Column>
                        <Header as='h2'>
                        
                        <Button onClick={this.props.goBack} icon="arrow left" style={{backgroundColor: 'transparent', color: '#000000de'}}/>
                        Tareas de Desviste
                        </Header>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <Incidence ref={ref => this.incidence = ref} phase={PHASE} task={this.props.task}  cancelTaskByIncidence={this.props.cancelTaskByIncidence} rodillo={this.props.task.rodillo_id} pedido={this.props.task.rodillo.partida.pedido_id} />
                    </Grid.Column>
                 </Grid.Row>
              </Grid>
            <Divider />
                {this.props.task.hora_inicio && (
                    <TaskStatusSegment task={this.props.task}   finishTask={this.finishTask}></TaskStatusSegment>
                )}

                <TaskOrderSegment order={this.props.task.rodillo.partida.pedido} kilos={this.props.task.rodillo.partida.kilos}/>

                <Header as='h3' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px', backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='list' />
                    <Header.Content>Información de la partida</Header.Content>
                </Header>
                <TaskItemSegment roller={this.props.task.rodillo.id} item={this.props.task.rodillo.partida} />
                {this.renderInitButton()}
                <this.renderForm />
                
            </div>
        )
    }
}
