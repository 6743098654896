import React, { Component } from "react";
import { Segment, Grid, Image } from "semantic-ui-react";
import Currency from "react-currency-formatter";
import Config from "../../config/index";
import Feathers from "../../redux/FeathersRedux";

export default class TaskItemSegment extends Component {
  state = {
    path: "",
    pdfs: [],
    images: [],
  };

  async componentDidMount() {
    if (process.env.NODE_ENV != "development") {
      await this.setState({
        path: "",
      });
    }

    if (this.props.item) {
      await this.fetchPdfs();
      await this.fetchImages();
    }
  }

  fetchImages = async () => {
    await Feathers.service("draw_files")
      .find({
        query: {
          dibujo_id: this.props.item.dibujo_id,
          type: "IMG",
        },
      })
      .then((res) => {
        this.setState({
          images: res.data,
        });
      });
  };

  fetchPdfs = async () => {
    await Feathers.service("draw_files")
      .find({
        query: {
          dibujo_id: this.props.item.dibujo_id,
          type: "PDF",
        },
      })
      .then((res) => {
        this.setState({
          pdfs: res.data,
        });
      });
  };

  render() {
    return (
      <Segment size={"large"}>
        <Grid>
          <Grid.Row style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
            {this.props.roller && (
              <Grid.Column
                style={{ marginTop: 5 }}
                width={2}
                largeScreen={1}
                tablet={2}
              >
                <span style={{ display: "block", fontWeight: "bold" }}>
                  #Rodillo
                </span>
                <span>{this.props.roller}</span>
              </Grid.Column>
            )}
            <Grid.Column
              style={{ marginTop: 5 }}
              width={3}
              largeScreen={3}
              tablet={4}
            >
              <span style={{ display: "block", fontWeight: "bold" }}>
                Producto
              </span>
              <span>
                {this.props.item ? this.props.item.producto.nombre : "N/A"}
              </span>
            </Grid.Column>
            <Grid.Column
              style={{ marginTop: 5 }}
              width={3}
              largeScreen={2}
              tablet={4}
            >
              <span style={{ display: "block", fontWeight: "bold" }}>
                Material
              </span>
              <span>
                {this.props.item
                  ? this.props.item.material
                    ? this.props.item.material.nombre
                    : "---"
                  : "---"}
              </span>
            </Grid.Column>
            <Grid.Column
              style={{ marginTop: 5 }}
              width={1}
              largeScreen={2}
              tablet={2}
            >
              <span style={{ display: "block", fontWeight: "bold" }}>
                Dureza
              </span>
              <span>
                {this.props.item
                  ? this.props.item.dureza
                    ? this.props.item.dureza.valor
                    : "---"
                  : "---"}
              </span>
            </Grid.Column>
            <Grid.Column
              style={{ marginTop: 5 }}
              width={1}
              largeScreen={2}
              tablet={2}
            >
              <span style={{ display: "block", fontWeight: "bold" }}>
                Color
              </span>
              <span>
                {this.props.item
                  ? this.props.item.color
                    ? this.props.item.color.nombre
                    : "---"
                  : "---"}
              </span>
            </Grid.Column>
            <Grid.Column
              style={{ marginTop: 5 }}
              width={1}
              largeScreen={2}
              tablet={2}
            >
              <span style={{ display: "block", fontWeight: "bold" }}>
                Pegamento
              </span>
              <span>
                {this.props.item
                  ? this.props.item.material
                    ? this.props.item.material.pegamento
                      ? this.props.item.material.pegamento.nombre
                      : "---"
                    : "---"
                  : "---"}
              </span>
            </Grid.Column>
            <Grid.Column
              style={{ marginTop: 5 }}
              width={1}
              largeScreen={2}
              tablet={2}
            >
              <span style={{ display: "block", fontWeight: "bold" }}>
                Cantidad
              </span>
              <span>{this.props.item ? this.props.item.cantidad : "---"}</span>
            </Grid.Column>
            <Grid.Column
              style={{ marginTop: 5 }}
              width={3}
              largeScreen={2}
              tablet={4}
            >
              <span style={{ display: "block", fontWeight: "bold" }}>
                Kilos
              </span>
              <span>
                {this.props.item
                  ? this.props.item.kilos
                  : "---" / this.props.item
                  ? this.props.item.cantidad
                  : "---"}
              </span>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
            {this.props.item ? (
              this.props.item.producto_id == 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={1}
                  largeScreen={1}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    Parte
                  </span>
                  <span>{this.props.item.mancheta.parte}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}
            {this.props.item ? (
              this.props.item.producto_id == 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={1}
                  largeScreen={1}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    Perímetro
                  </span>
                  <span>{this.props.item.mancheta.perimetro_espec}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}
            {this.props.item ? (
              this.props.item.producto_id == 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={1}
                  largeScreen={1}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    Espesor
                  </span>
                  <span>{this.props.item.mancheta.espesor_espec}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}
            {this.props.item ? (
              this.props.item.producto_id == 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={1}
                  largeScreen={1}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    Altura
                  </span>
                  <span>{this.props.item.mancheta.altura_espec}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}
            {this.props.item ? (
              this.props.item.producto_id == 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={2}
                  largeScreen={2}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    Tipo Mancheta
                  </span>
                  <span>{this.props.item.mancheta.tipomancheta.nombre}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}
            {this.props.item ? (
              this.props.item.producto_id == 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={2}
                  largeScreen={2}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    Forma Mancheta
                  </span>
                  <span>{this.props.item.mancheta.formamancheta.nombre}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}
          </Grid.Row>

          <Grid.Row style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
            {this.props.item ? (
              this.props.item.producto_id != 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={1}
                  largeScreen={1}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    DN
                  </span>
                  <span>{this.props.item.dn}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}
            {this.props.item ? (
              this.props.item.producto_id != 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={1}
                  largeScreen={1}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    DE
                  </span>
                  <span>{this.props.item.de}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}

            {this.props.item ? (
              this.props.item.producto_id != 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={1}
                  largeScreen={1}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    LC
                  </span>
                  <span>{this.props.item.lc}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}
            {this.props.item ? (
              this.props.item.producto_id != 5 ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={1}
                  largeScreen={1}
                  tablet={3}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    LT
                  </span>
                  <span>{this.props.item.lt}</span>
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}

            {/*  {
                            this.props.item.dibujo ? 
                            (
                                <Grid.Column style={{marginTop: 5}} width={3} largeScreen={3} tablet={4}>
                                    <span style={{display: 'block', fontWeight: 'bold'}}>Dibujo</span>
                                    <Image src={Config.apiUrl +  this.props.item.dibujo.imagen} size="small" />
                                </Grid.Column>
                            ) :
                            (
                                <span></span>
                            )
                        } */}

            {this.props.item ? (
              this.props.item.chaflan ? (
                <Grid.Column
                  style={{ marginTop: 5 }}
                  width={3}
                  largeScreen={3}
                  tablet={4}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    Chaflan
                  </span>
                  <Image
                    src={this.state.path + this.props.item.chaflan.imagen}
                    size="mini"
                  />
                </Grid.Column>
              ) : (
                <span></span>
              )
            ) : (
              ""
            )}
            <Grid.Column
              style={{ marginTop: 5 }}
              width={7}
              largeScreen={6}
              tablet={5}
            >
              <span style={{ display: "block", fontWeight: "bold" }}>
                Observaciones
              </span>
              <span>
                {this.props.item
                  ? this.props.item.observaciones
                    ? this.props.item.observaciones
                    : "N/A"
                  : "N/A"}
              </span>
            </Grid.Column>
          </Grid.Row>

          <span style={{ display: "block", fontWeight: "bold", marginTop: 5 }}>
            Información del Dibujo (imágenes)
          </span>
          <Grid.Row>
            {this.state.images ? (
              this.state.images.map((image) => (
                <Grid.Column
                  style={{ marginTop: 0 }}
                  width={2}
                  largeScreen={2}
                  tablet={2}
                >
                  <a
                    target="_blank"
                    href={`${Config.apiUrl}/documents/drawFiles/${image.document}`}
                  >
                    <Image
                      src={`${Config.apiUrl}/documents/drawFiles/${image.document}`}
                      size="small"
                    />
                  </a>
                </Grid.Column>
              ))
            ) : (
              <Grid.Column
                style={{ marginTop: 0 }}
                width={2}
                largeScreen={2}
                tablet={2}
              >
                N/A
              </Grid.Column>
            )}
          </Grid.Row>
          <span style={{ display: "block", fontWeight: "bold", marginTop: 5 }}>
            Información del Dibujo (PDFs)
          </span>
          <Grid.Row>
            {this.state.pdfs.length ? (
              this.state.pdfs.map((pdf) => (
                <Grid.Column
                  style={{ marginTop: 0 }}
                  width={4}
                  largeScreen={4}
                  tablet={4}
                >
                  <a
                    target="_blank"
                    href={`${Config.apiUrl}/documents/drawFiles/${pdf.document}`}
                  >
                    {pdf.document}
                  </a>
                </Grid.Column>
              ))
            ) : (
              <Grid.Column
                style={{ marginTop: 0 }}
                width={4}
                largeScreen={4}
                tablet={4}
              >
                N/A
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
