import React, {Component} from 'react';
import { Form, Grid, Button } from 'semantic-ui-react';
import Feathers from '../../redux/FeathersRedux'
import {notify} from 'react-notify-toast';

const componentForm = {
    street_number: {googleName: 'short_name', stateName: 'n_exterior'},
    route: {googleName: 'long_name', stateName: 'direccion'},
    locality: {googleName: 'long_name', stateName: 'ciudad'},
    sublocality_level_1: {googleName: 'long_name', stateName: 'colonia'},
    administrative_area_level_1: {googleName: 'short_name', stateName: 'estado'},
    country: {googleName: 'long_name', stateName: 'pais'},
    postal_code: {googleName: 'short_name', stateName: 'cp'}
};

const REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class ClientsForm extends Component {

    constructor() {
        super()

        this.state = {
            cliente: {
                nombre: '',
                email: '',
                telefono: '',
                rfc: '',
                direccion: '',
                n_interior: '',
                n_exterior: '',
                colonia: '',
                ciudad: '',
                cp: null,
                estado: '',
                pais: '',
                lat: null,
                lng: null,
                moneda_id: null,
            },
            loading: false,
            moneda_options: []
        }

        this.autocomplete = null;

        this.fillInAddress = this.fillInAddress.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.resetForm = this.resetForm.bind(this)
    }

    componentDidMount() {
        if (window.google) {
            this.autocomplete = new window.google.maps.places.Autocomplete(
                (document.getElementById('autocomplete')), { types: ['geocode']}
            );
    
            this.autocomplete.addListener('place_changed', this.fillInAddress);
        }

        this.fetchMonedaOptions()
    }


    fetchMonedaOptions = async _ => {
        let options = await Feathers.service('moneda').find({
            query: {
                $limit: '-1'
            }
        })

        let moneda_options = options.map(v => {
            return {
                key: v.id,
                value: v.id,
                text: v.nombre + " / " + v.cod
            }
        })


        this.setState({
            moneda_options
        })
    }



    handleSubmit() {

        if (!this.state.cliente.nombre) {
            notify.show('El cliente debe llevar un nombre', 'error', 2000);
            return;
        }

        if (!this.state.cliente.email) {
            notify.show('El cliente debe llevar un email', 'error', 2000);
            return;
        }

        // if (!this.state.cliente.telefono) {
        //     notify.show('El cliente debe llevar un número de teléfono', 'error', 2000);
        //     return;
        // }
        // if (!this.state.cliente.rfc) {
        //     notify.show('El cliente debe llevar un rfc', 'error', 2000);
        //     return;
        // }
        // if (!this.state.cliente.moneda_id) {
        //     notify.show('El cliente debe tener una mondea asignada', 'error', 2000);
        //     return;
        // }
        // if (!this.state.cliente.direccion) {
        //     notify.show('El cliente debe llevar una dirección', 'error', 2000);
        //     return;
        // }
        // if (!this.state.cliente.telefono) {
        //     notify.show('El cliente debe llevar un número de teléfono', 'error', 2000);
        //     return;
        // }

        /* if (!REGEX.test(this.state.cliente.email)) {
            notify.show('Ingresa un email válido', 'error', 2000);
            return;
        } */

        this.setState({
            loading: true
        }, () => {
            Feathers.service('cliente').create({
                ...this.state.cliente
            }).then(res => {
                this.resetForm();

                notify.show('Cliente registrado', 'success');
                this.setState({ loading: false })
            }).catch(err => {
                console.log(err.message, 'error')
                notify.show('Se produjo un error', 'error');
                this.setState({ loading: false })
            })
        })
    }

    resetForm() {
        this.setState({
            cliente: {
                nombre: '',
                email: '',
                telefono: '',
                rfc: '',
                direccion: '',
                n_interior: '',
                n_exterior: '',
                colonia: '',
                ciudad: '',
                cp: '',
                estado: '',
                pais: '',
                lat: null,
                lng: null,
                moneda_id: null,
            }
        })

        document.getElementById('autocomplete').value = '';
    }

    fillInAddress = () => {
        let place = this.autocomplete.getPlace();

        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];

            if (componentForm[addressType]) {
                let val = place.address_components[i][componentForm[addressType].googleName];

                this.setState({
                    cliente: {...this.state.cliente, [componentForm[addressType].stateName]: val}
                })
            }
        }

        this.setState({
            cliente: {
                ...this.state.cliente, 
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),        
            }
        })
    }

    render() {
        return (
            <div>
                <Form size={'large'}>
                    <Grid>
                        <Grid.Row columns={2} divided>
                            <Grid.Column>
                                <Grid.Row>
                                    <Form.Input label='Nombre/Razón social' name='nombre' value={this.state.cliente.nombre} type='text' placeholder='Nombre/Razón social' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} required/>
                                    <Form.TextArea label='Email(s)' name='email' value={this.state.cliente.email} placeholder='Email(s) del cliente' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} required/>
                                    <Form.Input label='Teléfono' name='telefono' value={this.state.cliente.telefono} type='text' placeholder='Teléfono' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                    <Form.Input label='RFC' name='rfc' value={this.state.cliente.rfc} type='text' placeholder='RFC' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                    <Form.Dropdown label='Moneda' value={this.state.cliente.moneda_id} options={this.state.moneda_options} selection placeholder={"Moneda"} onChange={(_, d) => {
                                        this.setState({
                                            cliente: {
                                                ...this.state.cliente,
                                                moneda_id: d.value
                                            }
                                        })
                                    }} />
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid.Row>
                                    <Form.Input id='autocomplete' label='Dirección' name='direccion' type='text' placeholder='Dirección' value={this.state.cliente.direccion} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                    <Form.Group>
                                        <Form.Input label='No. interior' value={this.state.cliente.n_interior} name='n_interior' placeholder='No. interior' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                        <Form.Input label='No. exterior' value={this.state.cliente.n_exterior} name='n_exterior' placeholder='No. exterior' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                    </Form.Group>
                                    <Form.Input label='Colonia' value={this.state.cliente.colonia} name='colonia' type='text' placeholder='Colonia' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                    <Form.Group>
                                        <Form.Input label='Ciudad' value={this.state.cliente.ciudad} name='ciudad' placeholder='Ciudad' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                        <Form.Input label='C.P.' value={this.state.cliente.cp} name='cp' placeholder='C.P.' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Input label='Estado' value={this.state.cliente.estado} name='estado' placeholder='Estado' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                        <Form.Input label='País' value={this.state.cliente.pais} name='pais' placeholder='País' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                    </Form.Group>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column floated='right' width={5}>
                                <Button disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='REGISTRAR' icon='send' labelPosition='left' />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
        )
    }
}
    