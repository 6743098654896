import React, { Component } from "react";
import ReactTable from "react-table";
import Feathers from "../../redux/FeathersRedux";
import "react-table/react-table.css";
import { Button, Grid, Form, Modal, Label } from "semantic-ui-react";
import swal from "sweetalert2";
import moment from "moment";
import feathers from "@feathersjs/client/dist/feathers";

export default class SettingsList extends Component {
  state = {
    settings: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 100,
    currentRow: {},
    currentDollarValue: 0,
    isEditModalOpened: false,
    selectedSetting: {
      id: "",
      nombre: "",
      valor: "",
    },
    columna: "",
    sortDirection: false,
  };
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Valor":
        array.data.sort((a, b) => {
          const nameA = a.valor; // ignore upper and lowercase
          const nameB = b.valor; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  fetchApiDollar = async () => {
    await Feathers.service("configuraciones")
      .find({
        query: {
          nombre: "cambio_dolar",
        },
      })
      .then((res) => {
        const currentDate = moment().format("YYYY-MM-DD");
        const isCurrent = moment(currentDate).isSame(res.data[0].fecha_cambio);
        if (isCurrent) {
          console.log("Ya se hizo una peticion del valor del dolar hoy");
        } else {
          const URl_API =
            "https://openexchangerates.org/api/latest.json?app_id=cc049e4fed0747f79ce473bdf6c3ea83";
          fetch(URl_API)
            .then((response) => response.json())
            .then((data) => {
              Feathers.service("configuraciones").patch(res.data[0].id, {
                valor: data.rates.MXN,
                fecha_cambio: currentDate,
              });
            });
        }
      });

    /*      */
  };

  componentDidMount() {
    this.fetchApiDollar();
  }

  fetchData = async (state = {}) => {
    await this.setState({
      loading: false,
    });

    let pageSize = state.pageSize || 100;

    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
    };

    this.setState({ loading: true }, () => {
      Feathers.service("configuraciones")
        .find({
          query,
        })
        .then((res) => {
          console.log(res);
          this.handleSort(res);
          this.setState({
            settings: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        });
    });
  };

  patchSetting = async (_) => {
    if (!this.state.selectedSetting.valor) {
      return swal({
        type: "error",
        title: "Oops...",
        text: "Ingresa un valor",
      });
    }

    await this.setState({
      loading: true,
    });

    let patchedSetting = await Feathers.service("configuraciones").patch(
      this.state.selectedSetting.id,
      {
        valor: this.state.selectedSetting.valor,
      }
    );

    await this.setState({
      isEditModalOpened: false,
      loading: false,
    });

    this.fetchData(this.state);
  };

  renderEditModal = () => {
    if (
      this.state.selectedSetting.id === 10 ||
      this.state.selectedSetting.id === 9
    ) {
      return (
        <Modal open={this.state.isEditModalOpened} size={"tiny"}>
          <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
            Editar: {this.state.selectedSetting.nombre}
            <Button
              circular
              size="mini"
              basic
              icon="close"
              floated="right"
              onClick={(_) => this.setState({ isEditModalOpened: false })}
            ></Button>
          </Modal.Header>
          <Modal.Content>
            <Form onSubmit={this.renderNewInput}>
              <Form.Group>
                <Form.Field width={12}>
                  <label>Valor</label>
                  <Form.Input
                    fluid
                    value={this.state.selectedSetting.valor}
                    onChange={(_, d) => {
                      this.setState({
                        selectedSetting: {
                          ...this.state.selectedSetting,
                          valor: d.value,
                        },
                      });
                    }}
                  />
                  <Label basic color="red" pointing>
                    {" "}
                    Si desea agregar un nuevo email separar con una coma.{" "}
                  </Label>
                </Form.Field>
                <Form.Field width={2}>
                  <label>Guardar</label>
                  <Button
                    icon={"save"}
                    basic
                    color={"blue"}
                    onClick={(_) => {
                      this.patchSetting();
                    }}
                    loading={this.state.loading}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
        </Modal>
      );
    }
    return (
      <Modal open={this.state.isEditModalOpened} size={"tiny"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          Editar: {this.state.selectedSetting.nombre}
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={(_) => this.setState({ isEditModalOpened: false })}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Valor</label>
                <Form.Input
                  value={this.state.selectedSetting.valor}
                  onChange={(_, d) => {
                    this.setState({
                      selectedSetting: {
                        ...this.state.selectedSetting,
                        valor: d.value,
                      },
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Guardar</label>
                <Button
                  icon={"save"}
                  basic
                  color={"blue"}
                  onClick={(_) => {
                    this.patchSetting();
                  }}
                  loading={this.state.loading}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    );
  };

  render() {
    return (
      <div>
        <this.renderEditModal />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ReactTable
                columns={[
                  {
                    Header: "Nombre",
                    headerStyle: { fontSize: 20 },
                    Cell: (row) => {
                      return row.original.nombre;
                    },
                  },
                  {
                    Header: "Valor",
                    headerStyle: { fontSize: 20 },
                    Cell: (row) => {
                      return row.original.valor;
                    },
                  },
                  {
                    Header: "Acciones",
                    sortable: false,
                    headerStyle: { fontSize: 20 },
                    Cell: (row) => {
                      return (
                        <Button
                          icon={"pencil"}
                          basic
                          color={"blue"}
                          onClick={(_) => {
                            this.setState({
                              isEditModalOpened: true,
                              selectedSetting: row.original,
                            });
                          }}
                        />
                      );
                    },
                  },
                ]}
                manual
                data={this.state.settings}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState({
                    columna: column,
                    sortDirection: direction,
                  });
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
