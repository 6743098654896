import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import {
  Button,
  Grid,
  Form,
  Label,
  Search,
  Image,
  Popup,
} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import CatalogEquipmentFile from "./CatalogEquipmentFile";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import "moment/locale/es";
import { formatDate, parseDate } from "react-day-picker/moment";
import Config from "../../config";
import { push } from "react-router-redux";
import { connect } from "react-redux";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class CatalogEquipmentList extends Component {
  constructor() {
    super();

    this.state = {
      userProfile: 0,
      isAdmin: 0,
      equipments: [],
      maquinaria: [],
      equipment: null,
      total: 0,
      page: 0,
      loading: false,
      currentRow: {},
      isInfoOpened: false,
      isEditOpened: false,
      isFileOpened: false,
      pageSize: 100,
      filterOPtions: {
        id: null,
        tipo_maquina_id: null,
        tipo_maquina_options: [],
        fecha_compra: "",
      },

      $sort: {
        $fechaCompraSortDirection: null,
      },

      //Search bar Objects
      maquina: "",
      tipo_maquina: "",

      //Search bar loadings
      nombreLoading: null,
      tipoMaquinaLoading: null,

      //Search bar results
      nombreResults: null,
      tipoMaquinaResults: null,

      columna: "",
      sortDirection: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.openInfoModal = this.openInfoModal.bind(this);
    this.closeInfoModal = this.closeInfoModal.bind(this);
    this.openFileModal = this.openFileModal.bind(this);
    this.closeFileModal = this.closeFileModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Maquina":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Tipo":
        array.data.sort((a, b) => {
          const nameA = a.tipo_maquinaria.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.tipo_maquinaria.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha de compra":
        array.data.sort((a, b) => {
          const nameA = a.fecha_compra.toUpperCase(); // ignore upper and lowercase
          const nameB = b.fecha_compra.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        
        break;
    }
      if (!this.state.sortDirection) {
        array.data.reverse();
      }
      return array;
  };
  componentDidMount() {
    this.fetchUserProfile();
    this.getTipoMaquinaFilter();
  }

  //Hacemos un peticion para saber si el usuario en sesion es administrador
  fetchUserProfile = () => {
    this.setState({
      userProfile: this.props.auth.user.id,
    });
    this.setState({ loading: true }, () => {
      //Hacemos una consulta que trae un 1 solo si el usuario es admin
      Feathers.service("usuario_perfil")
        .find({
          query: {
            usuario_id: this.state.userProfile,
            perfil_id: 1,
          },
        })
        .then((res) => {
          this.setState({
            isAdmin: res[0].perfil_id,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    });
  };

  /* 
    !Si el usuario es administrador podrá tener las opciones de:
      !!Subir archivos
      !!Editar equipos registrados
      !!Desactivar equipos registrados 
  */
  actionButtons(row) {
    const isAdmin = this.state.isAdmin;
    if (isAdmin === 1) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Popup
            content="Agregar recursos"
            trigger={
              <Button
                size="tiny"
                compact
                basic
                color="black"
                icon="upload"
                onClick={() => this.openFileModal(row)}
              />
            }
          />
          <Popup
            content="Informacion del equipo"
            trigger={
              <Button
                size="tiny"
                compact
                basic
                color="grey"
                icon="eye"
                onClick={() => this.infoEquiment(row)}
              />
            }
          />
          <Popup
            content="Actualizar datos"
            trigger={
              <Button
                size="tiny"
                compact
                basic
                color="blue"
                icon="pencil"
                onClick={() => this.openEditModal(row)}
              />
            }
          />
          <Toggle
            defaultChecked={!!row.original.activo}
            icons={false}
            onChange={(event) => this.handleChange(event, row)}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Popup
            content="Informacion del equipo"
            trigger={
              <Button
                size="tiny"
                compact
                basic
                color="grey"
                icon="eye"
                onClick={() => this.infoEquiment(row)}
              />
            }
          />
        </div>
      );
    }
  }

  getTipoMaquinaFilter = async (_) => {
    let tipo_maquinaria = await Feathers.service("tipo_maquinaria").find({
      query: {
        //$limit: -1,
        activo: 1,
      },
    });
    this.setState({
      filterOPtions: {
        ...this.state.filterOPtions,
        tipo_maquina_options: tipo_maquinaria.data.map((v) => {
          return {
            key: v.id,
            text: v.nombre,
            value: v.id,
          };
        }),
      },
    });
  };

  fetchData(state = {}) {
    let pageSize = state.pageSize || 100;
    let query = {
      $limit: state.pageSize || 100,
      $skip: pageSize * state.page,
    };

    if (this.state.$sort.$fechaCompraSortDirection !== null) {
      query.$sort["fecha_compra"] = this.state.$sort.$fechaCompraSortDirection;
    }

    if (this.state.filterOPtions.id) {
      query["id"] = this.state.filterOPtions.id;
    }

    if (this.state.filterOPtions.fecha_compra) {
      query["fecha_compra"] = {
        $gte: moment(this.state.filterOPtions.fecha_compra).format(
          "YYYY-MM-DD"
        ),
      };
    }

    if (this.state.filterOPtions.tipo_maquina_id) {
      query["tipo_maquina_id"] = this.state.filterOPtions.tipo_maquina_id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("maquinaria")
        .find({
          query,
          activo: 1,
        })
        .then((res) => {
          console.log(res)
          this.handleSort(res)
          this.setState({
            equipments: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        });
    });
  }

  openInfoModal(row) {
    this.setState({
      isInfoOpened: true,
      currentRow: row.original,
    });
  }

  closeInfoModal() {
    this.setState({
      isInfoOpened: false,
    });
  }

  openFileModal(row) {
    this.setState({
      isFileOpened: true,
      currentRow: row.original,
    });
  }

  infoEquiment = (row) => {
    const maquinariaid = row.original.id;
    this.props.dispatch(push("/catalogequipmentInfo/" + maquinariaid));
  };

  closeFileModal() {
    this.setState({
      isFileOpened: false,
    });
  }

  openEditModal = (row) => {
    const maquinariaid = row.original.id;
    this.props.dispatch(push("/catalogequipment/" + maquinariaid));
  };

  closeEditModal(row) {
    this.setState({
      isEditOpened: false,
    });
  }

  handleChange(event, row) {
    Feathers.service("maquinaria").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  handleResultNombreSelect = async (e, { result }) => {
    this.setState({ maquina: result.nombre });

    await this.setState({
      filterOPtions: {
        ...this.state.filterOPtions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleNombreSearchChange = (e, { value }) => {
    this.setState({ nombreLoading: true, maquina: value });

    setTimeout(() => {
      if (this.state.maquina.length < 1) return this.resetNombreComponent();
      Feathers.service("maquinaria")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            //activo: 1
          },
        })
        .then((res) => {
          this.setState({
            nombreLoading: false,
            nombreResults: res.data,
          });
        });
    }, 500);
  };

  resetNombreComponent = () =>
    this.setState({ nombreLoading: false, nombreResults: [], maquina: "" });
  resetTypeComponent = () =>
    this.setState({ typeLoading: false, typeResults: [], tipo_maquina: "" });

  handleCleanButton = async () => {
    await this.setState({
      maquina: "",
      nombreResults: null,
      filterOPtions: {
        ...this.state.filterOPtions,
        id: null,
        tipo_maquina_id: null,
        fecha_compra: "",
      },
    });

    this.fetchData(this.state);
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <Form.Field>
                    <label>Nombre</label>
                    <Search
                      icon=""
                      loading={this.state.nombreLoading}
                      value={this.state.maquina}
                      results={this.state.nombreResults}
                      onResultSelect={this.handleResultNombreSelect}
                      onSearchChange={this.handleNombreSearchChange}
                      resultRenderer={renderSearchResults}
                      placeholder="Nombre"
                      className="full-width"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Tipo</label>
                    <Form.Dropdown
                      selection
                      options={this.state.filterOPtions.tipo_maquina_options}
                      onChange={(e, { value }) => {
                        this.setState(
                          {
                            filterOPtions: {
                              ...this.state.filterOPtions,
                              tipo_maquina_id: value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                      value={this.state.filterOPtions.tipo_maquina_id}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Fecha de Compra</label>
                    <DayPickerInput
                      value={this.state.filterOPtions.fecha_compra}
                      format="YYYY-MM-DD"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      onDayChange={(date) => {
                        this.setState(
                          {
                            filterOPtions: {
                              ...this.state.filterOPtions,
                              fecha_compra: date,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ReactTable
                columns={[
                  {
                    Header: "Imagen",
                    sortable: false,
                    Cell: (row) => {
                      if (row.original.imagen === null) return "No hay imagen";
                      return (
                        <div>
                          <Image
                            src={Config.apiUrl + "/" + row.original.imagen}
                            style={{ height: "100px", margin: "0 auto" }}
                          />
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Maquina",
                    Cell: (row) => {
                      if (row.original.nombre === null) return "---";
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <h4>{row.original.nombre}</h4>
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Tipo",
                    Cell: (row) => {
                      if (row.original.tipo_maquinaria === null) return "---";
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <span>{row.original.tipo_maquinaria.nombre}</span>
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Fecha de Compra",
                    Cell: (row) => {
                      if (row.original.fecha_compra === null) return "---";
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <span>{row.original.fecha_compra}</span>
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Acciones",
                    sortable: false,
                    Cell: (row) => this.actionButtons(row),
                  },
                ]}
                manual
                data={this.state.equipments}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / 100)}
                onFetchData={this.fetchData}
                defaultPageSize={50}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState({
                    columna: column,
                    sortDirection: direction,
                  });
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <CatalogEquipmentFile
          open={this.state.isFileOpened}
          maquinaria={this.state.currentRow}
          onClose={this.closeFileModal}
          callback={this.fetchData}
        ></CatalogEquipmentFile>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, null, null, {
  withRef: true,
})(CatalogEquipmentList);
