import React, { Component } from "react";
import Feathers from "../redux/FeathersRedux";
import TableHeader, {
  Grid,
  Table,
  Header,
  Image,
  Button,
  Form,
  Label,
} from "semantic-ui-react";
import Config from "../config/index";
import swal from "sweetalert2";
import ConfigStages from "../config/etapas-ahuladorodillos";
import moment from "moment";
import { push } from "react-router-redux";
import { connect } from "react-redux";

class IncidenceViewContainer extends Component {
  state = {
    incidence: {
      rodillo: {
        partida: {
          producto_id: null,
        },
      },
      tipoincidencia: {},
      usuario: {},
    },
    paramid: "",
    options: [
      {
        text: "Revisión",
        value: 1,
        paraMancheta: 1,
      },
      {
        text: "Molino",
        value: 2,
      },
      {
        text: "Desviste",
        value: 3,
      },
      {
        text: "Construcción",
        value: 4,
        paraMancheta: 1,
      },
      {
        text: "Desbaste",
        value: 6,
      },
      {
        text: "Rectificado",
        value: 7,
      },
      // DO NOT SHOW
      // {
      //   text: "Control de Calidad",
      //   value: 10000,
      //   paraMancheta: 1,
      // },
      {
        text: "Desmonte",
        value: 9,
        paraMancheta: 1,
      },
      {
        text: "Corte",
        value: 10,
        paraMancheta: 1,
      },
      {
        text: "Producto Terminado",
        value: 9999,
        paraMancheta: 1,
      },
    ],
    stagetoSend: 1,
    textSolution: "",
  };

  async componentWillReceiveProps(nextp) {
    await this.setState({
      paramid: nextp.match.params.id,
    });
    this.fetchData();
  }

  async componentDidMount() {
    console.log("this", this.props);
    await this.setState({
      paramid: this.props.match.params.id,
    });
    await this.fetchData();
  }

  fetchData = () => {
    Feathers.service("incidencia")
      .find({
        query: {
          id: this.state.paramid,
        },
      })
      .then((res) => {
        this.setState(
          {
            incidence: res.data[0],
          },
          () => {
            if (this.state.incidence.tipoincidencia.etapa === 8) {
              this.setState({
                incidence: {
                  ...this.state.incidence,
                  tipoincidencia: {
                    ...this.state.incidence.tipoincidencia,
                    etapa: 10000,
                  },
                },
              });
            }
          }
        );
      });
  };

  renderTableBody = () => {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <b>Status</b>
          </Table.Cell>
          <Table.Cell>
            <this.renderStatus />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Fecha Hora</b>
          </Table.Cell>
          <Table.Cell>{this.state.incidence.fecha_hora}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Tipo de incidencia</b>
          </Table.Cell>
          <Table.Cell>{this.state.incidence.tipoincidencia.nombre}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Descripción</b>
          </Table.Cell>
          <Table.Cell>{this.state.incidence.descripcion}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Usuario Reportador</b>
          </Table.Cell>
          <Table.Cell>
            {this.state.incidence.usuario.nombre}{" "}
            {this.state.incidence.usuario.apellido}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Etapa</b>
          </Table.Cell>
          <Table.Cell>
            {ConfigStages[this.state.incidence.tipoincidencia.etapa]}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  };

  renderIncidenceImage = () => {
    if (this.state.incidence) {
      return <Image src={Config.apiUrl + "/" + this.state.incidence.imagen} />;
    }
  };

  handleSolveButton = async () => {
    if (!this.state.stagetoSend && !this.state.stagetoSend === 2) return;
    if (!this.state.textSolution) return;

    /*
      aqui es donde se handlea la opción que seleccionó el usuario 
    */

    // 3 4 6 7
    // if (this.state.stagetoSend === 1) {
    //   console.log("incidencia", this.state.incidence);
    //   await Feathers.service("revision")
    //     .create({
    //       rodillo_id: this.state.incidence.rodillo_id,
    //       pedido_id: this.state.incidence.pedido_id,
    //       incidencia_id: this.state.incidence.id,
    //     })
    //     .then(async (res) => {
    //       await Feathers.service("revision")
    //         .patch({
    //           incidencia_id: this.state.incidence.id,
    //         })
    //         .then((res) => {
    //           console.log(res, "RES -------- REVISION");
    //         });
    //       console.log("res", res);
    //     });
    // }
    if (this.state.stagetoSend === 3) {
      await Feathers.service("rodillo").patch(this.state.incidence.rodillo_id, {
        stage: 1,
      });

      await Feathers.service("desviste").create({
        rodillo_id: this.state.incidence.rodillo_id,
      });
    }
    if (this.state.stagetoSend === 4) {
      await Feathers.service("rodillo").patch(this.state.incidence.rodillo_id, {
        stage: 9,
      });

      await Feathers.service("construccion").create({
        rodillo_id: this.state.incidence.rodillo_id,
      });
    }
    if (this.state.stagetoSend === 6) {
      await Feathers.service("rodillo").patch(this.state.incidence.rodillo_id, {
        stage: 6,
      });

      await Feathers.service("desbaste").create({
        rodillo_id: this.state.incidence.rodillo_id,
      });
    }
    if (this.state.stagetoSend === 7) {
      await Feathers.service("rodillo").patch(this.state.incidence.rodillo_id, {
        stage: 7,
      });

      await Feathers.service("rectificado").create({
        rodillo_id: this.state.incidence.rodillo_id,
      });
    }
    if (this.state.stagetoSend === 2) {
      await Feathers.service("molino").create({
        partida_id: this.state.incidence.rodillo.partida_id,
        usuario_id: this.state.incidence.usuario_id,
      });

      await Feathers.service("rodillo").patch(this.state.incidence.rodillo_id, {
        stage: 1,
      });

      await Feathers.service("desviste").create({
        rodillo_id: this.state.incidence.rodillo_id,
      });
    }
    // DO NOT SEND
    // if (this.state.stagetoSend === 10000) {
    //   await Feathers.service("control_calidad").create({
    //     rodillo_id: this.state.incidence.rodillo_id,
    //   });
    // }

    if (this.state.stagetoSend === 9) {
      await Feathers.service("rodillo").patch(this.state.incidence.rodillo_id, {
        stage: 5,
      });

      await Feathers.service("desmonte").create({
        rodillo_id: this.state.incidence.rodillo_id,
      });
    }

    if (this.state.stagetoSend === 10) {
      await Feathers.service("rodillo").patch(this.state.incidence.rodillo_id, {
        stage: 8,
      });

      await Feathers.service("corte").create({
        rodillo_id: this.state.incidence.rodillo_id,
      });
    }

    // if (this.state.stagetoSend === 9999) {
    //   // vamos a traer la tarea de qa a la cual se le levantó una incidencia

    //   // let tarea_control_calidad = await Feathers.service(
    //   //   "control_calidad"
    //   // ).find({
    //   //   query: {
    //   //     incidencia_id: this.state.incidence.id,
    //   //   },
    //   // });

    //   tarea_control_calidad = JSON.parse(
    //     JSON.stringify(tarea_control_calidad.data[0])
    //   );

    //   // se va a dar como buena la trrea en control de calidad que tenia la incidencia levantada
    //   await Feathers.service("control_calidad").patch(
    //     tarea_control_calidad.id,
    //     {
    //       hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
    //       status_tarea_id: 2,
    //     }
    //   );
    // }

    await Feathers.service("incidencia").patch(this.state.incidence.id, {
      solucion: this.state.textSolution,
    });

    await swal("¡Listo!", "¡Se ha resuelto la incidencia!", "success");

    await this.fetchData();
    this.props.history.goBack();
  };

  clean = (_) => {
    //console.log("Hola");
    this.setState({
      stagetoSend: 0,
      textSolution: "",
    });
  };

  renderForm = () => {
    return (
      <Form size={"large"}>
        <Form.Group widths="equal">
          <this.renderSendTo />
          <Form.Field>
            <Form.TextArea
              label="Solución"
              placeholder="Escriba una solución..."
              onChange={(event, data) => {
                this.setState({
                  textSolution: data.value,
                });
              }}
              value={this.state.textSolution}
              fluid
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Responder</label>
            <Button
              fluid
              color="green"
              icon="send outline"
              onClick={() => {
                this.handleSolveButton();
                this.props.history.goBack();
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Limpiar</label>
            <Button
              fluid
              color="red"
              icon="eraser"
              onClick={() => this.clean()}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  renderSendTo = () => {
    let realOptions;
    console.log(this.state.incidence.tipoincidencia.etapa, "las etapas");
    if (this.state.incidence.tipoincidencia.etapa != 2) {
      if (
        [5, 3].indexOf(this.state.incidence.rodillo.partida.producto_id) !== -1
      ) {
        realOptions = this.state.options.filter((v) => {
          if (
            v.value <= this.state.incidence.tipoincidencia.etapa &&
            v.paraMancheta === 1
          ) {
            return v;
          }
        });
      }

      if (
        [6, 7, 8, 9, 11, 12, 14, 15].indexOf(
          this.state.incidence.rodillo.partida.producto_id
        ) !== -1
      ) {
        realOptions = this.state.options.filter((v) => {
          if (
            v.value <= this.state.incidence.tipoincidencia.etapa &&
            (v.value === 10000 || v.value === 1)
          ) {
            return v;
          }
        });
      }

      if (
        [2].indexOf(this.state.incidence.rodillo.partida.producto_id) !== -1
      ) {
        realOptions = this.state.options.filter((v) => {
          if (
            v.value <= this.state.incidence.tipoincidencia.etapa &&
            (v.value === 7 || v.value === 1)
          ) {
            return v;
          }
        });
      }

      if (
        [10].indexOf(this.state.incidence.rodillo.partida.producto_id) !== -1
      ) {
        realOptions = this.state.options.filter((v) => {
          if (
            v.value <= this.state.incidence.tipoincidencia.etapa &&
            (v.value === 6 || v.value === 1)
          ) {
            return v;
          }
        });
      }

      if (
        [1, 4].indexOf(this.state.incidence.rodillo.partida.producto_id) !== -1
      ) {
        realOptions = this.state.options.filter((v) => {
          if (v.value <= this.state.incidence.tipoincidencia.etapa) {
            return v;
          }
        });
      }
      return (
        <Form.Field>
          <label>Etapa</label>
          <Form.Dropdown
            selection
            placeholder="Selecciona Etapa"
            options={realOptions}
            name="stagetoSend"
            onChange={(event, data) => {
              console.log(data.value, "ETAPA");
              this.setState({ stagetoSend: data.value });
            }}
            value={this.state.stagetoSend}
          />
        </Form.Field>
      );
    }

    return null;
  };

  renderSolucion = () => {
    return (
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Solución dada</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{this.state.incidence.solucion}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  renderStatus = () => {
    if (this.state.incidence.solucion === undefined) return null;
    console.log(this.state.incidence, "cierrate");
    if (!this.state.incidence.solucion) {
      return (
        <Label tag color="red">
          Abierta
        </Label>
      );
    } else {
      return (
        <Label tag color="teal">
          Cerrado
        </Label>
      );
    }
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as={"h2"}>
                <Button
                  onClick={() => this.props.history.goBack()}
                  icon="arrow left"
                  style={{ backgroundColor: "transparent", color: "#000000" }}
                />
                Incidencia
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column style={{ padding: "2rem" }}>
              <Table basic="very" celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <h3>Información</h3>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {this.renderTableBody()}
              </Table>
            </Grid.Column>

            <Grid.Column style={{ padding: "2rem" }}>
              {this.renderIncidenceImage()}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              {!this.state.incidence.solucion ? (
                <this.renderForm />
              ) : (
                <this.renderSolucion />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(IncidenceViewContainer);
