import React from "react";
import { Tab, Grid, Header } from "semantic-ui-react";
import RemissionList from "../components/Orders/RemissionList";
import RemissionCreate from "../components/Orders/RemissionCreate";

export default class RemissionContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      currIndex: 0,
    };

    this.panes = [
      {
        menuItem: { key: "list", icon: "list", content: "Listado de órdenes sin remisión" },
        pane: (
          <Tab.Pane
            style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
            key={1}
          >
            <RemissionList ref={(ref) => (this.list = ref)} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: "add", icon: "add circle", content: "Remisiones emitidas" },
        pane: (
          <Tab.Pane key={2}>
            <RemissionCreate onCreate={this.onCreate} />
          </Tab.Pane>
        ),
      },
    ];
  }

  setTab = (index) => {
    this.setState({
      currIndex: index || 0,
    });
  };

  onCreate = () => {
    this.list.getWrappedInstance().fetchData();
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="shop" content="Remisiones de órdenes" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Tab
                menu={{ secondary: true, pointing: true, color: "red" }}
                panes={this.panes}
                renderActiveOnly={false}
                activeIndex={this.state.currIndex}
                onTabChange={(event, data) => this.setTab(data.activeIndex)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
