import React, { Component } from 'react';
import { Header, Segment, Grid, Icon, Button, Divider, Form } from 'semantic-ui-react'
import { TaskOrderSegment, TaskStatusSegment, TaskItemSegment} from '../'
import Timer from '../../Timer'
import Incidence from '../../Incidence/Incidence'
import swal from 'sweetalert2'
import Feathers from '../../../redux/FeathersRedux';

const PHASE = 10;

export default class CuttingTask extends Component {

  constructor(props){
    super(props);
    this.state = {
         producto_id: this.props.task.rodillo.partida.producto_id,
         peso_antes: null,
         peso_despues: null
    }
  }


    renderInitButton = () => {
        if (this.props.task.hora_inicio) return null;
        
        return (
            <div style={{marginTop: 20}}>
                <Button onClick={() => this.props.initTask(this.props.task)} icon='play' fluid color="green" content='Iniciar' />
            </div>
        )
    }

   



    finishTask = () => {
     if([5].indexOf(this.state.producto_id) !== -1){
        if (!this.props.task.peso_antes || !this.props.task.peso_despues) return swal({
            title: 'Atención',
            text: 'Primero debes capturar la información pendiente para finalizar la tarea',
            type: 'info',
        })
     }

        this.props.finishTask && this.props.finishTask();
    };

    savePesoAntes = () => {
        swal({
            title: '¿Registar información?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            reverseButtons: true
        })
            .then(res => {
                if (!res.value) return;

                Feathers.service('corte').patch(this.props.task.id, {
                    peso_antes: this.state.peso_antes,
                })
                    .then(res => {
                        this.props.updateTask && this.props.updateTask();
                    })
            })
    }

    savePesoDespues = () => {
        swal({
            title: '¿Registar información?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            reverseButtons: true
        })
            .then(res => {
                if (!res.value) return;

                Feathers.service('corte').patch(this.props.task.id, {
                    peso_despues: this.state.peso_despues
                })
                    .then( async res => {
                        await this.props.updateTask && await this.props.updateTask();
                        await this.props.finishTask && await this.props.finishTask();
                    })
            })
    }

    renderForm = () => {
        if([5].indexOf(this.state.producto_id) !== -1){
          if (this.props.task.hora_inicio == null ) return null;
            return (
                <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                    <Segment>
                        <Form>
                            <Grid>
                                <Grid.Row>

                                    <Grid.Column computer={4} tablet={4} mobile={16}>
                                        <Form.Input name="peso_antes" onChange={(e, d) => {
                                            this.setState({
                                                peso_antes: d.value
                                            })
                                        }} type="number" label='Peso Antes' placeholder='Peso Antes' value={this.props.task.peso_antes} disabled={this.props.task.peso_antes} />
                                    </Grid.Column>

                                    <Grid.Column computer={4} tablet={4} mobile={16}>
                                        <Button type="button" onClick={this.savePesoAntes} disabled={ !this.state.peso_antes || this.props.task.peso_antes } fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR PESO ANTES' />
                                    </Grid.Column>

                                    <Grid.Column computer={4} tablet={4} mobile={16}>
                                        <Form.Input name="peso_despues" onChange={(e, d) => {
                                            this.setState({
                                                peso_despues: d.value
                                            })
                                        }} type="number" label='Peso Después' placeholder='Peso Después' disabled={ this.props.task.peso_despues } value={this.props.task.peso_despues } />
                                    </Grid.Column>

                                    <Grid.Column computer={4} tablet={4} mobile={16}>
                                        <Button type="button" onClick={this.savePesoDespues} disabled={ !this.props.task.peso_antes || !this.state.peso_despues || this.props.task.peso_despues } fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR PESO DESPUÉS' />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Segment>
                </div>
            )
        }
        return null
    };
  
    render() {
        return (
            <div>
              <Grid>
                 <Grid.Row columns={2}>
                    <Grid.Column>
                        <Header as='h2'>
                        <Button onClick={this.props.goBack} icon="arrow left" style={{backgroundColor: 'transparent', color: '#000000de'}}/>
                        Tareas de Corte
                        </Header>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <Incidence phase={PHASE} task={this.props.task}  cancelTaskByIncidence={this.props.cancelTaskByIncidence} rodillo={this.props.task.rodillo_id} pedido={this.props.task.rodillo.partida.pedido_id} />
                    </Grid.Column>
                 </Grid.Row>
              </Grid>
            <Divider />
                {this.props.task.hora_inicio && (
                    <TaskStatusSegment task={this.props.task}   finishTask={this.finishTask}></TaskStatusSegment>
                )}

                <TaskOrderSegment order={this.props.task.rodillo.partida.pedido} kilos={this.props.task.rodillo.partida.kilos}/>

                <Header as='h3' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px', backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='list' />
                    <Header.Content>Información de la partida</Header.Content>
                </Header>
                <TaskItemSegment roller={this.props.task.rodillo.id} item={this.props.task.rodillo.partida} />
                {this.renderInitButton()}
                <this.renderForm />
            </div>
        )
    }
}
