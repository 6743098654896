import React, { Component } from "react";
import Feathers from "../../../redux/FeathersRedux";
import { connect } from "react-redux";
import {
  Header,
  Segment,
  Grid,
  Icon,
  Button,
  Divider,
} from "semantic-ui-react";
import {
  TaskOrderSegment,
  TaskStatusSegment,
  TaskItemSegment,
  TaskStatus,
} from "../../../components/Tasks";
import swal from "sweetalert2";
import moment from "moment";

const PHASE = 1;

class ViewTask extends Component {
  constructor() {
    super();
    this.state = {
      pedido: {},
      partida: [],
    };
    // this.goBack = this.goBack.bind(this);
  }

  renderItems() {
    if (Array.isArray(this.state.pedido.partidas)) {
      return this.state.pedido.partidas.map((partida) => (
        <TaskItemSegment item={partida} />
      ));
    }

    return <TaskItemSegment item={this.state.pedido.partidas} />;
  }

  async getPedido() {
    await Feathers.service("pedido")
      .find({
        query: {
          id: this.props.match.params.id,
        },
      })
      .then((res) => {
        console.log(res.data, 'data pedido')
        this.setState({ pedido: res.data[0] });
      });
  }

  async getPartida() {
    await Feathers.service(this.props.match.params.tarea)
      .find({
        query: {
          id: this.state.pedido.partidas.id,
        },
      })
      .then((res) => {
        this.setState({ partida: res.data });
      });
    console.log(this.state.partida.hora_inicio);
  }

  async finishTask() {
    const id = this.props.match.params.id;
    swal({
      title: "¿Finalizar tarea?",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Sí, finalizar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service(this.props.match.params.tarea)
          .patch(this.state.partida[0].tareaId, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          })
          .then((res) => {
            console.log("Exito al finalizar la tarea", res);
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error. Intente de nuevo",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true,
            });
          });
      }
    });
  }

  async startTask() {
    const id = this.props.match.params.id;
    const userId = this.props.auth.user.id;
    console.log(this.state.partida[0].tareaId, 'rodillo id')
    swal({
      title: "¿Seguro que quiere iniciar tarea?",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Sí, iniciar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service(this.props.match.params.tarea)
          .patch(this.state.partida[0].tareaId, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 1,
            usuario_id: userId,
          })
          .then((res) => {
            console.log("Exito al iniciar la tarea", res);
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error. Intente de nuevo",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true,
            });
          });
      }
    });
  }

  renderInitButton() {
    // if (this.state.pedido.hora_inicio) return null;
    return (
      <div style={{ marginTop: 20 }}>
        {!this.state.partida.hora_inicio && (
          <Button
            onClick={async () => {
              await this.startTask();
              //await this.props.finishTask()
            }}
            icon="play"
            fluid
            color="green"
            content="Iniciar"
          />
        )}
        {this.state.partida.usuario_id && (
          <Button
            onClick={async () => {
              await this.finishTask();
              //await this.props.finishTask()
            }}
            icon="history"
            fluid
            color="red"
            content="Finalizar"
          />
        )}
      </div>
    );
  }

  async componentDidMount() {
    console.log(moment().format("HH:mm"));
    await this.getPedido();
    await this.getPartida();
  }

  render() {
    return (
      <div>
        {this.state.pedido.cliente && (
          <>
            {/* {this.getPartida()} */}

            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Header as="h2">
                    <Button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      icon="arrow left"
                      style={{
                        backgroundColor: "transparent",
                        color: "#000000de",
                      }}
                    />
                    Tarea de {this.props.match.params.tarea}
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
                    <Header
                      as="h3"
                      attached="top"
                      inverted
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        backgroundColor: "#393e46",
                        borderRadius: "10px",
                      }}
                    >
                      <Icon name="info" />
                      <Header.Content>Información del pedido</Header.Content>
                    </Header>
                    <Segment size={"large"}>
                      <Grid>
                        <Grid.Row
                          columns={5}
                          style={{
                            paddingTop: "0.5rem",
                            paddingBottom: "0.5rem",
                          }}
                        >
                          <Grid.Column>
                            <span
                              style={{ display: "block", fontWeight: "bold" }}
                            >
                              #Pedido
                            </span>
                            <span>{this.state.pedido.id}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <span
                              style={{ display: "block", fontWeight: "bold" }}
                            >
                              #Cliente
                            </span>
                            <span>{this.state.pedido.cliente.id}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <span
                              style={{ display: "block", fontWeight: "bold" }}
                            >
                              Cliente
                            </span>
                            <span>{this.state.pedido.cliente.nombre}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <span
                              style={{ display: "block", fontWeight: "bold" }}
                            >
                              Fecha alta
                            </span>
                            <span>{this.state.pedido.fecha_alta}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <span
                              style={{ display: "block", fontWeight: "bold" }}
                            >
                              Fecha entrega
                            </span>
                            <span>{this.state.pedido.fecha_entrega}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <span
                              style={{ display: "block", fontWeight: "bold" }}
                            >
                              Kilos totales
                            </span>
                            <span>{this.state.pedido.partidas.kilos}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <span
                              style={{ display: "block", fontWeight: "bold" }}
                            >
                              Orden de compra
                            </span>
                            <span>
                              {this.state.pedido.orden_compra
                                ? this.state.pedido.orden_compra
                                : "N/A"}
                            </span>
                          </Grid.Column>
                          <Grid.Column>
                            <span
                              style={{ display: "block", fontWeight: "bold" }}
                            >
                              Número de salida
                            </span>
                            <span>
                              {this.state.numero_salida
                                ? this.state.numero_salida
                                : "N/A"}
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />

            <Header
              as="h3"
              attached="top"
              inverted
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                backgroundColor: "#393e46",
                borderRadius: "10px",
              }}
            >
              <Icon name="list" />
              <Header.Content>Información de partidas</Header.Content>
            </Header>
            {this.renderItems()}

            {this.state.partida.hora_inicio === undefined && (
              <>{this.renderInitButton()}</>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ViewTask);
