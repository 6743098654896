import React, { Component } from "react";
import { Modal, Grid, Icon, Button } from "semantic-ui-react";

export default class ObservationModal extends Component {
  render() {
    return (
      <Modal open={this.props.isOpened} size={"small"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          Observaciones de esta partida
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Grid
            columns={2}
            divided
            textAlign="center"
            style={{ fontSize: "16px" }}
          >
            <Grid.Row>
              <Grid.Column>
                <Grid.Row>
                {this.props.data ? this.props.data : 'SIN OBSERVACIONES'}
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
