import React, { Component } from "react";
import { Modal, Grid, Icon, Button, Form } from "semantic-ui-react";
import Notifications, { notify } from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";

export default class MaterialsEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      material: {
        nombre: "",
        pegamento_id: null,
        densidad: null,
        costo: "",
        precio: "",
      },
      pegamento_options: [],
      loading: false,
      valueDollar: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (this.state.material.id !== nextProps.material.id) {
      this.setState({
        material: { ...this.state.material, ...nextProps.material },
      });
    }
  }


  fetchDollarValue = async () => {
    await Feathers.service("configuraciones").find({
      query: {
        id: 1
      },
    })
    .then(res => {
      this.setState({
        valueDollar: res.data[0].valor
      })
    })
  }

  fecthPegamentos = async () => {
    let pegamentos = await Feathers.service("pegamento").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    let pegamento_options = pegamentos.map((v) => {
      return {
        key: v.id,
        text: v.nombre,
        value: v.id,
      };
    });

    this.setState({
      pegamento_options,
    });
  };

  componentDidMount() {
    this.fecthPegamentos();
    this.fetchDollarValue();
  }


  handleSubmit() {
    if (!this.state.material.nombre) {
      notify.show("Debe llevar un nombre", "error", 2000);
      return;
    }
    if (!this.state.material.pegamento_id) {
      notify.show("Debe llevar un pegamento", "error", 2000);
      return;
    }
    if (!this.state.material.densidad) {
      notify.show("Debe llevar una densidad", "error", 2000);
      return;
    }
    if (!this.state.material.costo) {
      notify.show("Debe llevar un costo", "error", 2000);
      return;
    }
    if (!this.state.material.precio) {
      notify.show("Debe de llevar un precio", "error", 2000);
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("material")
          .patch(this.state.material.id, {
            nombre: this.state.material.nombre,
            pegamento_id: this.state.material.pegamento_id,
            densidad: this.state.material.densidad,
            costo: this.state.material.costo,
            precio: this.state.material.precio,
          })
          .then((res) => {
            this.props.onClose && this.props.onClose();
            this.props.callback && this.props.callback();
            notify.show("Material Compuesto actualizado", "success");
            this.setState({ loading: false });
          })
          .catch((err) => {
            notify.show("Se produjo un error", "error");
            this.setState({ loading: false });
          });
      }
    );
  }

  render() {
    return (
      <Modal open={this.props.isOpened} size={"large"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          Editar Compuesto
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Form size={"large"}>
            <Grid>
              <Grid.Row columns={2} divided>
                <Grid.Column>
                  <Grid.Row>
                    <Form.Input
                      label="Nombre"
                      name="nombre"
                      value={this.state.material.nombre}
                      type="text"
                      placeholder="Nombre"
                      onChange={(e, { name, value }) =>
                        this.setState({
                          material: { ...this.state.material, [name]: value },
                        })
                      }
                      required
                    />
                    <Form.Dropdown
                      label="Pegamento"
                      value={this.state.material.pegamento_id}
                      required
                      placeholder="Pegamento"
                      fluid
                      selection
                      search
                      options={this.state.pegamento_options}
                      onChange={(e, d) => {
                        this.setState({
                          material: {
                            ...this.state.material,
                            pegamento_id: d.value,
                          },
                        });
                      }}
                    />
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Grid.Row>
                    <Form.Input
                      label="Densidad"
                      name="densidad"
                      value={this.state.material.densidad}
                      type="number"
                      placeholder="Densidad"
                      onChange={(e, { name, value }) =>
                        this.setState({
                          material: { ...this.state.material, [name]: value },
                        })
                      }
                      required
                    />
                    <Form.Group widths="equal">
                      <Form.Input
                        label="Costo USD"
                        name="costo"
                        value={this.state.material.costo}
                        type="number"
                        placeholder="Costo"
                        onChange={(e, { name, value }) =>
                          this.setState({
                            material: { ...this.state.material, [name]: value },
                          })
                        }
                        required
                      />
                      <Form.Input
                        label="Precio USD"
                        name="precio"
                        value={this.state.material.precio}
                        type="number"
                        placeholder="Precio"
                        onChange={(e, { name, value }) =>
                          this.setState({
                            material: { ...this.state.material, [name]: value },
                          })
                        }
                        required
                      />
                    </Form.Group>
                    
                  <Form.Group widths="equal">
                    <Form.Input
                      label="Costo MXN"
                      name="costoMX"
                      readOnly
                      value={this.state.material.costo * this.state.valueDollar}
                    />
                    <Form.Input
                      label="Precio MXN"
                      name="precioMX"
                      readOnly
                      value={this.state.material.precio * this.state.valueDollar}
                    />
                  </Form.Group>

                  </Grid.Row>
                  <Button
                    disabled={this.state.loading}
                    loading={this.state.loading}
                    type="button"
                    onClick={this.handleSubmit}
                    floated="right"
                    positive
                    content="ACTUALIZAR"
                    icon="send"
                    labelPosition="left"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
