import React, { Component } from "react";
import { Header, Segment, Grid, Icon, Button, Table } from "semantic-ui-react";
import {
  LineChart,
  Line,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

class VulcanizationTemperature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      temperatures: [],
    };
  }

  getCurrentTemperature = async () => {
    this.setState(
      {
        loading: true,
      },
      (_) => {
        this.props.getTemperature && this.props.getTemperature();
        this.setState({
          loading: false,
        });
      }
    );
  };

  render() {
    if (!this.props.temperatures.length) return <></>;

    return (
      <>
        <Segment size={"large"} loading={this.state.loading}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={10}>
                <Header>
                  <Header.Content>Datos capturados</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={6} textAlign="right">
                <Button
                  circular
                  icon="undo"
                  onClick={this.getCurrentTemperature}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid>
            <Grid.Column width={16}>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  width={400}
                  height={400}
                  data={this.props.temperatures}
                  margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                >
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="hora" />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip />
                  <Legend />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="temperatura"
                    stroke="#ec4646"
                    activeDot={{ r: 8 }}
                  />
                  {/* <Line 
                    yAxisId="right"
                    type="monotone" 
                    dataKey="presion" 
                    stroke="#16c79a" 
                  /> */}
                </LineChart>
              </ResponsiveContainer>
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    );
  }
}

export default VulcanizationTemperature;
