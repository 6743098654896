import React, { Component } from "react";
import {
  Grid,
  Segment,
  Icon,
  Header,
  Container,
  Button,
  Input
} from "semantic-ui-react";
import Config from "../../config";
import swal from "sweetalert2";
import ReactTable from "react-table";
import axios from "axios";
import feathersRestClient from "@feathersjs/rest-client";
import feathers from "@feathersjs/feathers";
import Feathers from "../../redux/FeathersRedux";

export default class DrawPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfs: [],
      nameDraw: '',
      total: 0,
      pageSize: 10,
      page: 0,
      loading: false,
      data: {
        file: "",
        draw_id: "",
      },
    };
  }

  fetchDraw = async () => {
    await Feathers.service('dibujo').get(this.props.id, {
      query: {
        $select: ['nombre']
      }
    })
      .then((res) => {
        this.setState({
          nameDraw: res.nombre,
        })
      })
  }

  fetchData = async () => {
    await Feathers.service("draw_files").find({
      query: {
        dibujo_id: this.props.id,
        type: 'PDF'
      },
    })
      .then((res) => {
        console.log(res, 'response files')
        this.setState({
          pdfs: res.data
        })
      })
  };

  removeFile = (document) => {
    swal({
      title: "¿Eliminar archivo?",
      text: "Se eliminará el pdf de este listado",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        this.setState({ loading: true });
        await Feathers.service("draw_files").remove(document.id);
        swal({
          title: "Exito",
          text: "Se eliminó con éxito la imagen",
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        this.fetchData();
        this.setState({ loading: false })
      }
    });
  };

  uploadFile = async () => {
    let newDataToSend = new FormData();
    if (
      document.getElementById("documentFile") &&
      document.getElementById("documentFile").files.length > 0 &&
      document.getElementById("documentFile").files[0]
    ) {
      let form = document.getElementById("documentFile").files[0];
      newDataToSend.set("documentFile", form);
    } else {
      swal({
        title: "No ha seleccionado ningún archivo",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }
    newDataToSend.set("dibujo_id", this.props.id);
    newDataToSend.set("type", 'PDF');
    const token = localStorage.getItem("bingham-jwt");
    const app = feathers();
    const restClient = feathersRestClient(Config.apiUrl);
    app.configure(restClient.axios(axios));

    app
      .service("draw_files")
      .create(newDataToSend, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        if (document.getElementById("documentFile")) {
          document.getElementById("documentFile").value = null;
        }

        swal({
          title: "¡Subida Exitosa!",
          type: "success",
          confirmButtonText: "OK",
        }).then((res) => {
          //this.props.onSave();
          this.fetchData();
          this.setState({ loading: false });
        });
      })
      .catch((err) => {
        //alert("Se produjo un error");
        swal({
          title: "¡Ocurrió un error!",
          type: "error",
          confirmButtonText: "OK",
        })
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount = () => {
    this.fetchDraw();
  }


  render() {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Container style={{ margin: 20 }}>
                <Segment placeholder padded>

                  <Grid style={{ width: "70%", margin: "0 auto" }}>
                    <Grid.Row>
                      <Grid.Column textAlign='center' width={16}>
                        <Header icon as="h3">
                          <Icon name="pdf file outline" />
                          Subir pdf
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid style={{ width: "50%", margin: "0 auto" }}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Input
                          style={{ width: '100%' }}
                          accept="application/pdf"
                          type="file"
                          id="documentFile"
                          name="document"
                          onChange={(event) => {
                            if (
                              event.target.files &&
                              event.target.files.length > 0
                            ) {
                              this.setState({
                                document: event.target.files[0],
                              });
                            }
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={16} textAlign='right'>
                        <Button
                          loading={this.state.loading}
                          onClick={this.uploadFile}
                          floated="right"
                          color="blue"
                          labelPosition="left"
                          icon="upload"
                          content="Subir"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Container style={{ margin: 20 }}>
                <Segment>
                  <Header as="h2">
                    <Icon name="pdf file outline" />
                    <Header.Content>
                      PDfs del dibujo: {this.state.nameDraw}
                      <Header.Subheader>
                        Pdfs que pertenecen a este dibujo
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <ReactTable
                    columns={[
                      {
                        Header: "PDF",
                        id: "file",
                        accessor: (d) => {
                          return (
                            <a
                              target="_blank"
                              href={`${Config.apiUrl}/documents/drawFiles/${d.document}`}
                            >
                              {d.document}
                            </a>
                          );
                        },
                      },
                      {
                        Header: "Acciones",
                        Cell: (row) => (
                          <Button
                            size="tiny"
                            content="Eliminar"
                            compact
                            basic
                            color="red"
                            icon="delete"
                            onClick={() => this.removeFile(row.original)}
                          />
                        ),
                      },
                    ]}
                    manual
                    data={this.state.pdfs}
                    loading={this.state.loading}
                    pages={Math.ceil(this.state.total / 5)}
                    onFetchData={this.fetchData}
                    defaultPageSize={10}
                    style={{ textAlign: "center", marginTop: "1rem" }}
                  />
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}
