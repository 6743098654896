import React, {Component} from 'react';
import {Modal, Grid, Icon, Button} from 'semantic-ui-react'

export default class ClientsInfoModal extends Component {

    render() {
        return (
            <Modal open={this.props.isOpened} size={'small'}>
                <Modal.Header style={{backgroundColor: "#f3f3f3"}}>{this.props.client.nombre}
                <Button circular size='mini' basic icon='close' floated='right' onClick={this.props.onClose}></Button>
                </Modal.Header>
                <Modal.Content>
                    <Grid columns={3} divided textAlign='center' style={{fontSize: "16px"}}>
                        <Grid.Row>
                            <Grid.Column >
                                <Grid.Row>
                                    <Icon name="mail"></Icon> <span style={{fontWeight: 'bold'}}>Email</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.email}
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column >
                                <Grid.Row>
                                    <Icon name="address card"></Icon> <span style={{fontWeight: 'bold'}}>Colonia</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.colonia || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column >
                                <Grid.Row>
                                    <span style={{fontWeight: 'bold'}}>CP</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.cp || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid.Row>
                                    <Icon name="phone"></Icon> <span style={{fontWeight: 'bold'}}>Teléfono</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.telefono || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid.Row>
                                    <Icon name="address card"></Icon> <span style={{fontWeight: 'bold'}}>Ciudad</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.ciudad || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid.Row>
                                    <Icon name="address book"></Icon> <span style={{fontWeight: 'bold'}}>RFC</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.rfc || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row >   
                            <Grid.Column>
                                <Grid.Row>
                                    <Icon name="address card"></Icon> <span style={{fontWeight: 'bold'}}>Estado</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.estado || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid.Row>
                                    <Icon name="address card"></Icon> <span style={{fontWeight: 'bold'}}>Calle</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.direccion}
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid.Row>
                                    <Icon name="address card"></Icon> <span style={{fontWeight: 'bold'}}>Pais</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.pais || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row> 
                            <Grid.Column >
                                <Grid.Row>
                                    <Icon name="hashtag"></Icon> <span style={{fontWeight: 'bold'}}>No. exterior</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.n_exterior || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column >
                                <Grid.Row>
                                    <Icon name="hashtag"></Icon> <span style={{fontWeight: 'bold'}}>No. interior</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.n_interior || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column >
                                <Grid.Row>
                                    <Icon name="money"></Icon> <span style={{fontWeight: 'bold'}}>Moneda</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.client.moneda ? this.props.client.moneda.nombre : 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
            </Modal>
        )
    }
}