import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import {
  Button,
  Grid,
  Icon,
  Form,
  Search,
  Label,
  Popup,
} from "semantic-ui-react";
import { connect } from "react-redux";
import swal from "sweetalert2";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import "moment/locale/es";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { exportToXls } from "../../utils/Utils";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

class InvoiceCreate extends Component {
  constructor() {
    super();

    this.state = {
      orders: [],
      total: 0,
      page: 0,
      loading: false,
      currentRow: {},
      isInfoOpened: false,
      isEditOpened: false,
      pageSize: 100,
      filterOptions: {
        id: null,
        status_id: null,
        status_options: [],
        fecha_alta: "",
        fecha_entrega: "",
      },
      mancheta_id: null,
      results: null,
      client: "",
      $sort: {
        $fechaAltaSortDirection: null,
        $fechaEntregaSortDirection: null,
        $saeSortDirection: null,
      },
    };

  }

  componentDidMount() {
    this.getStatusFilterOptions();
  }

  getStatusFilterOptions = async (_) => {
    let status_pedido = await Feathers.service("status_pedido").find({
      query: {
        $limit: -1,
        activo: 1,
      },
    });

    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        status_options: status_pedido.map((v) => {
          return {
            key: v.id,
            text: v.nombre,
            value: v.id,
          };
        }),
      },
    });
  };

  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: state.pageSize || 100,
      $skip: pageSize * state.page,
      status_id: {
        $in: [1, 2, 3],
      },
      $sort: {},
      invoice_at: 1
    };

    if (this.state.$sort.$fechaAltaSortDirection !== null) {
      query.$sort["fecha_alta"] = this.state.$sort.$fechaAltaSortDirection;
    }

    if (this.state.$sort.$fechaEntregaSortDirection !== null) {
      query.$sort[
        "fecha_entrega"
      ] = this.state.$sort.$fechaEntregaSortDirection;
    }

    if (this.state.$sort.$saeSortDirection !== null) {
      query.$sort = {
        ref_sae: this.state.$sort.$saeSortDirection,
      };
    }

    if (this.state.filterOptions.id) {
      query["cliente_id"] = this.state.filterOptions.id;
    }

    if (this.state.filterOptions.status_id) {
      query["status_id"] = this.state.filterOptions.status_id;
    }

    if (this.state.filterOptions.fecha_alta) {
      query["fecha_alta"] = {
        $gte: moment(this.state.filterOptions.fecha_alta).format("YYYY-MM-DD"), // .hour(0).minute(1).second(1).toDate()
      };
    }

    if (this.state.filterOptions.fecha_entrega) {
      query["fecha_entrega"] = {
        $lte: moment(this.state.filterOptions.fecha_entrega).format(
          "YYYY-MM-DD"
        ), // .hour(23).minute(58).second(59).toDate()
      };
    }

    this.setState({ loading: true }, () => {
      Feathers.service("pedido")
        .find({
          query,
        })
        .then((res) => {
          this.setState({
            orders: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        });
    });
  };

  showInvoice = async (order) => {
    try {
      window.open('', '_blank').location.href = `https://facturacion.bingham.acsyt.com/storage/orders/${order.id}/invoice.pdf`;
      //window.open('', '_blank').location.href = `http://localhost:8000/storage/orders/${order.id}/invoice.pdf`;
    } catch (error) {
      swal({
        title: "Ocurrió un error",
        type: "error",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        reverseButtons: true,
      });
    }
  };

  handleResultSelect = async (e, { result }) => {
    this.setState({ client: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingSearch: true, client: value });

    setTimeout(() => {
      if (this.state.client.length < 1) return this.resetComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingSearch: false,
            results: res.data,
          });
        });
    }, 500);
  };

  handleCleanButton = async () => {
    await this.setState({
      client: "",
      results: null,
      filterOptions: {
        ...this.state.filterOptions,
        id: null,
        status_id: null,
        fecha_entrega: "",
        fecha_alta: "",
      },
    });

    this.fetchData(this.state);
  };

  resetComponent = () =>
    this.setState({ loadingSearch: false, results: [], client: "" });

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Cliente</label>
                  <Search
                    icon=""
                    loading={this.state.loadingSearch}
                    value={this.state.client}
                    results={this.state.results}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    resultRenderer={renderSearchResults}
                    placeholder="Cliente"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Estatus</label>
                  <Form.Dropdown
                    selection
                    options={this.state.filterOptions.status_options}
                    onChange={(e, { value }) => {
                      this.setState(
                        {
                          filterOptions: {
                            ...this.state.filterOptions,
                            status_id: value,
                          },
                        },
                        (_) => this.fetchData(this.state)
                      );
                    }}
                    value={this.state.filterOptions.status_id}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Fecha Alta</label>
                  <DayPickerInput
                    value={this.state.filterOptions.fecha_alta}
                    format="YYYY-MM-DD"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    onDayChange={(date) => {
                      this.setState(
                        {
                          filterOptions: {
                            ...this.state.filterOptions,
                            fecha_alta: date,
                          },
                        },
                        (_) => this.fetchData(this.state)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Fecha Entrega</label>
                  <DayPickerInput
                    value={this.state.filterOptions.fecha_entrega}
                    format="YYYY-MM-DD"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    onDayChange={(date) => {
                      this.setState(
                        {
                          filterOptions: {
                            ...this.state.filterOptions,
                            fecha_entrega: date,
                          },
                        },
                        (_) => this.fetchData(this.state)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      exportToXls(this.state.orders, "admin_orders");
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            {
              Header: "SAE",
              id: "ref_sae",
              maxWidth: 80,
              accessor: (d) => d.ref_sae,
            },
            { Header: "Cliente", accessor: "cliente.nombre" },
            {
              Header: "Fecha Entrega",
              id: "fecha_entrega",
              accessor: (d) => new Date(d.fecha_entrega).toLocaleDateString(),
            },
            {
              Header: "1er Producto",
              id: "p-producto",
              accessor: (d) => {
                if (!d.partidas) {
                  return "---";
                }
                if (d.partidas.length) {
                  return d.partidas[0].producto.nombre;
                } else {
                  return d.partidas.producto.nombre;
                }
              },
            },
            {
              Header: "Cantidad",
              id: "p-cantidades",
              maxWidth: 100,
              accessor: (d) => {
                if (!d.partidas) {
                  return "---";
                }

                if (d.partidas.length) {
                  return d.partidas[0].cantidad;
                } else {
                  return d.partidas.cantidad;
                }
              },
            },
            {
              Header: "Dureza",
              accessor: "partidas.dureza.valor",
              maxWidth: 100,
            },
            {
              Header: "P. Mancheta",
              accessor: "partidas.manchetas.parte",
              maxWidth: 100,
            },
            {
              Header: "Status",
              id: "status",
              accessor: (d) => {
                if (d.status_id === 1)
                  return (
                    <Label basic color="grey">
                      <Icon name="check" /> {d.status.nombre}{" "}
                    </Label>
                  );
                if (d.status_id === 2)
                  return (
                    <Label basic color="blue">
                      <Icon name="check" /> {d.status.nombre}{" "}
                    </Label>
                  );
                if (d.status_id === 3)
                  return (
                    <Label basic color="green">
                      <Icon name="check" /> {d.status.nombre}{" "}
                    </Label>
                  );
                if (d.status_id === 4)
                  return (
                    <Label basic color="green">
                      <Icon name="check" /> {d.status.nombre}{" "}
                    </Label>
                  );
                if (d.status_id === 5)
                  return (
                    <Label basic color="green">
                      <Icon name="check" /> {d.status.nombre}{" "}
                    </Label>
                  );
                if (d.status_id === 6)
                  return (
                    <Label basic color="red">
                      <Icon name="check" /> {d.status.nombre}{" "}
                    </Label>
                  );
              },
            },
            {
              Header: "Acciones",
              Cell: (row) => (
                <div>
                  <Popup
                    content="Ver factura"
                    trigger={
                      <Button
                        size="mini"
                        compact
                        basic
                        color="orange"
                        icon="file pdf outline"
                        onClick={() => this.showInvoice(row.original)}
                      />
                    }
                  />
                </div>
              ),
            },
          ]}
          manual
          data={this.state.orders}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / 100)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            if (column.id === "ref_sae") {
              let $saeSortDirection = this.state.$sort.$saeSortDirection;

              if ($saeSortDirection === null) {
                $saeSortDirection = -1;
              }

              if ($saeSortDirection === 1) {
                $saeSortDirection = -1;
              } else {
                $saeSortDirection = 1;
              }

              await this.setState({
                $sort: {
                  ...this.state.$sort,
                  $saeSortDirection,
                  $fechaAltaSortDirection: null,
                  $fechaEntregaSortDirection: null,
                },
              });

              this.fetchData(this.state);
            }

            if (column.id === "fecha_alta") {
              let $fechaAltaSortDirection = this.state.$sort
                .$fechaAltaSortDirection;

              if ($fechaAltaSortDirection === null) {
                $fechaAltaSortDirection = -1;
              }

              if ($fechaAltaSortDirection === 1) {
                $fechaAltaSortDirection = -1;
              } else {
                $fechaAltaSortDirection = 1;
              }

              await this.setState({
                $sort: {
                  ...this.state.$sort,
                  $fechaAltaSortDirection,
                  $fechaEntregaSortDirection: null,
                  $saeSortDirection: null,
                },
              });

              this.fetchData(this.state);
            }

            if (column.id === "fecha_entrega") {
              let $fechaEntregaSortDirection = this.state.$sort
                .$fechaEntregaSortDirection;

              if ($fechaEntregaSortDirection === null) {
                $fechaEntregaSortDirection = -1;
              }

              if ($fechaEntregaSortDirection === 1) {
                $fechaEntregaSortDirection = -1;
              } else {
                $fechaEntregaSortDirection = 1;
              }

              await this.setState({
                $sort: {
                  ...this.state.$sort,
                  $fechaEntregaSortDirection,
                  $fechaAltaSortDirection: null,
                  $saeSortDirection: null,
                },
              });

              this.fetchData(this.state);
            }
          }}
        />
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(InvoiceCreate);
