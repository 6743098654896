import React, { Component } from "react";
import { Grid, Label, Icon, Modal } from "semantic-ui-react";

export default class InfoTaskAssigned extends Component {
  periodicidad() {
    if (this.props.data.tiene_periodicidad && this.props.data.periodicidad) {
      if (this.props.data.periodicidad.id === 1) {
        return (
          <Label color="blue" size={"medium"}>
            <Icon name="repeat" />
            Tarea diaria
          </Label>
        );
      } else if (this.props.data.periodicidad.id === 2) {
        return (
          <Label color="teal" size={"medium"}>
            <Icon name="repeat" />
            Tarea semanal
          </Label>
        );
      } else if (this.props.data.periodicidad.id === 2) {
        return (
          <Label color="green" size={"medium"}>
            <Icon name="repeat" />
            Tarea mensual
          </Label>
        );
      }
    }
  }

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.close} size="tiny">
        <Modal.Header>
          <div>
            <h3>Detalles:</h3>
          </div>
          <Label>Creada el: {this.props.data.fecha_creacion}</Label>
          {this.props.data.entregable ? (
            <Label color="red" size={"medium"}>
              <Icon name="file pdf outline" />
              Requiere entregable
            </Label>
          ) : (
            <></>
          )}
          {this.periodicidad()}
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div>
                  <h3>Tarea a realizar:</h3>
                </div>
                <div>
                  <p>{this.props.data.titulo}</p>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div>
                  <h3>Descripción:</h3>
                </div>
                <div>
                  <p>{this.props.data.descripcion}</p>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <div>
                  <h3>Tipo de tarea:</h3>
                </div>
                <div>
                  <p>{this.props.data.tipo_tarea.nombre}</p>
                </div>
              </Grid.Column>
              {this.props.data.maquinaria ? (
                <Grid.Column>
                  <div>
                    <h3>Maquina:</h3>
                  </div>
                  <div>
                    <p>{this.props.data.maquinaria.nombre}</p>
                  </div>
                </Grid.Column>
              ) : (
                <></>
              )}
              {this.props.data.codigos_iso ? (
                <Grid.Column>
                  <div>
                    <h3>Código:</h3>
                  </div>
                  <div>
                    <p>{this.props.data.codigos_iso.code}</p>
                  </div>
                </Grid.Column>
              ) : (
                <></>
              )}
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <div>
                  <h3>Fecha y hora de inicio:</h3>
                </div>
                <div>
                  <p>{`${this.props.data.fecha_inicio} ${this.props.data.hora_inicio}`}</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div>
                  <h3>Fecha y hora a finalizar:</h3>
                </div>
                <div>
                  <p>{`${this.props.data.fecha_fin} ${this.props.data.hora_fin}`}</p>
                </div>
              </Grid.Column>
            </Grid.Row>
            {this.props.data.entregable ? (
              <Grid.Row>
                <Grid.Column>
                  <div>
                    <h3>Descripción del entregable:</h3>
                  </div>
                  <div>
                    <p>{this.props.data.descripcion_entregable}</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <></>
            )}
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
