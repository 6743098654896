import React, {Component} from 'react'
import {Header, Segment, Icon, Grid} from 'semantic-ui-react'
import { TaskStatus } from './'
import Timer from '../Timer'

export default class TaskOrderSegment extends Component {
    render() {
        return (
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Header as='h3' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px', backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='settings' />
                    <Header.Content>Estatus de la tarea</Header.Content>
                </Header>
                <Segment size={"large"}>
                    <Grid>
                        <Grid.Row columns={4}>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Estatus</span>
                                <TaskStatus status={this.props.task.status_tarea_id} text={"Iniciada"} />
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Hora inicio</span>
                                <span>{this.props.task.hora_inicio}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Hora Fin</span>
                                <span>{this.props.task.fin}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span style={{display: 'block', fontWeight: 'bold'}}>Contador</span>
                                <Timer finishTask={this.props.finishTask} restartTask={() => this.restartTask()}  start={this.props.task.hora_inicio} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        )
    }
}