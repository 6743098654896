import React, { Component }  from "react";
import { Modal, Grid, Icon, Form, Button } from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";

export default class MaterialsInfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueDollar: 0,
    };
  }

  fetchDollarValue = async () => {
    await Feathers.service("configuraciones").find({
      query: {
        id: 1
      },
    })
    .then(res => {
      this.setState({
        valueDollar: res.data[0].valor
      })
    })
  }

  componentDidMount() {
    this.fetchDollarValue();
  }

  render() {
    return (
      <Modal open={this.props.isOpened} size={"small"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          Nombre: {this.props.material.nombre}
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Grid divided textAlign="center" style={{ fontSize: "16px" }}>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Grid.Row>
                    <Icon name="clone"></Icon>{" "}
                    <span style={{ fontWeight: "bold" }}>Pegamento</span>
                  </Grid.Row>
                  <Grid.Row>
                    {this.props.material.pegamento
                      ? this.props.material.pegamento.nombre
                      : "---"}
                  </Grid.Row>
                </Grid.Column>

                <Grid.Column width={8}>
                  <Grid.Row>
                    <Icon name="clone"></Icon>{" "}
                    <span style={{ fontWeight: "bold" }}>Densidad</span>
                  </Grid.Row>
                  <Grid.Row>
                    {this.props.material.densidad
                      ? this.props.material.densidad
                      : 1}
                  </Grid.Row>
                </Grid.Column>

              </Grid.Row>

              <Grid.Row>

              <Grid.Column width={4}>
                  <Grid.Row>
                    <Icon name="dollar"></Icon>{" "}
                    <span style={{ fontWeight: "bold" }}>Costo USD</span>
                  </Grid.Row>
                  <Grid.Row>
                    {this.props.material.costo ? this.props.material.costo : "---"}
                  </Grid.Row>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Grid.Row>
                    <Icon name="dollar"></Icon>{" "}
                    <span style={{ fontWeight: "bold" }}>Costo MXN</span>
                  </Grid.Row>
                  <Grid.Row>
                    {this.props.material.costo ? this.props.material.costo *  this.state.valueDollar : "---"}
                  </Grid.Row>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Grid.Row>
                    <Icon name="dollar"></Icon>{" "}
                    <span style={{ fontWeight: "bold" }}>Precio USD</span>
                  </Grid.Row>
                  <Grid.Row>
                    {this.props.material.precio
                      ? this.props.material.precio
                      : "---"}
                  </Grid.Row>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Grid.Row>
                    <Icon name="dollar"></Icon>{" "}
                    <span style={{ fontWeight: "bold" }}>Precio MXN</span>
                  </Grid.Row>
                  <Grid.Row>
                    {this.props.material.precio ? this.props.material.precio *  this.state.valueDollar : "---"}
                  </Grid.Row>
                </Grid.Column>
                
              </Grid.Row>
              
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
