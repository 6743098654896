import React, {Component} from 'react'
import {Header, Table, Segment, Icon} from 'semantic-ui-react'

export default class VulcanizationDetail extends Component {
    render() {
        if (!this.props.detail.length) return null;

        return (
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Header as='h4' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px', backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='thermometer empty' />
                    <Header.Content>Rodillos que se están vulcanizando</Header.Content>
                </Header>
                
                    <Table striped compact>
                        <Table.Header>
                            <Table.Row>
                            <Table.HeaderCell>Unidad</Table.HeaderCell>
                            <Table.HeaderCell>SAE</Table.HeaderCell>
                            <Table.HeaderCell>Cliente</Table.HeaderCell>
                            <Table.HeaderCell>Tipo</Table.HeaderCell>
                            <Table.HeaderCell>Parte - Mancheta</Table.HeaderCell>
                            <Table.HeaderCell>Material</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.props.detail.map((p, index) => {
                                return (
                                    <Table.Row key={p.id}>
                                        <Table.Cell>{p.rodillo_id}</Table.Cell>
                                        <Table.Cell>
                                        {
                                            p.rodillo.partida.pedido.ref_sae ?  (p.rodillo.partida.pedido.ref_sae) : ("---")
                                        }
                                        </Table.Cell>
                                        <Table.Cell>{p.rodillo.partida.pedido.cliente.nombre}</Table.Cell>
                                        <Table.Cell> {p.rodillo.partida.producto  ? (p.rodillo.partida.producto.nombre) : ("---") } </Table.Cell>
                                        <Table.Cell>
                                        {
                                            p.rodillo.partida.mancheta ? p.rodillo.partida.mancheta.parte : "---"
                                        }
                                        </Table.Cell>
                                        <Table.Cell>
                                        {
                                            p.rodillo.partida.material ? p.rodillo.partida.material.nombre : "---"
                                        }
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                
            </div>
        )
    }
}