import React, { Component } from "react";
import Feathers from "../../../redux/FeathersRedux";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../../components/Tasks/Construction/ConstructionTask";
import { notify } from "react-notify-toast";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const SERVICE = "construccion";

class ConstructionPoliuretanoContainerTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishingTask: false,
      ready: false,
      task: null,
    };

    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.initTask = this.initTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  checkActiveTasks() {
    Feathers.service(SERVICE)
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          if (res.data[0].rodillo.esMancheta === 0) {
            this.setState({
              task: res.data[0],
              ready: true,
            });
          } else {
            this.setState({
              ready: true,
            });
          }
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  viewTask() {
    Feathers.service("construccion")
      .get(this.props.match.params.id)
      .then(
        (task) => {
          this.setState({ task });
        },
      );
  }

  initTask(task) {
    Feathers.service(SERVICE)
      .patch(task.id, {
        hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
        status_tarea_id: 1,
        usuario_id: this.props.user.id,
        //aproducir: res.value,
      })
      .then((res) => {
        Feathers.service(SERVICE)
          .get(res.id)
          .then(
            (task) => this.setState({ task }),
            () => this.fetchData(this.state)
          );
      });
  }

  finishTask() {
    swal({
      title: "¿Finalizar tarea de construcción?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (res.value) {
        this.setState({
          finishingTask: true,
        });
        Feathers.service(SERVICE)
          .patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          })
          .then(async (res) => {
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(6);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de constucción poliuretano #${this.state.task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }
            this.props.history.goBack();
            this.setState(
              {
                //task: null,
                finishingTask: false,
              },
            );
          });

        /* if (this.state.task.aproducir === 1) {
        } */

        //if (this.state.task.aproducir >= 2) {
        // como se seleccionó mas de uno al producir, tenemos que buscar los que quedan pendientes.

        /*
                      Tenemos el objeto de colecciones.

                      la colección tipo 4 es poliuretano rodillo
                      la tipo 3 es poliuretano pieza.

                      Tenemos que agarrar la coleccion del tipo de producto que es la tarea 
                    */

        /* let tipo_producto = this.state.task.rodillo.partida.producto_id;

        let coleccion = this.state.colecciones.find((o, i) => {
          if (o.tipo === tipo_producto) return o;
        }); */

        /* coleccion = JSON.parse(JSON.stringify(coleccion));

        // ya que tenemos la colección, tenemos que agarrar los pendientes

        let unidades_pendientes = JSON.parse(
          JSON.stringify(coleccion.poliuretanos)
        ); */

        /*
                    
                      de los pendientes, tenemos que sacar el corriente, es decir
                      el que está siendo construido primero, el actual, 
                      la task, pues.
                    */

        /* unidades_pendientes = unidades_pendientes.filter((v) => {
          if (v.id !== this.state.task.id) return v;
        }); */

        /*

                      tenemos que agarrar solo de unidades pendientes los que el usuario seleccinó como a producir 


                      recordemos que "a producir" toma en cuenta la unidad produciendose, por lo que
                      tendremos que restarle un -1
                    
                    */

        /* let unidades_a_producir = unidades_pendientes.slice(
          0,
          this.state.task.aproducir - 1
        ); */

        /*
                    
                    ya que tenemos las unidades a producir, hay que actualizarlas, con los mismos datos de lo
                    que llamamos unidad corriente;  me gustaría más llamarla, unidad original.


                    esos datos son:

                    venda_id
                    dn_final
                    de_final
                    
                    la hora inicio y hora fin serán dinámicas
                    TODO: hay que pensar bien eso de hora inicio y hora fin
                    */

        /* let atributos_a_actualizar = {
          de_final: this.state.task.de_final,
          dn_final: this.state.task.dn_final,
          venda_id: this.state.task.venda_id,
        }; */

        /*
                    Vamos a, con un for of, recorrer los pendientes e iremos actualizandolos
                    */

        /* for (let pendiente of unidades_a_producir) {
          let pendiente_actualizado = await Feathers.service(
            "construccion"
          ).patch(pendiente.id, {
            de_final: atributos_a_actualizar.de_final,
            dn_final: atributos_a_actualizar.dn_final,
            venda_id: atributos_a_actualizar.venda_id,
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
          });

          let pendiente_terminado = await Feathers.service(
            "construccion"
          ).patch(pendiente.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          });
        } */

        /*
                    
                    ahora terminaremos el original

                    */

        /* try {
          let original_terminado = await Feathers.service(
            "construccion"
          ).patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          });
        } catch (error) {
          console.log(error);
        }

        this.setState(
          {
            task: null,
            finishingTask: false,
          },
          () => this.fetchData(this.state)
        ); */
        //}
      }
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("construccion")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        this.setState({ task: null });
        notify.show("Incidencia registrada", "success");
        this.fetchData(this.state);
      });
  }

  updateTask() {
    Feathers.service(SERVICE)
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  componentDidMount() {
    this.viewTask()
  }

  render() {
    if (this.state.task) {
      return (
        <TaskContainer
          loading={this.state.finishingTask}
          goBack={this.goBack}
          initTask={this.initTask}
          finishTask={this.finishTask}
          task={this.state.task}
          updateTask={this.updateTask}
          cancelTaskByIncidence={this.cancelTaskByIncidence}
        />
      );
    }

    return <span>Cargando...</span>;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ConstructionPoliuretanoContainerTask);
