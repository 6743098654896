import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import { Grid, Label } from "semantic-ui-react";
import CardVideo from "./CardVideo";

export default class VideosView extends Component {
  constructor() {
    super();
    this.state = {
      maquinaria_video: [],
      total: 0,
    };
  }

  componentDidMount = () => {
    this.fetchVideos();
  };

  fetchVideos = () => {
    const idMaquina = this.props.idMaquina;
    Feathers.service("maquinaria_video")
      .find({
        query: {
          maquinaria_id: idMaquina,
        },
      })
      .then((res) => {
        this.setState({
          maquinaria_video: res.data,
          total: res.total,
        });
        console.log(this.state.maquinaria_video, "mis videos");
      });
  };



  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
            <Label>
              Total: {this.state.maquinaria_video.length}
            </Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            
            {this.state.maquinaria_video.map(video => (
              <Grid.Column style={{marginBottom: '2rem'}}>
                <CardVideo 
                key={video.id}
                id={video.id}
                nombre={video.nombre_video}
                link={video.link_video}
                total={video.length}
                callback={this.fetchVideos}
                isAdmin={this.props.isAdmin}
              />
              </Grid.Column>
            ))}
            
          </Grid.Row>       
        </Grid>
      </div>
    );
  }
}
