import React from 'react';
import { Tab, Grid, Header } from 'semantic-ui-react'
import ClientsList from '../components/Clients/ClientsList';
import ClientsForm from '../components/Clients/ClientsForm';

const panes = [
    {
        menuItem: { key: 'list', icon: 'list', content: 'Lista' },
        pane: <Tab.Pane style={{boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)"}} key={1}><ClientsList/></Tab.Pane>
    },
    {
        menuItem: { key: 'add', icon: 'add circle', content: 'Nuevo' },
        pane: <Tab.Pane style={{boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)"}} key={2}><ClientsForm/></Tab.Pane>
    }
]

export default class ClientsContainer extends React.Component {
    
    constructor() {
        super()

        this.state = {
            currIndex: 0
        }
    }

    setTab = (index) => {
        this.setState({
          currIndex: index || 0
        });
    }

    render() {
        return (
            <div>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header as='h2' icon='address card outline' content='Clientes' />      
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Tab 
                            menu={{ secondary: true, pointing: true, color: 'red' }} 
                            panes={panes}
                            renderActiveOnly={false}
                            activeIndex={this.state.currIndex} 
                            onTabChange={(event, data) => this.setTab(data.activeIndex)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </div>
        )
    }
}