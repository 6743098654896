import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import { Modal, Embed, Button, Grid, Icon  } from "semantic-ui-react";
import swal from "sweetalert2";


class CardVideo extends Component {
  constructor() {
    super();
    this.state = {
      openVideo: false
    };
  }

  openVideo = () => {
    this.setState({
      openVideo: true,
    });
  };

  closeVideo = () => {
    this.setState({
      openVideo: false,
    });
  };

  Modal() {
    return (
      <Modal open={this.state.openVideo}>
        <Modal.Header style={{ height: "55px" }}>
          
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.closeVideo}
          />
        </Modal.Header>
        <Modal.Content>
          <Embed id={this.props.link} source="youtube" autoplay={true}/>
        </Modal.Content>
      </Modal>
    );
  }

  removeVideo = () => {
    const id = this.props.id
    swal({
      title: "¿Eliminar este video?",
      text: "Se eliminará el video seleccionado",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        await Feathers.service("maquinaria_video").remove(id);
        swal({
          title: "Exito",
          text: "Se eliminó con éxito el video",
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        this.props.callback()
      }
    });
  };

  render() {
    const isAdmin = this.props.isAdmin;
    return (
      <div>
        <section
          style={{
            boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)",
            padding: "0.8rem",
            borderRadius: "10px",
            height: "120px",
          }}
        >
          <section style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                height: "70%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon name="youtube play" circular color='red' size="large" />
              <h4 style={{ margin: "0 0 0 5px" }}>{this.props.nombre}</h4>
            </div>

            {isAdmin === 1            
            ?
            <div style={{ height: "30%", display: 'flex'}}>
              <Button
                size="tiny"
                content="Eliminar"
                basic
                color="red"
                icon='cancel'
                onClick={this.removeVideo}
                style={{width: '50%'}}
              />
              <Button
                size="tiny"
                content="Ver video"
                color="blue"
                icon=" play circle outline"
                style={{width: '50%'}}
                onClick={this.openVideo}
              />
            </div>
            :
            <div style={{ height: "30%", display: 'flex'}}>
              <Button
                size="tiny"
                content="Ver video"
                color="blue"
                icon=" play circle outline"
                fluid
                onClick={this.openVideo}
              />
            </div>
            }
            
          </section>
        </section>
        {this.Modal()}
      </div>
    );
  }
}

export default CardVideo;
