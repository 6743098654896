import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import {
  Form,
  Button,
  Select,
  Modal,
  Header,
  Container,
  Icon,
  Grid,
  Image,
  Search,
  Label,
} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../react-toggle.css";
import PropTypes from "prop-types";
import { exportToXls } from "../../utils/Utils";
import Config from "../../config";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import Toggle from "react-toggle";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);
const renderSaeSearchResults = ({ id, ref_sae }) => (
  <Label key={id} content={ref_sae} />
);

renderSearchResults.propTypes = {
  id: PropTypes.number,
  nombre: PropTypes.string,
};

class NotSolveList extends Component {
  state = {
    incidences: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 100,
    currentRow: {},
    filterInitialDay: "",
    filterEndDay: "",
    filterStage: "",
    shouldExport: false,
    filterStageOptions: [
      {
        key: 1,
        value: 1,
        text: "Revisión",
      },
      {
        key: 2,
        value: 2,
        text: "Molino",
      },
      {
        key: 3,
        value: 3,
        text: "Desviste",
      },
      {
        key: 4,
        value: 4,
        text: "Construcción",
      },
      {
        key: 5,
        value: 5,
        text: "Vulcanizado",
      },
      {
        key: 6,
        value: 6,
        text: "Desbaste",
      },
      {
        key: 7,
        value: 7,
        text: "Rectificado",
      },
      {
        key: 8,
        value: 8,
        text: "Control de Calidad",
      },
    ],
    imageToShow: "",
    modalOpen: false,
    filterStatusOptions: [
      {
        key: 1,
        value: 1,
        text: "Sin Resolver",
      },
      {
        key: 2,
        value: 2,
        text: "Resuelto",
      },
    ],
    filterStatus: "",
    filterSae: "",
    filterSaeOptions: {},
    $sort: {
      $fechaSortDirection: null,
      $saeSortDirection: null,
    },
    sae: "",
    resultsSae: [],
    loadingSAESearch: false,
    columna: "",
    sortDirection: "",
    showSae: false,
    tableColumns: [
      {
        Header: "OT",
        id: "ref_sae",
        accessor: (d) => {
          if (d.pedido && d.pedido.ref_sae) return d.pedido.ref_sae;
          return "---";
        },
      },
      {
        Header: "Partida",
        id: "partida_id",
        accessor: (d) => {
          if (d) {
            if (d.rodillo)
              if (d.rodillo.partida_id) {
                return d.rodillo.partida_id;
              }
          }
          return "---";
        },
      },
      {
        Header: "Imagen",
        imagen: "imagen",
        sortable: false,
        Cell: (row) => {
          if (row.original.imagen) {
            return (
              <Button
                icon="search"
                onClick={() => this.handleOpen(row.original.imagen)}
              />
            );
          }
          return "---";
        },
      },
      {
        Header: "Tipo de incidencia",
        accessor: "tipoincidencia.nombre",
      },
      { Header: "Descripción", accessor: "descripcion" },
      { Header: "Fecha Hora", accessor: "fecha_hora" },
      {
        Header: "Usuario",
        id: "usuario_id",
        accessor: (d) => d.usuario.nombre + " " + d.usuario.apellido,
      },

      { Header: "#Rodillo", accessor: "rodillo_id" },
      {
        Header: "Etapa",
        id: "id.tipo_incidencia_id",
        accessor: (d) => {
          if (d.tipoincidencia.etapa === 1) return "Revisión";
          if (d.tipoincidencia.etapa === 2) return "Molino";
          if (d.tipoincidencia.etapa === 3) return "Desviste";
          if (d.tipoincidencia.etapa === 4) return "Construcción";
          if (d.tipoincidencia.etapa === 5) return "Vulcanizado";
          if (d.tipoincidencia.etapa === 6) return "Desbaste";
          if (d.tipoincidencia.etapa === 7) return "Rectificado";
          // if (d.tipoincidencia.etapa === 8) return "Control de Calidad"; // DO NOT SHOW QA
          if (d.tipoincidencia.etapa === 9) return "Desmonte";
          if (d.tipoincidencia.etapa === 10) return "Corte";
        },
      },
      {
        Header: "Acciones",
        sortable: false,
        Cell: (row) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                size={"tiny"}
                compact
                basic
                primary
                icon="eye"
                onClick={() => this.handleViewIncidence(row)}
              />
            </div>
          );
        },
      },
    ],
  };

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Tipo de incidencia":
        array.data.sort((a, b) => {
          const nameA = a["tipoincidencia"]["nombre"].toUpperCase(); // ignore upper and lowercase
          const nameB = b["tipoincidencia"]["nombre"].toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Descripción":
        array.data.sort((a, b) => {
          const nameA = a["descripcion"].toUpperCase(); // ignore upper and lowercase
          const nameB = b["descripcion"].toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha Hora":
        array.data.sort((a, b) => {
          const nameA = a["fecha_hora"].toUpperCase(); // ignore upper and lowercase
          const nameB = b["fecha_hora"].toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Usuario":
        array.data.sort((a, b) => {
          const nameA = a["usuario"]["nombre"].toUpperCase(); // ignore upper and lowercase
          const nameB = b["usuario"]["nombre"].toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "OT":
        array.data.sort((a, b) => {
          return a["pedido"]["ref_sae"] - b["pedido"]["ref_sae"];
        });
        break;
      case "#Rodillo":
        array.data.sort((a, b) => {
          return a["rodillo"]["id"] - b["rodillo"]["id"];
        });
        break;
      case "Etapa":
        array.data.sort((a, b) => {
          return a["tipoincidencia"]["etapa"] - b["tipoincidencia"]["etapa"];
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  handleOpen = async (logicImageUrl) => {
    var imageToOpen = (await Config.apiUrl) + "/" + logicImageUrl;
    await this.setState({ modalOpen: true, imageToOpen: imageToOpen });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  async componentDidMount() {
    await this.fetchData();
  }

  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      solucion: null,
      pedido_id: {
        $ne: null,
      },
      $sort: {
        fecha_hora: -1,
      },
    };
    this.setState({ loading: true }, async () => {
      let query = {
        $limit: this.state.shouldExport ? "-1" : pageSize,
        $skip: pageSize * state.page,
        solucion: null,
        pedido_id: {
          $ne: null,
        },
        $sort: {
          fecha_hora: -1,
        },
      };

      let res = await Feathers.service("incidencia").find({ query });

      res = this.handleSort(res);

      if (
        this.state.filterSae ||
        this.state.filterStage ||
        this.state.filterStatus
      ) {
        if (this.state.filterStage.length !== 0) {
          if (!this.state.shouldExport) {
            res.data = res.data.filter((fila) => {
              return fila.tipoincidencia.etapa === this.state.filterStage;
            });
          } else {
            res = res.filter((fila) => {
              return fila.tipoincidencia.etapa === this.state.filterStage;
            });
          }
        }
      }

      if (res.data) {
        if (res.data.length === 0) {
          query.$skip = (this.state.page + 1) * this.state.pageSize;
          res = await Feathers.service("incidencia").find({ query });

          res = this.handleSort(res);

          if (
            this.state.filterSae ||
            this.state.filterStage ||
            this.state.filterStatus
          ) {
            if (this.state.filterStage.length !== 0) {
              res.data = res.data.filter((fila) => {
                return fila.tipoincidencia.etapa === this.state.filterStage;
              });
            }
          }
        }
      } else {
        if (res.length === 0) {
          query.$skip = (this.state.page + 1) * this.state.pageSize;
          res = await Feathers.service("incidencia").find({ query });

          res = this.handleSort(res);

          if (
            this.state.filterSae ||
            this.state.filterStage ||
            this.state.filterStatus
          ) {
            if (this.state.filterStage.length !== 0) {
              res = res.filter((fila) => {
                return fila.tipoincidencia.etapa === this.state.filterStage;
              });
            }
          }
        }
      }

      // if (this.state.columna === "") {
      //   res.data.sort((a, b) => {
      //     const nameA = a.pedido.fecha_entrega.toUpperCase();
      //     const nameB = b.pedido.fecha_entrega.toUpperCase();

      //     if (nameA < nameB) {
      //       return -1;
      //     }
      //     if (nameA > nameB) {
      //       return 1;
      //     }
      //     return 0;
      //   });
      // }

      if (!this.state.shouldExport) {
        this.setState({
          incidences: res.data,
          total: res.total,
          loading: false,
          page: !res.data.length ? this.state.page + 1 : this.state.page,
          pageSize: state.pageSize || 100,
        });
      }

      if (this.state.shouldExport) {
        exportToXls([], "report_incidences_not_solved");
        this.setState({
          shouldExport: false,
          loading: false,
          page: state.page,
          pageSize: state.pageSize || 100,
        });
      }
    });
  };

  renderFilterOptions = () => {
    return (
      <Form size={"large"}>
        <div style={{ marginBottom: "1rem" }}>
          <label>Seleccione un filtro para tener resultados específicos:</label>
        </div>
        <Form.Group>
          {/*  <Form.Field >
                <label>Fecha Inicio</label>
                    <DayPickerInput value={this.state.filterInitialDay} format="YYYY-MM-DD" formatDate={formatDate} parseDate={parseDate}  onDayChange={date => this.setState({filterInitialDay: date})} />
                </Form.Field>
                <Form.Field>
                <label>Fecha Fin</label>
                    <DayPickerInput value={this.state.filterEndDay} format="YYYY-MM-DD" formatDate={formatDate} parseDate={parseDate} onDayChange={date => this.setState({filterEndDay: date})} />
                </Form.Field> */}
          <Form.Field>
            <label>Etapa por tareas:</label>
            <Select
              placeholder="Selecciona una etapa"
              options={this.state.filterStageOptions}
              value={this.state.filterStage}
              onChange={(event, data) => {
                this.setState({ filterStage: data.value });
                this.fetchData();
              }}
            />
          </Form.Field>
          {/* <Form.Field>
            <label>SAE:</label>
            <Search
              loading={this.state.loadingSAESearch}
              value={this.state.sae}
              results={this.state.resultsSae}
              onResultSelect={this.handleSaeResultSelect}
              onSearchChange={this.handleSaeSearchChange}
              resultRenderer={renderSaeSearchResults}
              placeholder="Nombre"
              className="full-width"
            />
          </Form.Field> */}
          <Form.Field>
            <label>Limpiar</label>
            <Button
              basic
              circular
              color="red"
              icon="delete"
              onClick={this.handleCleanFilter}
            />
          </Form.Field>
          <Form.Field>
            <label>Exportar</label>
            <Button
              basic
              color="green"
              circular
              icon="file excel outline"
              onClick={() => {
                this.setState({ shouldExport: true }, () => {
                  this.fetchData(this.state);
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Mostrar OT</label>
            {/* <input type="checkbox" name="showSAE"/> */}
            <Toggle
              icons={false}
              onClick={() => {
                this.handleSaeToggle();
              }}
            ></Toggle>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  handleSaeResultSelect = async (e, { result }) => {
    this.setState({ sae: result.ref_sae });
    this.setState({
      filterSaeOptions: {
        ...this.state.filterSaeOptions,
        ref_sae: result.ref_sae,
      },
    });

    this.fetchData(this.state);
  };

  resetSaeComponent = (_) => {
    this.setState({
      loadingSAESearch: false,
      resultsSae: [],
      sae: "",
    });
  };

  handleSaeSearchChange = async (e, { value }) => {
    this.setState({ loadingSAESearch: true, sae: value });

    setTimeout(() => {
      if (this.state.sae.length < 1) return this.resetSaeComponent();

      Feathers.service("pedido")
        .find({
          query: {
            ref_sae: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingSAESearch: false,
            resultsSae: res.data,
          });
        });
    }, 500);
  };
  handleViewIncidence = (row) => {
    //window.location = "/reports/incidences/view/" + row.original.id
    this.props.dispatch(push("/reports/incidences/view/" + row.original.id));
  };

  handleCleanFilter = () => {
    this.setState({
      filterInitialDay: "",
      filterEndDay: "",
      filterStage: "",
      filterSaeOptions: "",
      filterSae: "",
    });
    this.resetSaeComponent();
    this.fetchData();
  };
  render() {
    return (
      <div>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="small"
        >
          <Header icon="browser" content="Incidencia" />
          <Modal.Content>
            <Container fluid textAlign="center">
              <Image src={this.state.imageToOpen} size="small" />
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.handleClose} inverted>
              <Icon name="checkmark" /> Ok
            </Button>
          </Modal.Actions>
        </Modal>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="cancel"
                content="Reporte de incidencias no resueltas"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              {this.renderFilterOptions()}
              <ReactTable
                columns={
                  this.state.showSae
                    ? this.state.tableColumns
                    : this.state.tableColumns.slice(1)
                }
                manual
                data={this.state.incidences}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                defaultPageSize={100}
                onFetchData={this.fetchData}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState(
                    {
                      columna: column,
                      sortDirection: direction,
                    },
                    () => {
                      console.log(this.state);
                    }
                  );
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
export default connect(null, null)(NotSolveList);
