import React from "react";
import {
  Form,
  Dropdown,
  Step,
  Segment,
  Label,
  Grid,
  Input,
  Button,
  Search,
  Popup,
} from "semantic-ui-react";
import moment from "moment";
import Feathers, {
  feathersAuthentication,
  services,
} from "../../redux/FeathersRedux";
import Config from "../../config/index";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import swal from "sweetalert2";

// todas las lineas de código que tengan ! warning es porque me da miedo, jajaja, es que hay que checar que
// en el order form si se eliminen esos atribtos del objeto antes de registrarlos

/**
 * * En caso de que se reciban props, se seteara porque se trata de una edición.
 * * Si se trata de una edición, tenemos que handlear bien el patch
 *
 */

const renderSearchDibujoResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class PanelOrdersStepper extends React.Component {
  timeout = null;

  constructor(props) {
    super(props);
    /*
            TODO: parece que tenemos que guardar en el objeto partida dentro del state cual es el costo unitario puesto que
        */
    this.state = {
      active: "Producto",
      pedido: props.pedido,
      partida: {
        id: null, // ! cuidao con esto, que tampoco se debe de enviar al añadir la partida
        producto_id: null,
        material_id: null,
        color_id: null,
        cantidad: 0,
        dn: 0,
        de: 0,
        lc: 0,
        lt: 0,
        kilos: 0,
        precio: 0,
        observaciones: "",
        dibujo_id: null,
        chaflan_id: null,
        dureza_id: null,
        mancheta_id: null,
        costo_interno: null,
        formula: null, // ! hay que tener cuidao con esto, porque no debe de enviarse al crear la partida
        parte_id: null, // ! warning
        tipo_mancheta_id: null, // ! warning
        forma_mancheta_id: null, // ! warning
        perimetro: null, // ! warning
        espesor: null, // ! warning
        altura: null, // ! warning
        dureza: null, // ! warning,
        unitario: null, // ! warning
        dibujo: null,
      },
      productos: props.productos,
      formulas: "",
      formulasMancheta: props.formulasMancheta,
      selectedFormula: {},
      selectedDibujo: {},
      colores: props.colores,
      tipo_manchetas: [],
      tipo_mancheta_id: 0,
      dibujos: [],
      chaflanes: [],
      parte_id: 0,
      manchetas: [],
      parte_mancheta: [],
      forma_mancheta: [],
      forma_id: 0,
      selected_Mancheta: {},
      extras: {
        kilos_por_pieza: "",
      },
      configuraciones: {
        cambio_dolar: "",
        valor_formula_1: "",
        valor_formula_2: "",
        // factorDE: "",
        // factorDN: "",
        // factorLC: "",
        // factorLT: "",
      },
      loadingCalculos: false,
      loadingPreloadedData: false,
      total_unitario: 0,

      formulaColor: "",

      filterOptions: {
        dibujo_id: "",
      },
      dibujo: "",
      dibujoLoading: null,
      dibujoResults: null,
    };

    this.renderStep = this.renderStep.bind(this);
  }

  handleDibujoResultSelect = async (e, { result }) => {
    this.setState({
      dibujo: result.nombre,
    });
    this.updatePartida("dibujo", result);
    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        dibujo_id: result.id,
      },
    });

    //this.fetchData(this.state)
  };

  handleDibujoSearchChange = (e, { value }) => {
    this.setState({ dibujoLoading: true, dibujo: value });

    this.timeout = setTimeout(() => {
      if (this.state.dibujo.length < 1) return this.resetDibujoComponent();

      Feathers.service("dibujo")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            dibujoLoading: false,
            dibujoResults: res.data,
          });
        });
    }, 500);
  };

  resetDibujoComponent = () =>
    this.setState({ dibujoLoading: false, dibujoResults: [], dibujo: "" });

  checkIfThereIsaPartidaPreloaded = async (_) => {
    /**
     * Necesitamos revisar si hay una partida precargada, pues eso significa que estamos hablando de una edición y no de una creación
     * de una partida
     */

    if (this.props.preloadedPartida !== null) {
      await this.setState({
        loadingPreloadedData: true,
      });
      console.log(this.props.preloadedPartida, "props");
      this.setState(
        {
          selectedFormula: this.props.preloadedPartida.formula,
          formulaColor: this.props.preloadedPartida.formula
            ? this.props.preloadedPartida.formula.color.nombre
            : "",
          selectedDibujo: this.props.preloadedPartida.dibujo,
          dibujo: this.props.preloadedPartida.dibujo
            ? this.props.preloadedPartida.dibujo.nombre
            : "",
          partida: {
            ...this.state.partida,
            id: this.props.preloadedPartida.id, // ! warning
            producto_id: this.props.preloadedPartida.producto_id,
            material_id: this.props.preloadedPartida.material_id,
            color_id: this.props.preloadedPartida.color_id,
            cantidad: this.props.preloadedPartida.cantidad,
            dn: this.props.preloadedPartida.dn,
            de: this.props.preloadedPartida.de,
            lc: this.props.preloadedPartida.lc,
            lt: this.props.preloadedPartida.lt,
            kilos: this.props.preloadedPartida.kilos,
            precio: this.props.preloadedPartida.precio,
            observaciones: this.props.preloadedPartida.observaciones,
            dibujo_id: this.props.preloadedPartida.dibujo_id,
            chaflan_id: this.props.preloadedPartida.chaflan_id,
            dureza_id: this.props.preloadedPartida.dureza_id,
            mancheta_id: this.props.preloadedPartida.mancheta_id,
            costo_interno: this.props.preloadedPartida.costo_interno,
            formula: this.props.preloadedPartida.formula, // ! warning
            dibujo: this.props.preloadedPartida.dibujo,

            parte_id: this.props.preloadedPartida.parte_id, // ! warning
            tipo_mancheta_id: this.props.preloadedPartida.tipo_mancheta_id, // ! warning
            forma_mancheta_id: this.props.preloadedPartida.forma_mancheta_id, // ! warning,
            perimetro: this.props.preloadedPartida.perimetro, // ! warning
            espesor: this.props.preloadedPartida.espesor, // ! warning
            altura: this.props.preloadedPartida.altura, // ! warning
            dureza: this.props.preloadedPartida.dureza, // ! warning
            unitario: this.props.preloadedPartida.unitario, // ! warning
          },
        },
        (_) => this.getManchetasPreloadedRequirements()
      );
    }

    if (this.props.preloadedPartida === null) {
      console.log("No tiene partida precargada");
    }
  };

  getManchetasPreloadedRequirements = async (_) => {
    await this.setState({
      loadingPreloadedData: true,
    });

    if (this.props.preloadedPartida.producto_id === 5) {
      // tenemos que traer el tipo de mancheta y al forma de mancheta

      // empezamos con el tipo de mancheta

      // nos traemos los tipos de manchetas
      let tipo_manchetas_pre = await Feathers.service("tipo_mancheta").find({
        query: {
          $limit: "-1",
        },
      });
      // las formateamos de la menera adecuada
      tipo_manchetas_pre = tipo_manchetas_pre.map((v) => {
        return {
          key: v.id,
          value: v.id,
          text: v.nombre,
        };
      });

      let manchetas = await Feathers.service("manchetas").find({
        query: {
          $limit: "-1",
        },
      });

      // para traer los tipos de manchetas que serán permitidos

      manchetas = manchetas.filter((v) => {
        if (v.parte == this.state.partida.parte_id) return v;
      });

      let tipos_de_manchetas = manchetas.map((v) => v.tipo_mancheta_id);

      let tipo_manchetas = tipo_manchetas_pre.filter((v) => {
        if (tipos_de_manchetas.indexOf(v.value) !== -1) return v;
      });

      // terminamos con el tipo de mancheta

      // empezamos con la forma de mancheta

      manchetas = manchetas.filter((v) => {
        if (
          v.parte === this.state.partida.parte_id &&
          v.tipo_mancheta_id === this.state.partida.tipo_mancheta_id
        )
          return v;
      });

      let formas_aceptadas = manchetas.map((v) => v.forma_mancheta_id);

      let formas = await Feathers.service("forma_mancheta").find({
        query: {
          $limit: "-1",
        },
      });

      formas = formas.filter((v) => {
        if (formas_aceptadas.indexOf(v.id) !== -1) return v;
      });

      let forma_mancheta = formas.map((v) => {
        return {
          key: v.id,
          text: v.nombre,
          value: v.id,
        };
      });

      // terminamos con la forma de mancheta
      // seleccionamos la mancheta

      manchetas = manchetas.filter((v) => {
        if (
          v.parte === this.state.partida.parte_id &&
          v.tipo_mancheta_id === this.state.partida.tipo_mancheta_id &&
          v.forma_mancheta_id === this.state.partida.forma_mancheta_id
        )
          return v;
      });

      let findDureza = await Feathers.service("dureza").find({
        query: {
          $limit: "-1",
          valor: manchetas[0].dureza,
        },
      });

      let material_id = 0;

      let formulas = this.state.formulasMancheta || [];

      let formulas_manchetas = formulas.filter((v) => {
        if (v.value.id === 84 || v.value.id === 85) return v;
      });

      let formula_de_la_mancheta = [];

      if (manchetas[0].dureza === 60) {
        formula_de_la_mancheta = JSON.parse(
          JSON.stringify(formulas_manchetas)
        ).filter((v) => {
          if (v.value.id == 84) return v;
        });
        material_id = 7;
      }

      if (manchetas[0].dureza === 70) {
        formula_de_la_mancheta = JSON.parse(
          JSON.stringify(formulas_manchetas)
        ).filter((v) => {
          if (v.value.id == 85) return v;
        });

        material_id = 7;
      }

      // ya seleccionamos la mancheta

      this.setState({
        tipo_manchetas,
        forma_mancheta,
        selected_Mancheta: manchetas[0],
      });
    }

    await this.setState({
      loadingPreloadedData: false,
    });
  };

  // #region fetching data
  fetchConfiguraciones = async (_) => {
    try {
      const configuraciondolar =
        this.props.getDolarPrice && (await this.props.getDolarPrice());
      //Probando traer el valor de la formula asignado en configuraciones generales
      const configuracionValorFormula1 =
        this.props.getValorFormula1 && (await this.props.getValorFormula1());
      const configuracionValorFormula2 =
        this.props.getValorFormula2 && (await this.props.getValorFormula2());
      // get factors
      // const factorDN =
      //   this.props.getFactorDN && (await this.props.getFactorDN());
      // const factorDE =
      //   this.props.getFactorDE && (await this.props.getFactorDE());
      // const factorLC =
      //   this.props.getFactorLC && (await this.props.getFactorLC());
      // const factorLT =
      //   this.props.getFactorDN && (await this.props.getFactorLT());

      // console.log("factores:", factorDN, factorDE, factorLT, factorLC);

      //console.log("configuraciondolarconfiguraciondolar:", configuraciondolar.value.data[0].valor);
      //console.log("valor formula", valorFormula1);
      // console.log(
      //   "configuraciondolar.value[0].valor:",
      //   configuraciondolar.value.data[0].valor
      // );
      this.setState(
        {
          configuraciones: {
            ...this.state.configuraciones,
            cambio_dolar: +configuraciondolar.value.data[0].valor,
            //Probando traer el valor de la formula asignado en configuraciones generales
            valor_formula_1: +configuracionValorFormula1.value.data[0].valor,
            valor_formula_2: +configuracionValorFormula2.value.data[0].valor,
            // factorDE: factorDE.value.total,
            // factorDN: factorDN.value.total,
            // factorLC: factorLC.value.total,
            // factorLT: factorLT.value.total,
          },
        },
        console.log("factoresfaskjfashf9uhashf: ", this.state)
      );
    } catch (error) {
      console.log(error);
    }
  };

  fetchManchetas = async () => {
    let manchetas = await Feathers.service("manchetas").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    let parte_mancheta = manchetas.map((v) => {
      return {
        key: v.id,
        text: v.parte,
        value: v.parte,
      };
    });

    this.setState({
      manchetas,
      parte_mancheta,
    });
  };

  fetchDrawings = () => {
    Feathers.service("dibujo")
      .find({
        query: {
          $limit: "-1",
          activo: 1,
        },
      })
      .then((res) => {
        let draws = this.parseDropdown(res);
        this.setState({ dibujos: draws });
      });
  };

  fetchBevels = () => {
    Feathers.service("chaflan")
      .find()
      .then((res) => {
        let bevels = this.parseDropdown(res.data);
        this.setState({ chaflanes: bevels });
      });
  };

  // #endregion  fetching data

  parseDropdown = (array) => {
    let pews = array.map((pew) => {
      let grr = {
        key: pew.nombre,
        text: pew.nombre,
        value: pew.id,
      };
      /* if (pew.imagen) {
        let formatedImage = pew.imagen === "/" ? pew.imagen : "/" + pew.imagen;
        const url = Config.apiUrl + formatedImage;
        grr.image = {
          avatar: true,
          src: url,
          size: "mini"
        };
      } */
      return grr;
    });
    return pews;
  };

  updatePartida = (key, value) => {
    //this.props.cleanPartidaProps && this.props.cleanPartidaProps();
    //console.log(this.props, "props")
    this.fetchFormulas(value);
    if (key === "dibujo") {
      this.setState({
        partida: {
          ...this.state.partida,
          dibujo: value,
          dibujo_id: value.id,
        },
      });
    } else {
      this.setState({
        partida: {
          ...this.state.partida,
          [key]: value,
        },
      });
    }
  };

  /**
   * Actualiza el costo unitario del producto
   * @memberof PanelOrdersStepper
   */
  onChangeCostoUnitario = async (product) => {
    //console.log(this.state.partida);
    //console.log("product:", product.target.value);
    await this.setState({
      partida: {
        ...this.state.partida,
        unitario: product.target.value,
      },
    });
  };

  calcFechas = () => {
    return moment(this.state.pedido.fecha_entrega).diff(moment(), "days", true);
  };

  calculoCostoInterno = async () => {
    console.log("Ya entro aqui");
    const { pedido, partida } = this.state;
    /*
        tenemos que calcular el precio interno, es decir el que le cuesta
        a bingham producir ese producto.
        */
    console.log(this.state.configuraciones);

    if (partida.material_id) {
      console.log("query", partida.material_id, partida.dureza_id);
      let formula = await Feathers.service("formula").find({
        query: {
          material_id: partida.material_id,
          dureza_id: partida.dureza_id,
          //color_id: partida.color_id,
          $limit: "-1",
        },
      });

      if (!formula.length) {
        swal({
          title: "Error",
          text: "No se encontró una fórmula para este producto.",
          type: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        this.props.closeModal();
        return 0;
      }

      console.log("formula", formula);

      let formula_id = formula[0].id;

      let formula_materia_prima = await Feathers.service(
        "formula_materia_prima"
      ).find({
        query: {
          formula_id: formula_id,
        },
      });

      let suma = 0;
      let sumaPartes = 0;

      /*
            para calcular ese costo interno del que hablamos, debemos multiplicar las partes de ese material que dice la formula, por el costo
            y vamos a dividir eso entre las partes
            
            */
      for (let fmp of formula_materia_prima) {
        let materia_prima = await Feathers.service("materia_prima").get(
          fmp.materia_prima_id
        );

        if (materia_prima.costo === "MXN") {
          suma += +fmp.partes * +materia_prima.costo; //180
          sumaPartes += +fmp.partes; //25
        } else {
          let conversion_mp =
            materia_prima.costo * this.state.configuraciones.cambio_dolar;
          suma += +fmp.partes * +conversion_mp; //180
          sumaPartes += +fmp.partes; //25
        }
      }

      if (sumaPartes === 0) return 0;

      let costo_interno_kilo = suma / sumaPartes; //180/25=7.2

      //   console.log('costo_interno_kilocosto_interno_kilocosto_interno_kilo:', costo_interno_kilo)
      return costo_interno_kilo;
    }
    let product = await Feathers.service("producto").get(partida.producto_id);
    const price = +product.precio * partida.cantidad;
    this.setState({
      partida: {
        ...this.state.partida,
        unitario: price,
      },
    });
    return price;
    // return 0;
  };

  calcPartida = async () => {
    // TODO: ver respuesta de bingham de como los factores dn, de, lt y lc van a afectar al precio
    console.log("vamos a calcular");
    const { pedido, partida } = this.state;
    console.log(this.state.configuraciones);

    await this.setState({
      loadingCalculos: true,
    });

    let costo_interno = await this.calculoCostoInterno();
    //("costo_internocosto_interno:", costo_interno);
    //console.log("props: ", this.props)

    if ([1].indexOf(this.state.partida.producto_id) !== -1) {
      console.log("producto 1");
      partida.de = +partida.de;
      partida.dn = +partida.dn;
      partida.lc = +partida.lc;

      let material = await Feathers.service("material").get(
        partida.material_id
      );

      //Variables que traen los valores asignados para la formula
      let valornewFormula1 = this.state.configuraciones.valor_formula_1;
      let valorNewFormula2 = this.state.configuraciones.valor_formula_2;
      console.log(valorNewFormula2, valornewFormula1);
      //FORMULA
      partida.kilos = +(
        (Math.pow(+partida.de + valornewFormula1, 2) -
          Math.pow(+partida.dn, 2)) *
        +partida.lc *
        valorNewFormula2 *
        +partida.cantidad
      ).toFixed(6);
      console.log(partida.kilos);

      partida.kilos *= +material.densidad;

      partida.kilos = partida.kilos.toFixed(6);

      console.log(partida.kilos);
      costo_interno *= partida.kilos;

      console.log(costo_interno);
      partida.costo_interno = costo_interno;

      let factor = 150;
      if (partida.kilos > 65) factor = 10;
      else if (partida.kilos > 50) factor = 30;
      else if (partida.kilos > 40) factor = 45;
      else if (partida.kilos > 35) factor = 60;
      else if (partida.kilos > 30) factor = 75;
      else if (partida.kilos > 25) factor = 90;
      else if (partida.kilos > 20) factor = 115;

      let precio = 350;

      if (material.precio) {
        precio = +material.precio * this.state.configuraciones.cambio_dolar;
      }

      let total = (precio + factor) * partida.kilos;
      console.log(total);

      let diffFechas = this.calcFechas();

      if (diffFechas >= 12) {
        total *= 1.1;
      } else if (diffFechas >= 6) {
        total *= 1.22;
      } else if (diffFechas >= 2.5) {
        total *= 1.33;
      }

      /*
                debido a que el calculador saca el precio en moneda nacional mexicana.

                Si la moneda seleccionada es 1, es decir moneda mexicana, se deja como tal

                si se lecciona 2, es decir dollar estadounidense, entonces se divide entre el factor (eg 20) para sacar
                lo que sería el costo en dolares
            */

      partida.precio =
        pedido.moneda_id == 1
          ? Math.ceil(total)
          : total / this.state.configuraciones.cambio_dolar;

      //let total_unitario = this.props.preloadedPartida ? this.props.preloadedPartida.unitario : +(partida.precio / partida.cantidad).toFixed(2);
      let total_unitario = +(partida.precio / partida.cantidad).toFixed(2);
      partida.unitario = total_unitario;

      //partida.precio = this.props.preloadedPartida ? this.props.preloadedPartida.precio : partida.precio;
      partida.precio = partida.precio;

      console.log(partida, "nueva partida");

      this.setState({
        total_unitario,
        partida: {
          ...this.state.partida,
          ...partida,
        },
      });
    }

    if ([4].indexOf(this.state.partida.producto_id) !== -1) {
      partida.de = +partida.de;
      partida.dn = +partida.dn;
      partida.lc = +partida.lc;

      //FORMULA
      //Variables que traen los valores asignados para la formula
      let valornewFormula1 = this.state.configuraciones.valor_formula_1;
      let valorNewFormula2 = this.state.configuraciones.valor_formula_2;

      partida.kilos = +(
        (Math.pow(partida.de + valornewFormula1, 2) - Math.pow(partida.dn, 2)) *
        partida.lc *
        valorNewFormula2 *
        partida.cantidad
      ).toFixed(6);

      if (partida.material_id) {
        let material = await Feathers.service("material").get(
          partida.material_id
        );

        partida.kilos *= +material.densidad;

        partida.kilos = partida.kilos.toFixed(3);
      }

      costo_interno *= partida.kilos;
      partida.costo_interno = costo_interno;

      this.setState({
        partida: {
          ...this.state.partida,
          ...partida,
        },
      });
    }

    if ([5].indexOf(this.state.partida.producto_id) !== -1) {
      let selected_Mancheta = JSON.parse(
        JSON.stringify(this.state.selected_Mancheta)
      );
      let total_unitario = selected_Mancheta.usd;

      if (selected_Mancheta) {
        let total = selected_Mancheta.usd * partida.cantidad;

        /*
                si la moneda es 1 = mexicana, entonces muultiplicamos todo por el tipo de cambio (eg 20) para sacar el costo en dolares
                si la moneda es 2, que es en dolares, pues se guarda el valor como tal en dolares

                esto es debido a que el precio se calcula en dolares
                */
        partida.precio =
          pedido.moneda_id === 1
            ? Math.ceil(total * this.state.configuraciones.cambio_dolar)
            : total.toFixed(2);
        total_unitario =
          pedido.moneda_id === 1
            ? Math.ceil(
                total_unitario * this.state.configuraciones.cambio_dolar
              )
            : total_unitario.toFixed(2);
        partida.precio = this.props.preloadedPartida
          ? this.props.preloadedPartida.precio
          : partida.precio;

        partida.kilos = selected_Mancheta.kilos * partida.cantidad;

        if (partida.material_id) {
          let material = await Feathers.service("material").get(
            partida.material_id
          );

          partida.kilos *= +material.densidad;

          partida.kilos = partida.kilos.toFixed(3);
        }

        costo_interno *= partida.kilos;
        partida.costo_interno = costo_interno;
      } else {
        partida.precio = 0;
      }

      partida.unitario = this.props.preloadedPartida
        ? this.props.preloadedPartida.unitario
        : total_unitario;

      this.setState({
        total_unitario,
        partida: {
          ...this.state.partida,
          ...partida,
        },
      });
    }

    if ([3].indexOf(this.state.partida.producto_id) !== -1) {
      partida.kilos = this.state.extras.kilos_por_pieza * partida.cantidad;

      if (partida.material_id) {
        let material = await Feathers.service("material").get(
          partida.material_id
        );

        partida.kilos *= +material.densidad;

        partida.kilos = partida.kilos.toFixed(6);
      }

      costo_interno *= partida.kilos;
      partida.costo_interno = costo_interno;

      this.setState({
        partida: {
          ...this.state.partida,
          ...partida,
        },
      });
    }

    await this.setState({
      loadingCalculos: false,
    });
  };

  handleClick = (e, { title }) => {
    const { partida } = this.state;
    console.log(partida, "partida");

    /*
        ponemos los ids dentro del arreglo para ver si son del producto donde se debe mostrar o no
        */
    if ([1, 4].indexOf(this.state.partida.producto_id) !== -1) {
      if (title == "Final") {
        console.log("calculado partida de nuevo");
        if (!partida.producto_id || !partida.material_id || !partida.color_id)
          return;
        this.calcPartida();
      }
    }
    if (
      [2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15].indexOf(
        this.state.partida.producto_id
      ) !== -1
    ) {
      if (title == "Final") {
        if (!partida.producto_id) return;
        this.calcPartida();
      }
    }
    this.setState({ active: title });
  };

  updateParteMancheta = async (value) => {
    // primero actualizamos el valor de la parte
    //this.props.cleanPartidaProps();

    await this.setState({
      parte_id: value,
      partida: {
        ...this.state.partida,
        parte_id: value,
      },
    });

    // nos traemos los tipos de manchetas
    let tipo_manchetas_pre = await Feathers.service("tipo_mancheta").find({
      query: {
        $limit: "-1",
      },
    });

    // las formateamos de la menera adecuada
    tipo_manchetas_pre = tipo_manchetas_pre.map((v) => {
      return {
        key: v.id,
        value: v.id,
        text: v.nombre,
      };
    });

    let manchetas = JSON.parse(JSON.stringify(this.state.manchetas));

    // para traer los tipos de manchetas que serán permitidos

    manchetas = manchetas.filter((v) => {
      if (v.parte == value) return v;
    });

    let tipos_de_manchetas = manchetas.map((v) => v.tipo_mancheta_id);

    let tipo_manchetas = tipo_manchetas_pre.filter((v) => {
      if (tipos_de_manchetas.indexOf(v.value) !== -1) return v;
    });

    this.setState({
      tipo_manchetas,
    });
  };

  updateTipoMancheta = async (value) => {
    //this.props.cleanPartidaProps();
    await this.setState({
      partida: {
        ...this.state.partida,
        tipo_mancheta_id: value,
      },
      tipo_mancheta_id: value,
    });

    let parte_id = this.state.parte_id;
    let tipo_mancheta_id = this.state.tipo_mancheta_id;

    let manchetas = JSON.parse(JSON.stringify(this.state.manchetas));

    manchetas = manchetas.filter((v) => {
      if (v.parte === parte_id && v.tipo_mancheta_id === tipo_mancheta_id)
        return v;
    });

    let formas_aceptadas = manchetas.map((v) => v.forma_mancheta_id);

    let formas = await Feathers.service("forma_mancheta").find({
      query: {
        $limit: "-1",
      },
    });

    formas = formas.filter((v) => {
      if (formas_aceptadas.indexOf(v.id) !== -1) return v;
    });

    let forma_mancheta = formas.map((v) => {
      return {
        key: v.id,
        text: v.nombre,
        value: v.id,
      };
    });

    this.setState({
      forma_mancheta,
    });
  };

  selectMancheta = async () => {
    let parte_id = this.state.parte_id;
    let tipo_mancheta_id = this.state.tipo_mancheta_id;
    let forma_id = this.state.forma_id;

    let manchetas = JSON.parse(JSON.stringify(this.state.manchetas));

    manchetas = manchetas.filter((v) => {
      if (
        v.parte === parte_id &&
        v.tipo_mancheta_id === tipo_mancheta_id &&
        v.forma_mancheta_id === forma_id
      )
        return v;
    });

    let findDureza = await Feathers.service("dureza").find({
      query: {
        $limit: "-1",
        valor: manchetas[0].dureza,
      },
    });

    let material_id = 0;

    let formulas = this.state.formulasMancheta || [];

    // let formulas_manchetas = formulas.filter(v => {
    //   if (v.value.id === 84 || v.value.id === 85) return v;
    // });
    let formulas_manchetas = formulas.filter((v) => {
      if (v.value.id === 64 || v.value.id === 65) return v;
    });

    let formula_de_la_mancheta = [];

    if (manchetas[0].dureza === 60) {
      formula_de_la_mancheta = JSON.parse(
        JSON.stringify(formulas_manchetas)
      ).filter((v) => {
        if (v.value.id == 64) return v;
      });

      material_id = 7;
    }

    if (manchetas[0].dureza === 70) {
      formula_de_la_mancheta = JSON.parse(
        JSON.stringify(formulas_manchetas)
      ).filter((v) => {
        if (v.value.id == 65) return v;
      });

      material_id = 7;
    }

    this.setState({
      selected_Mancheta: manchetas[0],
      partida: {
        ...this.state.partida,
        mancheta_id: manchetas[0].id,
        dureza_id: findDureza[0].id,
        material_id,
        formula:
          formula_de_la_mancheta.length > 0
            ? formula_de_la_mancheta[0].value
            : null, // ! para que se vea en la tabla
        color_id: 6,
      },
      selectedFormula:
        formula_de_la_mancheta.length > 0
          ? formula_de_la_mancheta[0].value
          : null,
    });
  };

  updateFormaMancheta = async (value) => {
    //this.props.cleanPartidaProps();
    await this.setState({
      forma_id: value,
      partida: {
        ...this.state.partida,
        forma_mancheta_id: value,
      },
    });

    this.selectMancheta();
  };

  fetchFormulas(product) {
    Feathers.service("formula_productos")
      .find({
        query: {
          //$limit: "-1",
          //activo: 1,
          producto_id: product,
        },
      })
      .then((res) => {
        const idsFormulas = res.data.map((e) => e.formula_id);
        Feathers.service("formula")
          .find({
            query: {
              id: {
                $in: idsFormulas,
              },
            },
          })
          .then((res) => {
            this.setState({
              formulas: res.data.map((formula) => {
                return {
                  key: formula.id,
                  text: formula.nombre,
                  value: formula,
                };
              }),
            });
          });
      });
  }

  componentDidMount() {
    console.log("pros:", this.props);

    this.fetchDrawings();
    this.fetchBevels();
    this.fetchManchetas();
    this.fetchConfiguraciones();

    if (this.props.preloadedPartida) {
      this.fetchFormulas(this.props.preloadedPartida.producto_id);
    }
    this.checkIfThereIsaPartidaPreloaded();
  }

  renderStep() {
    switch (this.state.active) {
      case "Producto":
        return (
          <Form>
            <Form.Group widths="equal">
              <Form.Dropdown
                required
                label="Producto"
                selection
                options={
                  this.props.partidas.every(
                    (partida) => partida.producto_id !== 5
                  )
                    ? this.state.productos
                    : this.state.productos.filter((producto) => {
                        return producto.value === 5;
                      })
                }
                placeholder="Producto"
                value={this.state.partida.producto_id}
                onChange={(e, { name, value }) => {
                  this.setState(
                    {
                      selectedFormula: {},
                      formulaColor: "",
                      partida: {
                        ...this.state.partida,
                        material_id: null,
                        color_id: null,
                        dureza_id: null,
                        formula: null, // ! hay que tener cuidao con esto, porque no debe de enviarse al crear la partida
                      },
                    },
                    () => {
                      this.updatePartida("producto_id", value);
                    }
                  );
                }}
              />

              {[1, 3, 4].indexOf(this.state.partida.producto_id) !== -1 ? (
                <Form.Dropdown
                  required
                  label="Fórmula"
                  selection
                  options={this.state.formulas}
                  placeholder="Formula"
                  value={this.state.partida.formula}
                  onChange={(e, { name, value }) => {
                    //this.props.cleanPartidaProps && this.props.cleanPartidaProps();
                    this.setState({
                      ...this.state,
                      partida: {
                        ...this.state.partida,
                        dureza_id: value.dureza_id,
                        material_id: value.material_id,
                        color_id: value.color_id,
                        formula: value,
                      },
                      selectedFormula: value,
                      formulaColor: value.color.nombre,
                    });
                  }}
                />
              ) : (
                <span></span>
              )}

              {[1, 3, 4].indexOf(this.state.partida.producto_id) !== -1 ? (
                <Form.Field>
                  <label>Color</label>
                  <Form.Input value={this.state.formulaColor} readOnly />
                </Form.Field>
              ) : (
                <span></span>
              )}

              {[5].indexOf(this.state.partida.producto_id) !== -1 ? (
                <Form.Dropdown
                  required
                  label="Parte Mancheta"
                  search
                  selection
                  options={this.state.parte_mancheta}
                  placeholder="Parte Mancheta"
                  value={this.state.partida.parte_id}
                  onChange={(e, { name, value }) =>
                    this.updateParteMancheta(value)
                  }
                />
              ) : (
                <span></span>
              )}

              {[5].indexOf(this.state.partida.producto_id) !== -1 ? (
                <Form.Dropdown
                  required
                  label="Tipo Mancheta"
                  selection
                  options={this.state.tipo_manchetas}
                  placeholder="Tipo Mancheta"
                  value={this.state.partida.tipo_mancheta_id}
                  onChange={(e, { name, value }) =>
                    this.updateTipoMancheta(value)
                  }
                />
              ) : (
                <span></span>
              )}

              {[5].indexOf(this.state.partida.producto_id) !== -1 ? (
                <Form.Dropdown
                  required
                  label="Forma Mancheta"
                  selection
                  options={this.state.forma_mancheta}
                  placeholder="Forma Mancheta"
                  value={this.state.partida.forma_mancheta_id}
                  onChange={(e, { name, value }) =>
                    this.updateFormaMancheta(value)
                  }
                />
              ) : (
                <span></span>
              )}
            </Form.Group>
          </Form>
        );
      case "Caracteristicas":
        return (
          <Form id="stepperForm2">
            <Grid>
              <Grid.Row columns={3}>
                {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16].indexOf(
                  this.state.partida.producto_id
                ) !== -1 ? (
                  <Grid.Column>
                    <Form.Input
                      required
                      label="Cantidad"
                      placeholder="Cantidad"
                      type="number"
                      name="cantidad"
                      value={this.state.partida.cantidad}
                      onChange={(e, { name, value }) =>
                        this.updatePartida(name, value)
                      }
                    />
                  </Grid.Column>
                ) : ( 
                  <span></span>
                )} */}
                <Grid.Column>
                  <Form.Input
                    required
                    label="Cantidad"
                    placeholder="Cantidad"
                    type="number"
                    name="cantidad"
                    value={this.state.partida.cantidad}
                    onChange={(e, { name, value }) =>
                      this.updatePartida(name, value)
                    }
                  />
                </Grid.Column>

                {[5].indexOf(this.state.partida.producto_id) !== -1 ? (
                  <Grid.Column>
                    <Form.Dropdown
                      required
                      label="Color"
                      selection
                      options={this.state.colores}
                      placeholder="Color"
                      value={this.state.partida.color_id}
                      onChange={(e, { name, value }) => {
                        //this.props.cleanPartidaProps && this.props.cleanPartidaProps();
                        this.setState({
                          partida: {
                            ...this.state.partida,
                            color_id: value,
                          },
                        });
                      }}
                    />
                  </Grid.Column>
                ) : (
                  <span></span>
                )}
                {[1, 4, 5].indexOf(this.state.partida.producto_id) !== -1 ? (
                  <Grid.Column>
                    <Form.Input
                      label="Fórmula"
                      placeholder="Fórmula"
                      type="text"
                      value={this.state.selectedFormula.nombre}
                    />
                  </Grid.Column>
                ) : (
                  <span></span>
                )}
                {[3].indexOf(this.state.partida.producto_id) !== -1 ? (
                  <Grid.Column>
                    <Form.Input
                      label="Kilos por pieza"
                      placeholder="Kilos"
                      type="number"
                      value={this.state.extras.kilos_por_pieza}
                      onChange={(e, d) => {
                        //this.props.cleanPartidaProps && this.props.cleanPartidaProps();
                        this.setState(
                          {
                            extras: {
                              ...this.state.extras,
                              kilos_por_pieza: d.value,
                            },
                          } /*, () => this.calcPartida()*/
                        );
                      }}
                    />
                  </Grid.Column>
                ) : (
                  <span></span>
                )}
                {[1, 4, 2].indexOf(this.state.partida.producto_id) !== -1 ? (
                  <Grid.Column>
                    <Form.Field>
                      <label>Dibujos</label>
                      <Search
                        icon=""
                        loading={this.state.dibujoLoading}
                        value={this.state.dibujo}
                        results={this.state.dibujoResults}
                        onResultSelect={this.handleDibujoResultSelect}
                        onSearchChange={this.handleDibujoSearchChange}
                        resultRenderer={renderSearchDibujoResults}
                        placeholder="Busca por nombre del dibujo"
                        className="full-width"
                      />
                    </Form.Field>
                    {/* <Form.Dropdown
                      required
                      label="Dibujo"
                      selection
                      options={this.state.dibujos}
                      placeholder="Dibujo"
                      value={this.state.partida.dibujo_id}
                      onChange={(e, { value }) => {
                        this.updatePartida("dibujo_id", value);
                      }}
                    /> */}
                  </Grid.Column>
                ) : (
                  <span></span>
                )}
                {[1, 4].indexOf(this.state.partida.producto_id) !== -1 ? (
                  <Grid.Column>
                    <Form.Dropdown
                      required
                      label="Chaflan"
                      selection
                      fluid
                      options={this.state.chaflanes}
                      placeholder="Chaflan"
                      value={this.state.partida.chaflan_id}
                      onChange={(e, { value }) =>
                        this.updatePartida("chaflan_id", value)
                      }
                    />
                  </Grid.Column>
                ) : (
                  <span></span>
                )}
              </Grid.Row>

              {[1, 4, 2].indexOf(this.state.partida.producto_id) !== -1 ? (
                <Grid.Row columns={4}>
                  <Grid.Column>
                    <Form.Input
                      required
                      label={"Diametro Nucleo"} // + " Factor: " + this.state.configuraciones.factorDN
                      placeholder="Diametro Nucleo"
                      type="number"
                      name="dn"
                      value={this.state.partida.dn}
                      onChange={(e, { name, value }) =>
                        this.updatePartida(name, value)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      required
                      label={"Diametro Externo"} //  + " Factor: " + this.state.configuraciones.factorDE
                      placeholder="Diametro Externo"
                      type="number"
                      name="de"
                      value={this.state.partida.de}
                      onChange={(e, { name, value }) =>
                        this.updatePartida(name, value)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      required
                      label={"Longitud Cara"} //  + " Factor: " + this.state.configuraciones.factorLC
                      placeholder="Longitud Cara"
                      type="number"
                      name="lc"
                      value={this.state.partida.lc}
                      onChange={(e, { name, value }) =>
                        this.updatePartida(name, value)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label={"Longitud Total"} // + " Factor: " + this.state.configuraciones.factorLT
                      placeholder="Longitud Total"
                      type="number"
                      name="lt"
                      value={this.state.partida.lt}
                      onChange={(e, { name, value }) =>
                        this.updatePartida(name, value)
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              ) : (
                <span></span>
              )}
              {[5].indexOf(this.state.partida.producto_id) !== -1 ? (
                <Grid.Row columns={4}>
                  <Grid.Column>
                    <Form.Input
                      label="Perímetro"
                      placeholder="Perímetro"
                      type="number"
                      name="perimetro"
                      value={this.state.selected_Mancheta.perimetro_espec}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label="Espesor"
                      placeholder="Espesor"
                      type="number"
                      name="espesor"
                      value={this.state.selected_Mancheta.espesor_espec}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label="Altura"
                      placeholder="Altura"
                      type="number"
                      name="altura"
                      value={this.state.selected_Mancheta.altura_espec}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label="Dureza"
                      placeholder="Dureza"
                      type="number"
                      name="dureza"
                      value={this.state.selected_Mancheta.dureza}
                    />
                  </Grid.Column>
                </Grid.Row>
              ) : (
                <span></span>
              )}
            </Grid>
          </Form>
        );
      case "Final":
        return (
          <Form loading={this.state.loadingCalculos}>
            <Grid columns={4} divided>
              <Grid.Row>
                <Grid.Column>
                  <Popup
                    open={this.state.partida.observaciones.length > 100}
                    content="Las observaciones deben de tener menos de 100 carácteres."
                    trigger={
                      <Form.Input
                        label="Observaciones"
                        placeholder="Observaciones"
                        type="text"
                        name="observaciones"
                        value={this.state.partida.observaciones}
                        onChange={(e, { name, value }) =>
                          this.updatePartida(name, value)
                        }
                      />
                    }
                  ></Popup>
                </Grid.Column>
                {[1, 3, 4].indexOf(this.state.partida.producto_id) !== -1 ? (
                  <Grid.Column>
                    <Form.Field required>
                      <Form.Input
                        label="Peso Total"
                        type="text"
                        name="peso-total"
                        value={`${this.state.partida.kilos} kg`}
                        onChange={(e, d) => {
                          //this.props.cleanPartidaProps && this.props.cleanPartidaProps();
                          if (
                            [3].indexOf(this.state.partida.producto_id) !== -1
                          ) {
                            this.setState({
                              partida: {
                                ...this.state.partida,
                                kilos: d.value,
                              },
                            });
                          }
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                ) : null}

                {/* {[1].indexOf(this.state.partida.producto_id) !== -1 ? (
                  <Grid.Column>
                    <Form.Field required>
                      <label>Costo Unitario</label>
                      <NumberFormat
                        allowEmptyFormatting={true}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        prefix={"$"}
                        value={this.state.partida.unitario}
                        decimalScale={2}
                        onValueChange={async values => {
                          this.props.cleanPartidaProps();
                          let new_total =
                            +this.state.partida.cantidad * +values.floatValue;
                          this.setState({
                            total_unitario: values.floatValue,
                            partida: {
                              ...this.state.partida,
                              precio: new_total,
                              unitario: values.floatValue
                            }
                          });
                        }}
                        // onChange={e => this.onChangeCostoUnitario(e)}
                        // displayType="text"
                      />
                    </Form.Field>
                  </Grid.Column>
                ) : null} */}

                {/* {[5].indexOf(this.state.partida.producto_id) !== -1 ? (
                  <Grid.Column>
                    <Form.Field required>
                      <label>Costo Unitario</label>
                      <NumberFormat
                        allowEmptyFormatting={true}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        prefix={"$"}
                        value={this.state.partida.unitario}
                        decimalScale={2}
                        onValueChange={async values => {
                          this.props.cleanPartidaProps();
                          let new_total =
                            +this.state.partida.cantidad * +values.floatValue;
                          this.setState({
                            total_unitario: values.floatValue,
                            partida: {
                              ...this.state.partida,
                              precio: new_total,
                              unitario: values.floatValue
                            }
                          });
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                ) : null} */}
                {/* consto unitario */}
                <Grid.Column>
                  <Form.Field required>
                    <label>Costo Unitario</label>
                    <NumberFormat
                      allowEmptyFormatting={true}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={this.state.partida.unitario}
                      decimalScale={2}
                      onValueChange={async (values) => {
                        //this.props.cleanPartidaProps && this.props.cleanPartidaProps();
                        let new_total =
                          +this.state.partida.cantidad * +values.floatValue;
                        this.setState({
                          total_unitario: values.floatValue,
                          partida: {
                            ...this.state.partida,
                            precio: new_total,
                            unitario: values.floatValue,
                          },
                        });
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                {/* Precio total */}
                <Grid.Column>
                  <Form.Field required>
                    <label>Precio Total</label>
                    <NumberFormat
                      allowEmptyFormatting={true}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={this.state.partida.precio}
                      decimalScale={2}
                      disabled={true}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column floated="right" width={8}>
                  <Button.Group fluid>
                    <Button onClick={this.props.closeModal}>Cancelar</Button>
                    <Button.Or text="o" />
                    <Button
                      positive
                      disabled={this.state.partida.precio < 1 || this.state.partida.observaciones.length > 100}
                      onClick={() => {
                        // TODO: solo cambiar el id si no es una actualizacion
                        if (this.props.preloadedPartida === null) {
                          this.state.partida.id =
                            this.props.partidas.length + 1;
                        }

                        if (this.props.preloadedPartida !== null) {
                          this.props.changeFormula(this.state.selectedFormula);
                        }

                        this.props.addProducto(
                          this.state.partida,
                          this.state.selectedFormula,
                          this.state.dibujo
                        );
                      }}
                    >
                      Añadir Producto
                    </Button>
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        );
    }
  }

  render() {
    return (
      <Segment loading={this.state.loadingPreloadedData}>
        {/* TITLES */}
        <Step.Group attached="top">
          <Step
            active={this.state.active == "Producto"}
            icon="lab"
            link
            onClick={this.handleClick}
            title="Producto"
            description="Escoja un producto"
          />
          <Step
            active={this.state.active == "Caracteristicas"}
            icon="options"
            link
            onClick={this.handleClick}
            title="Caracteristicas"
            description="Especifique las caracteristicas"
          />
          <Step
            active={this.state.active == "Final"}
            icon="eye"
            link
            onClick={this.handleClick}
            title="Final"
            description="Revise el producto"
          />
        </Step.Group>
        {/* CONTENT */}
        <Segment attached>{this.renderStep()}</Segment>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    configuraciones: state.services.configuraciones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDolarPrice: () =>
      dispatch(
        services.configuraciones.find({
          query: {
            nombre: "cambio_dolar",
            $limit: "-1",
          },
        })
      ),
    getValorFormula1: () =>
      dispatch(
        services.configuraciones.find({
          query: {
            nombre: "valor_formula_1",
            $limit: "-1",
          },
        })
      ),
    getValorFormula2: () =>
      dispatch(
        services.configuraciones.find({
          query: {
            nombre: "valor_formula_2",
            $limit: "-1",
          },
        })
      ),
    //
    // getFactorDN: () =>
    //   dispatch(
    //     services.configuraciones.find({
    //       query: { nombre: "factor_dn", $limit: "-1" },
    //     })
    //   ),
    // getFactorDE: () =>
    //   dispatch(
    //     services.configuraciones.find({
    //       query: { nombre: "factor_de", $limit: "-1" },
    //     })
    //   ),
    // getFactorLC: () =>
    //   dispatch(
    //     services.configuraciones.find({
    //       query: { nombre: "factor_lc", $limit: "-1" },
    //     })
    //   ),
    // getFactorLT: () =>
    //   dispatch(
    //     services.configuraciones.find({
    //       query: { nombre: "factor_lt", $limit: "-1" },
    //     })
    //   ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelOrdersStepper);
