import React, {Component} from 'react';
import {Modal, Grid, Icon, Button} from 'semantic-ui-react'

export default class ContactsProviderInfo extends Component {

    render() {
        return (
            <Modal open={this.props.isOpened} size={'small'}>
                <Modal.Header style={{backgroundColor: "#f3f3f3"}}>{this.props.data.nombre}
                <Button circular size='mini' basic icon='close' floated='right' onClick={this.props.onClose}></Button>
                </Modal.Header>
                <Modal.Content>
                    <Grid columns={2} divided textAlign='center' style={{fontSize: "16px"}}>
                        <Grid.Row>
                            <Grid.Column >
                                <Grid.Row>
                                    <Icon name="mail"></Icon> <span style={{fontWeight: 'bold'}}>Email</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.data.email}
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid.Row>
                                    <Icon name="phone"></Icon> <span style={{fontWeight: 'bold'}}>Teléfono</span>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.props.data.telefono || 'N/D'}
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </Modal.Content>
            </Modal>
        )
    }
}