import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Modal,
  ModalActions,
  Header,
} from "semantic-ui-react";
import { notify } from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";
import swal from "sweetalert2";

class DeleteTaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <Modal open={this.props.open} size={"small"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3", fontSize: "24px" }}>
          ¿Desea eliminar esta tarea de su calendario?
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
            disable={this.state.loading}
          ></Button>
        </Modal.Header>
        <ModalActions>
          <Button
            content="NO, CANCELAR"
            onClick={this.props.onClose}
            negative
            size="small"
            disable={this.state.loading}
          ></Button>
          <Button
            content="ELIMINAR SOLO ESTA TAREA"
            size="small"
            onClick={() => {
              this.setState(
                {
                  loading: true,
                },
                () => {
                  Feathers.service("calendario_tarea")
                    .patch(this.props.calendarTaskId, {
                      activo: 0,
                      status_tarea_id: 4,
                    })
                    .then((res) => {
                      this.setState(
                        {
                          loading: false,
                        },
                        () => this.props.onCloseComplete()
                      );
                    })
                    .catch((err) => {
                      swal({
                        title: "Se produjo un error. Intente de nuevo",
                        type: "error",
                        confirmButtonText: "ok",
                        reverseButtons: true,
                      });
                    });
                }
              );
            }}
            disable={false}
            positive
          />
          {this.props.tienePeriodicidad && (
            <Button
              content="ELIMINAR TODAS"
              size="small"
              onClick={() => {
                this.setState(
                  {
                    loading: true,
                  },
                  () => {
                    const params = {
                      query: {
                        clave: this.props.calendarTaskClave,
                        status_tarea_id: 1,
                      },
                    };
                    Feathers.service("calendario_tarea")
                      .patch(
                        null,
                        {
                          activo: 0,
                          status_tarea_id: 4,
                        },
                        params
                      )
                      .then((res) => {
                        this.setState(
                          {
                            loading: false,
                          },
                          () => this.props.onCloseComplete()
                        );
                      })
                      .catch((err) => {
                        swal({
                          title: "Se produjo un error. Intente de nuevo",
                          type: "error",
                          confirmButtonText: "ok",
                          reverseButtons: true,
                        });
                      });
                  }
                );
              }}
              disable={this.state.loading}
              positive
            />
          )}
        </ModalActions>
      </Modal>
    );
  }
}

export default DeleteTaskModal;
