import React, { Component } from "react";
import { Label, Icon } from "semantic-ui-react";

export default class TaskStatus extends Component {
  render() {
    if (this.props.status === 1) {
      return (
        <div>
          <Label>
            <Icon name="clock" /> Pendiente
          </Label>
        </div>
      );
    } else if (this.props.status === 2)
      return (
        <div>
          <Label color="blue">
            <Icon name="history" /> En ejecución
          </Label>
        </div>
      );
    else if (this.props.status === 3)
      return (
        <div>
          <Label color="green">
            <Icon name="check" /> Finalizada
          </Label>
        </div>
      );
  }
}
