import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Grid, Icon, Form, Search, Label } from "semantic-ui-react";
import "react-table/react-table.css";
import Feathers from "../../redux/FeathersRedux";
import ContactsProviderInfo from "./ContactsProviderInfo";
import ProvidersEdit from "../Providers/ProvidersEdit";
import { push } from "react-router-redux";
import { connect } from "react-redux";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class ContactsProviderList extends Component {
  constructor() {
    super();
    this.state = {
      providers: [],
      total: 0,
      page: 0,
      loading: false,

      currentRow: {},
      // results of the search querys
      results: null,
      // result objects

      provider: "",

      modalOpened: false,
      pageSize: 100,
      filterOptions: {
        id: null,
      },
      $sortDirection: 1,
      // loading of the search bars
      loadingSearch: false,
      columna: "",
      sortDirection: false,
    };
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre del Proveedor":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Teléfono":
        array.data.sort((a, b) => {
          const nameA = a.telefono.toUpperCase(); // ignore upper and lowercase
          const nameB = b.telefono.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Email":
        array.data.sort((a, b) => {
          const nameA = a.email ? a.email.toUpperCase() : ""; // ignore upper and lowercase
          const nameB = b.email ? b.email.toUpperCase() : ""; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData = (state = {}) => {
    console.log(this.props);

    let pageSize = state.pageSize || 100;

    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
      $sort: {
        id: -1,
      },
    };

    if (this.state.filterOptions.id) {
      query["id"] = this.state.filterOptions.id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("proveedor")
        .find({
          query,
        })
        .then((res) => {
          this.handleSort(res);
          this.setState({
            providers: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        });
    });
  };

  handleResultSelect = async (e, { result }) => {
    this.setState({ client: result.nombre });
    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });
    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingSearch: true, client: value });

    setTimeout(() => {
      if (this.state.client.length < 1) return this.resetComponent();

      Feathers.service("proveedor")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingSearch: false,
            results: res.data,
          });
        });
    }, 500);
  };

  handleCleanButton = async () => {
    this.resetComponent();

    await this.setState({
      filterOptions: {
        id: null,
      },
    });

    this.fetchData(this.state);
  };

  resetComponent = () =>
    this.setState({ loadingSearch: false, results: [], client: "" });

  openModal(row) {
    this.setState({
      modalOpened: true,
      currentRow: row.original,
    });
  }

  closeModal() {
    this.setState({
      modalOpened: false,
    });
  }

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre del Proveedor</label>
                  <Search
                    icon=""
                    loading={this.state.loadingSearch}
                    value={this.state.provider}
                    results={this.state.results}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    resultRenderer={renderSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>

                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                {/* <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        exportToXls(this.state.providers, "admin_providers");
                      }}
                    />
                  </Form.Field> */}
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre del Proveedor", accessor: "nombre" },
            { Header: "Teléfono", accessor: "telefono" },
            { Header: "Email", accessor: "email" },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      basic
                      size={"tiny"}
                      compact
                      color="blue"
                      icon="pencil"
                      onClick={() => {
                        const { dispatch } = this.props;
                        const userid = row.original.id;
                        dispatch(push("/providers/" + userid));
                      }}
                    />
                    <Button
                      basic
                      size={"tiny"}
                      compact
                      color="grey"
                      icon="eye"
                      onClick={() => this.openModal(row)}
                    />
                  </div>
                );
              },
              maxWidth: 100,
            },
          ]}
          manual
          data={this.state.providers}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />
        <ContactsProviderInfo
          isOpened={this.state.modalOpened}
          data={this.state.currentRow}
          onClose={this.closeModal}
        />
      </div>
    );
  }
}

export default connect()(ContactsProviderList);
