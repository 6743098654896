import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Button,
  Grid,
  Icon,
  Progress,
  Header,
  Dropdown,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import "react-table/react-table.css";
import Feathers from "../redux/FeathersRedux";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import "moment/locale/es";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { exportToXls } from "../utils/Utils";

const renderSearchFormulaResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderSearchMateriaPrimaResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderSearchMaterialResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);
/*
En ese componente, se podrá filtrar por lo siguiente
 * Fecha
 * Almacen Destino
 * Almacen Origen
 * Tipo de Movimiento
*/

export default class StockMovementsReportContainer extends Component {
  state = {
    loading: false,
    loadingFormulaSearch: false,
    loadingMateriaPrimaSearch: false,
    loadingMaterialSearch: false,

    movements: [],
    filterDates: {
      initDay: "",
      lastDay: "",
    },
    filterOptions: {
      almacen_origen_id: "",
      almacen_destino_id: "",
      formula_id: "",
      materia_prima_id: "",
      material_id: "",
    },
    options: {
      almacenes: [],
      tipo_movimiento: [],
    },
    formula: "",
    materia_prima: "",
    material: "",
    resultsMaterial: [],
    resultsFormula: [],
    resultsMateriaPrima: [],
    columna: "Fecha y Hora",
    sortDirection: true,
    shouldExport: false,
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Almacen Origen":
        array.data.sort((a, b) => {
          const nameA = a.almacen_origen.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.almacen_origen.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Almacen Destino":
        array.data.sort((a, b) => {
          const nameA = a.almacen_destino.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.almacen_destino.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Cantidad":
        array.data.sort((a, b) => {
          return parseInt(a.cantidad) - parseInt(b.cantidad);
        });
        break;
      case "Tipo de Movimiento":
        array.data.sort((a, b) => {
          const nameA = a.tipo_movimiento.tipo_movimiento.toUpperCase(); // ignore upper and lowercase
          const nameB = b.tipo_movimiento.tipo_movimiento.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha y Hora":
        array.data.sort((a, b) => {
          const nameA = a.fecha_hora.toUpperCase(); // ignore upper and lowercase
          const nameB = b.fecha_hora.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Material/Materia Prima/Compuesto":
        array.data.sort((a, b) => {
          const nameA = a.materia_prima
            ? a.materia_prima.nombre.trimLeft().toUpperCase()
            : ""; // ignore upper and lowercase
          const nameB = b.materia_prima
            ? b.materia_prima.nombre.trimLeft().toUpperCase()
            : ""; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  handleNombreResultSelect = async (e, { result }) => {
    this.setState({ nombre: result.nombre });

    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };
  s;
  componentDidMount() {
    /*
        TODO: traer toda la información para los filtros
        */

    this.fetch_almacenes();
    this.fetch_tipo_movimiento();
  }

  handleSearchFormulaChange = (e, { value }) => {
    this.setState({ loadingFormulaSearch: true, formula: value });

    setTimeout(() => {
      if (this.state.formula.length < 1) return this.resetComponent();

      Feathers.service("formula")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingFormulaSearch: false,
            resultsFormula: res.data,
          });
        });
    }, 500);
  };

  handleSearchMateriaPrimaChange = (e, { value }) => {
    this.setState({ loadingMateriaPrimaSearch: true, materia_prima: value });

    setTimeout(() => {
      if (this.state.materia_prima.length < 1) return this.resetComponent();

      Feathers.service("materia_prima")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingMateriaPrimaSearch: false,
            resultsMateriaPrima: res.data,
          });
        });
    }, 500);
  };

  handleSearchMaterialChange = (e, { value }) => {
    this.setState({ loadingMaterialSearch: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingMaterialSearch: false,
            resultsMaterial: res.data,
          });
        });
    }, 500);
  };

  handleResultFormulaSelect = async (e, { result }) => {
    this.setState({ formula: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        formula_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleResultMateriaPrimaSelect = async (e, { result }) => {
    this.setState({ materia_prima: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        materia_prima_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleResultMaterialSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        material_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  resetComponent = (_) => {
    this.setState({
      formula: "",
      resultsFormula: [],
      loadingFormulaSearch: false,
      materia_prima: "",
      resultsMateriaPrima: [],
      loadingMateriaPrimaSearch: false,
      material: "",
      resultsMaterial: [],
      loadingMaterialSearch: false,
    });
  };

  fetch_almacenes = async (_) => {
    // vamos a treaer los almacenes y formatearlos para los select de los filtros.

    let almacenes = await Feathers.service("almacen").find({
      query: {
        $limit: "-1",
      },
    });

    almacenes = almacenes.map((v) => {
      return {
        text: v.nombre,
        value: v.id,
        key: v.id,
      };
    });

    this.setState({
      options: {
        ...this.state.options,
        almacenes,
      },
    });
  };

  fetch_tipo_movimiento = async (_) => {
    // vamos a traer los tipos de movimiento y formatearlos para los select de los filtros

    let tipo_movimiento = await Feathers.service("tipo_movimiento").find({
      query: {
        $limit: "-1",
      },
    });

    tipo_movimiento = tipo_movimiento.map((v) => {
      return {
        text: v.tipo_movimiento,
        value: v.id,
        key: v.id,
      };
    });

    this.setState({
      options: {
        ...this.state.options,
        tipo_movimiento,
      },
    });
  };

  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 100;
    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.almacen_origen_id !== "") {
      query["almacen_origen_id"] = this.state.filterOptions.almacen_origen_id;
    }

    if (this.state.filterOptions.almacen_destino_id !== "") {
      query["almacen_destino_id"] = this.state.filterOptions.almacen_destino_id;
    }

    if (this.state.filterOptions.formula_id !== "") {
      //console.log("entro aqui");
      //console.log(this.state.filterOptions);
      query["formula_id"] = this.state.filterOptions.formula_id;
    }

    if (this.state.filterOptions.materia_prima_id !== "") {
      query["clave_id"] = this.state.filterOptions.materia_prima_id;
      query["almacen_origen_id"] = 1;
    }

    if (this.state.filterOptions.material_id !== "") {
      query["clave_id"] = this.state.filterOptions.material_id;
      query["almacen_origen_id"] = 3;
    }

    if (this.state.filterDates.initDay !== "") {
      let fechainicio = moment(this.state.filterDates.initDay)
        .hour(0)
        .minute(2)
        .second(2);
      fechainicio = fechainicio.format("YYYY-MM-DD HH:mm:ss");
      query["fecha_hora"] = {
        $gte: fechainicio,
      };
    }

    if (this.state.filterDates.lastDay !== "") {
      let fechafin = moment(this.state.filterDates.lastDay)
        .hour(22)
        .minute(60)
        .second(60);
      fechafin = fechafin.format("YYYY-MM-DD HH:mm:ss");

      if (this.state.filterDates.initDay) {
        query["fecha_hora"] = {
          ...query.fecha_hora,
          $lte: fechafin,
        };
      } else {
        query["fecha_hora"] = {
          $lte: fechafin,
        };
      }
    }

    this.setState({ loading: true }, () => {
      Feathers.service("movimientos_inventario")
        .find({
          query,
        })
        .then((res) => {
          // console.log("Resultado: ");
          // console.log(res.data);
          // console.log(state.page);
          // console.log(state.pageSize);
          // console.log(res.total);
          this.handleSort(res);
          if (!this.state.shouldExport) {
            this.setState({
              movements: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 10,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "report_movements");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };

  _handleFilterButton = (_) => {
    this.fetchData(this.state);
  };

  _handleCleanButton = (_) => {
    this.resetComponent();
    this.setState(
      {
        filterDates: {
          ...this.state.filterDates,
          initDay: "",
          lastDay: "",
        },
        filterOptions: {
          ...this.state.filterOptions,
          almacen_origen_id: "",
          almacen_destino_id: "",
          formula_id: "",
          materia_prima_id: "",
          material_id: "",
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  renderFilterOptions = (_) => {
    return (
      <Form>
        <Form.Group>
          <Form.Field>
            <label>Fecha Inicio</label>
            <DayPickerInput
              value={this.state.filterDates.initDay}
              format="YYYY-MM-DD"
              formatDate={formatDate}
              parseDate={parseDate}
              onDayChange={(date) => {
                this.setState({
                  filterDates: {
                    ...this.state.filterDates,
                    initDay: date,
                  },
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Fecha Fin</label>
            <DayPickerInput
              value={this.state.filterDates.lastDay}
              format="YYYY-MM-DD"
              formatDate={formatDate}
              parseDate={parseDate}
              onDayChange={(date) => {
                this.setState({
                  filterDates: {
                    ...this.state.filterDates,
                    lastDay: date,
                  },
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Almacen de Origen</label>
            <Form.Dropdown
              selection
              options={this.state.options.almacenes}
              value={this.state.filterOptions.almacen_origen_id}
              onChange={(_, { value }) => {
                this.setState({
                  filterOptions: {
                    ...this.state.filterOptions,
                    almacen_origen_id: value,
                  },
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Almacen de Destino</label>
            <Form.Dropdown
              selection
              options={this.state.options.almacenes}
              value={this.state.filterOptions.almacen_destino_id}
              onChange={(_, { value }) => {
                this.setState({
                  filterOptions: {
                    ...this.state.filterOptions,
                    almacen_destino_id: value,
                  },
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Fórmula</label>
            <Search
              icon=""
              loading={this.state.loadingFormulaSearch}
              value={this.state.formula}
              results={this.state.resultsFormula}
              onResultSelect={this.handleResultFormulaSelect}
              onSearchChange={this.handleSearchFormulaChange}
              resultRenderer={renderSearchFormulaResults}
              placeholder="Fórmula"
              className="full-width"
            />
          </Form.Field>
          <Form.Field>
            <label>Materia prima</label>
            <Search
              icon=""
              loading={this.state.loadingMateriaPrimaSearch}
              value={this.state.materia_prima}
              results={this.state.resultsMateriaPrima}
              onResultSelect={this.handleResultMateriaPrimaSelect}
              onSearchChange={this.handleSearchMateriaPrimaChange}
              resultRenderer={renderSearchMateriaPrimaResults}
              placeholder="Materia prima"
              className="full-width"
            />
          </Form.Field>
          <Form.Field>
            <label>Material</label>
            <Search
              icon=""
              loading={this.state.loadingMaterialSearch}
              value={this.state.material}
              results={this.state.resultsMaterial}
              onResultSelect={this.handleResultMaterialSelect}
              onSearchChange={this.handleSearchMaterialChange}
              resultRenderer={renderSearchMaterialResults}
              placeholder="Material"
              className="full-width"
            />
          </Form.Field>
          <Form.Field>
            <label>Filtrar</label>
            <Button
              basic
              circular
              icon="filter"
              onClick={this._handleFilterButton}
            />
          </Form.Field>
          <Form.Field>
            <label>Limpiar</label>
            <Button
              basic
              color="red"
              circular
              icon="x"
              onClick={this._handleCleanButton}
            />
          </Form.Field>
          <Form.Field>
            <label>Exportar</label>
            <Button
              basic
              color="green"
              circular
              icon="file excel outline"
              onClick={() => exportToXls([], "report_movements")}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="move"
                content="Reporte de Movimiento de Inventario"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <this.renderFilterOptions />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ReactTable
                columns={[
                  {
                    Header: "Almacen Origen",
                    accessor: "almacen_origen.nombre",
                  },
                  {
                    Header: "Almacen Destino",
                    accessor: "almacen_destino.nombre",
                  },
                  { Header: "Cantidad", accessor: "cantidad" },
                  {
                    Header: "Tipo de Movimiento",
                    accessor: "tipo_movimiento.tipo_movimiento",
                  },
                  { Header: "Fecha y Hora", accessor: "fecha_hora" },
                  {
                    Header: "Material/Materia Prima/Compuesto",
                    Cell: (row) => {
                      // ! importante

                      /* 
                            El registro de la tabla clave_id puede significar tanto materia prima como material, y entre otras cosas.
                            la diferencia, o más bien, todo depende, de el inventario de origen; si viene del de inventario de materias prima
                            pues será una materia  prima
                            */

                      if (row.original.almacen_origen_id === 1) {
                        if (row.original.materia_prima !== null) {
                          return row.original.materia_prima.nombre;
                        }
                      }

                      if (row.original.almacen_origen_id === 2) {
                        if (row.original.material !== null) {
                          return row.original.material.nombre;
                        }
                      }

                      if (row.original.almacen_origen_id === 3) {
                        if (row.original.formula !== null)
                          return row.original.formula.nombre;
                      }

                      if (row.original.material)
                        return row.original.material.nombre;

                      return "---";
                    },
                  },
                ]}
                manual
                data={this.state.movements}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState({
                    columna: column,
                    sortDirection: direction,
                  });
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
