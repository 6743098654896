import React, { Component } from "react";
import Feathers from "../redux/FeathersRedux";
import { Grid, Header, Image, Button } from "semantic-ui-react";
import { push } from "react-router-redux";
import { connect } from "react-redux";

class CalendarTaskReportContainer extends Component {
  constructor() {
    super();
    this.state = {
      task_type: [],
      task_iso: [],
      loading: false,
    };
  }

  //Traer todos los tipos de tareas excluyendo la norma ISO 9000
  fetchTaskTypes = () => {
    Feathers.service("tipo_tarea")
      .find({
        query: {
          activo: true,
          nombre: {
            $ne: 'ISO 9000'
          }
        },
      })
      .then((res) => {
        this.setState({
          task_type: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Traer el tipo de tarea de norma ISO por aparte 
  fetchTaskISO = () => {
    Feathers.service("tipo_tarea").find({
      query: {
        nombre: 'ISO 9000',
        //activo: true
      }
    })
    .then(res => {
      this.setState({
        task_iso: res.data[0]
      })
    })
    .catch(err => {
      console.warn(err);
    })
  }

  handleTaskData = (id) => {
    this.props.dispatch(push(`/reports/calendartaskreport/${id}`));
  };

  //Pagina para subcarpetas de la norma ISO 9000
  handleISOCodes = () => {
    this.props.dispatch(push(`/reports/iso/codes`))
  }

  componentDidMount() {
    this.fetchTaskTypes();
    this.fetchTaskISO();
  }

  render() {
    //const { nombre, id } = this.state.task_iso
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header
                as="h2"
                icon="calendar check outline"
                content="Reporte de Tareas del Calendario"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            {this.state.task_type.map((item) => (
              <Grid.Column key={item.id}>
                <section  style={{ marginBottom: "3rem" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "1rem" }}>
                      <Image
                        src={require("../resources/images/carpeta.svg")}
                        size="tiny"
                      />
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <h4 style={{ marginBottom: "5px" }}>{item.nombre}</h4>
                      <Button
                        basic
                        size="tiny"
                        color="grey"
                        onClick={() => this.handleTaskData(item.id)}
                      >
                        Abrir carpeta
                      </Button>
                    </div>
                  </div>
                </section>
              </Grid.Column>
            ))}
            {this.state.task_iso.activo 
            ? 
            <Grid.Column>
                <section  style={{ marginBottom: "3rem" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "1rem" }}>
                      <Image
                        src={require("../resources/images/carpeta.svg")}
                        size="tiny"
                      />
                    </div>
                    <div style={{ marginTop: "5px" }}>
                    <h4 style={{ marginBottom: "5px" }}>{this.state.task_iso.nombre}</h4>
                      <Button
                        basic
                        size="tiny"
                        color="grey"
                        onClick={() => this.handleISOCodes()}
                      >
                        Abrir carpeta
                      </Button>
                    </div>
                  </div>
                </section>
            </Grid.Column>
            :
            <></>
            }
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(CalendarTaskReportContainer);
