import React, { Component } from "react";
import Feathers from "../../../redux/FeathersRedux";
import swal from "sweetalert2";
import moment from "moment";
import CheckTask from "../../../components/Tasks/Checks/CheckTask";
import { connect } from "react-redux";
import LoaderData from "../../LoaderData";

class CheckContainer extends Component {
  timeout = null;

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      task: undefined,
      loadingCheckButton: false,
    };

    this.initTask = this.initTask.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.restartTask = this.restartTask.bind(this);
    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.goBack = this.goBack.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
  }

  checkActiveTasks() {
    Feathers.service("revision")
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          this.setState({
            task: res.data[0],
            ready: true,
          });
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  updateTask() {
    Feathers.service("revision")
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  goBack() {
    this.props.history.goBack();
  }

  viewTask() {
    Feathers.service("revision")
      .get(this.props.match.params.id)
      .then((task) => {
        const userId = this.props.user.id;
        const taskUserId = task.usuario ? task.usuario.id : null;
        console.log(userId, taskUserId, "ids");
        if (taskUserId && taskUserId !== userId) {
          swal({
            title:
              "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
            type: "warning",
            confirmButtonText: "Aceptar",
          });
          this.goBack();
        } else {
          this.setState({ task });
        }
      });
  }

  async initTask(task) {
    return await swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (res.value) {
        const validate = await Feathers.service("revision").get(
          this.state.task.id
        );
        if (validate.hora_inicio) {
          swal({
            title:
              "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
            type: "warning",
            confirmButtonText: "Aceptar",
          });
          this.goBack();
        } else {
          Feathers.service("revision")
            .patch(task.id, {
              hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
              status_tarea_id: 1,
              usuario_id: this.props.user.id,
            })
            .then((res) => {
              Feathers.service("revision")
                .get(res.id)
                .then((task) => this.setState({ task }));
            });
        }

        return 0;
      } else {
        return 1;
      }
    });
  }

  // async internInitTask(task) {
  //   return await swal({
  //     title: "¿Iniciar tarea?",
  //     type: "warning",
  //     showCancelButton: true,
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Si",
  //     cancelButtonText: "No",
  //     reverseButtons: true,
  //   }).then(async (res) => {
  //     if (res.value) {
  //       const validate = await Feathers.service("desviste").get(
  //         this.state.task.id
  //       );
  //       if (validate.hora_inicio) {
  //         swal({
  //           title:
  //             "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
  //           type: "warning",
  //           confirmButtonText: "Aceptar",
  //         });
  //         this.goBack();
  //       } else {
  //         await Feathers.service("revision").patch(task.id, {
  //           hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
  //           status_tarea_id: 1,
  //           usuario_id: this.props.user.id,
  //         });
  //       }

  //       return 0;
  //     } else {
  //       return 1;
  //     }
  //   });
  // }

  finishTask = async (task = this.state.task) => {
    swal({
      title: "¿Iniciar y finalizar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (res.value) {
        const validate = await Feathers.service("revision").get(
          this.state.task.id
        );
        if (validate.hora_inicio) {
          swal({
            title: "Esta tarea fue finalizada, seleccione otra...",
            type: "warning",
            confirmButtonText: "Aceptar",
          });
          this.goBack();
        } else {
          Feathers.service("revision")
            .patch(task.id, {
              hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
              hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
              status_tarea_id: 2,
              usuario_id: this.props.user.id,
            })
            .then(async (res) => {
              // this.fetchData()
              //TODO: SI LA TAREA TIENE NOTIFICACIONE ACTIVAS, CREAR UNA NOTI AL FINALIZAR ESTA TAREA)
              const checkNotification = await Feathers.service(
                "notification_tasks"
              ).get(1);
              if (checkNotification.activo) {
                Feathers.service("notificacion").create({
                  mensaje: `Se ha finalizado una tarea de revisión #${task.id}`,
                  perfil_id: "1",
                  fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                  tipo: 4,
                });
              }
              this.goBack()
            })
            .catch((error) => {
              console.log(error, 'error al finalizar')
              swal({
                title: "Esta tarea ya finalizada",
                text: "La tarea ya se finalizó. Recargue la pagina.",
                type: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
              });
              this.goBack();
            });
        }
      }
    });
  };

  restartTask() {
    swal({
      title: "¿Reiniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("revision")
          .patch(this.state.task.id, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            hora_fin: null,
            status_tarea_id: 1,
          })
          .then((res) => {
            this.viewTask();
          });
      }
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("revision")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        Feathers.service("pedido").patch(this.state.task.pedido_id, {
          status_id: 6,
        });
        this.setState({ task: null });
        //notify.show('Incidencia registrada', 'success');
        swal({
          title: "Incidencia Registrada",
          type: "success",
          confirmButtonText: "OK",
        });
      });
  }

  deleteOrder = (unidad) => {
    swal({
      title: "¿Está usted seguro?",
      text: "La orden se va a eliminar.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
    }).then(async (result) => {
      if (result.value) {
        const pedido_id = unidad.pedido_id;
        const rodillo_id = unidad.rodillo_id;

        const partidas_del_pedido = await Feathers.service("partida").find({
          query: {
            $limit: "-1",
            pedido_id: pedido_id,
          },
        });

        const partidas_ids = partidas_del_pedido.map((v) => v.id);

        const unidades_de_la_partida = await Feathers.service("rodillo").find({
          query: {
            $limit: "-1",
            partida_id: {
              $in: partidas_ids,
            },
          },
        });

        const unidades_ids = unidades_de_la_partida.map((v) => v.id);

        await this.setState({
          loading: true,
        });

        // baja de construccion

        try {
          let tarea_contruccion = await Feathers.service("construccion").find({
            query: {
              rodillo_id: {
                $in: unidades_ids,
              },
            },
          });

          if (tarea_contruccion.data.length >= 1) {
            let baja_construccion = await Feathers.service(
              "construccion"
            ).patch(
              null,
              {
                status_tarea_id: 3,
                cancel: 1,
              },
              {
                query: {
                  rodillo_id: {
                    $in: unidades_ids,
                  },
                },
              }
            );

            // let baja_construccion =  await Feathers.service('construccion').patch( null, {
            //     status_tarea_id : 3
            // }, {
            //     rodillo_id
            // })
          }
        } catch (error) {
          console.log("error en construccion");
          console.log(error);
        }

        let tarea_control_calidad = await Feathers.service(
          "control_calidad"
        ).find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_control_calidad.data.length >= 1) {
          let baja_control_calidad = await Feathers.service(
            "control_calidad"
          ).patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_corte = await Feathers.service("corte").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_corte.data.length >= 1) {
          let baja_corte = await Feathers.service("corte").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_desbaste = await Feathers.service("desbaste").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_desbaste.data.length >= 1) {
          let baja_desbaste = await Feathers.service("desbaste").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_desmonte = await Feathers.service("desmonte").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_desmonte.data.length >= 1) {
          let baja_desmonte = await Feathers.service("desmonte").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_desviste = await Feathers.service("desviste").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_desviste.data.length >= 1) {
          let baja_desviste = await Feathers.service("desviste").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        // vamos a handlear lo de la tabla pedido

        let baja_pedido = await Feathers.service("pedido").patch(pedido_id, {
          status_id: 6,
        });

        // seguiremos con la baja de rectificado

        let tarea_rectificado = await Feathers.service("rectificado").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_rectificado.data.length >= 1) {
          let baja_rectificado = await Feathers.service("rectificado").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        let tarea_revision = await Feathers.service("revision").find({
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        if (tarea_revision.data.length >= 1) {
          let baja_revision = await Feathers.service("revision").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                rodillo_id: {
                  $in: unidades_ids,
                },
              },
            }
          );
        }

        // vamos a handlear lo que pasa en la tabla rodillo

        let baja_rodillo = await Feathers.service("rodillo").patch(
          null,
          {
            status_tarea_id: 3,
          },
          {
            query: {
              id: {
                $in: unidades_ids,
              },
            },
          }
        );

        // vamos a handlear lo de los molinos
        // primero tenemos que traer las tareas de molino cuya partida sea parte del pedido y que no estén iniciadas

        const tarea_molino = await Feathers.service("molino").find({
          query: {
            partida_id: {
              $in: partidas_ids,
            },
            status_tarea_id: null,
            $limit: "-1",
          },
        });

        const molino_ids = tarea_molino.map((v) => v.id);

        if (molino_ids.length >= 1) {
          const baja_molino = await Feathers.service("molino").patch(
            null,
            {
              status_tarea_id: 3,
            },
            {
              query: {
                id: {
                  $in: molino_ids,
                },
              },
            }
          );
        }

        // vulcanizado

        const baja_vulcanizado = await Feathers.service(
          "pendiente_vulcanizado"
        ).remove(null, {
          query: {
            rodillo_id: {
              $in: unidades_ids,
            },
          },
        });

        const incidencias_resuletas = await Feathers.service(
          "incidencia"
        ).patch(
          null,
          {
            solucion: "Orden cancelada",
          },
          {
            query: {
              rodillo_id: {
                $in: unidades_ids,
              },
            },
          }
        );

        await this.setState({
          loading: false,
        });

        this.fetchData(this.state);
      }
    });
  };

  componentDidMount() {
    this.viewTask();
  }

  render() {
    if (this.state.task) {
      return (
        <CheckTask
          task={this.state.task}
          initTask={this.initTask}
          goBack={this.goBack}
          finishTask={this.finishTask}
          cancelTaskByIncidence={this.cancelTaskByIncidence}
        />
      );
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CheckContainer);
