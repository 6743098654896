import React, { Component } from "react";
import { Button, Form, Grid, Modal } from "semantic-ui-react";
import { notify } from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";
import swal from "sweetalert2";

export default class FoldersBinghamEditModal extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        id: '',
        nombre: "",
      },
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        data: {
          nombre: nextProps.data.nombre,
          id: nextProps.data.id,
        },
      });
    }
  }

  handleSubmit = async () => {
    if (!this.state.data.nombre) {
      swal({
        type: "warning",
        title: "Faltan datos por registrar...",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }

    this.setState(
      {
        loading: true,
      },
      async () => {
       await Feathers.service("carpetas_bingham")
          .patch(this.state.data.id, {
            ...this.state.data,
          })
          .then(async (res) => {
            await this.setState({
              loading: false,
            });
            await this.props.onEdit();
            await this.props.onClose();
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error.",
              type: "error",
              confirmButtonText: "OK",
              reverseButtons: true,
            });
            this.setState({ loading: false });
          });
      }
    );
  };

  render() {
    return (
      <Modal open={this.props.open} size={"small"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          Editar
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content scrolling>
          <Form size={"large"} onSubmit={this.handleSubmit}>
            <Grid>
              <Grid.Row columns={2} divided>
                <Grid.Column>
                  <Form.Input
                    label="Nombre de la carpeta"
                    name="nombre"
                    value={this.state.data.nombre}
                    type="text"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        data: {
                          ...this.state.data,
                          [name]: value,
                        },
                      })
                    }
                  />
                </Grid.Column>

                <Grid.Column>
                  <Button
                    disabled={this.state.loading}
                    loading={this.state.loading}
                    type="submit"
                    //onClick={this.handleSubmit}
                    positive
                    content="ACTUALIZAR"
                    icon="send"
                    floated="left"
                    style={{ marginTop: 25 }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
