import React, { Component } from 'react';
import { Header, Grid, Icon, Button, Divider } from 'semantic-ui-react'
import { TaskOrderSegment, TaskStatusSegment, TaskItemSegment} from '../'
import Incidence from '../../Incidence/Incidence'
const PHASE = 9;

export default class UnmountTask extends Component {

   state = {
       
   }
   
    componentDidMount(){
    }

    renderInitButton = () => {
        if (this.props.task.hora_inicio) return null;
        
        return (
            <div style={{marginTop: 20}}>
                <Button onClick={() => this.props.initTask(this.props.task)} icon='play' fluid color="blue" content='Iniciar' />
            </div>
        )
    }

   

    

    finishTask = () => {

        this.props.finishTask && this.props.finishTask();
    }

  
    render() {
        return (
            <div>
              <Grid>
                 <Grid.Row columns={2}>
                    <Grid.Column>
                        <Header as='h2'>
                        
                        <Button onClick={this.props.goBack} icon="arrow left" style={{backgroundColor: 'transparent', color: '#000000de'}}/>
                        Tareas de Desmonte 
                        </Header>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <Incidence phase={PHASE} task={this.props.task}  cancelTaskByIncidence={this.props.cancelTaskByIncidence} rodillo={this.props.task.rodillo_id} pedido={this.props.task.rodillo.partida.pedido_id} />
                    </Grid.Column>
                 </Grid.Row>
              </Grid>
            <Divider />
                {this.props.task.hora_inicio && (
                    <TaskStatusSegment task={this.props.task}   finishTask={this.finishTask}></TaskStatusSegment>
                )}

                <TaskOrderSegment order={this.props.task.rodillo.partida.pedido} kilos={this.props.task.rodillo.partida.kilos}/>

                <Header as='h3' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px', backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='list' />
                    <Header.Content>Información de la partida</Header.Content>
                </Header>
                <TaskItemSegment roller={this.props.task.rodillo.id} item={this.props.task.rodillo.partida} />
                {this.renderInitButton()}
            </div>
        )
    }
}
