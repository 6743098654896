import React, { Component } from "react";
import feathersRestClient from "@feathersjs/rest-client";
import feathers from "@feathersjs/feathers";
import moment from "moment";
import axios from "axios";
import Feathers from "../../redux/FeathersRedux";
import {
  Modal,
  Form,
  Grid,
  Button,
  Icon,
  Segment,
  Message,
} from "semantic-ui-react";
import Config from "../../config";
import swal from "sweetalert2";

export default class UploadTaskFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      task_file: {
        document: null,
        tarea_calendario_id: null,
        description: "",
      },
      loading: false,
      done: false,
      comment: "",
      retardo: false,
    };
  }

  finishTask = () => {
    const idTask = this.props.data.id;
    swal({
      title: "¿Finalizar tarea?",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Sí, finalizar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("calendario_tarea")
          .patch(idTask, {
            status_tarea_id: 3,
            fecha_fin_usuario: moment().format("YYYY-MM-DD"),
            retardo: this.state.retardo,
            comentario: this.state.comment,
          })
          .then((res) => {
            this.props.callback();
            this.props.onClose();
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error. Intente de nuevo",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true,
            });
          });
      }
    });
  };

  uploadFile = async () => {
    const description = this.state.task_file.description;
    const idTask = this.props.data.id;
    let newDataToSend = new FormData();

    //TODO validar que se ingrese una description

    if (
      document.getElementById("documentFile") &&
      document.getElementById("documentFile").files.length > 0 &&
      document.getElementById("documentFile").files[0]
    ) {
      let form = document.getElementById("documentFile").files[0];
      newDataToSend.set("documentFile", form);
    } else {
      swal({
        title: "Rellene todos los campos...",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }

    newDataToSend.set("description", description);
    newDataToSend.set("tarea_calendario_id", idTask);

    const token = localStorage.getItem("bingham-jwt");
    const app = feathers();
    const restClient = feathersRestClient(Config.apiUrl);
    app.configure(restClient.axios(axios));

    app
      .service("tarea_file")
      .create(newDataToSend, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        if (document.getElementById("documentFile")) {
          document.getElementById("documentFile").value = null;
        }

        //this.props.onClose && this.props.onClose();
        //this.props.callback && this.props.callback();
        swal({
          title: "¡Subida Exitosa!",
          type: "success",
          confirmButtonText: "OK",
        }).then((res) => {
          this.setState({
            loading: false,
            done: true,
            task_file: {
              description: "",
            },
          });
        });
      })
      .catch((err) => {
        alert("Se produjo un error");
        this.setState({
          loading: false,
        });
      });
  };

  render = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    const descripcionEntregable = this.props.data.descripcion_entregable;
    return (
      <Modal open={this.props.open} size={"small"}>
        <Modal.Header>
          Subir Entregable
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Message info size="small">
            <Message.Header>
              Esta tarea requiere de un entregable
            </Message.Header>
            <p>
              Para poder finalizar correctamente esta tarea deberá subir un un
              archivo PDF de acuerdo a lo que se solicita.
            </p>
          </Message>
          <Segment vertical>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column
                  width={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon bordered size="large" name="user" />
                </Grid.Column>
                <Grid.Column width={14} style={{ padding: 0 }}>
                  <div>
                    <h4>Ricardo Maynez:</h4>
                  </div>
                  <div>
                    <p>{descripcionEntregable}</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment placeholder="true" padded>
            <Form size="large" id="form" style={{ marginTop: "1rem" }}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Form.Field required>
                      <input
                        accept=".pdf, .xlsx"
                        type="file"
                        id="documentFile"
                        name="document"
                        //value={this.state.document}
                        onChange={(event) => {
                          if (
                            event.target.files &&
                            event.target.files.length > 0
                          ) {
                            this.setState({ document: event.target.files[0] });
                          }
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={16}>
                    <Form.TextArea
                      label="Descripción del documento"
                      type="text"
                      name="description"
                      required
                      value={this.state.task_file.description}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          task_file: {
                            ...this.state.task_file,
                            [name]: value,
                          },
                        })
                      }
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={16}>
                    <Button
                      loading={this.state.loading}
                      onClick={this.uploadFile}
                      floated="right"
                      basic
                      color="blue"
                      labelPosition="left"
                      icon="upload"
                      content="Guardar"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>

          {this.props.data.fecha_fin < currentDate ? (
            <>
              <Message warning size="small">
                <Message.Header>
                  Tarea fuera del periodo establecido
                </Message.Header>
                <p>
                  Para poder finalizar correctamente esta tarea deberá indicar
                  el motivo por el cual no se cumplió durante tiempo
                  establecido.
                </p>
              </Message>
              <Segment placeholder="true" padded>
                <Form size="large" style={{ marginTop: "1rem" }}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Form.TextArea
                          label="Comentario"
                          type="text"
                          name="comentario"
                          required
                          value={this.state.comment}
                          onChange={(e, { name, value }) =>
                            this.setState({
                              comment: value,
                              retardo: true,
                            })
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Segment>
            </>
          ) : (
            <></>
          )}

          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Button
                  type="submit"
                  onClick={this.finishTask}
                  content="Finalizar tarea"
                  floated="right"
                  color="green"
                  labelPosition="left"
                  icon="check"
                  disabled={this.state.done === false}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  };
}
