import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import {
  Button,
  Modal,
  Grid,
  Icon,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ContactsCustomerInfo from "./ContactsCustomerInfo";
import ClientsEditModal from "../Clients/ClientsEditModal";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

export default class ContactsCustomerList extends Component {
  constructor() {
    super();

    this.state = {
      clients: [],
      total: 0,
      page: 0,
      loading: false,

      currentRow: {},
      isEditOpened: false,
      // results of the search querys
      results: null,
      // result objects

      client: "",

      modalOpened: false,
      pageSize: 100,
      filterOptions: {
        id: null,
      },
      $sortDirection: 1,
      // loading of the search bars
      loadingSearch: false,
      columna: "",
      sortDirection: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
  }
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre del Cliente":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Teléfono":
        array.data.sort((a, b) => {
          const nameA = a.telefono.toUpperCase(); // ignore upper and lowercase
          const nameB = b.telefono.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Email":
        array.data.sort((a, b) => {
          const nameA = a.email ? a.email.toUpperCase() : ""; // ignore upper and lowercase
          const nameB = b.email ? b.email.toUpperCase() : ""; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData(state = {}) {
    let pageSize = 100;

    let query = {
      $limit: pageSize,
      $skip: 100 * state.page,
      // $sort: {
      //   nombre: this.state.$sortDirection,
      // },
    };

    if (this.state.filterOptions.id) {
      query["id"] = this.state.filterOptions.id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("cliente")
        .find({
          query,
        })
        .then((res) => {
          res = this.handleSort(res);
          this.setState({
            clients: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        }, console.log("Current page: ", this.state.page));
    });
  }

  openModal(row) {
    this.setState({
      modalOpened: true,
      currentRow: row.original,
    });
  }

  closeModal() {
    this.setState({
      modalOpened: false,
    });
  }

  handleResultSelect = async (e, { result }) => {
    this.setState({ client: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingSearch: true, client: value });

    setTimeout(() => {
      if (this.state.client.length < 1) return this.resetComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            loadingSearch: false,
            results: res.data,
          });
        });
    }, 500);
  };

  handleCleanButton = async () => {
    this.resetComponent();

    await this.setState({
      filterOptions: {
        id: null,
      },
    });

    this.fetchData(this.state);
  };

  openEditModal(row) {
    this.setState(
      {
        isEditOpened: true,
        currentRow: row.original,
      },
      () => {}
    );
    this.resetComponent()
  }

  closeEditModal() {
    this.handleCleanButton()
    this.setState({
      isEditOpened: false,
    });
  }

  resetComponent = () =>
    this.setState({ loadingSearch: false, results: [], client: "" });

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre del Cliente</label>
                  <Search
                    icon=""
                    loading={this.state.loadingSearch}
                    value={this.state.client}
                    results={this.state.results}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    resultRenderer={renderSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                {/* <Form.Field>
                                    <label>Exportar</label>
                                    <Button basic color='green' circular icon="file excel outline" onClick={() => {
                                        exportToXls(this.state.clients, 'admin_clients')
                                    }} />
                                </Form.Field> */}
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre del Cliente", accessor: "nombre" },
            { Header: "Teléfono", accessor: "telefono" },
            { Header: "Email", accessor: "email" },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      basic
                      size={"tiny"}
                      compact
                      color="blue"
                      icon="pencil"
                      onClick={async () => {
                        this.openEditModal(row);
                        await this.fetchData();
                      }}
                    />
                    <Button
                      basic
                      size={"tiny"}
                      compact
                      color="grey"
                      icon="eye"
                      onClick={() => this.openModal(row)}
                    />
                  </div>
                );
              },
              maxWidth: 100,
            },
          ]}
          manual
          data={this.state.clients}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          //page={this.state.page}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState({
              columna: column,
              sortDirection: direction,
            });
          }}
        />
        <ContactsCustomerInfo
          isOpened={this.state.modalOpened}
          data={this.state.currentRow}
          onClose={this.closeModal}
        />
        <ClientsEditModal
          isOpened={this.state.isEditOpened}
          client={this.state.currentRow}
          onClose={this.closeEditModal}
          callback={this.onClientEdited}
        ></ClientsEditModal>
      </div>
    );
  }
}
