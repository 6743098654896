import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Button,
  Grid,
  Icon,
  Header,
  Divider,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../redux/FeathersRedux";
import { exportToXls } from "../utils/Utils";

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);
const renderCodigoSearchResults = ({ id, codigo }) => (
  <Label key={id} content={codigo} />
);

export default class InventoryRawMaterialContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rawMaterials: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      modalOpened: false,
      editModalOpened: false,
      currentRow: {},
      $sort: {
        $cantidadSortDirection: null,
      },

      filterOptions: {
        tipo_inventario_id: null,
        almacen_id: null,
        clave_id: null,
      },

      // inventario options

      inventario_options: [],

      // almacen options

      almacen_options: [],

      // search bar opjects

      material: null,
      codigo: null,

      // search bar loadings

      materialLoading: null,
      codigoLoading: null,

      // search bar results

      materialResults: null,
      codigoResults: null,
      columna: "",
      sortDirection: false,
      shouldExport: false,
    };

    this.fetchData = this.fetchData.bind(this);
  }
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "#Inventario":
        array.data.sort((a, b) => {
          return (
            a.tipoinventario.nombre_tipo_inventario -
            b.tipoinventario.nombre_tipo_inventario
          );
        });
        break;
      case "#Almacen":
        array.data.sort((a, b) => {
          const nameA = a.almacen.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.almacen.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Materia Prima":
        array.data.sort((a, b) => {
          const nameA = a.materiaprima.nombre.trimLeft().toUpperCase(); // ignore upper and lowercase
          const nameB = b.materiaprima.nombre.trimLeft().toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Orden":
        array.data.sort((a, b) => {
          return a.materiaprima.orden - b.materiaprima.orden;
        });
        break;
      case "Grupo":
        array.data.sort((a, b) => {
          const nameA = a.materiaprima.grupo.toUpperCase(); // ignore upper and lowercase
          const nameB = b.materiaprima.grupo.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Químico":
        array.data.sort((a, b) => {
          const nameA = a.materiaprima.quimico.toUpperCase().trimLeft(); // ignore upper and lowercase
          const nameB = b.materiaprima.quimico.toUpperCase().trimLeft(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Código":
        array.data.sort((a, b) => {
          const nameA = a.materiaprima.codigo.toUpperCase().trimLeft(); // ignore upper and lowercase
          const nameB = b.materiaprima.codigo.toUpperCase().trimLeft(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Cantidad":
        array.data.sort((a, b) => {
          const nameA = a.cantidad.toUpperCase(); // ignore upper and lowercase
          const nameB = b.cantidad.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  componentDidMount() {
    this.fetchOptions();
  }

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        clave_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleCodigoResultSelect = async (e, { result }) => {
    this.setState({ codigo: result.codigo });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        clave_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("materia_prima")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  handleCodigoSearchChange = (e, { value }) => {
    this.setState({ codigoLoading: true, codigo: value });

    setTimeout(() => {
      if (this.state.codigo.length < 1) return this.resetCodigoComponent();

      Feathers.service("materia_prima")
        .find({
          query: {
            codigo: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            codigoLoading: false,
            codigoResults: res.data,
          });
        });
    }, 500);
  };

  resetMaterialComponent = () =>
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  resetCodigoComponent = () =>
    this.setState({ codigoLoading: false, codigoResults: [], codigo: "" });

  fetchOptions = async (_) => {
    let tipo_inventario = await Feathers.service("tipo_inventario").find({
      query: {
        $limit: "-1",
      },
    });

    let inventario_options = tipo_inventario.map((v) => {
      return {
        key: v.id,
        text: v.nombre_tipo_inventario,
        value: v.id,
      };
    });

    let almacen = await Feathers.service("almacen").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    let almacen_options = almacen.map((v) => {
      return {
        key: v.id,
        text: v.nombre,
        value: v.id,
      };
    });

    this.setState({
      inventario_options,
      almacen_options,
    });
  };

  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 10;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.$sort.$cantidadSortDirection !== null) {
      query["$sort"] = {
        cantidad: this.state.$sort.$cantidadSortDirection,
      };
    }

    if (this.state.filterOptions.tipo_inventario_id !== null) {
      query["tipo_inventario_id"] = this.state.filterOptions.tipo_inventario_id;
    }

    if (this.state.filterOptions.almacen_id !== null) {
      query["almacen_id"] = this.state.filterOptions.almacen_id;
    }

    if (this.state.filterOptions.clave_id !== null) {
      query["clave_id"] = this.state.filterOptions.clave_id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("inventario")
        .find({
          query,
        })
        .then((res) => {
          console.log(res);
          this.handleSort(res);

          if (!this.state.shouldExport) {
            this.setState({
              rawMaterials: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 10,
            });
          }
          if (this.state.shouldExport) {
            exportToXls(res, "inventory_rawmaterials");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  };

  handleCleanButton = (_) => {
    this.resetMaterialComponent();
    this.resetCodigoComponent();
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          tipo_inventario_id: null,
          almacen_id: null,
          clave_id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="history" content="Inventario" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form size={"large"}>
                <Form.Group>
                  <Form.Field>
                    <label>Inventario</label>
                    <Form.Dropdown
                      selection
                      options={this.state.inventario_options}
                      onChange={(_, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              tipo_inventario_id: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                      value={this.state.filterOptions.tipo_inventario_id}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Almacen</label>
                    <Form.Dropdown
                      selection
                      options={this.state.almacen_options}
                      onChange={(_, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              almacen_id: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                      value={this.state.filterOptions.almacen_id}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Materia</label>
                    <Search
                      icon=""
                      loading={this.state.materialLoading}
                      value={this.state.material}
                      results={this.state.materialResults}
                      onResultSelect={this.handleMaterialResultSelect}
                      onSearchChange={this.handleMaterialSearchChange}
                      resultRenderer={renderMaterialSearchResults}
                      placeholder="Materia"
                      className="full-width"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Código</label>
                    <Search
                      icon=""
                      loading={this.state.codigoLoading}
                      value={this.state.codigo}
                      results={this.state.codigoResults}
                      onResultSelect={this.handleCodigoResultSelect}
                      onSearchChange={this.handleCodigoSearchChange}
                      resultRenderer={renderCodigoSearchResults}
                      placeholder="Código"
                      className="full-width"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      circular
                      basic
                      color="red"
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      circular
                      basic
                      color="green"
                      icon="file excel outline"
                      onClick={() => {
                        this.setState({ shouldExport: true }, () => {
                          this.fetchData(this.state);
                        });
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <ReactTable
          columns={[
            {
              Header: "#Inventario",
              accessor: "tipoinventario.nombre_tipo_inventario",
            },
            { Header: "#Almacen", accessor: "almacen.nombre" },
            { Header: "Materia Prima", accessor: "materiaprima.nombre" },
            { Header: "Orden", accessor: "materiaprima.orden" },
            { Header: "Grupo", accessor: "materiaprima.grupo" },
            { Header: "Químico", accessor: "materiaprima.quimico" },
            { Header: "Código", accessor: "materiaprima.codigo" },
            { Header: "Cantidad", accessor: "cantidad" },
          ]}
          manual
          data={this.state.rawMaterials}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState({
              columna: column,
              sortDirection: direction,
            });
            this.fetchData();
          }}
        />
      </div>
    );
  }
}
