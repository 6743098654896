import React, { Component } from "react";
import { Form, Grid, Button, Dropdown } from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import { notify } from "react-notify-toast";
import swal from 'sweetalert2'

const REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default class ProvidersForm extends Component {
  constructor() {
    super();
    this.state = {
      provider: {
        nombre: "",
        email: "",
        telefono: "",
        rfc: "",
        direccion: "",
        n_interior: "",
        n_exterior: "",
        colonia: "",
        ciudad: "",
        cp: "",
        estado: "",
        pais: "",
        moneda_id: null,
      },
      loading: false,
      error: false,
      moneda_options: [],
      rawMaterials: [],
      options: [],
    };
  }


  fetchRawMaterials = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("materia_prima")
          .find({
            query: {
              activo: 1,
              doPagination: false
            }
          })
          .then(res => {
            let options = res.map((v, i) => {
              return {
                key: i,
                text: `${v.nombre} - ${v.codigo}`,
                value: v.id
              };
            });
            this.setState({
              options,
              loading: false,
            });
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    );
  };

  fetchMonedaOptions = async (_) => {
    let options = await Feathers.service("moneda").find({
      query: {
        $limit: "-1",
      },
    });

    let moneda_options = options.map((v) => {
      return {
        key: v.id,
        value: v.id,
        text: v.nombre + " / " + v.cod,
      };
    });

    this.setState({
      moneda_options,
    });
  };

  handleSubmit = () => {
    if (!this.state.provider.nombre) {
      notify.show("El provider debe llevar un nombre", "error", 2000);
      return;
    }

    if (!this.state.provider.email) {
      notify.show("El provider debe llevar un email", "error", 2000);
      return;
    }

    if (!REGEX.test(this.state.provider.email)) {
      notify.show("Ingresa un email válido", "error", 2000);
      return;
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("proveedor")
          .create({
            nombre: this.state.provider.nombre,
            email: this.state.provider.email,
            telefono: this.state.provider.telefono,
            rfc: this.state.provider.rfc,
            direccion: this.state.provider.direccion,
            n_interior: this.state.provider.n_interior,
            n_exterior: this.state.provider.n_exterior,
            colonia: this.state.provider.colonia,
            ciudad: this.state.provider.ciudad,
            cp: this.state.provider.cp,
            estado: this.state.provider.estado,
            pais: this.state.provider.pais,
            moneda_id: this.state.provider.moneda_id,
            rawMaterials: this.state.rawMaterials
          })
          .then((res) => {
            this.props.onCreate();
            swal({
              title: "Registro exitoso",
              type: "success",
              confirmButtonText: "ok",
              reverseButtons: true
            }).then((res) => {
              this.setState({
                provider: {
                  nombre: "",
                  email: "",
                  telefono: "",
                  rfc: "",
                  direccion: "",
                  n_interior: "",
                  n_exterior: "",
                  colonia: "",
                  ciudad: "",
                  cp: "",
                  estado: "",
                  pais: "",
                  moneda_id: null,
                },
                loading: false,
                rawMaterials: []
              });
            })
          })
          .catch((err) => {
            console.log(err, 'aver el puto error de su puta madre')
            swal({
              title: "Se produjo un error, intente de nuevo.",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true
            }).then((res) => {
              this.setState({ loading: false });
            })
          });
      }
    );
  }

  componentDidMount() {
    this.fetchMonedaOptions();
    this.fetchRawMaterials();
  }


  render() {
    return (
      <div>
        <Form size={"large"}>
          <Grid>
            <Grid.Row columns={2} divided>
              <Grid.Column>
                <Grid.Row>
                  <Form.Input
                    label="Nombre/Razón social"
                    name="nombre"
                    value={this.state.provider.nombre}
                    type="text"
                    placeholder="Nombre/Razón social"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        provider: { ...this.state.provider, [name]: value },
                      })
                    }
                    required
                  />
                  <Form.Input
                    type="email"
                    label="Email"
                    name="email"
                    value={this.state.provider.email}
                    placeholder="Email"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        provider: { ...this.state.provider, [name]: value },
                      })
                    }
                    required
                  />
                  <Form.Input
                    label="Teléfono"
                    name="telefono"
                    value={this.state.provider.telefono}
                    type="text"
                    placeholder="Teléfono"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        provider: { ...this.state.provider, [name]: value },
                      })
                    }
                  />
                  <Form.Input
                    label="RFC"
                    name="rfc"
                    value={this.state.provider.rfc}
                    type="text"
                    placeholder="RFC"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        provider: { ...this.state.provider, [name]: value },
                      })
                    }
                  />
                  <Form.Dropdown
                    label="Moneda"
                    value={this.state.provider.moneda_id}
                    options={this.state.moneda_options}
                    selection
                    placeholder={"Moneda"}
                    onChange={(_, d) => {
                      this.setState({
                        provider: {
                          ...this.state.provider,
                          moneda_id: d.value,
                        },
                      });
                    }}
                  />
                  <Form.Input label="Materias primas">
                    <Dropdown
                      placeholder="Seleccione las materias primas de este proveedor"
                      fluid
                      multiple
                      selection
                      search
                      options={this.state.options}
                      value={this.state.rawMaterials}
                      onChange={(e, { value }) =>
                        this.setState({
                          error: false,
                          rawMaterials: [...value] 
                        })
                      }
                    />
                  </Form.Input>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row>
                  <Form.Input
                    label="Dirección"
                    name="direccion"
                    type="text"
                    placeholder="Dirección"
                    value={this.state.provider.direccion}
                    onChange={(e, { name, value }) =>
                      this.setState({
                        provider: { ...this.state.provider, [name]: value },
                      })
                    }
                  />
                  <Form.Group>
                    <Form.Input
                      label="No. interior"
                      value={this.state.provider.n_interior}
                      name="n_interior"
                      placeholder="No. interior"
                      width={8}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          provider: { ...this.state.provider, [name]: value },
                        })
                      }
                    />
                    <Form.Input
                      label="No. exterior"
                      value={this.state.provider.n_exterior}
                      name="n_exterior"
                      placeholder="No. exterior"
                      width={8}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          provider: { ...this.state.provider, [name]: value },
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Input
                    label="Colonia"
                    value={this.state.provider.colonia}
                    name="colonia"
                    type="text"
                    placeholder="Colonia"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        provider: { ...this.state.provider, [name]: value },
                      })
                    }
                  />
                  <Form.Group>
                    <Form.Input
                      label="Ciudad"
                      value={this.state.provider.ciudad}
                      name="ciudad"
                      placeholder="Ciudad"
                      width={8}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          provider: { ...this.state.provider, [name]: value },
                        })
                      }
                    />
                    <Form.Input
                      label="C.P."
                      value={this.state.provider.cp}
                      name="cp"
                      placeholder="C.P."
                      width={8}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          provider: { ...this.state.provider, [name]: value },
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Estado"
                      value={this.state.provider.estado}
                      name="estado"
                      placeholder="Estado"
                      width={8}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          provider: { ...this.state.provider, [name]: value },
                        })
                      }
                    />
                    <Form.Input
                      label="País"
                      value={this.state.provider.pais}
                      name="pais"
                      placeholder="País"
                      width={8}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          provider: { ...this.state.provider, [name]: value },
                        })
                      }
                    />
                  </Form.Group>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column floated="right" width={5}>
                <Button
                  disabled={this.state.loading}
                  loading={this.state.loading}
                  type="button"
                  onClick={this.handleSubmit}
                  floated="right"
                  positive
                  content="REGISTRAR"
                  icon="send"
                  labelPosition="left"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}
