import React, { Component } from "react";
import ReactTable from "react-table";
import Feathers from "../../redux/FeathersRedux";
import "react-table/react-table.css";
import Toggle from "react-toggle";

class NotificationTaskList extends Component {
  state = {
    data: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 100,
    currentRow: {},
    columna: "",
    sortDirection: false,
  };
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Tarea":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;
    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
    };
    this.setState({ loading: true }, () => {
      Feathers.service("notification_tasks")
        .find({
          query,
        })
        .then((res) => {
          this.handleSort(res);
          this.setState({
            data: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        });
    });
  };

  handleChange = (event, row) => {
    Feathers.service("notification_tasks").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  };

  render() {
    return (
      <div>
        <ReactTable
          columns={[
            {
              Header: "Tarea",
              headerStyle: { fontSize: 20 },
              accessor: "nombre",
            },
            {
              Header: "Activar notificación",
              sortable: false,
              headerStyle: { fontSize: 20 },
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.data}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={20}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }
            this.setState({
              columna: column,
              sortDirection: direction,
            });
            this.fetchData();
          }}
        />
      </div>
    );
  }
}

export default NotificationTaskList;
