import React, { Component } from "react";
import { Button, Form, Dropdown} from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import swal from "sweetalert2";
export default class UsersForm extends Component {
  state = {
    usuario: {
      nombre: "",
      apellido: "",
      email: "",
      login: "",
      password: "",
      celular: "",
    },
    loading: false,
    options: [],
    perfiles: [],
    error: false
  };

  componentDidMount = () => {
    this.fetchData();
    this.fetchPermmision();
  };

  fetchData = () => {
    
    let options;
    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("perfil")
          .find({
            query: {
              activo: 1,
              doPagination: false
            }
          })
          .then(res => {
            options = res.map((v, i) => {
              return {
                key: i,
                text: v.nombre,
                value: v.id
              };
            });

            this.setState({
              options,
              loading: false
            });
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    );
  };

  /**
   * Obtiene todos los permisos
   * @memberof UsersForm
   */
  fetchPermmision = async () => {
    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("permiso")
          .find({
            query: {
              doPagination: false
            }
          })
          .then(res => {
            //Hola
            const permission = res.map((v, i) => {
              return {
                key: i,
                text: v.nombre,
                value: v.id
              };
            });
            //hola
            this.setState({
              permission: permission
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  handleSubmit = () => {
    const { nombre, apellido, email, login, password, celular } = this.state.usuario;
    
    if (this.state.perfiles.length < 1 || !nombre || !apellido || !email || !login || !password || !celular) {
      this.setState({loading:  false})
      swal({
        title: "Faltan datos por registrar",
        type: "info",
        confirmButtonText: "ok",
        reverseButtons: true
      })
      return;
    }

    this.setState(
      {
        loading: true
      },
      () => {
        Feathers.service("usuario")
          .create({
            nombre: this.state.usuario.nombre,
            apellido: this.state.usuario.apellido,
            celular: this.state.usuario.celular,
            email: this.state.usuario.email,
            login: this.state.usuario.login,
            password: this.state.usuario.password,
            perfiles: this.state.perfiles
            // ...this.state.usuario
          })
          .then(res => {
            //notify.show("Usuario Creado", "success");
            this.props.onCreate();
            swal({
              title: "Registro éxitoso",
              type: "success",
              confirmButtonText: "ok",
              reverseButtons: true
            }).then((res) => {
              this.setState({
                loading: false,
                perfiles: [],
                usuario: {
                  nombre: "",
                  apellido: "",
                  email: "",
                  login: "",
                  password: "",
                  celular: "",
                }
              });
            })
          })
          .catch(err => {
           //notify.show("Se produjo un error", "error");
            swal({
              title: "Se produjo un error, intente de nuevo.",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true
            }).then((res) => {
              this.setState({ loading: false });
            })

          });
      }
    );
  };

  render() {
    return (
      <div>
        <Form onSubmit={this.handleSubmit} size={'large'}>
          <Form.Group widths="equal">
            <Form.Input
              label="Nombre"
              name="nombre"
              value={this.state.usuario.nombre}
              type="text"
              placeholder="Nombre"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              
            />
            <Form.Input
              label="Apellido"
              name="apellido"
              value={this.state.usuario.apellido}
              type="text"
              placeholder="Apellido"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              
            />
            <Form.Input
                label="Celular"
                name="celular"
                value={this.state.usuario.celular}
                type="number"
                placeholder="Celular"
                onChange={(e, { name, value }) =>
                  this.setState({
                    usuario: { ...this.state.usuario, [name]: value }
                  })
                }
                
              />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              label="Usuario"
              name="login"
              value={this.state.usuario.login}
              type="text"
              placeholder="Login"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              
            />
            <Form.Input
              label="Password"
              name="password"
              value={this.state.usuario.password}
              type="password"
              placeholder="Password"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              label="Email"
              name="email"
              value={this.state.usuario.email}
              type="email"
              placeholder="Email"
              onChange={(e, { name, value }) =>
                this.setState({
                  usuario: { ...this.state.usuario, [name]: value }
                })
              }
              
            />
            <Form.Input label="Perfil">
              <Dropdown
                placeholder="Perfil"
                fluid
                multiple
                selection
                options={this.state.options}
                value={this.state.perfiles}
                onChange={(e, { value }) =>
                  this.setState({
                    error: false,
                    perfiles: [...value] 
                  })
                }
              />
            </Form.Input>
          </Form.Group>
  
          <Button
            type="submit"
            floated="right"
            positive
            style={{ alignItem: "right" }}
            content="REGISTRAR"
            icon="send"
            labelPosition="left"
            loading={this.state.loading}
          />
        </Form>
      </div>
    );
  }
}
