import React, { Component } from "react";
import {
  Form,
  Grid,
  Button,
  Dropdown,
  Header,
  Divider,
  Segment,
  Icon,
  Image,
  List,
  Tab,
} from "semantic-ui-react";
import Feathers from "../redux/FeathersRedux";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import Config from "../config";
import ManualView from "../components/CatalogEquipment/ManualView";
import VideosView from "../components/CatalogEquipment/VideosView";

class EquipmentInfoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userProfile: 0,
      isAdmin: 0,
      maquinaria: {
        nombre: "",
        descripcion: "",
        fecha_compra: null,
        tipo_maquina_id: null,
        imagen: null,
        perfiles: [],
      },
      equipments: [],
      options: [],
      dates: [],
      error: false,
      currIndex: 0,
    };

    this.fetchUserProfile = this.fetchUserProfile.bind(this);
  }

  //Obtener perfil del usuario en sesion para otorgar permiso de borrar documentos
  fetchUserProfile = () => {
    this.setState({
      userProfile: this.props.auth.user.id,
    });
    this.setState({ loading: true }, () => {
      //Hacemos una consulta que trae un 1 solo si el usuario es admin
      Feathers.service("usuario_perfil")
        .find({
          query: {
            usuario_id: this.state.userProfile,
            perfil_id: 1,
          },
        })
        .then((res) => {
          this.setState({
            isAdmin: res[0].perfil_id,
          });
        })
        .catch((err) => {});
    });
  };

  equipmentData = () => {
    let perfiles_maquina;
    this.setState({ loading: true }, () => {
      Feathers.service("maquinaria")
        .find({
          query: {
            id: this.props.match.params.id,
          },
        })
        .then((res) => {
          this.setState({
            maquinaria: {
              ...this.state.maquinaria,
              nombre: res.data[0].nombre,
              descripcion: res.data[0].descripcion,
              fecha_compra: res.data[0].fecha_compra,
              tipo_maquina_id: res.data[0].tipo_maquinaria.nombre,
              imagen: res.data[0].imagen,
            },
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    });

    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("maquinaria_perfil")
          .find({
            query: {
              maquinaria_id: this.props.match.params.id,
            },
          })

          .then((res) => {
            perfiles_maquina = res.data.map((v, i) => {
              return v.perfil_id;
            });

            this.setState({
              maquinaria: {
                ...this.state.maquinaria,
                perfiles: perfiles_maquina,
              },
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  fetchPerfiles = () => {
    let options;
    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("perfil")
          .find({
            query: {
              doPagination: false,
            },
          })
          .then((res) => {
            options = res.map((v, i) => {
              return {
                key: i,
                text: v.nombre,
                value: v.id,
              };
            });

            this.setState({
              options,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  //Obtener las fechas de mantenimiento para este equipo
  fetchDates = () => {
    const idMaquina = this.props.match.params.id;
    Feathers.service("calendario_tarea")
      .find({
        query: {
          maquina_mantenimiento_id: idMaquina,
          activo: 1,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        console.log(res.data, 'aver data')
        this.setState({
          dates: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  sourcesView = [
    {
      menuItem: { key: "list", icon: "file text", content: "Manuales" },
      render: () => (
        <Tab.Pane attached={false}>
          {" "}
          <ManualView
            idMaquina={this.props.match.params.id}
            isAdmin={this.state.isAdmin}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "video", icon: "file video outline", content: "Videos" },
      render: () => (
        <Tab.Pane attached={false}>
          {" "}
          <VideosView
            isAdmin={this.state.isAdmin}
            idMaquina={this.props.match.params.id}
          />
        </Tab.Pane>
      ),
    },
  ];

  setTab = (index) => {
    this.setState({
      currIndex: index || 0,
    });
  };

  componentDidMount = () => {
    this.fetchUserProfile();
    this.equipmentData();
    this.fetchPerfiles();
    this.fetchDates();
  };

  handleChange = (e, data) => this.setState(data);

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as={"h1"}>
                <Button
                  onClick={() => this.props.dispatch(push("/catalogequipment"))}
                  icon="arrow left"
                  style={{ backgroundColor: "transparent", color: "#000000" }}
                />
                Información del equipo
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Grid style={{ fontSize: "16px" }}>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Grid.Row>
                <Segment style={{ marginTop: "1rem" }} size="large">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Image
                      src={Config.apiUrl + "/" + this.state.maquinaria.imagen}
                      style={{ height: "180px" }}
                    />
                  </div>
                </Segment>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Grid.Row>
                <Icon name="tag"></Icon>{" "}
                <span style={{ fontWeight: "bold" }}>Nombre:</span>
              </Grid.Row>
              <Grid.Row>
                <Segment
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  size="large"
                >
                  {this.state.maquinaria.nombre
                    ? this.state.maquinaria.nombre
                    : "---"}
                </Segment>
              </Grid.Row>
              <Grid.Row>
                <Icon name="list alternate oine"></Icon>{" "}
                <span style={{ fontWeight: "bold" }}>Descripción:</span>
              </Grid.Row>
              <Grid.Row>
                <Segment style={{ marginTop: "1rem" }} size="large">
                  {this.state.maquinaria.descripcion
                    ? this.state.maquinaria.descripcion
                    : "---"}
                </Segment>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column width={4}>
              <Grid.Row>
                <Icon name="calendar alternate outline"></Icon>{" "}
                <span style={{ fontWeight: "bold" }}>Fecha de compra:</span>
              </Grid.Row>
              <Grid.Row>
                <Segment style={{ marginTop: "0.5rem" }} size="large">
                  {this.state.maquinaria.fecha_compra
                    ? this.state.maquinaria.fecha_compra
                    : "---"}
                </Segment>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={4}>
              <Grid.Row>
                <Icon name="clone"></Icon>{" "}
                <span style={{ fontWeight: "bold" }}>Tipo de maquinaria:</span>
              </Grid.Row>
              <Grid.Row>
                <Segment style={{ marginTop: "0.5rem" }} size="large">
                  {this.state.maquinaria.tipo_maquina_id
                    ? this.state.maquinaria.tipo_maquina_id
                    : "---"}
                </Segment>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid.Row>
                <Icon name="users"></Icon>{" "}
                <span style={{ fontWeight: "bold" }}>Perfiles a cargo:</span>
              </Grid.Row>
              <Grid.Row>
                <Segment style={{ marginTop: "0.5rem" }} size="large">
                  {this.state.maquinaria.perfiles.length > 0 ? (
                    <Form.Input>
                      <Dropdown
                        placeholder="Perfil"
                        fluid
                        multiple
                        selection
                        options={this.state.options}
                        value={this.state.maquinaria.perfiles}
                        className="input-readOnly"
                      />
                    </Form.Input>
                  ) : (
                    "No se han asignado perfiles a este equipo."
                  )}
                </Segment>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header as="h3">
                <Icon name="calendar check outline" />
                <Header.Content>
                  Fechas próximas de mantenimiento:
                </Header.Content>
              </Header>
              <Segment>
                {this.state.dates.length >= 1 ? (
                  <Grid columns={4}>
                    <Grid.Row>
                      {this.state.dates.map((date) => (
                        <Grid.Column key={date.id} style={{marginBottom: '1rem'}}>
                          <div>
                            <p style={{fontWeight: 'bold'}}>{date.titulo}</p>
                          </div>
                          <div>
                            <p>Fecha: {date.fecha_inicio}</p>
                          </div>
                          <div>
                            <p>A cargo de: {`${date.usuario.nombre} ${date.usuario.apellido}`}</p>
                          </div>
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                ) : (
                  <p>Aún no hay fechas de mantenimiento para este equipo.</p>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header as="h3">
                <Icon name="archive" />
                <Header.Content>Recursos disponibles:</Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Tab
                onTabChange={this.handleChange}
                menu={{ pointing: true }}
                panes={this.sourcesView}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, null, null, {
  withRef: true,
})(EquipmentInfoContainer);
