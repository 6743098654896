import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import { Form, Grid, Button, Icon } from "semantic-ui-react";
import swal from "sweetalert2";

export default class VideoForm extends Component {
  constructor() {
    super();
    this.state = {
      maquinaria_video: {
        nombre_video: "",
        link_video: "",
      },
    };
  }

  registerLink = () => {
    const idMaquinaria = this.props.idMaquinaria;
    if (
      !this.state.maquinaria_video.nombre_video ||
      !this.state.maquinaria_video.link_video
    ) {
      swal({
        title: "Faltan datos por registrar.",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }
    Feathers.service("maquinaria_video")
      .create({
        nombre_video: this.state.maquinaria_video.nombre_video,
        link_video: this.state.maquinaria_video.link_video,
        maquinaria_id: idMaquinaria,
      })
      .then((res) => {
        this.props.callback && this.props.callback();
        swal({
          title: "Registro Exitoso!",
          type: "success",
          confirmButtonText: "OK",
        }).then((res) => {
          this.setState({
            maquinaria_video: {
              nombre_video: "",
              link_video: "",
            },
          });
        });
      })
      .catch((err) => {
        swal({
          title: "¡Se produjo un error, intente de nuevo!",
          type: "error",
          confirmButtonText: "OK",
        });
      });
  };

  render() {
    return (
      <Form id="form-video" style={{ marginTop: "1rem" }}>
        <Grid>
          <Grid.Row columns={3}>
          <Grid.Column
            width={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon name="youtube" color="red" size="big" />
          </Grid.Column>
          <Grid.Column width={6}>
            <Form.Input
              required
              type="text"
              name="nombre_video"
              placeholder="Nombre del video"
              value={this.state.maquinaria_video.nombre_video}
              onChange={(e, { name, value }) =>
                this.setState({
                  maquinaria_video: {
                    ...this.state.maquinaria_video,
                    [name]: value,
                  },
                })
              }
            />
          </Grid.Column>
          <Grid.Column  width={9}>
          <Form.Input
              required
              type="text"
              name="link_video"
              placeholder="Link de YouTube"
              value={this.state.maquinaria_video.link_video}
              onChange={(e, { name, value }) =>
                this.setState({
                  maquinaria_video: {
                    ...this.state.maquinaria_video,
                    [name]: value,
                  },
                })
              }
            />
          </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                //loading={this.state.loading}
                onClick={this.registerLink}
                floated="right"
                positive
                labelPosition="left"
                icon="upload"
                content="Guardar"
                basic
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}
