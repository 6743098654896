import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import TaskStatus from "../../components/Tasks/TaskStatus";
import { Button, Grid, Header, Search, Label, Form } from "semantic-ui-react";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../components/Tasks/Windmill/WindmillTask";
import { notify } from "react-notify-toast";
import { connect } from "react-redux";
import { exportToXls } from "../../utils/Utils";
import LoaderData from "../LoaderData";
import ObservationModal from "../../components/ObservationModal";
import Toggle from "react-toggle";
import TasksFilter from "../../components/FIlters/TasksFilter";

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

const renderMaterialSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class WindmillContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      reloading: false,
      ///
      ready: false,
      task: null,

      client: null,


      filters: ["pedido", "partida", "costumer", "material", "entrega"],
      filterOptions: {
        cliente_id: null,
        material_id: null,
        ref_sae: null,
        pedido_id: null,
        partida_id: null,
      },
      filterValues: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },
      cliente: null,
      material: null,
      clienteLoading: null,
      materialLoading: null,
      clienteResults: null,
      materialResults: null,
      // search bar options

      material: null,
      sae: null,

      // search bar loading

      materialLoading: null,
      saeLoading: null,

      // search bar  results

      materialResult: null,
      saeResults: null,
      interval: undefined,

      modalOpened: false,
      currentRow: {},
      columna: "",
      sortDirection: false,
      showSae: false,
      shouldExport: false,
      tableColumns: [
        {
          Header: "OT",
          headerStyle: { fontSize: 16 },
          maxWidth: 80,
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida.pedido.ref_sae
                  : row.original.ref_sae
                  ? row.original.ref_sae
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Partida",
          headerStyle: { fontSize: 16 },
          id: "partida_id",
          accessor: (d) => {
            if (d.partida_id) return d.partida_id;
            return "---";
          },
        },
        {
          Header: "Cliente",
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida.pedido.cliente.nombre
                  : row.original.clienteNombre
                  ? row.original.clienteNombre
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Material",
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida.pedido
                    ? row.original.partida.material.nombre
                    : "---"
                  : row.original.matNombre
                  ? row.original.matNombre
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Fecha Ent.",
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? moment(row.original.partida.pedido.fecha_entrega).format(
                      "DD-MM-YYYY"
                    )
                  : row.original.fecha_entrega
                  ? row.original.fecha_entrega
                  : "---"}
              </span>
            );
          },
        },
        
        {
          Header: "Dureza",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida.dureza
                    ? row.original.partida.dureza.valor
                    : "---"
                  : row.original.valor
                  ? row.original.valor
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "DN",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida
                    ? row.original.partida.dn
                    : "---"
                  : row.original.dn
                  ? row.original.dn
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "DE",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida.de
                    ? row.original.partida.de
                    : "---"
                  : row.original.de
                  ? row.original.de
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "LC",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida.lc
                    ? row.original.partida.lc
                    : "---"
                  : row.original.lc
                  ? row.original.lc
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "LT",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida.lt
                    ? row.original.partida.lt
                    : "---"
                  : row.original.lt
                  ? row.original.lt
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Kilos",
          maxWidth: 80,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida.kilos
                    ? row.original.partida.kilos
                    : "---"
                  : row.original.kilos
                  ? row.original.kilos
                  : "---"}
              </span>
            );
          },
        },
        {
          Header: "Cant",
          maxWidth: 100,
          headerStyle: { fontSize: 16 },
          Cell: (row) => {
            return (
              <span>
                {row.original.partida
                  ? row.original.partida.cantidad
                    ? row.original.partida.cantidad
                    : "---"
                  : row.original.cantidad
                  ? row.original.cantidad
                  : "---"}
              </span>
            );
          },
        },

        {
          Header: "Acciones",
          sortable: false,
          headerStyle: { fontSize: 16 },
          Cell: this.renderActions,
        },
      ],
    };

    this.renderList = this.renderList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    // this.renderActions = this.renderActions.bind(this);
    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.goBack = this.goBack.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.initTask = this.initTask.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
  }
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "SAE":
        array.data.sort((a, b) => {
          return a.ref_sae - b.ref_sae;
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.partida.pedido.cliente.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha Ent.":
        array.data.sort((a, b) => {
          const nameA = a.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          const nameB = b.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Material":
        array.data.sort((a, b) => {
          let nameA = a.partida.material.nombre
            ? a.partida.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase
          let nameB = b.partida.material.nombre
            ? b.partida.material.nombre.toUpperCase()
            : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A = a.partida.dureza.valor;
          let B = b.partida.dureza.valor;
          return A - B;
        });
        break;
      case "DN":
        array.data.sort((a, b) => {
          let A = a.partida.dn;
          let B = b.partida.dn;
          return A - B;
        });
        break;
      case "DE":
        array.data.sort((a, b) => {
          let A = a.partida.de;
          let B = b.partida.de;
          return A - B;
        });
        break;
      case "LC":
        array.data.sort((a, b) => {
          let A = a.partida.lc;
          let B = b.partida.lc;
          return A - B;
        });
        break;
      case "LT":
        array.data.sort((a, b) => {
          let A = a.partida.lt;
          let B = b.partida.lt;
          return A - B;
        });
        break;
      case "Kilos":
        array.data.sort((a, b) => {
          let A = a.partida.kilos;
          let B = b.partida.kilos;
          return A - B;
        });
        break;
      case "Cantidad":
        array.data.sort((a, b) => {
          let A = a.partida.cantidad ? b.partida.cantidad : 0;
          let B = b.partida.cantidad ? b.partida.cantidad : 0;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  componentDidMount() {
    this.checkActiveTasks();
  }

  openModal = (row) => {
    this.setState({
      modalOpened: true,
      currentRow: row.partida.observaciones,
    });
  };
  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  };

  checkActiveTasks() {
    Feathers.service("molino")
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          this.setState({
            task: res.data[0],
            ready: true,
          });
        } else {
          this.setState({
            ready: true,
          });
        }
      });
  }

  fetchData = async (state = {}) => {
    this.setState({
      loading: true,
    });
    
    let pageSize = state.pageSize || 10;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      $or: [{ status_tarea_id: null }, { status_tarea_id: 1 }],
    };

    if (this.state.filterOptions.material_id !== null) {
      let partidas_con_material = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          material_id: this.state.filterOptions.material_id,
        },
      });

      let partidas_ids = partidas_con_material.map((v) => v.id);

      query["partida_id"] = {
        $in: partidas_ids,
      };
    }

    if (this.state.filterOptions.cliente_id !== null) {
      let pedidos_del_cliente = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          cliente_id: this.state.filterOptions.cliente_id,
        },
      });

      let pedidos_ids = pedidos_del_cliente.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          producto_id: {
            $in: [1],
          },
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      query["partida_id"] = {
        $in: partidas_ids,
      };
    }

    if (this.state.filterValues.pedidoValue) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          id: this.state.filterValues.pedidoValue
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      query["partida_id"] = {
        $in: partidas_ids,
      };
    }

    if (this.state.filterValues.partidaValue) {
      query["partida_id"] = this.state.filterValues.partidaValue;
    }


    if (this.state.filterValues.fechaEntrega) {
      let pedidos_id = await Feathers.service("pedido").find({
        query: {
          $limit: "-1",
          fecha_entrega: this.state.filterValues.fechaEntrega,
        },
      });

      let pedidos_ids = pedidos_id.map((v) => v.id);

      let partidas_de_los_pedidos = await Feathers.service("partida").find({
        query: {
          $limit: "-1",
          pedido_id: {
            $in: pedidos_ids,
          },
        },
      });

      let partidas_ids = partidas_de_los_pedidos.map((v) => v.id);

      
      query["partida_id"] = {
        $in: partidas_ids,
      };
    }
    
    Feathers.service("molino")
      .find({
        query,
      })
      .then((res) => {
        res = this.handleSort(res);

        console.log(res.data, "--------")

        if (res.data) {
          if (this.state.columna === "") {
            res.data.sort((a, b) => {
              const nameA = a.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase
              const nameB = b.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          }
        } else {
          if (this.state.columna === "") {
            res.sort((a, b) => {
              const nameA = a.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase
              const nameB = b.partida.pedido.fecha_entrega.toUpperCase(); // ignore upper and lowercase

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          }
        }

        if (!this.state.shouldExport) {
          this.setState({
            tasks: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        }

        if (this.state.shouldExport) {
          exportToXls(res, "task_windmill");
          this.setState({
            shouldExport: false,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        }
      });

  };

  initTask(task) {
    swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("molino")
          .patch(task.id, {
            hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 1,
            usuario_id: this.props.user.id,
          })
          .then((res) => {
            Feathers.service("molino")
              .get(res.id)
              .then((task) => {
                this.setState({ task });
              });
          });
      }
    });
  }

  viewTask(task) {
    console.log("task,", task);
    Feathers.service("molino")
      .get(task.id)
      .then((task) => this.setState({ task }));
  }

  goBack() {
    this.setState({ task: null });
  }

  finishTask() {
    swal({
      title: "¿Finalizar tarea de molino?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (!res.value) return;

      Feathers.service("molino")
        .patch(this.state.task.id, {
          hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
          status_tarea_id: 2,
        })
        .then(async (res) => {
          //!Registrar notis si esta activa para esta tarea
          const checkNotification = await Feathers.service(
            "notification_tasks"
          ).get(2);
          if (checkNotification.activo) {
            Feathers.service("notificacion").create({
              mensaje: `Se ha finalizado una tarea de molino #${this.state.task.id}`,
              perfil_id: "1",
              fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
              tipo: 4,
            });
          }

          this.setState({
            task: null,
          });
        });
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    this.setState({ task: null });
    notify.show("Incidencia registrada. Tarea reiniciada", "success");
    /* Feathers.service("molino")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        this.setState({ task: null });
        }); */

    /*  Feathers.service("molino")
          .get(res.id)
          .then((task) => this.setState({ task, reloading: false })); */
  }

  renderActions = (row) => {
    row = row.original;

    if (row.hora_inicio && row.hora_fin) {
      return <span>Terminado</span>;
    }

    return (
      <>
        <Button
          onClick={() => this.viewTask(row)}
          icon="play"
          basic
          size="mini"
          color="blue"
          content="Ver"
        />
        <Button
          onClick={() => this.openModal(row)}
          icon="eye"
          size="mini"
          basic
          color="blue"
          content="Obser."
          style={{ backgroundColor: "#0098f0" }}
        />
      </>
    );
  };

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  renderActions = (row) => {
    row = row.original;
    return (
      <>
        <Button
          onClick={() => this.viewTask(row)}
          icon="play"
          basic
          size="mini"
          color="blue"
          content="Ver"
        />
        <Button
          onClick={() => this.openModal(row)}
          icon="eye"
          size="mini"
          basic
          color="blue"
          content="Obser."
          style={{ backgroundColor: "#0098f0" }}
        />
      </>
    );
  };

  handleClienteResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          cliente_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleMaterialResultSelect = async (e, { result }) => {
    this.setState({ material: result.nombre });

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          material_id: result.id,
        },
      },
      () => this.fetchData(this.state)
    );
  };

  handleClienteSearchChange = (e, { value }) => {
    this.setState({ clienteLoading: true, cliente: value });

    this.timeout = setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  handleMaterialSearchChange = (e, { value }) => {
    this.setState({ materialLoading: true, material: value });

    setTimeout(() => {
      if (this.state.material.length < 1) return this.resetMaterialComponent();

      Feathers.service("material")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
            $limit: 3,
          },
        })
        .then((res) => {
          this.setState({
            materialLoading: false,
            materialResults: res.data,
          });
        });
    }, 500);
  };

  onPartidaChange = (partida) => {
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        partidaValue: partida,
      },
    }, () => this.fetchData(this.state));
  };

  onChangeRodillo = (rodillo) => {
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        rodilloValue: rodillo,
      },
    }, () => this.fetchData(this.state));
  };

  onChangePedido = (pedido) => {
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        pedidoValue: pedido,
      },
    }, () => this.fetchData(this.state));
  };

  onDateChange = (date) => {
    const dateFormat = moment(date).format('YYYY-MM-DD')
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        fechaEntrega: dateFormat,
      },
    }, () => this.fetchData(this.state));
  };

  resetClienteComponent = () => {
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });
  }

  resetMaterialComponent = () => {
    this.setState({
      materialLoading: false,
      materialResults: [],
      material: "",
    });
  }

  handleCleanButton = async () => {
    this.resetClienteComponent();
    this.resetMaterialComponent();
    this.setState({
      filterValues: {
        rodilloValue: null,
        partidaValue: null,
        pedidoValue: null,
        fechaEntrega: null,
      },
      filterOptions: {
        cliente_id: null,
        material_id: null,
        ref_sae: null,
        pedido_id: null,
        partida_id: null,
      }
    }, () => {
      this.fetchData(this.state)
    });
  };


  renderList() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="play" content="Molino" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <TasksFilter
                    loading={this.state.loading}
                    clienteLoading={this.state.clienteLoading}
                    filters={this.state.filters}
                    costumer={this.state.cliente || ""}
                    costumers={this.state.clienteResults}
                    onSelectCostumer={this.handleClienteResultSelect}
                    onSearchCostumerChange={this.handleClienteSearchChange}
                    costumerResultRenderer={renderClientSearchResults}
                    partidaValue={this.state.filterValues.partidaValue}
                    onChangePartida={this.onPartidaChange}
                    rodilloValue={this.state.filterValues.rodilloValue}
                    onChangeRodillo={this.onChangeRodillo}
                    fechaEntrega={this.state.filterValues.fechaEntrega}
                    onDateChange={this.onDateChange}
                    materialLoading={this.state.materialLoading}
                    materials={this.state.materialResults}
                    material={this.state.material ? this.state.material : ""}
                    handleMaterialResultSelect={this.handleMaterialResultSelect}
                    handleMaterialSearchChange={this.handleMaterialSearchChange}
                    renderMaterialSearchResults={renderMaterialSearchResults}
                    pedidoValue={this.state.filterValues.pedidoValue || ""}
                    onPedidoChange={this.onChangePedido}
                  />
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        this.setState({ shouldExport: true }, () => {
                          this.fetchData(this.state);
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Mostrar OT</label>
                    {/* <input type="checkbox" name="showSAE"/> */}
                    <Toggle
                      icons={false}
                      onClick={() => {
                        this.handleSaeToggle();
                      }}
                    ></Toggle>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ReactTable
                columns={
                  this.state.showSae
                    ? this.state.tableColumns
                    : this.state.tableColumns.slice(1)
                }
                manual
                data={this.state.tasks}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                // onFetchData={(state) => {
                //   this.fetchData(state);
                //   this.interval = setInterval(() => {
                //     window.location.reload();
                //     //console.log('recargando...')
                //   }, 300000)
                // }}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState(
                    {
                      columna: column,
                      sortDirection: direction,
                    },
                    () => {
                      console.log(this.state);
                    }
                  );
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ObservationModal
          data={this.state.currentRow}
          isOpened={this.state.modalOpened}
          onClose={this.closeModal}
        />
      </div>
    );
  }

  render() {
    if (this.state.ready) {
      if (this.state.task) {
        return (
          <TaskContainer
            task={this.state.task}
            initTask={this.initTask}
            goBack={this.goBack}
            finishTask={this.finishTask}
            cancelTaskByIncidence={this.cancelTaskByIncidence}
          />
        );
      } else if (this.state.reloading) {
        return <span>Reiniciando tarea...</span>;
      }

      return this.renderList();
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(WindmillContainer);
