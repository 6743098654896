import feathers from '@feathersjs/client';
import io from 'socket.io-client';
import reduxifyAuthentication from 'feathers-reduxify-authentication';
import reduxifyServices from 'feathers-redux';
import { combineReducers } from 'redux';
import Config from '../config'

const socket = io(Config.apiUrl, {transports: ['websocket']});
const app = feathers();

//app.configure(feathers.socketio(socket));

app.configure(feathers.socketio(socket, {
  timeout: 20000
}));
app.configure(feathers.authentication({
    storage: window.localStorage, 
    storageKey: Config.jwtToken
}));




export const services = reduxifyServices(app, ['notificacion', 'configuraciones']);

export default app;
export const feathersAuthentication = reduxifyAuthentication(app);

export const feathersServices = combineReducers({
  notificacion: services.notificacion.reducer,
  configuraciones: services.configuraciones.reducer
});


