import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import {
  Modal,
  Form,
  Grid,
  Button,
  Icon,
  Segment,
  Message,
} from "semantic-ui-react";
import swal from "sweetalert2";
import moment from "moment";

export default class CommentsModal extends Component {
  constructor(props) {
    super();
    this.state = {
      comment: "",

    };
  }

finishTask = () => {
    const idTask = this.props.data.id;
    this.setState({
      loading: true
    })
    swal({
      title: "¿Finalizar tarea?",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Sí, finalizar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("calendario_tarea")
          .patch(idTask, {
            status_tarea_id: 3,
            fecha_fin_usuario: moment().format("YYYY-MM-DD"),
            retardo: true,
            comentario: this.state.comment,
          })
          .then((res) => {
            this.props.onClose();
            this.props.callback();
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error. Intente de nuevo",
              type: "error",
              confirmButtonText: "ok",
              reverseButtons: true,
            });
          });
      }
    });
  };

  render() {
    return (
      <Modal open={this.props.open} size={"small"}>
        <Modal.Header>
          Finalizar tarea
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Message warning size="small">
            <Message.Header>Tarea fuera del periodo establecido</Message.Header>
            <p>
              Para poder finalizar correctamente esta tarea deberá indicar el motivo por el cual no se cumplió durante tiempo establecido. 
            </p>
          </Message>
          <Segment placeholder="true" padded>
            <Form size="large">
              <Form.TextArea
                label="Comentario"
                type="text"
                name="comentario"
                required
                value={this.state.comment}
                onChange={(e, { name, value }) =>
                  this.setState({
                    comment: value,
                  })
                }
              />
            </Form>
          </Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Button
                  type="submit"
                  onClick={this.finishTask}
                  content="Finalizar tarea"
                  floated="right"
                  color="green"
                  labelPosition="left"
                  icon="check"
                  disabled={!this.state.comment}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
