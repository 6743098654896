import React, { Component } from "react";
import ReactTable from "react-table";
import Feathers from "../../redux/FeathersRedux";
import "react-table/react-table.css";
import { Button, Grid, Form } from "semantic-ui-react";
import swal from "sweetalert2";

class NotificationList extends Component {
  state = {
    settings: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 100,
    currentRow: {},
    filterOptions: {
      tipo: null,
      perfil_id: null,
    },

    // options of the filter notification by type
    type_options: [
      {
        key: 1,
        value: 1,
        text: "Alerta Inventario",
      },
      {
        key: 2,
        value: 2,
        text: "Incidencia",
      },
    ],
    columna: "",
    sortDirection: false,
    // options of the filter notification by profile

    profile_options: [],
  };
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Tipo":
        array.data.sort((a, b) => {
          return a.tipo_notificacion - b.tipo_notificacion;
        });
        break;
      case "Perfil":
        array.data.sort((a, b) => {
          const nameA = a.perfil.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.perfil.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  componentDidMount() {
    this.fetchProfileOptions();
  }

  fetchProfileOptions = async (_) => {
    // getting the profiles to show them in a dropdown

    let profiles = await Feathers.service("perfil").find({
      query: {
        doPagination: false,
        activo: 1,
      },
    });

    let parsed_profiles = profiles.map((v) => {
      return {
        text: v.nombre,
        key: v.id,
        value: v.id,
      };
    });

    this.setState({
      profile_options: parsed_profiles,
    });
  };

  _delete = (setting) => {
    swal({
      title: "¿Está usted seguro?",
      text: "Se eliminará el permiso",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        let deleted = await Feathers.service("notificacion_tipo_perfil").remove(
          setting.id
        );
        this.fetchData(this.state);
      }
    });
  };

  handleCleanButton = (_) => {
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          tipo: null,
          perfil_id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.tipo !== null) {
      query["tipo_notificacion"] = this.state.filterOptions.tipo;
    }

    if (this.state.filterOptions.perfil_id !== null) {
      query["perfil_id"] = this.state.filterOptions.perfil_id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("notificacion_tipo_perfil")
        .find({
          query,
        })
        .then((res) => {
          this.handleSort(res);
          this.setState({
            settings: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        });
    });
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <Form.Field>
                    <label>Tipo</label>
                    <Form.Dropdown
                      selection
                      options={this.state.type_options}
                      value={this.state.filterOptions.tipo}
                      onChange={(e, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              tipo: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Perfil</label>
                    <Form.Dropdown
                      selection
                      options={this.state.profile_options}
                      value={this.state.filterOptions.perfil_id}
                      onChange={(e, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              perfil_id: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ReactTable
          columns={[
            {
              Header: "Tipo",
              headerStyle: { fontSize: 20 },
              Cell: (row) => {
                if (row.original.tipo_notificacion === 1)
                  return "Alerta Inventario";
                if (row.original.tipo_notificacion === 2) return "Incidencia";
                if (row.original.tipo_notificacion === 5) return "Incidencia comercial";
              },
            },
            {
              Header: "Perfil",
              headerStyle: { fontSize: 20 },
              Cell: (row) => {
                if (row.original.perfil) return row.original.perfil.nombre;
                return "---";
              },
            },
            {
              Header: "Acción",
              sortable: false,
              headerStyle: { fontSize: 20 },
              Cell: (row) => {
                return (
                  <Button
                    icon={"trash"}
                    basic
                    color={"red"}
                    onClick={() => this._delete(row.original)}
                  />
                );
              },
            },
          ]}
          manual
          data={this.state.settings}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />
      </div>
    );
  }
}

export default NotificationList;
