import React, { Component } from "react";
import { Grid, Header, Form, Button, Message } from "semantic-ui-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import moment from 'moment';
import { notify } from "react-notify-toast";

class GeneralReportContainer extends Component {
  constructor() {
    super();
    this.state = {
      initDate: '',
      endDate: '',
      loading: false,
    };
  }


  generateReport = async () => {
    if(this.state.initDate && this.state.endDate){
      if (this.state.initDate > this.state.endDate) {
        return notify.show('Fecha inicio no puede ser mayor a fecha fin', 'info', 5000);
  
      }
    }
    this.setState({
      loading: true
    })
    //const URl_API = `http://127.0.0.1:8000/api/rodillos/export?initDate=${this.state.initDate}&endDate=${this.state.endDate}`;
    const URl_API = `https://facturacion.bingham.acsyt.com/api/rodillos/export?initDate=${this.state.initDate}&endDate=${this.state.endDate}`;
    fetch(URl_API)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          loading: false
        })
        window.open(`https://facturacion.bingham.acsyt.com/storage/${data[0]}`, "_blank")
        console.log(data[0], 'data nueva fetch')
      })
      .catch((error) => {
        this.setState({
          loading: false
        })
        return notify.show('Error al generar reporte', 'info', 5000);
      })
    // try {
    //   //console.log(this.state.initDate, this.state.endDate, 'dates')

    // } catch (error) {
    //   this.setState({
    //     loading: false
    //   })
    //   console.log(error)
    // }
  }

  downloadReport = async () => {
    //window.open('http://127.0.0.1:8000/storage/reporte-tareas.xlsx', "_blank")
    window.open('https://facturacion.bingham.acsyt.com/storage/reporte-tareas.xlsx', "_blank")
  }

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header
                as="h2"
                icon="calendar check outline"
                content="Reporte General de Tareas"
              />
            </Grid.Column>
          </Grid.Row>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '80%' }}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
                <Form size="large">
                  <Form.Group>
                    <Form.Field >
                      <label>Fecha Inicio</label>
                      <DayPickerInput
                        value={this.state.initDate}
                        format="DD-MM-YYYY"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        onDayChange={(date) => {
                          this.setState({
                            initDate: moment(date).format('YYYY-MM-DD')
                          })
                        }}

                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Fecha Fin</label>
                      <DayPickerInput
                        value={this.state.endDate}
                        format="DD-MM-YYYY"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        onDayChange={(date) => {
                          this.setState({
                            endDate: moment(date).format('YYYY-MM-DD')
                          })
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: "15px" }}>
                <Button
                  size="tiny"
                  color="blue"
                  onClick={() => this.generateReport()}
                  loading={this.state.loading}
                  //disabled={!this.state.initDate || !this.state.endDate}
                >
                  Generar Reporte
                </Button>
              </div>
              {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: "15px" }}>
                <Button
                  size="tiny"
                  color="blue"
                  onClick={() => this.downloadReport()}
                >
                  Descargar último reporte generado
                </Button>
              </div> */}
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: "15px" }}>
                <Message info>
                  <span>El tiempo en generar un reporte puede tomar un par de minutos dependiendo del rango de fechas seleccionado. Si no selecciona fechas por defecto se descargará el reporte del mes actual. </span>
                </Message>
              </div>
            </div>
          </div>
        </Grid>
      </div>
    );
  }
}

export default GeneralReportContainer
