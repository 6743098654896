import React from "react";
import { Tab, Grid, Header } from "semantic-ui-react";
import ProvidersList from "../components/Providers/ProvidersList";
import ProvidersForm from "../components/Providers/ProvidersForm";

export default class ProvidersContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      currIndex: 0,
    };

    this.panes = [
      {
        menuItem: { key: "list", icon: "list", content: "Lista" },
        pane: (
          <Tab.Pane
            style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
            key={1}
          >
            <ProvidersList  ref={ref => this.list = ref}/>
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: "add", icon: "add circle", content: "Nuevo" },
        pane: (
          <Tab.Pane
            style={{ boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)" }}
            key={2}
          >
            <ProvidersForm onCreate={this.onCreate}/>
          </Tab.Pane>
        ),
      },
    ];
  }

  setTab = (index) => {
    this.setState({
      currIndex: index || 0,
    });
  };

  onCreate = () => {
    this.list.getWrappedInstance().fetchData();
  }

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as="h2" icon="address card outline" content="Proveedores" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Tab
                menu={{ secondary: true, pointing: true, color: "red" }}
                panes={this.panes}
                renderActiveOnly={false}
                activeIndex={this.state.currIndex}
                onTabChange={(event, data) => this.setTab(data.activeIndex)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
