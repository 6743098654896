import React, { Component } from "react";
import Feathers from "../redux/FeathersRedux";
import { Grid, Header, Image, Button } from "semantic-ui-react";
import { push } from "react-router-redux";
import { connect } from "react-redux";

class CalendarTaskIsoReportContainer extends Component {
  constructor() {
    super();
    this.state = {
      codes: [],
      loading: false,
    };
  }

  fetchData = () => {
    Feathers.service("codigos_iso")
      .find({
        query: {
          activo: true,
        },
      })
      .then((res) => {
        this.setState({
          codes: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleTaskData = (id) => {
    this.props.dispatch(push(`/reports/isoreports/${id}`));
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              
              <Header
                as="h2"
                icon="calendar check outline"
              >
                <Button
                    onClick={() =>
                      this.props.dispatch(push("/reports/calendartaskreport"))
                    }
                    icon="arrow left"
                    style={{ backgroundColor: "transparent", color: "#000000" }}
                  />
                  Codigos norma ISO 9000
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            {this.state.codes.map((item) => (
              <Grid.Column key={item.id}>
                <section  style={{ marginBottom: "3rem" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "1rem" }}>
                      <Image
                        src={require("../resources/images/carpeta.svg")}
                        size="tiny"
                      />
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <h4 style={{ marginBottom: "5px" }}>{`${item.document} - ${item.code}`}</h4>
                      <Button
                        basic
                        size="tiny"
                        color="grey"
                        onClick={() => this.handleTaskData(item.id)}
                      >
                        Abrir carpeta
                      </Button>
                    </div>
                  </div>
                </section>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(CalendarTaskIsoReportContainer);
