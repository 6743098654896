import React, { Component } from "react";
import { Button, Form, Grid, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import { notify } from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";
import swal from "sweetalert2";

class GluesEditModal extends Component {
  state = {
    pegamento: {
      nombre: "",
      descripcion: "",
    },
    toEdit: 0,
    loading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.pegamento) {
      this.setState({
        pegamento: {
          nombre: nextProps.pegamento.nombre,
          descripcion: nextProps.pegamento.descripcion,
        },
        toEdit: nextProps.pegamento.id,
      });
    }
  }

  handleSubmit = (_) => {
    if (!this.state.pegamento.nombre) {
      notify.show("Hace falta el nombre", "error", 2000);
      swal({
        type: "error",
        title: "Oops...",
        text: "Hace falta el nombre",
      });
      return;
    }
    // if (!this.state.pegamento.descripcion) {
    //   notify.show('Hace falta el descripción', 'error', 2000);
    //   swal({
    //     type: 'error',
    //     title: 'Oops...',
    //     text: 'Hace falta el descripción',
    //   })
    //   return;
    // }
    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("pegamento")
          .patch(this.state.toEdit, {
            ...this.state.pegamento,
          })
          .then(async (res) => {
            await this.setState({
              loading: false,
            });
            await this.props.onEdit();
            await this.props.onClose();
          })
          .catch((err) => {
            notify.show("Se produjo un error", "error");
            this.setState({ loading: false });
          });
      }
    );
  };

  render() {
    return (
      <Modal open={this.props.open} size={"large"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          Editar
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content scrolling>
          <Form size={"large"}>
            <Grid>
              <Grid.Row columns={2} divided>
                <Grid.Column>
                  <Grid.Row>
                    <Form.Input
                      label="Nombre"
                      name="nombre"
                      value={this.state.pegamento.nombre}
                      type="text"
                      placeholder="Pegamente"
                      onChange={(e, { name, value }) =>
                        this.setState({
                          pegamento: { ...this.state.pegamento, [name]: value },
                        })
                      }
                      required
                    />
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Grid.Row>
                    <Form.Input
                      label="Descripción"
                      placeholder="Descripción"
                      type="text"
                      name="descripcion"
                      value={this.state.pegamento.descripcion}
                      onChange={(e, { name, value }) =>
                        this.setState({
                          pegamento: { ...this.state.pegamento, [name]: value },
                        })
                      }
                      required
                    />
                    <Button
                      disabled={this.state.loading}
                      loading={this.state.loading}
                      type="button"
                      onClick={this.handleSubmit}
                      floated="right"
                      positive
                      content="ACTUALIZAR"
                      icon="send"
                      labelPosition="left"
                    />
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

GluesEditModal.propTypes = {};

export default GluesEditModal;
