import React, { Component } from "react";
import Feathers from "../../../redux/FeathersRedux";
import swal from "sweetalert2";
import moment from "moment";
import TaskContainer from "../../../components/Tasks/Unmount/UnmountTask";
import { notify } from "react-notify-toast";
import { connect } from "react-redux";
import LoaderData from "../../LoaderData";

class UnmountContainerTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      task: null,
    };

    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.initTask = this.initTask.bind(this);
    this.cancelTaskByIncidence = this.cancelTaskByIncidence.bind(this);
    this.viewTask = this.viewTask.bind(this);
    this.goBack = this.goBack.bind(this);
    this.updateTask = this.updateTask.bind(this);
  }

  updateTask() {
    Feathers.service("desmonte")
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  componentDidMount() {
    this.checkActiveTasks();
  }

  goBack() {
    this.props.history.goBack();
  }

  checkActiveTasks() {
    // Feathers.service("desmonte")
    //   .find({
    //     query: {
    //       usuario_id: this.props.user.id,
    //       status_tarea_id: 1,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     if (res.total > 0) {
    //       this.setState({
    //         task: res.data[0],
    //         ready: true,
    //       });
    //     } else {
    //       this.setState({
    //         ready: true,
    //       });
    //     }
    //   });
    Feathers.service("desmonte")
      .get(this.props.match.params.id)
      .then((task) => {
        const userId = this.props.user.id;
        const taskUserId = task.usuario ? task.usuario.id : null;
        console.log(userId, taskUserId, "ids");
        if (taskUserId && taskUserId !== userId) {
          swal({
            title:
              "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
            type: "warning",
            confirmButtonText: "Aceptar",
          });
          this.goBack();
        } else {
          this.setState({ task });
        }
      });
  }

  viewTask() {
    Feathers.service("desmonte")
      .get(this.props.match.params.id)
      .then((task) => {
        const userId = this.props.user.id;
        const taskUserId = task.usuario ? task.usuario.id : null;
        console.log(userId, taskUserId, "ids");
        if (taskUserId && taskUserId !== userId) {
          swal({
            title:
              "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
            type: "warning",
            confirmButtonText: "Aceptar",
          });
          this.goBack();
        } else {
          this.setState({ task });
        }
      });
  }

  initTask(task) {
    swal({
      title: "¿Iniciar tarea?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (res) => {
      if (res.value) {
        const validate = await Feathers.service("desmonte").get(
          this.state.task.id
        );
        if (validate.hora_inicio) {
          swal({
            title:
              "Esta tarea ya fue iniciada por otro usuario, seleccione otra...",
            type: "warning",
            confirmButtonText: "Aceptar",
          });
          this.goBack();
        } else {
          Feathers.service("desmonte")
            .patch(task.id, {
              hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
              status_tarea_id: 1,
              usuario_id: this.props.user.id,
            })
            .then((res) => {
              Feathers.service("desmonte")
                .get(res.id)
                .then((task) => this.setState({ task }));
            });
        }
      }
    });
  }

  finishTask() {
    swal({
      title: "¿Finalizar tarea de desmonte?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        Feathers.service("desmonte")
          .patch(this.state.task.id, {
            hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_tarea_id: 2,
          })
          .then(async (res) => {
            const checkNotification = await Feathers.service(
              "notification_tasks"
            ).get(8);
            if (checkNotification.activo) {
              Feathers.service("notificacion").create({
                mensaje: `Se ha finalizado una tarea de desmonte #${this.state.task.id}`,
                perfil_id: "1",
                fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 4,
              });
            }
            this.goBack();
          });
      }
    });
  }

  cancelTaskByIncidence(incidencia_id) {
    Feathers.service("desmonte")
      .patch(this.state.task.id, {
        incidencia_id,
        status_tarea_id: 3,
        hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        console.log(res);

        this.setState({
          task: null,
        });
        notify.show("Incidencia registrada.", "success");
      });
  }

  render() {
    if (this.state.task) {
      return (
        <TaskContainer
          goBack={this.goBack}
          initTask={this.initTask}
          finishTask={this.finishTask}
          task={this.state.task}
          updateTask={this.updateTask}
          cancelTaskByIncidence={this.cancelTaskByIncidence}
        />
      );
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(UnmountContainerTask);
