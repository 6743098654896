import React, { Component } from "react";
import {
  Grid,
  Segment,
  Icon,
  Header,
  Container,
  Button,
} from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import Config from "../../config";
import swal from "sweetalert2";
import ReactTable from "react-table";


export default class DocumentsFolderReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      total: 0,
      pageSize: 10,
      page: 0,
      loading: false,
    };
  }

  fetchData = async () => {
    const idFolder = this.props.id;
    await Feathers.service("documentos_bingham").find({
      query: {
        folder_id: idFolder
      },
    })
    .then((res) => {
      this.setState({
        documents: res.data
      })
    })
  };

  removeDocument = (document) => {
    swal({
      title: "¿Eliminar este documento?",
      text: "Se eliminará el documento",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        this.setState({ loading: true });
        await Feathers.service("documentos_bingham").remove(document.id);
        swal({
          title: "Exito",
          text: "Se eliminó con éxito el documento",
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        this.fetchData();
        this.setState({ loading: false})
      }
    });
  };


  render() {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Container style={{ margin: 20 }}>
                <Segment>
                  <Header as="h2">
                    <Icon name="pdf file outline" />
                    <Header.Content>
                      Documentos
                      <Header.Subheader>
                        Listado de documentos guardados en esta carpeta
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <ReactTable
                    columns={[
                      {
                        Header: "Documentos",
                        id: "document",
                        accessor: (d) => {
                          return (
                            <a
                              target="_blank"
                              href={`${Config.apiUrl}/documents/bingham/${d.document}`}
                            >
                              {d.document}
                            </a>
                          );
                        },
                      },
                      {
                        Header: "Acciones",
                        Cell: (row) => (
                          <Button
                            size="tiny"
                            content="Eliminar"
                            compact
                            basic
                            color="red"
                            icon="delete"
                            onClick={() => this.removeDocument(row.original)}
                          />
                        ),
                      },
                    ]}
                    manual
                    data={this.state.documents}
                    loading={this.state.loading}
                    pages={Math.ceil(this.state.total / 5)}
                    onFetchData={this.fetchData}
                    defaultPageSize={10}
                    style={{ textAlign: "center", marginTop: "1rem" }}
                  />
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}
