import React, {Component} from 'react';
import {Button, Form, Grid, Image, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {notify} from "react-notify-toast";
import Feathers from "../../redux/FeathersRedux";
import swal from 'sweetalert2';
import { Dropzone } from '../Dropzone'
import  Config  from '../../config/index'


class DrawEditModal extends Component {


    state = {
        draw: {
            nombre:"",
        },
        toEdit: 0,
        loading: false,
        files: [],
        image : null,
        delete_image: false,
    };

    onImageDrop(files, rejectedFiles) {
      this.setState({ files })
    }

    onDropChange = (image) => {
      this.setState({ image })
    }

    renderDropContent(){
      if (!this.state.files.length) return (
        <p>Drop an image or click to select a file to upload.</p>
      )

      return (
        <Image src={this.state.files[0].preview} style={{ height: 130, width: 'auto'}} />
      )
    }

    toDataURL = async (url) => {
      return new Promise( (resolve,reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      } );
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.draw) {
            this.setState({
                draw: {
                    nombre: nextProps.draw.nombre,
                },
                toEdit: nextProps.draw.id,
            })

            if(nextProps.draw.imagen != null){
              let image_to_load = {}
              let formatedImage = nextProps.draw.imagen[0] === "/" ? nextProps.draw.imagen : "/" + nextProps.draw.imagen;
              image_to_load["preview"] = Config.apiUrl + formatedImage;
              image_to_load["base64"] = this.toDataURL(Config.apiUrl + formatedImage);
              this.setState({
                image: image_to_load
              })
            }
        }

    }

    handleSubmit = _ =>{
        if (!this.state.draw.nombre) {
            swal({
                type: 'error',
                title: 'Oops...',
                text: 'Registre todos los campos.',
            });
            return;
        }

        this.setState({
            loading: true
        }, () => {
            Feathers.service('dibujo').patch( this.state.toEdit , {
                ...this.state.draw,
                //image: this.state.image.base64
            }).then(async res => {
                await this.setState({
                    loading: false
                });
                await this.props.onEdit();
                await this.props.onClose();
            }).catch(err => {
                notify.show('Se produjo un error', 'error');
                this.setState({ loading: false })
                console.log(err)
            })
        })
    };

    /* deleteImage = ()=>{
      this.setState({delete_image:true});
    } */

    render() {
        return (
            <Modal open={this.props.open}  size={'large'} >
                <Modal.Header style={{backgroundColor: "#f3f3f3"}} >Editar
                <Button circular size='mini' basic icon='close' floated='right' onClick={this.props.onClose}></Button>
                </Modal.Header>
                <Modal.Content scrolling>
                    <Form size={'large'}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Grid.Row>
                                        <Form.Input label='Nombre' name='nombre' value={this.state.draw.nombre} type='text' placeholder='Nombre' onChange={(e, {name, value}) => this.setState({ draw: {...this.state.draw, [name] : value} })} required/>
                                    </Grid.Row>
                                    <Grid.Row>
                                        {/* <Form.Field required>
                                          <label> Imagen </label>
                                          <Dropzone onChange={this.onDropChange} image={this.state.image} />
                                        </Form.Field> */}
                                        <Button style={{
                                            marginTop: 10,
                                        }} disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='ACTUALIZAR' icon='send' labelPosition='left' />
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}

DrawEditModal.propTypes = {};

export default DrawEditModal;
