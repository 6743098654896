import React, { Component } from 'react';
import { Header, Segment, Grid, Icon, Form, Button, Divider, Checkbox, Modal } from 'semantic-ui-react'
import { TaskOrderSegment, TaskStatusSegment, TaskItemSegment} from '../'
import Feathers from '../../../redux/FeathersRedux';
import Incidence from '../../Incidence/Incidence'
import swal from 'sweetalert2'


const SERVICE = 'control_calidad';
const PHASE = 8;

export default class QATask extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // esMancheta: 0,
            producto_id: this.props.task.rodillo.partida.producto_id,
            modalOpened: false,
            dn: null,
            de: null,
            lc: null,
            lt: null,
            mancheta: {
                perimetro: null,
                espesor: null,
                altura: null,
                peso: null
            },
            checkitems : {
                espigas: false,
                rectificado: false,
                acabado: false,
                globos: false,
                desprendimiento_de_nucleo: false,
                dureza: false,
                color: false,
                dn: false,
                de: false,
                lc: false,
                lt: false,
                limpieza: false,
            },
            descriptionToIncidence : ''
        }

        this.renderForm = this.renderForm.bind(this)
        this.saveInfo = this.saveInfo.bind(this)
        this.finishTask = this.finishTask.bind(this)
        
    }

    async componentDidMount(){
        // await this.checkIfMancheta()
        await this.fetchPeso();
    }

    fetchPeso = async () => {
        let corte  = await Feathers.service('corte').find({
            query : {
                rodillo_id: this.props.task.rodillo_id,
                $limit: '-1',
                peso_despues: {
                    $ne: null
                } 
            }
        })


        if(corte.length > 0){
            this.setState({
                mancheta: {
                    ...this.state.mancheta,
                    peso: corte[0].peso_despues
                }
            })
        }
    }

    // checkIfMancheta = async () => {
    //     await this.setState({
    //         esMancheta: this.props.task.rodillo.esMancheta
    //     })
    // }

    openModal = ()  => {
        this.setState({
            modalOpened: true,
        });
    }


    closeModal= () =>  {
        this.setState({
            modalOpened: false
        })
    }

    renderInitButton = () => {
        if (this.props.task.hora_inicio) return null;
        return (
            <div style={{marginTop: 20}}>
                <Button onClick={() => this.props.initTask(this.props.task)}  icon='play' fluid color="green" content='Iniciar' />
             </div>
        )
    }

    async finishTask() {
        if( [1, 4].indexOf(this.state.producto_id) !== -1){
            if (!this.props.task.de || !this.props.task.lc ) return swal({
                title: 'Atención',
                text: 'Primero debes capturar la información pendiente para finalizar la tarea',
                type: 'info',
               })
    
            
            const dn_introducido = this.props.task.dn
            const de_introducido = this.props.task.de
            const lc_introducido = this.props.task.lc
            const lt_introducido = this.props.task.lt

            // vamos a traer los valores que deben ser
            const dn_esperado = this.props.task.rodillo.partida.dn
            const de_esperado = this.props.task.rodillo.partida.de
            const lc_esperado = this.props.task.rodillo.partida.lc
            const lt_esperado = this.props.task.rodillo.partida.lt

            // ya tenemos los valores que deben ser

            // vamos a traer las holguras

            let holgura_dn = await Feathers.service('configuraciones').find({
              query: {
                nombre: 'holgura_dn'
              }
            })
          
            holgura_dn = +(holgura_dn.data[0].valor)


            let holgura_de = await Feathers.service('configuraciones').find({
              query: {
                nombre: 'holgura_de'
              }
            })
          
            holgura_de = +(holgura_de.data[0].valor)


            let holgura_lc = await Feathers.service('configuraciones').find({
              query: {
                nombre: 'holgura_lc'
              }
            })
          
            holgura_lc = +(holgura_lc.data[0].valor)


            let holgura_lt = await Feathers.service('configuraciones').find({
              query: {
                nombre: 'holgura_lt'
              }
            })
          
            holgura_lt = +(holgura_lt.data[0].valor)

            // ya tenemos todas las holguras
            

            // ! esto lo comentamos porque don maynez nos dijo que quitaramos la captura de dn para rodillos en control de calidad en el correo del 09 de abril 2019
            // if(+(dn_introducido) <  +(dn_esperado - holgura_dn) || +(dn_introducido) >  dn_esperado ){
            //   await swal({
            //     type: 'error',
            //     title: 'Oops...',
            //     text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
            //   })
            //   await this.incidence.getWrappedInstance().openFromOutside()
            //   return
            // }

            if(+(de_introducido) <  +(de_esperado - holgura_de) || +(de_introducido) >  de_esperado ){
              await swal({
                type: 'error',
                title: 'Oops...',
                text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
              })
              await this.incidence.getWrappedInstance().openFromOutside()
              return
            }
            
            if(+(lc_introducido) <  +(lc_esperado - holgura_lc) || +(lc_introducido) >  lc_esperado ){
              await swal({
                type: 'error',
                title: 'Oops...',
                text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
              })
              await this.incidence.getWrappedInstance().openFromOutside()
              return
            }

            // !Esto se comenta por petición de Ricardo en ya no ingrear el largo total en control de calidad 
            /* if(+(lt_introducido) <  +(lt_esperado - holgura_lc) || +(lt_introducido) >  lt_esperado ){
              await swal({
                type: 'error',
                title: 'Oops...',
                text: 'Los valores no conciden. Se tendrá que levantar una incidencia',
              })
              await this.incidence.getWrappedInstance().openFromOutside()
              return
            } */


            if(Object.values(this.state.checkitems).some(v => v === false)) {
              this.openModal()
              return 
            }
        }

        if([5].indexOf(this.state.producto_id) !== -1){
            if (!this.props.task.perimetro || !this.props.task.espesor|| !this.props.task.altura || !this.props.task.peso) return swal({
                title: 'Atención',
                text: 'Primero debes capturar la información pendiente para finalizar la tarea',
                type: 'info',
            });

            let task_parte = this.props.task.rodillo.partida.mancheta.parte

            let query = {
                $limit: '-1',
                parte: task_parte
            };


            let mancheta = await Feathers.service('manchetas').find({
                query
            })

            let manchetaOriginal = JSON.parse(JSON.stringify(mancheta[0]))


            //TODO: Definir bien cual es la condición para ingresar los datos de la mancheta:
            if(this.props.task.perimetro >= manchetaOriginal.perimetro_min &&
                this.props.task.perimetro <= manchetaOriginal.perimetro_max &&
                this.props.task.espesor >= manchetaOriginal.espesor_min &&
                this.props.task.espesor <= manchetaOriginal.espesor_max &&
                this.props.task.altura >= manchetaOriginal.altura_min &&
                this.props.task.altura <= manchetaOriginal.altura_max){ 

            //if(this.props.task.perimetro >= manchetaOriginal.perimetro_min){

            }else{

                await swal({
                    type: 'error',
                    title: 'Oops...',
                    text: 'No pasa el control de calidad. Se tendrá que levantar una incidencia',
                  })

                  await this.incidence.getWrappedInstance().openFromOutside()

                /* // await Feathers.service("control_calidad").patch(this.props.task.id, {
                //     hora_fin: moment().format('YYYY-MM-DD HH:mm:ss'),
                //     status_tarea_id: 3
                // })

                // await Feathers.service("revision").create({
                //     pedido_id: this.props.task.rodillo.partida.pedido_id,
                //     status_tarea_id : 1,
                //     rodillo_id: this.props.task.rodillo.id
                // })

                // this.props.closeTask && this.props.closeTask() */
                return
            }
            


        }

        this.props.finishTask && this.props.finishTask();
    }


    renderCheckList = () => {
        if (!this.props.task.hora_inicio) return null;
        // if (this.state.esMancheta === 1) return null;
        if([1, 4].indexOf(this.state.producto_id) !== -1){
          return(
            <div>
              <Header as='h3' attached="top" inverted style={{paddingTop: '5px', marginBottom: '5px' ,backgroundColor: "#393e46", borderRadius: "10px"}}>
                <Icon name='check' />
                <Header.Content>CheckList</Header.Content>
              </Header>
              <div >
                <Segment size={"large"}>
                  <Form>
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox  label="Espigas"  name="espigas" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                espigas: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='Rectificado'  name="rectificado" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                rectificado: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='Acabado'  name="acabado" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                acabado: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='Globos' name="globos" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                globos: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='Desprendimiento de núcleo'  name="desprendimiento_de_nucleo" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                desprendimiento_de_nucleo: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='Dureza'  name="dureza" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                dureza: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='Color'  name="color" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                color: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='DN' name="dn" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                dn: data.checked
                              }
                            })
                          }}/>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='DE'  name="de" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                de: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='LC'  name="lc" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                lc: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='LT'  name="lt" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                lt: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                          <Checkbox label='Limpieza'  name="limpieza" onChange={async (event, data) => {
                            await this.setState({
                              checkitems : {
                                ...this.state.checkitems,
                                limpieza: data.checked
                              }
                            })
                          }} />
                        </Grid.Column>
                      </Grid.Row>

                    </Grid>
                  </Form>
                </Segment>
              </div>
            </div>
          )
        }
        return null;
    };

    renderManchetasForm = () => {
        if (!this.props.task.hora_inicio) return null;
        // if(this.state.esMancheta === 0) return null;
        if([5].indexOf(this.state.producto_id) !== -1){
          if(!this.props.task.perimetro){
            return (
              <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Segment size={"large"}>
                  <Form>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column computer={3} tablet={3} mobile={16}>
                          <Form.Input name="perimetro" onChange={(e, d) => {
                            this.setState({
                              mancheta: {
                                ...this.state.mancheta,
                                perimetro: d.value
                              }
                            })
                          }} type="number" label='Perímetro' placeholder='Perímetro'/>
                        </Grid.Column>
                        <Grid.Column computer={3} tablet={3} mobile={16}>
                          <Form.Input name="espesor"  onChange={(e, d) => {
                            this.setState({
                              mancheta: {
                                ...this.state.mancheta,
                                espesor: d.value
                              }
                            })
                          }} type="number" label='Espesor' placeholder='Espesor' />
                        </Grid.Column>
                        <Grid.Column computer={3} tablet={3} mobile={16}>
                          <Form.Input name="altura" onChange={(e, d) => {
                            this.setState({
                              mancheta: {
                                ...this.state.mancheta,
                                altura: d.value
                              }
                            })
                          }} type="number" label='Altura' placeholder='Altura'/>
                        </Grid.Column>
                        <Grid.Column computer={3} tablet={3} mobile={16}>
                          <Form.Input name="peso" onChange={(e, d) => {
                            // this.setState({
                            //     mancheta: {
                            //         ...this.state.mancheta,
                            //         peso: d.value
                            //     }
                            // })
                          }} type="number" label='Peso' placeholder='Peso' value={this.state.mancheta.peso} />
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={4} mobile={16}>
                          <Button type="button" onClick={this.saveInfo} disabled={!this.state.mancheta.perimetro || !this.state.mancheta.espesor || !this.state.mancheta.altura || !this.state.mancheta.peso} fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR' />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Segment>
              </div>
            )
          }
          return(
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
              <Segment size={"large"}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={4} tablet={4} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>Perímetro</span>
                      <span>{this.props.task.perimetro}</span>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={4} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>Espesor</span>
                      <span>{this.props.task.espesor}</span>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={4} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>Altura</span>
                      <span>{this.props.task.altura}</span>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={4} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>Peso</span>
                      <span>{this.props.task.peso}</span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>
          )
        }

        return null;
    };

    renderForm() {
        if (!this.props.task.hora_inicio) return null;
        // if(this.state.esMancheta === 1) return null;
        if([ 1, 4 ].indexOf(this.state.producto_id) !== -1){
          if (!this.props.task.de) {
            return (
              <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
                <Segment size={"large"}>
                  <Form>
                    <Grid>
                      <Grid.Row>
                        {/*<Grid.Column computer={3} tablet={3} mobile={16}>
                          <Form.Input name="dn" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DN' placeholder='Diámetro de núcleo'/>
                          </Grid.Column>*/}
                        <Grid.Column computer={3} tablet={3} mobile={16}>
                          <Form.Input name="de" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='DE' placeholder='Diámetro exterior'/>
                        </Grid.Column>
                        <Grid.Column computer={3} tablet={3} mobile={16}>
                          <Form.Input name="lc" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='LC' placeholder='Largo de cara'/>
                        </Grid.Column>
                        {/* <Grid.Column computer={3} tablet={3} mobile={16}>
                          <Form.Input name="lt" onChange={(e, {name, value}) => this.setState({ [name]: value })} type="number" label='LT' placeholder='Largo total'/>
                        </Grid.Column> */}
                        <Grid.Column computer={4} tablet={4} mobile={16}>
                          <Button type="button" onClick={this.saveInfo} disabled={ !this.state.de || !this.state.lc} fluid style={{marginTop: '25px'}} icon='check' positive content='REGISTRAR' />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Segment>
              </div>
            )
          }

          return (
            <div style={{margin: 'calc(2rem - .14285714em) 0 1rem'}}>
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={4} tablet={4} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>DE</span>
                      <span>{this.props.task.de}</span>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={4} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>LC</span>
                      <span>{this.props.task.lc}</span>
                    </Grid.Column>
                    {/* <Grid.Column computer={4} tablet={4} mobile={16}>
                      <span style={{display: 'block', fontWeight: 'bold'}}>LT</span>
                      <span>{this.props.task.lt}</span>
                    </Grid.Column> */}
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>
          );
        }

        return null;
    }

     saveInfo() {
        swal({
            title: '¿Registrar información?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            reverseButtons: true
        })
            .then(async res => {
                if (!res.value) return;
                
                await Feathers.service(SERVICE).patch(this.props.task.id, {
                    dn: this.state.dn,
                    de: this.state.de,
                    lc: this.state.lc,
                    lt: this.state.lt,
                    perimetro: this.state.mancheta.perimetro,
                    espesor: this.state.mancheta.espesor,
                    altura: this.state.mancheta.altura,
                    peso: this.state.mancheta.peso
                })

                this.props.updateTask && this.props.updateTask();
                this.finishTask();
            })
    }

    render() {
        return (
            <div>
                <Modal size='small' open={this.state.modalOpened} onClose={this.state.closeModal}>
                    <Header icon='warning sign' content='¿Estás seguro?' />
                    <Modal.Content>
                    <p>No has revisado todos los campos del checklist</p>
                    </Modal.Content>
                    <Modal.Actions>
                    
                    <Button basic color='red'  onClick={() => this.closeModal()}>
                        <Icon name='remove' /> Cancelar
                    </Button>
                    <Button basic color='red'  onClick={async () => {
                        await this.closeModal()
                        let checktopass = await JSON.parse(JSON.stringify(this.state.checkitems))
                        let arraycheck = await Object.keys(checktopass).filter( function (key) {  
                            if(checktopass[key] === false){
                                return key;
                             }
                        });

                        await this.setState({
                            descriptionToIncidence: arraycheck.join(',\n')
                        })
                        await this.incidence.getWrappedInstance().openFromOutside()
                    }}>
                        <Icon name='warning' /> Reportar Incidencia
                    </Button>
                    <Button color='green'  onClick={ async() => {
                        await this.closeModal()
                        this.props.finishTask && this.props.finishTask()
                    }}>
                        <Icon name='checkmark' />  Continuar
                    </Button>
                    </Modal.Actions>
                </Modal>
                <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Header as='h2'>
                        <Button onClick={this.props.goBack} icon="arrow left" style={{backgroundColor: 'transparent', color: '#000000de'}}/>
                            Tarea de Control de Calidad
                        </Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Incidence ref={ref => this.incidence = ref} phase={PHASE} desc={this.state.descriptionToIncidence} task={this.props.task} cancelTaskByIncidence={this.props.cancelTaskByIncidence} rodillo={this.props.task.rodillo_id} pedido={this.props.task.rodillo.partida.pedido_id}  />
                    </Grid.Column>
                </Grid.Row>
                </Grid>
                <Divider />    
                {this.props.task.hora_inicio && ( 
                <TaskStatusSegment task={this.props.task} finishTask={this.finishTask}></TaskStatusSegment>)}
                
                <TaskOrderSegment order={this.props.task.rodillo.partida.pedido} kilos={this.props.task.rodillo.partida.kilos} />

                <Header as='h3' attached="top" inverted style={{paddingTop: '5px', paddingBottom: '5px', backgroundColor: "#393e46", borderRadius: "10px"}}>
                    <Icon name='list' />
                    <Header.Content >Información de la partida</Header.Content>
                </Header>
                <TaskItemSegment roller={this.props.task.rodillo.id} item={this.props.task.rodillo.partida} />
                
                {this.renderForm()}
                {this.renderInitButton()}
                {this.renderCheckList()}
               
                <this.renderManchetasForm />

            </div>
        )
    }
}
