export default {
    1: "Revisión",
    2: "Molino",
    3: "Desviste",
    4: "Construcción",
    5: "Vulcanizado",
    6: "Desbaste",
    7: "Rectificado",
    8: "Control de Calidad",
    9: "Desmonte",
    10: "Corte"
}