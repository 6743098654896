import React, { Component } from "react";
import PropTypes from "prop-types";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import {
  Button,
  Image,
  Grid,
  Form,
  Search,
  Label,
  Popup,
} from "semantic-ui-react";
import Toggle from "react-toggle";
import DrawEditModal from "./DrawEditModal";
import { ImageModal } from "../../components/ImageModal";
import Config from "../../config/index";
import { push } from "react-router-redux";
import { connect } from "react-redux";

const renderNameSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

class DrawList extends Component {
  state = {
    draws: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 10,
    currentRow: {},
    isEditOpened: false,
    isImageModalOpened: false,
    imageToShow: "",
    $sortDirection: 1,
    filterOptions: {
      id: null,
    },

    // search bar objects

    nombre: null,

    // search bar results

    nombreResults: null,

    // search bar loadings

    nombreLoadings: false,
    columna: "",
    sortDirection: false,
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  handleNameResultSelect = async (e, { result }) => {
    this.setState({ nombre: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleNameSearchChange = (e, { value }) => {
    this.setState({ nombreLoadings: true, nombre: value });

    setTimeout(() => {
      if (this.state.nombre.length < 1) return this.resetNameComponent();

      Feathers.service("dibujo")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            nombreLoadings: false,
            nombreResults: res.data,
          });
        });
    }, 500);
  };

  resetNameComponent = () =>
    this.setState({ nombreLoadings: false, nombreResults: [], nombre: "" });

  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.id !== null) {
      query["id"] = this.state.filterOptions.id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("dibujo")
        .find({
          query,
        })
        .then((res) => {
          this.handleSort(res)
          this.setState({
            draws: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        });
    });
  };

  /*     handleChange = (data, row) => {
          Feathers.service('dibujo').patch(row.original.id, {
              activo: data.checked ? 1: 0
          })
              .then(res => {
                  this.fetchData();
              })
  
      }; */

  handleChange(event, row) {
    Feathers.service("dibujo").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  openEditModal = (row) => {
    this.setState(
      {
        currentRow: row.original,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isEditOpened: true,
          });
        }, 100);
      }
    );
  };

  closeEditModal = (_) => {
    this.setState({
      isEditOpened: false,
    });
  };

  closeImageModal = (_) => {
    this.setState({
      isImageModalOpened: false,
    });
  };

  openImageModal = (image) => {
    let formatedImage = image[0] === "/" ? image : "/" + image;
    const url = Config.apiUrl + formatedImage;
    this.setState({
      imageToShow: url,
      isImageModalOpened: true,
    });
  };

  handleCleanButton = (_) => {
    this.resetNameComponent();

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  openUploadFiles = (row) => {
    const drawId = row.original.id;
    //this.props.dispatch(push(`/rawmaterials/${materiaId}`));
    this.props.dispatch(push(`/reports/drawFiles/${drawId}`));
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre</label>
                  <Search
                    icon=""
                    loading={this.state.nombreLoadings}
                    value={this.state.nombre}
                    results={this.state.nombreResults}
                    onResultSelect={this.handleNameResultSelect}
                    onSearchChange={this.handleNameSearchChange}
                    resultRenderer={renderNameSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            /*  { Header: "Nombre", accessor: "nombre" }, */
            {
              Header: "Nombre",
              Cell: (row) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <h4>{row.original.nombre}</h4>
                  </div>
                );
              },
            },
            /* { Header: "Imagen", Cell: row => {
              return (
                <div>
                <Image
                  src={Config.apiUrl + row.original.imagen}
                  style={{ height: "100px", margin: "0 auto" }}
                />
              </div>
              )
            }}, */
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Popup
                      content="Archivos"
                      trigger={
                        <Button
                          basic
                          size={"tiny"}
                          compact
                          color="teal"
                          icon="archive"
                          onClick={() => this.openUploadFiles(row)}
                        />
                      }
                    />
                    <Button
                      size={"tiny"}
                      compact
                      basic
                      color="blue"
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                    {/* <Checkbox toggle onClick={(e, data) => this.handleChange(data, row)} checked={row.original.activo === 1}/> */}
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.draws}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={100}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />

        <DrawEditModal
          open={this.state.isEditOpened}
          onEdit={() => this.fetchData(this.state)}
          draw={this.state.currentRow}
          onClose={this.closeEditModal}
        />
        <ImageModal
          open={this.state.isImageModalOpened}
          onClose={this.closeImageModal}
          image={this.state.imageToShow}
        />
      </div>
    );
  }
}

/* DrawList.propTypes = {};

export default DrawList; */

export default connect(null, null, null, {
  withRef: true,
})(DrawList);
