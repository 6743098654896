import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Icon, Button, Form} from 'semantic-ui-react'
import Feathers from '../../redux/FeathersRedux'
import {notify} from "react-notify-toast";

class FreightForm extends Component {
    state = {
        flete: {
            tipo_flete: '',
            precio : '',
            activo: 1
        },
        loading: false
    };


    handleSubmit = _ =>{
        if (!this.state.flete.tipo_flete) {
            notify.show('Hace falta el tipo de flete', 'error', 2000);
            return;
        }
        if (!this.state.flete.precio) {
            notify.show('Hace falta el precio', 'error', 2000);
            return;
        }
        this.setState({
            loading: true
        }, () => {
            Feathers.service('flete').create({
                ...this.state.flete
            }).then(res => {
                notify.show('Flete Creado', 'success');
                this.props.onCreate()

                this.setState({ loading: false, flete: {
                        tipo_flete: '',
                        precio : '',
                        activo: 1
                    }});

            }).catch(err => {
                notify.show('Se produjo un error', 'error');
                this.setState({ loading: false })
            })
        })
    };


    render() {
        return (
            <div>
                <Form size={'large'}>
                    <Grid>
                        <Grid.Row columns={2} divided>
                            <Grid.Column>
                                <Grid.Row>
                                    <Form.Input label='Tipo de flete' name='tipo_flete' value={this.state.flete.tipo_flete} type='text' placeholder='Tipo de flete' onChange={(e, {name, value}) => this.setState({ flete: {...this.state.flete, [name] : value} })} required/>
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column>
                                <Grid.Row>
                                    <Form.Input label='Precio' placeholder='Precio' type="number" name='precio' value={this.state.flete.precio} onChange={(e, {name, value}) => this.setState({ flete: {...this.state.flete, [name] : value} })}  required/>
                                    <Button disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='REGISTRAR' icon='send' labelPosition='left' />
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
        );
    }
}

FreightForm.propTypes = {};

export default FreightForm;
