import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Grid, Header, Form, Search, Label } from "semantic-ui-react";
import "react-table/react-table.css";
import Feathers from "../redux/FeathersRedux";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import "moment/locale/es";
import { formatDate, parseDate } from "react-day-picker/moment";
import { exportToXls } from "../utils/Utils";
import Toggle from "react-toggle";
import ReportFilter from "../components/FIlters/ReportFilter";

const renderClientSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);
const renderSearchSaeResults = ({ id, ref_sae }) => (
  <Label key={id} content={ref_sae} />
);

export default class FinishedProductReportContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rodillos: [],
      total: 0,
      page: 0,
      loading: false,
      pageSize: 100,
      modalOpened: false,
      editModalOpened: false,
      currentRow: {},
      shouldExport: false,
      filter: {
        initDay: "",
        lastDay: "",
        cliente_id: null,
        producto_id: null,
        ref_sae: null,
        rodilloValue: "",
        partidaValue: "",
        pedidoValue: "",
        statusValue: "",
      },
      filters: ["rodillo", "pedido", "partida", "range", "costumer", "product"],
      $sort: {
        $fechaEntregaSortDirection: null,
        $fechaTerminadoSortDirection: null,
        $saeSortDirection: null,
      },
      // search bar objects

      cliente: null,
      sae: null,

      // search bar results

      clienteResults: null,
      saeResults: null,

      // search bar loading

      clienteLoading: null,
      saeLoading: null,

      // dropdowns.

      producto_options: [],

      columna: "Fecha Entrega",
      sortDirection: true,
      showSae: false,
      tableColumns: [
        {
          Header: "SAE",
          id: "ref_sae",
          accessor: (d) => {
            if (d.ref_sae) {
              return d.ref_sae;
            }

            return "---";
          },
        },

        { Header: "Orden de Trabajo", id: "pedido", accessor: (d) => d.pedidoid },
        { Header: "Rodillo", id: "id", accessor: (d) => d.id },
        {
          Header: "Partida",
          id: "partidaid",
          accessor: (d) => d.partidaid,
        },
        {
          Header: "Cliente",
          id: "nombre",
          accessor: (d) => d.nombre,
        },
        {
          Header: "Fecha Entrega",
          id: "fecha_entrega",
          accessor: (d) => {
            return moment(d.fecha_entrega).format("YYYY-MM-DD");
          },
        },

        {
          Header: "Fecha Terminado",
          id: "fecha_terminado",
          accessor: (d) => {
            return d.fecha_terminado;
          },
        },
        {
          Header: "Producto",
          id: "producto",
          accessor: (d) => d.producto,
        },
        { Header: "Dureza", id: "dureza", accessor: (d) => d.dureza },

        { Header: "Kilos", id: "kilos", accessor: (d) => d.kilos },
        { Header: "Precio", id: "precio", accessor: (d) => d.precio },
        {
          Header: "Costo Interno",
          id: "costo_interno",
          accessor: (d) => {
            if (d.costo_interno) {
              return d.costo_interno;
            } else {
              return "---";
            }
          },
        },
      ],
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleSaeToggle = this.handleSaeToggle.bind(this);
  }

  handleSaeToggle = () => {
    this.setState(
      {
        showSae: !this.state.showSae,
      },
      () => {
        console.log(this.state);
      }
    );
    console.log(this.state.showSae);
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "SAE":
        array.data.sort((a, b) => {
          return a.ref_sae - b.ref_sae;
        });
        break;
      case "Cliente":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "#Partida":
        array.data.sort((a, b) => {
          return parseInt(a.partidaid) - parseInt(b.partidaid);
        });
        break;
      case "#Unidad":
        array.data.sort((a, b) => {
          return parseInt(a.id) - parseInt(b.id);
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A = a.dureza ? parseInt(a.dureza) : 0;
          let B = b.dureza ? parseInt(b.dureza) : 0;
          return A - B;
        });
        break;
      case "Producto":
        array.data.sort((a, b) => {
          const nameA = a.producto.toUpperCase(); // ignore upper and lowercase
          const nameB = b.producto.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Kilos":
        array.data.sort((a, b) => {
          return parseInt(a.kilos) - parseInt(b.kilos);
        });
        break;
      case "Precio":
        array.data.sort((a, b) => {
          return parseInt(a.precio) - parseInt(b.precio);
        });
        break;
      case "Costo Interno":
        array.data.sort((a, b) => {
          return parseInt(a.costo_interno) - parseInt(b.costo_interno);
        });
        break;
      case "Fecha Entrega":
        array.data.sort((a, b) => {
          const nameA = a.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          const nameB = b.fecha_entrega.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Fecha Terminado":
        array.data.sort((a, b) => {
          const nameA = a.fecha_terminado.toUpperCase(); // ignore upper and lowercase
          const nameB = b.fecha_terminado.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  onRodilloChange = (rodillo) => {
    this.setState({
      filter: {
        ...this.state.filter,
        rodilloValue: rodillo,
      },
    });
  };

  onPartidaChange = (partida) => {
    this.setState({
      filter: {
        ...this.state.filter,
        partidaValue: partida,
      },
    });
  };

  onPedidoChange = (pedido) => {
    this.setState({
      filter: {
        ...this.state.filter,
        pedidoValue: pedido,
      },
    });
  };

  componentDidMount() {
    this.fetchDropdownOptions();
  }

  handleResultSaeSelect = async (e, { result }) => {
    this.setState({ sae: result.ref_sae });

    await this.setState({
      filter: {
        ...this.state.filter,
        ref_sae: result.ref_sae,
      },
    });
  };

  handleSearchSaeChange = (e, { value }) => {
    this.setState({ saeLoading: true, sae: value });

    setTimeout(() => {
      if (this.state.sae.length < 1) return this.resetSaeComponent();

      Feathers.service("pedido")
        .find({
          query: {
            ref_sae: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            saeLoading: false,
            saeResults: res.data,
          });
        });
    }, 500);
  };

  resetSaeComponent = (_) => {
    this.setState({
      sae: "",
      saeResults: null,
      saeLoading: null,
    });
  };

  fetchDropdownOptions = async (_) => {
    let producto_options = await Feathers.service("producto").find({
      query: {
        $limit: "-1",
      },
    });

    let formated_producto_options = producto_options.map((v) => {
      return {
        key: v.id,
        value: v.id,
        text: v.nombre,
      };
    });

    this.setState({
      producto_options: formated_producto_options,
    });
  };

  handleClientResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    await this.setState({
      filter: {
        ...this.state.filter,
        cliente_id: result.id,
      },
    });
  };

  handleClientSearchChange = async (e, { value }) => {
    await this.setState({ clienteLoading: true, cliente: value });

    setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  resetClienteComponent = () =>
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });

  fetchData(state = {}) {
    let pageSize = state.pageSize || 10;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      reportede: "rodillo",
    };

    if (this.state.$sort.$fechaEntregaSortDirection !== null) {
      query["$sort"] = {
        fecha_entrega: this.state.$sort.$fechaEntregaSortDirection,
      };
    }

    if (this.state.$sort.$fechaTerminadoSortDirection !== null) {
      query["$sort"] = {
        fecha_terminado: this.state.$sort.$fechaTerminadoSortDirection,
      };
    }

    if (this.state.$sort.$saeSortDirection !== null) {
      query["$sort"] = {
        ref_sae: this.state.$sort.$saeSortDirection,
      };
    }

    if (this.state.filter.initDay) {
      let fechainicio = moment(this.state.filter.initDay)
        .hour(0)
        .minute(0)
        .second(0);
      fechainicio = fechainicio.format("YYYY-MM-DD HH:mm:ss");
      query["fechainicio"] = fechainicio;
    }

    if (this.state.filter.lastDay) {
      let fechafin = moment(this.state.filter.lastDay)
        .hour(22)
        .minute(60)
        .second(60);
      fechafin = fechafin.format("YYYY-MM-DD HH:mm:ss");
      query["fechafin"] = fechafin;
    }

    if (this.state.filter.cliente_id) {
      query["client_id"] = this.state.filter.cliente_id;
    }

    if (this.state.filter.producto_id) {
      query["producto_id"] = this.state.filter.producto_id;
    }

    if (this.state.filter.ref_sae) {
      query["ref_sae"] = this.state.filter.ref_sae;
    }

    if (this.state.filter.rodilloValue !== null) {
      console.log("filter rodillo");
      query["rodillo"] = this.state.filter.rodilloValue;
    }

    if (this.state.filter.partidaValue !== null) {
      query["partida"] = this.state.filter.partidaValue;
    }

    if (this.state.filter.pedidoValue !== null) {
      query["pedido"] = this.state.filter.pedidoValue;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("reporte")
        .find({
          query,
        })
        .then((res) => {
          console.log("filter", query);
          this.handleSort(res);
          console.log("---", res.data);
          if (!this.state.shouldExport) {
            this.setState({
              rodillos: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 10,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res.data, "finished_product");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  }

  _handleFilterButton = () => {
    this.fetchData(this.state);
  };

  _handleCleanButton = async () => {
    this.resetClienteComponent();
    this.resetSaeComponent();

    await this.setState({
      filter: {
        ...this.state.filter,
        initDay: "",
        lastDay: "",
        cliente_id: null,
        producto_id: null,
        ref_sae: null,
      },
    });
    this.fetchData(this.state);
  };

  _hadleExportButton = async (_) => {
    this.setState({ shouldExport: true }, () => {
      this.fetchData(this.state);
    });
  };

  changeInitDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        initDay: date,
      },
    });
  };

  changeLastDay = (date) => {
    this.setState({
      filter: {
        ...this.state.filter,
        lastDay: date,
      },
    });
  };

  changeProduct = (d) => {
    this.setState({
      filter: {
        ...this.state.filter,
        producto_id: d,
      },
    });
  };

  handleClientResultSelect = async (e, { result }) => {
    this.setState({ cliente: result.nombre });

    await this.setState({
      filter: {
        ...this.state.filter,
        cliente_id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleClientSearchChange = async (e, { value }) => {
    await this.setState({ clienteLoading: true, cliente: value });

    setTimeout(() => {
      if (this.state.cliente.length < 1) return this.resetClienteComponent();

      Feathers.service("cliente")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
          },
        })
        .then((res) => {
          this.setState({
            clienteLoading: false,
            clienteResults: res.data,
          });
        });
    }, 500);
  };

  resetClienteComponent = () =>
    this.setState({ clienteLoading: false, clienteResults: [], cliente: "" });

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="checkmark"
                content="Reporte de Producto Terminado"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <Form size="small">
                <Form.Group>
                  <ReportFilter
                    loading={this.state.loading}
                    filters={this.state.filters}
                    initDay={this.state.filter.initDay}
                    lastDay={this.state.filter.lastDay}
                    changeInitDay={this.changeInitDay}
                    changeLastDay={this.changeLastDay}
                    costumer={this.state.cliente}
                    costumers={this.state.clienteResults}
                    onSelectCostumer={this.handleClientResultSelect}
                    onSearchCostumerChange={this.handleClientSearchChange}
                    costumerResultRenderer={renderClientSearchResults}
                    rodilloValue={this.state.rodilloValue}
                    onRodilloChange={this.onRodilloChange}
                    partidaValue={this.state.partidaValue}
                    onChangePartida={this.onPartidaChange}
                    pedidoValue={this.state.pedidoValue}
                    onPedidoChange={this.onPedidoChange}
                    onProductChange={this.changeProduct}
                    products={this.state.producto_options}
                    productValue={this.state.filter.producto_id}
                  />
                  <Form.Field>
                    <label>Filtrar</label>
                    <Button
                      basic
                      circular
                      icon="filter"
                      onClick={this._handleFilterButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this._handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        exportToXls([], "finished_product");
                      }}
                    />
                  </Form.Field>
                  {/* <Form.Field>
                    <label>Mostrar SAE</label>
                    <Toggle
                      icons={false}
                      onClick={() => {
                        this.handleSaeToggle();
                      }}
                    ></Toggle>
                  </Form.Field> */}
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ReactTable
                columns={
                  this.state.showSae
                    ? this.state.tableColumns
                    : this.state.tableColumns.slice(1)
                }
                manual
                data={this.state.rodillos}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState({
                    columna: column,
                    sortDirection: direction,
                  });
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
