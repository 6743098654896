import React, { Component } from "react";
import {
  Form,
  Grid,
  Button,
  Dropdown,
  Header,
  Divider,
} from "semantic-ui-react";
import Feathers from "../redux/FeathersRedux";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import swal from "sweetalert2";
import { Dropzone } from "../components/CatalogEquipment/Dropzone";
import Config from "../config";
import imageCompression from "browser-image-compression";

class EquipmentEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      maquinaria: {
        nombre: "",
        descripcion: "",
        fecha_compra: "",
        tipo_maquina_id: null,
      },
      perfiles: [],
      image: null,
      tipo_maquinaria_options: [],
      options: [],
      loading: false,
      error: false,
    };
  }

  componentDidMount = () => {
    this.equipmentData();
    this.fetchPerfiles();
    this.fetchTiposMaquinarias();
  };

  equipmentData = () => {
    let perfiles_maquina;
    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("maquinaria")
          .find({
            query: {
              id: this.props.match.params.id,
            },
          })
          .then((res) => {
            this.setState({
              maquinaria: {
                ...this.state.maquinaria,
                nombre: res.data[0].nombre,
                descripcion: res.data[0].descripcion,
                fecha_compra: res.data[0].fecha_compra,
                tipo_maquina_id: res.data[0].tipo_maquina_id,
              },
              image: Config.apiUrl + "/" + res.data[0].imagen,
            });
            this.setState({ loading: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    );

    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("maquinaria_perfil")
          .find({
            query: {
              maquinaria_id: this.props.match.params.id,
            },
          })

          .then((res) => {
            perfiles_maquina = res.data.map((v, i) => {
              return v.perfil_id;
            });

            this.setState({
              /* maquinaria: {
                ...this.state.maquinaria,
                perfiles: perfiles_maquina
              } */
              perfiles: perfiles_maquina,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  fetchPerfiles = () => {
    let options;
    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("perfil")
          .find({
            query: {
              doPagination: false,
            },
          })
          .then((res) => {
            options = res.map((v, i) => {
              return {
                key: i,
                text: v.nombre,
                value: v.id,
              };
            });

            this.setState({
              options,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  handleSubmit = async () => {
    if (
      !this.state.maquinaria.nombre ||
      !this.state.maquinaria.descripcion ||
      !this.state.maquinaria.tipo_maquina_id ||
      !this.state.maquinaria.fecha_compra ||
      !this.state.image ||
      this.state.perfiles.length < 1
    ) {
      swal({
        title: "Faltan datos por registrar.",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }

    let imageBase64 = this.state.image.base64;

    if (this.state.image.file) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 600,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(
        this.state.image.file,
        options
      );
      const compressedBase64 = await imageCompression.getDataUrlFromFile(
        compressedFile
      );

      imageBase64 = compressedBase64;
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        Feathers.service("maquinaria")
          .patch(this.props.match.params.id, {
            nombre: this.state.maquinaria.nombre,
            descripcion: this.state.maquinaria.descripcion,
            fecha_compra: this.state.maquinaria.fecha_compra,
            image: imageBase64,
            tipo_maquina_id: this.state.maquinaria.tipo_maquina_id,
            perfiles: this.state.perfiles,
          })
          .then((res) => {
            this.props.onCreate && this.props.onCreate();
            swal({
              title: "Datos actualizados correctamente",
              type: "success",
              confirmButtonText: "ok",
            }).then((res) => {
              this.setState({
                loading: false,
                /* maquinaria: {
                        nombre: "",
                        descripcion: "",
                        fecha_compra: null,
                        tipo_maquina_id: null,
                        perfiles: []
                        } */
              });
            });
          })
          .catch((err) => {
            swal({
              title: "Se produjo un error, intente de nuevo.",
              type: "error",
              confirmButtonText: "ok",
            });
            this.setState({ loading: false });
          });
      }
    );
  };

  //--------------------------------------------------

  //TODO Probando la edición de imágenes ya cargadas en el servidor.
  onImageDrop(files, rejectedFiles) {
    this.setState({ files });
  }

  onDropChange = (image) => {
    this.setState({
      image,
    });
  };

  //---------------------------------------------------

  fetchTiposMaquinarias = async () => {
    let tipo_maquinaria = await Feathers.service("tipo_maquinaria").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    let tipo_maquinaria_options = tipo_maquinaria.map((t) => {
      return {
        key: t.id,
        text: t.nombre,
        value: t.id,
      };
    });

    this.setState({
      tipo_maquinaria_options,
    });
  };

  render() {
    //const imageURl = Config.apiUrl + "/" + this.state.maquinaria.image
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header as={"h2"}>
                <Button
                  onClick={() => this.props.dispatch(push("/catalogequipment"))}
                  icon="arrow left"
                  style={{ backgroundColor: "transparent", color: "#000000" }}
                />
                Editar equipo
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Form
          size={"large"}
          style={{
            boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)",
            padding: "1rem",
          }}
        >
          <Grid>
            <Grid.Row columns={2} divided>
              <Grid.Column>
                <Form.Field required>
                  <label>Imagen</label>
                  <Dropzone
                    onChange={this.onDropChange}
                    image={this.state.image}
                    required
                  />
                </Form.Field>
                <Form.Input
                  label="Nombre"
                  name="nombre"
                  value={this.state.maquinaria.nombre}
                  type="text"
                  placeholder="Nombre maquinaria"
                  onChange={(e, { name, value }) =>
                    this.setState({
                      maquinaria: { ...this.state.maquinaria, [name]: value },
                    })
                  }
                  required
                />
                <Form.TextArea
                  label="Descripción"
                  placeholder=""
                  name="descripcion"
                  value={this.state.maquinaria.descripcion}
                  type="text"
                  onChange={(e, { name, value }) =>
                    this.setState({
                      maquinaria: { ...this.state.maquinaria, [name]: value },
                    })
                  }
                  required
                />
                <Form.Input
                  label="Fecha de Compra"
                  name="fecha_compra"
                  value={this.state.maquinaria.fecha_compra}
                  type="date"
                  onChange={(e, { name, value }) =>
                    this.setState({
                      maquinaria: { ...this.state.maquinaria, [name]: value },
                    })
                  }
                  required
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Dropdown
                  required
                  label="Tipo de maquinaria"
                  placeholder="Seleccione una Opción"
                  options={this.state.tipo_maquinaria_options}
                  selection
                  value={this.state.maquinaria.tipo_maquina_id}
                  onChange={(e, d) => {
                    this.setState({
                      maquinaria: {
                        ...this.state.maquinaria,
                        tipo_maquina_id: d.value,
                      },
                    });
                  }}
                />
                <Form.Input error={this.state.error} label="Perfiles a cargo">
                  <Dropdown
                    placeholder="Perfiles a cargo"
                    fluid
                    multiple
                    selection
                    options={this.state.options}
                    value={this.state.perfiles}
                    onChange={(e, { value }) =>
                      this.setState({
                        error: false,
                        perfiles: [...value],
                      })
                    }
                  />
                </Form.Input>
                <Button
                  disabled={this.state.loading}
                  loading={this.state.loading}
                  type="submit"
                  floated="right"
                  positive
                  content="Editar"
                  icon="send"
                  labelPosition="left"
                  onClick={this.handleSubmit}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(EquipmentEditContainer);
