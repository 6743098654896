import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import ReactTable from "react-table";
import { Button, Form, Search, Grid, Label } from "semantic-ui-react";
import Toggle from "react-toggle";
import MaintenanceTypeEditModal from "./MaintenanceTypeEditModal";
import { exportToXls } from "../../utils/Utils";

const renderNameSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

export default class MaintenanceTypeList extends Component {
  state = {
    types: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 10,
    currentRow: {},
    isEditOpened: false,
    $sortDirection: 1,

    filterOptions: {
      id: null,
    },

    // search bar objects

    type: null,

    // search bar results

    typeResults: null,

    // search bar loadings

    typeLoadings: false,
    columna: "",
    sortDirection: false,
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  handleNameResultSelect = async (e, { result }) => {
    this.setState({ type: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleNameSearchChange = (e, { value }) => {
    this.setState({ typeLoadings: true, type: value });

    setTimeout(() => {
      if (this.state.type.length < 1) return this.resetNameComponent();

      Feathers.service("tipos_mantenimiento")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            typeLoadings: false,
            typeResults: res.data,
          });
        });
    }, 500);
  };

  resetNameComponent = () =>
    this.setState({ typeLoadings: false, typeResults: [], type: "" });

  handleChange(event, row) {
    Feathers.service("tipos_mantenimiento").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  closeEditModal = (_) => {
    this.setState({
      isEditOpened: false,
    });
  };

  openEditModal = (row) => {
    this.setState(
      {
        currentRow: row.original,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isEditOpened: true,
          });
        }, 100);
      }
    );
  };

  handleCleanButton = (_) => {
    this.resetNameComponent();

    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  fetchData = (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $sort: {
        id: -1,
      },
      $limit: pageSize,
      $skip: pageSize * state.page,
    };

    if (this.state.filterOptions.id != null) {
      query["id"] = this.state.filterOptions.id;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("tipos_mantenimiento")
        .find({
          query,
        })
        .then((res) => {
          this.handleSort(res);
          this.setState({
            types: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        });
    });
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre:</label>
                  <Search
                    loading={this.state.typeLoadings}
                    value={this.state.type}
                    results={this.state.typeResults}
                    onResultSelect={this.handleNameResultSelect}
                    onSearchChange={this.handleNameSearchChange}
                    resultRenderer={renderNameSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  {/* <label>Exportar</label>
                                    <Button basic color="green" circular icon ="file excel outline" onClick={() => {
                                        exportToXls(this.state.types, 'admin_glues')
                                    }}/> */}
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre", accessor: "nombre" },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size={"tiny"}
                      compact
                      basic
                      color="blue"
                      icon="pencil"
                      onClick={() => this.openEditModal(row)}
                    />
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.types}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPagesize={5}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftkey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }
            this.setState({
              columna: column,
              sortDirection: direction,
            });
            this.fetchData();
          }}
        />
        <MaintenanceTypeEditModal
          open={this.state.isEditOpened}
          onEdit={this.fetchData}
          tipo_mantenimiento={this.state.currentRow}
          onClose={this.closeEditModal}
        />
      </div>
    );
  }
}
