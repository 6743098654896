import React, { Component } from "react";
import {
  Grid,
  Segment,
  Icon,
  Header,
  Input,
  Container,
  Button,
} from "semantic-ui-react";
import axios from "axios";
import feathersRestClient from "@feathersjs/rest-client";
import feathers from "@feathersjs/feathers";
import Config from "../../config";
import swal from "sweetalert2";


export default class DocumentsFolderReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        document: '',
        folder_id: '',

      },
      documents: [],
      loading: false,
    };
  }

  uploadFile = async () => {
    const idFolder = this.props.id;
    let newDataToSend = new FormData();
    if (
      document.getElementById("documentFile") &&
      document.getElementById("documentFile").files.length > 0 &&
      document.getElementById("documentFile").files[0]
    ) {
      let form = document.getElementById("documentFile").files[0];
      newDataToSend.set("documentFile", form);
    } else {
      swal({
        title: "No ha seleccionado ningún archivo",
        type: "warning",
        confirmButtonText: "OK",
        reverseButtons: true,
      });
      return;
    }
    newDataToSend.set("folder_id", idFolder);
    const token = localStorage.getItem("bingham-jwt");
    const app = feathers();
    const restClient = feathersRestClient(Config.apiUrl);
    app.configure(restClient.axios(axios));

    app
      .service("documentos_bingham")
      .create(newDataToSend, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        if (document.getElementById("documentFile")) {
          document.getElementById("documentFile").value = null;
        }
        
        swal({
          title: "¡Subida Exitosa!",
          type: "success",
          confirmButtonText: "OK",
        }).then((res) => {
          this.props.onSave();
          this.setState({ loading: false });
        });
      })
      .catch((err) => {
        alert("Se produjo un error");
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Container style={{ margin: 20 }}>
                <Segment placeholder textAlign="center" padded>
                  <Grid.Row>
                    <Header icon as="h3">
                      <Icon name="pdf file outline" />
                      El documento que suba se guardará en esta carpeta
                    </Header>
                  </Grid.Row>
                  <Grid
                    textAlign="center"
                    style={{ width: "50%", margin: "0 auto" }}
                  >
                    <Grid.Row columns={2}>
                      <Grid.Column width={10}>
                        <Input
                          accept="application/pdf"
                          type="file"
                          id="documentFile"
                          name="document"
                          onChange={(event) => {
                            if (
                              event.target.files &&
                              event.target.files.length > 0
                            ) {
                              this.setState({
                                document: event.target.files[0],
                              });
                            }
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Button
                          loading={this.state.loading}
                          onClick={this.uploadFile}
                          floated="right"
                          color="blue"
                          labelPosition="left"
                          icon="upload"
                          content="Subir"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}
