import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Button,
  Grid,
  Icon,
  Progress,
  Header,
  Dropdown,
  Form,
  Search,
  Label,
} from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../redux/FeathersRedux";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import "moment/locale/es";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { exportToXls } from "../utils/Utils";

export default class ManchetasReportContainer extends Component {
  state = {
    manchetas: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 100,
    filterOptions: {
      forma_mancheta_id: undefined,
      tipo_mancheta_id: undefined,
      status_tarea_id: undefined,
      parte: undefined,
    },

    // options for filtes

    forma_mancheta_id_options: [],
    tipo_mancheta_id_options: [],
    status_tarea_id_options: [],
    parte_options: [],
    columna: "",
    sortDirection: false,
    shouldExport: false,
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Consecutivo":
        array.data.sort((a, b) => {
          const nameA = a.consecutivo.toUpperCase(); // ignore upper and lowercase
          const nameB = b.consecutivo.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Parte":
        array.data.sort((a, b) => {
          return (
            parseInt(a.partida.manchetas.parte) -
            parseInt(b.partida.manchetas.parte)
          );
        });
        break;
      case "Tipo Mancheta":
        array.data.sort((a, b) => {
          const nameA = a.partida.manchetas.formamancheta.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.partida.manchetas.formamancheta.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Kilos":
        array.data.sort((a, b) => {
          return (
            parseInt(a.partida.manchetas.kilos) -
            parseInt(b.partida.manchetas.kilos)
          );
        });
        break;
      case "Perímetro Específico":
        array.data.sort((a, b) => {
          return parseInt(a.precio) - parseInt(b.precio);
        });
        break;
      case "Espesor Específico":
        array.data.sort((a, b) => {
          return (
            parseInt(a.partida.manchetas.espesor_espec) -
            parseInt(b.partida.manchetas.espesor_espec)
          );
        });
        break;
      case "Altura Específico":
        array.data.sort((a, b) => {
          return (
            parseInt(a.partida.manchetas.altura_espec) -
            parseInt(b.partida.manchetas.altura_espec)
          );
        });
        break;
      case "USD":
        array.data.sort((a, b) => {
          return (
            parseInt(a.partida.manchetas.usd) -
            parseInt(b.partida.manchetas.usd)
          );
        });
        break;
      case "Estatus":
        array.data.sort((a, b) => {
          return parseInt(a.status_tarea_id) - parseInt(b.status_tarea_id);
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  componentDidMount() {
    this.fetch_options();
    // this.fetchData(this.state)
  }

  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 100;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      esMancheta: 1,
    };

    await this.setState({
      loading: true,
    });

    if (this.state.filterOptions.status_tarea_id !== null) {
      query["status_tarea_id"] = this.state.filterOptions.status_tarea_id;
    }

    if (this.state.filterOptions.parte !== null) {
      // primero tenemos que traer las manchetas que tengan esa parte

      // luego tenemos que traer las partidas que tengan esa mancheta

      let manchetas = await Feathers.service("manchetas").find({
        query: {
          parte: this.state.filterOptions.parte,
          $limit: "-1",
        },
      });

      let manchetas_ids = manchetas.map((v) => {
        return v.id;
      });

      let partidas = await Feathers.service("partida").find({
        query: {
          mancheta_id: {
            $in: manchetas_ids,
          },
          $limit: "-1",
        },
      });

      let partidas_ids = partidas.map((v) => v.id);

      query["partida_id"] = {
        $in: partidas_ids,
      };

      // y luego buscaremos los rodillos que tengan esas partidas
    }

    if (this.state.filterOptions.forma_mancheta_id !== null) {
      // primero tenemos que traernos las manchetas que tengan esa forma

      let manchetas = await Feathers.service("manchetas").find({
        query: {
          forma_mancheta_id: this.state.filterOptions.forma_mancheta_id,
          $limit: "-1",
        },
      });

      let manchetas_ids = manchetas.map((v) => v.id);

      // luego las partidas que tengan esa mancheta

      let partidas = await Feathers.service("partida").find({
        query: {
          mancheta_id: {
            $in: manchetas_ids,
          },
          $limit: "-1",
        },
      });

      let partidas_ids = partidas.map((v) => v.id);

      // luego los rodillos que tengan esa partida

      query["partida_id"] = {
        $in: partidas_ids,
      };
    }

    if (this.state.filterOptions.tipo_mancheta_id !== null) {
      let manchetas = await Feathers.service("manchetas").find({
        query: {
          tipo_mancheta_id: this.state.filterOptions.tipo_mancheta_id,
          $limit: "-1",
        },
      });

      let manchetas_ids = manchetas.map((v) => v.id);

      // luego las partidas que tengan esa mancheta

      let partidas = await Feathers.service("partida").find({
        query: {
          mancheta_id: {
            $in: manchetas_ids,
          },
          $limit: "-1",
        },
      });

      let partidas_ids = partidas.map((v) => v.id);

      // luego los rodillos que tengan esa partida

      query["partida_id"] = {
        $in: partidas_ids,
      };
    }

    Feathers.service("rodillo")
      .find({
        query,
      })
      .then((res) => {
        console.log("aqui es");
        console.log(res.data);
        this.handleSort(res);

        if (!this.state.shouldExport) {
        this.setState({
          manchetas: res.data,
          total: res.total,
          loading: false,
          page: state.page,
          pageSize: state.pageSize || 100,
        });
      }

      if (this.state.shouldExport) {
        exportToXls(res, "report_manchetas");
        this.setState({
          shouldExport: false,
          loading: false,
          page: state.page,
          pageSize: state.pageSize || 100,
        });
      }
      });
  };

  fetch_options = async (_) => {
    let forma_mancheta = await Feathers.service("forma_mancheta").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    // key, text, value. It is the format that the semantic dropdowns accepts
    let parsed_formas = forma_mancheta.map((v) => {
      return {
        text: v.nombre,
        key: v.id,
        value: v.id,
      };
    });

    let tipo_mancheta = await Feathers.service("tipo_mancheta").find({
      query: {
        $limit: "-1",
        activo: 1,
      },
    });

    let parsed_tipos = tipo_mancheta.map((v) => {
      return {
        text: v.nombre,
        key: v.id,
        value: v.id,
      };
    });

    let status_tarea = await Feathers.service("status_tarea").find({
      query: {
        $limit: "-1",
      },
    });

    let parsed_status = status_tarea.map((v) => {
      return {
        text: v.status_tarea,
        key: v.id,
        value: v.id,
      };
    });

    let parte_manchetas = await Feathers.service("manchetas").find({
      query: {
        $limit: "-1",
      },
    });

    let parsed_partes = parte_manchetas.map((v) => {
      return {
        text: v.parte,
        key: v.parte,
        value: v.parte,
      };
    });

    this.setState({
      forma_mancheta_id_options: parsed_formas,
      tipo_mancheta_id_options: parsed_tipos,
      status_tarea_id_options: parsed_status,
      parte_options: parsed_partes,
    });
  };

  handleCleanButton = (_) => {
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          forma_mancheta_id: null,
          tipo_mancheta_id: null,
          status_tarea_id: null,
          parte: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="chevron circle down"
                content="Reporte de Manchetas"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form size="large">
                <Form.Group>
                  <Form.Field>
                    <label>Forma Mancheta</label>
                    <Form.Dropdown
                      selection
                      options={this.state.forma_mancheta_id_options}
                      value={this.state.filterOptions.forma_mancheta_id}
                      onChange={(e, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              forma_mancheta_id: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Tipo Mancheta</label>
                    <Form.Dropdown
                      selection
                      options={this.state.tipo_mancheta_id_options}
                      value={this.state.filterOptions.tipo_mancheta_id}
                      onChange={(e, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              tipo_mancheta_id: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Estatus</label>
                    <Form.Dropdown
                      selection
                      options={this.state.status_tarea_id_options}
                      value={this.state.filterOptions.status_tarea_id}
                      onChange={(e, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              status_tarea_id: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Parte</label>
                    <Form.Dropdown
                      selection
                      options={this.state.parte_options}
                      value={this.state.filterOptions.parte}
                      onChange={(e, d) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              parte: d.value,
                            },
                          },
                          (_) => this.fetchData(this.state)
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Exportar</label>
                    <Button
                      basic
                      color="green"
                      circular
                      icon="file excel outline"
                      onClick={() => {
                        this.setState({ shouldExport: true }, () => {
                          this.fetchData(this.state);
                        });
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ReactTable
                columns={[
                  {
                    Header: "Consecutivo",
                    headerStyle: { fontSize: 20 },
                    id: "consecutivo",
                    accessor: (d) => {
                      return d.consecutivo;
                    },
                  },
                  {
                    Header: "Parte",
                    headerStyle: { fontSize: 20 },
                    id: "parte",
                    accessor: (d) => {
                      return d.partida.manchetas.parte;
                    },
                  },

                  {
                    Header: "Tipo Mancheta",
                    headerStyle: { fontSize: 20 },
                    id: "tipomancheta",
                    accessor: (d) => {
                      return d.partida.manchetas.tipomancheta.nombre;
                    },
                  },
                  {
                    Header: "Forma Mancheta",
                    headerStyle: { fontSize: 20 },
                    id: "formamancheta",
                    accessor: (d) => {
                      return d.partida.manchetas.formamancheta.nombre;
                    },
                  },
                  {
                    Header: "Kilos",
                    headerStyle: { fontSize: 20 },
                    id: "kilos",
                    accessor: (d) => {
                      return d.partida.manchetas.kilos;
                    },
                  },
                  {
                    Header: "Perímetro Específico",
                    headerStyle: { fontSize: 20 },
                    id: "perimetro",
                    accessor: (d) => {
                      return d.partida.manchetas.perimetro_espec;
                    },
                  },
                  {
                    Header: "Espesor Específico",
                    headerStyle: { fontSize: 20 },
                    id: "espesor",
                    accessor: (d) => {
                      return d.partida.manchetas.espesor_espec;
                    },
                  },
                  {
                    Header: "Altura Específica",
                    headerStyle: { fontSize: 20 },
                    id: "altura",
                    accessor: (d) => {
                      return d.partida.manchetas.altura_espec;
                    },
                  },
                  {
                    Header: "USD",
                    headerStyle: { fontSize: 20 },
                    id: "usd",
                    accessor: (d) => {
                      return d.partida.manchetas.usd;
                    },
                  },
                  {
                    Header: "Estatus",
                    headerStyle: { fontSize: 20 },
                    id: "estatus",
                    accessor: (d) => {
                      if (d.status_tarea_id === 1) {
                        return (
                          <Label tag color="blue">
                            {" "}
                            {d.status.status_tarea}{" "}
                          </Label>
                        );
                      }
                      if (d.status_tarea_id === 2) {
                        return (
                          <Label tag color="green">
                            {" "}
                            {d.status.status_tarea}{" "}
                          </Label>
                        );
                      }
                      if (d.status_tarea_id === 3) {
                        return (
                          <Label tag color="teal">
                            {" "}
                            {d.status.status_tarea}{" "}
                          </Label>
                        );
                      }
                    },
                  },
                ]}
                manual
                data={this.state.manchetas}
                loading={this.state.loading}
                pages={Math.ceil(this.state.total / this.state.pageSize)}
                onFetchData={this.fetchData}
                defaultPageSize={100}
                style={{ textAlign: "center" }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState({
                    columna: column,
                    sortDirection: direction,
                  });
                  this.fetchData();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
