import React, {Component} from 'react';
import { Form, Button } from 'semantic-ui-react';
import Feathers from "../../redux/FeathersRedux";
import swal from 'sweetalert2';

class ManchetasForm extends Component {

  state = {
    loadingButton: false,
    mancheta: {
      parte: '',
      forma_mancheta_id: '',
      tipo_mancheta_id: '',
      perimetro_espec: '',
      perimetro_min: '',
      perimetro_max: '',
      espesor_espec: '',
      espesor_min: '',
      espesor_max: '',
      altura_espec: '',
      altura_min: '',
      altura_max: '',
      dureza: '',
      usd: '',
      kilos: '',
      diametro_molde: 0,
    },
    dropdowns : {
      forma_mancheta : [],
      tipo_mancheta: []
    }
  };

  componentDidMount(){
    this.fetchOptions()
  }

  fetchOptions = async  _ => {
    let forma_mancheta_unparsed = await Feathers.service('forma_mancheta').find({
      query: {
        $limit: '-1'
      }
    });

    let tipo_mancheta_unparsed = await Feathers.service('tipo_mancheta').find({
      query: {
        $limit: '-1'
      }
    });

    this.setState({
      dropdowns: {
        ...this.state.dropdowns,
        forma_mancheta: forma_mancheta_unparsed.map( v => {
          return {
            key: v.id,
            value: v.id,
            text: v.nombre
          }
        }),
        tipo_mancheta: tipo_mancheta_unparsed.map( v => {
          return {
            key: v.id,
            value: v.id,
            text: v.nombre
          }
        }),
      }
    })
  };


  renderDropdowns = _ => {
    return (
      <Form.Group widths={"equal"}>
        <Form.Field required={true}>
          <label>Parte</label>
          <Form.Input  placeholder={"Parte"} type={'number'} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                parte: d.value
              }
            })
          }}  value={this.state.mancheta.parte} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Forma Mancheta</label>
          <Form.Dropdown selection placeholder={"Forma Mancheta"} options={this.state.dropdowns.forma_mancheta} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                forma_mancheta_id: d.value
              }
            })
          }}  value={this.state.mancheta.forma_mancheta_id} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Tipo Mancheta</label>
          <Form.Dropdown selection placeholder={"Tipo Mancheta"} options={this.state.dropdowns.tipo_mancheta}  onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                tipo_mancheta_id: d.value
              }
            })
          }} value={this.state.mancheta.tipo_mancheta_id} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Diametro molde</label>
          <Form.Input  
            placeholder="Diametro molde" 
            type="number" 
            onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                diametro_molde: d.value
              }
              })
            }}  
            value={this.state.mancheta.diametro_molde} />
        </Form.Field>
      </Form.Group>
    )
  };

  renderPerimeters = _ => {
    return (
      <Form.Group widths={"equal"}>
        <Form.Field required={true}>
          <label>Perímetro específico</label>
          <Form.Input placeholder={"Perímetro específico"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                perimetro_espec: d.value
              }
            });
          }} value={this.state.mancheta.perimetro_espec} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Perímetro mínimo</label>
          <Form.Input placeholder={"Perímetro mínimo"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                perimetro_min: d.value
              }
            })
          }} value={this.state.mancheta.perimetro_min} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Perímetro máximo</label>
          <Form.Input placeholder={"Perímetro máximo"}  type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                perimetro_max: d.value
              }
            })
          }} value={this.state.mancheta.perimetro_max} />
        </Form.Field>
      </Form.Group>
    )
  };

  renderThicknesses = _ => {
    return (
      <Form.Group widths={"equal"}>
        <Form.Field required={true}>
          <label>Espesor específico</label>
          <Form.Input placeholder={"Espesor específico"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                espesor_espec: d.value
              }
            })
          }} value={this.state.mancheta.espesor_espec} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Espesor mínimo</label>
          <Form.Input placeholder={"Espesor mínimo"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                espesor_min: d.value
              }
            })
          }}  value={this.state.mancheta.espesor_min} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Espesor máximo</label>
          <Form.Input placeholder={"Espesor máximo"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                espesor_max: d.value
              }
            })
          }} value={this.state.mancheta.espesor_max} />
        </Form.Field>
      </Form.Group>
    )
  };

  renderHeights = _ => {
    return (
      <Form.Group widths={"equal"}>
        <Form.Field required={true} >
          <label>Altura específico</label>
          <Form.Input placeholder={"Altura específico"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                altura_espec: d.value
              }
            })
          }} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Altura mínimo</label>
          <Form.Input placeholder={"Altura mínimo"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                altura_min: d.value
              }
            })
          }} value={this.state.mancheta.altura_min} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Altura máximo</label>
          <Form.Input placeholder={"Altura máximo"} type={"number"}  onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                altura_max: d.value
              }
            })
          }} value={this.state.mancheta.altura_max} />
        </Form.Field>
      </Form.Group>
    )
  };

  renderUSDnKilos = _ => {
    return (
      <Form.Group widths={"equal"}>
        <Form.Field required={true}>
          <label>Dureza</label>
          <Form.Input placeholder={"Dureza"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                dureza: d.value
              }
            })
          }}  value={this.state.mancheta.dureza} />
        </Form.Field>
        <Form.Field required={true}>
          <label>USD</label>
          <Form.Input placeholder={"Precio en USD"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                usd: d.value
              }
            })
          }} value={this.state.mancheta.usd} />
        </Form.Field>
        <Form.Field required={true}>
          <label>Kilos</label>
          <Form.Input placeholder={"Kilos"} type={"number"} onChange={(e, d) => {
            this.setState({
              mancheta: {
                ...this.state.mancheta,
                kilos: d.value
              }
            })
          }} value={this.state.mancheta.kilos} />
        </Form.Field>
      </Form.Group>
    )
};



  renderAddButton = _ => {
    return (
      <Form.Field>
        <Button content={"Agregar"} icon={"plus"} color={"green"} floated={"right"} onClick={this._handleAdd} loading={this.state.loadingButton} />
      </Form.Field>
    )
  };

  _handleAdd = async _ => {
    await this.setState({
      loadingButton: true
    });
    let mancheta = JSON.parse(JSON.stringify(this.state.mancheta));
    if([mancheta.parte, mancheta.forma_mancheta_id, mancheta.tipo_mancheta_id, mancheta.perimetro_espec,
      mancheta.perimetro_min, mancheta.perimetro_max, mancheta.espesor_espec, mancheta.espesor_min, mancheta.espesor_max,
      mancheta.altura_espec, mancheta.altura_min, mancheta.altura_max, mancheta.dureza, mancheta.usd,
      mancheta.kilos].indexOf('') !== -1) return swal({
      type: 'error',
      title: 'Oops...',
      text: 'Has dejado el formulario incompleto.',
    }).then((result) => {
      if (result.value) {
        this.setState({
          loadingButton: false
        })
      }
    });

    let mancheta_guardada = await Feathers.service('manchetas').create(mancheta);
    this.props.onCreate && this.props.onCreate();

    this.setState({
      loadingButton: false,
      mancheta: {
        ...this.state.mancheta,
        parte: '',
        forma_mancheta_id: '',
        tipo_mancheta_id: '',
        perimetro_espec: '',
        perimetro_min: '',
        perimetro_max: '',
        espesor_espec: '',
        espesor_min: '',
        espesor_max: '',
        altura_espec: '',
        altura_min: '',
        altura_max: '',
        dureza: '',
        usd: '',
        kilos: '',
        diametro_molde: 0,
      },
    }, _ => {
      swal(
        '¡Bien hecho!',
        'Se ha agregado la mancheta',
        'success'
      )
    });
  };

  render() {
    return (
      <div>
        <Form size={'large'}>
          <this.renderDropdowns />
          <this.renderPerimeters />
          <this.renderThicknesses />
          <this.renderHeights />
          <this.renderUSDnKilos />
          <this.renderAddButton />
        </Form>
      </div>
    );
  }
}

export default ManchetasForm;