import React, { Component } from 'react'
import ReactTable from "react-table";
import { Button, Grid, Icon, Form, Search, Label} from 'semantic-ui-react';
import "react-table/react-table.css";
import Toggle from 'react-toggle'
import Feathers from '../../redux/FeathersRedux';
import { exportToXls } from '../../utils/Utils'

const renderSearchResults = ({id, nombre}) => <Label key={id} content={nombre} />


export default class ProfilesList extends Component {

  state = {
    profiles: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 10,
    currentRow: {},
    $sortDirection: 1,


    filterOptions: {
        id: null,
    },

    // Search Bar Results

    nameResults: [],


    // Search Bar Objects

    name: null,


    // Search Bar Loadings
    
    loadingName: false
  };

    handleResultSelect = async (e, { result }) => { 
        this.setState({ name: result.nombre }) 
        
        await this.setState({
            filterOptions: {
                ...this.state.filterOptions,
                id: result.id
            }
        })


        this.fetchData(this.state)
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ loadingName: true, name: value })

        setTimeout(() => {
            if (this.state.name.length < 1) return this.resetComponent();

            Feathers.service('perfil').find({
                query: {
                    nombre: {$like: `%${value}%`},
                    activo: 1
                
                }
            })
                .then((res) => {
                    this.setState({
                    loadingName: false,
                    nameResults: res.data
                    });
                });
        }, 500)
    }


    resetComponent = () => this.setState({ loadingName: false, nameResults: [], name: '' })

    handleCleanButton = _ => {
        this.resetComponent()
        this.setState({
            filterOptions: {
                ...this.state.filterOptions,
                id: null
            }
        }, _ => this.fetchData(this.state))
    }


  render() {
    return (
      <div>
            <Grid.Row>
                <Grid.Column>
                <Form>
                    <Form.Group>
                        <Form.Field>
                            <label>Nombre</label>
                            <Search 
                                icon=''
                                loading={this.state.loadingName}
                                value={this.state.name}
                                results={this.state.nameResults}
                                onResultSelect={this.handleResultSelect}
                                onSearchChange={this.handleSearchChange}
                                resultRenderer={renderSearchResults}
                                placeholder='Nombre'
                                className="full-width"
                            />
                    </Form.Field>
                    <Form.Field>
                        <label>Limpiar</label>
                        <Button circular icon="x" onClick={this.handleCleanButton} />
                    </Form.Field>
                    <Form.Field>
                        <label>Exportar</label>
                        <Button  circular icon="file excel outline" onClick={() => {
                            exportToXls(this.state.profiles, 'admin_profiles')
                        }} />
                    </Form.Field>
                </Form.Group>
                </Form>
                </Grid.Column>
            </Grid.Row>
        <ReactTable
                    columns={[
                    { Header: "Nombre", accessor: "nombre" },
                    ]}
                    manual
                    data={this.state.profiles}
                    loading={this.state.loading}
                    pages={Math.ceil(this.state.total / this.state.pageSize)}
                    onFetchData={this.fetchData}
                    defaultPageSize={100}
                    style={{textAlign: 'center'}}
                    onSortedChange={ async (newSorted, column, shiftKey) => {
                        if(column.id === "nombre"){
                            let $sortDirection = this.state.$sortDirection
                            if($sortDirection === 1){
                                $sortDirection = -1
                            }else{
                                $sortDirection = 1
                            }
      
                            await this.setState({
                                $sortDirection
                            })
      
                            this.fetchData(this.state)
                        }

                  }}
                />
      </div>
    )
  }


 
  fetchData = (state= {}) => {
    let pageSize = state.pageSize || 10;

    this.setState({ loading: true}, () => {

        let query =  {
            $limit: pageSize,
            $skip: pageSize * state.page,
            $sort: {
                id: -1
            }
        }


        if(this.state.filterOptions.id !== null){
            query["id"] = this.state.filterOptions.id
        }

      Feathers.service('perfil').find({
          query
      })
          .then(res => {
              this.setState({
                  profiles: res.data,
                  total: res.total,
                  loading: false,
                  page: state.page,
                  pageSize: state.pageSize || 10
              })
          })
  })
  }



}
