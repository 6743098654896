import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import { Grid, Button, Form, Label, Search } from "semantic-ui-react";
import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReportFileModal from "../TaskCalendar/ReportFileModal";
import feathers from "@feathersjs/client/dist/feathers";

const renderSearchResults = ({ id, titulo }) => (
  <Label key={id} content={titulo} />
);

export default class TaskTypeReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      total: 0,
      page: 0,
      pageSize: 0,
      currentRow: {},
      openModal: false,
      sorted: [],
      expanded: {},
      resized: [],
      taskInfo: [],

      filterOptions: {
        id: null,
        perfil_usuario_id: null,
        perfil_options: [],
        usuario_id: null,
        usuario_options: [],
        fecha_inicio: "",
        fecha_fin: "",
        fecha_fin_usuario: "",
      },
      //Search bar Objects
      nombre_tarea: "",
      //Search bar loadings
      nombre_tarea_loading: "",
      //Search bar results
      nombre_tarea_results: "",
      columna: "",
      sortDirection: false,
    };
  }

  //Obtener perfiles para hacer la busqueda
  getProfiles = async (_) => {
    await Feathers.service("perfil")
      .find({
        query: {},
      })
      .then((res) => {
        this.setState({
          filterOptions: {
            ...this.state.filterOptions,
            perfil_options: res.data.map((p) => {
              return {
                key: p.id,
                text: p.nombre,
                value: p.id,
              };
            }),
          },
        });
      });
  };

  //Obtener usuarios para hacer la busqueda
  getUsers = async (_) => {
    await Feathers.service("usuario")
      .find({
        query: {},
      })
      .then((res) => {
        this.setState({
          filterOptions: {
            ...this.state.filterOptions,
            usuario_options: res.data.map((u) => {
              return {
                key: u.id,
                text: `${u.nombre} ${u.apellido}`,
                value: u.id,
              };
            }),
          },
        });
      });
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Precio":
        array.data.sort((a, b) => {
          return parseInt(a.precio) - parseInt(b.precio);
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchTask = async (state = {}) => {
    const tipo_tarea = this.props.id;
    let pageSize = state.pageSize || 100;
    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
      status_tarea_id: 3,
      entregable: true,
      tipo_tarea_id: tipo_tarea,
      $sort: {
        id: -1,
      },
      //activo: 1,
    };

    if (this.state.filterOptions.id) {
      query["id"] = this.state.filterOptions.id;
    }

    if (this.state.filterOptions.perfil_usuario_id) {
      query["perfil_usuario_id"] = this.state.filterOptions.perfil_usuario_id;
    }

    if (this.state.filterOptions.usuario_id) {
      query["usuario_id"] = this.state.filterOptions.usuario_id;
    }

    if (this.state.filterOptions.fecha_inicio) {
      query["fecha_inicio"] = moment(
        this.state.filterOptions.fecha_inicio
      ).format("YYYY-MM-DD");
    }

    if (this.state.filterOptions.fecha_fin) {
      query["fecha_fin"] = moment(this.state.filterOptions.fecha_fin).format(
        "YYYY-MM-DD"
      );
    }

    if (this.state.filterOptions.fecha_fin_usuario) {
      query["fecha_fin_usuario"] = moment(
        this.state.filterOptions.fecha_fin_usuario
      ).format("YYYY-MM-DD");
    }

    await this.setState({ loading: true }, async () => {
      await Feathers.service("calendario_tarea")
        .find({
          query,
        })
        .then((res) => {
          this.handleSort(res);
          this.setState({
            data: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 100,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  resetTaskName = () => {
    this.setState({
      nombre_tarea_loading: "",
      nombre_tarea_results: "",
      nombre_tarea: "",
    });
  };

  handleTaskNameSelect = async (e, { result }) => {
    this.setState({ nombre_tarea: result.titulo });
    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });
    this.fetchTask(this.state);
  };

  handleTaskNameSearch = (e, { value }) => {
    const tipo_tarea = this.props.id;
    this.setState({ nombre_tarea_loading: true, nombre_tarea: value });
    setTimeout(() => {
      if (this.state.nombre_tarea.length < 1) return this.resetTaskName();
      Feathers.service("calendario_tarea")
        .find({
          query: {
            titulo: { $like: `%${value}%` },
            activo: 1,
            status_tarea_id: 3,
            entregable: true,
            tipo_tarea_id: tipo_tarea,
          },
        })
        .then((res) => {
          this.setState({
            nombre_tarea_loading: false,
            nombre_tarea_results: res.data,
          });
        });
    }, 500);
  };

  handleCleanButton = async () => {
    await this.setState({
      nombre_tarea: "",
      nombre_tarea_results: "",
      filterOptions: {
        ...this.state.filterOptions,
        id: null,
        perfil_usuario_id: null,
        usuario_id: null,
        fecha_inicio: "",
        fecha_fin: "",
        fecha_fin_usuario: "",
      },
    });

    this.fetchTask(this.state);
  };

  openFilesModal = (row) => {
    this.setState({
      openModal: true,
      currentRow: row.original,
    });
  };

  closeFilesModal = (_) => {
    this.setState({
      openModal: false,
    });
  };

  componentDidMount = async () => {
    await this.getProfiles();
    await this.getUsers();
  };

  render() {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <Form.Field>
                    <label>Nombre de la tarea</label>
                    <Search
                      icon=""
                      loading={this.state.nombre_tarea_loading}
                      value={this.state.nombre_tarea}
                      results={this.state.nombre_tarea_results}
                      onResultSelect={this.handleTaskNameSelect}
                      onSearchChange={this.handleTaskNameSearch}
                      resultRenderer={renderSearchResults}
                      className="full-width"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Perfil</label>
                    <Form.Dropdown
                      search
                      selection
                      options={this.state.filterOptions.perfil_options}
                      onChange={(e, { value }) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              perfil_usuario_id: value,
                            },
                          },
                          (_) => this.fetchTask(this.state)
                        );
                      }}
                      value={this.state.filterOptions.perfil_usuario_id}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Empleado</label>
                    <Form.Dropdown
                      search
                      selection
                      options={this.state.filterOptions.usuario_options}
                      onChange={(e, { value }) => {
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              usuario_id: value,
                            },
                          },
                          (_) => this.fetchTask(this.state)
                        );
                      }}
                      value={this.state.filterOptions.usuario_id}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Fecha Inicio</label>
                    <Form.Input
                      name="fecha_inicio"
                      type="date"
                      value={this.state.filterOptions.fecha_inicio}
                      onChange={(e, { name, value }) =>
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              [name]: value,
                            },
                          },
                          (_) => this.fetchTask(this.state)
                        )
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Fecha Fin</label>
                    <Form.Input
                      name="fecha_fin"
                      type="date"
                      value={this.state.filterOptions.fecha_fin}
                      onChange={(e, { name, value }) =>
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              [name]: value,
                            },
                          },
                          (_) => this.fetchTask(this.state)
                        )
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Fecha Fin por el Usuario</label>
                    <Form.Input
                      name="fecha_fin_usuario"
                      type="date"
                      value={this.state.filterOptions.fecha_fin_usuario}
                      onChange={(e, { name, value }) =>
                        this.setState(
                          {
                            filterOptions: {
                              ...this.state.filterOptions,
                              [name]: value,
                            },
                          },
                          (_) => this.fetchTask(this.state)
                        )
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Limpiar</label>
                    <Button
                      basic
                      color="red"
                      circular
                      icon="x"
                      onClick={this.handleCleanButton}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ReactTable
                columns={[
                  {
                    Header: "Perfil",
                    id: "perfil",
                    Cell: (row) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Label color="blue">
                            {row.original.perfil.nombre}
                          </Label>
                        </div>
                      );
                    },
                    maxWidth: 150,
                  },
                  {
                    Header: "Empleado",
                    id: "usuario",
                    Cell: (row) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <h4>
                            {row.original.usuario.nombre +
                              " " +
                              row.original.usuario.apellido}
                          </h4>
                        </div>
                      );
                    },
                    maxWidth: 200,
                  },
                  {
                    Header: "Tarea asignada",
                    Cell: (row) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <p>{row.original.titulo}</p>
                        </div>
                      );
                    },
                    maxWidth: 400,
                  },
                  {
                    Header: "Inicio",
                    id: "fecha_inicio",
                    Cell: (row) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <p>
                            {moment(row.original.fecha_inicio).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </div>
                      );
                    },
                    maxWidth: 140,
                  },
                  {
                    Header: "Fin",
                    id: "fecha_fin",
                    Cell: (row) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <p>
                            {moment(row.original.fecha_fin).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </div>
                      );
                    },
                    maxWidth: 140,
                  },
                  {
                    Header: "Finalizada",
                    id: "fecha_fin_usuario",
                    Cell: (row) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Label color="green">
                            {moment(row.original.fecha_fin_usuario).format(
                              "DD-MM-YYYY"
                            )}
                          </Label>
                        </div>
                      );
                    },
                    maxWidth: 140,
                  },
                  {
                    Header: "Entregables",
                    Cell: (row) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Button
                            size={"tiny"}
                            compact
                            color="teal"
                            icon="folder open outline"
                            onClick={() => this.openFilesModal(row)}
                          />
                        </div>
                      );
                    },
                    maxWidth: 100,
                  },
                ]}
                manual
                onFetchData={this.fetchTask}
                data={this.state.data}
                sorted={this.state.sorted}
                expanded={this.state.expanded}
                resized={this.state.resized}
                // Callbacks
                onExpandedChange={(expanded) => this.setState({ expanded })}
                onResizedChange={(resized) => this.setState({ resized })}
                loading={this.state.loading}
                //pages={Math.ceil(this.state.total / this.state.pageSize)}

                defaultPageSize={100}
                className="-striped -highlight"
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  borderRadius: "1rem",
                  boxShadow: "0px 3px 6px #00000029",
                }}
                onSortedChange={async (newSorted, column, shiftKey) => {
                  let direction;
                  if (column.Header == this.state.columna.Header) {
                    direction = this.state.sortDirection ? false : true;
                  } else {
                    direction = false;
                  }

                  this.setState({
                    columna: column,
                    sortDirection: direction,
                  });
                  this.fetchTask();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ReportFileModal
          open={this.state.openModal}
          onClose={this.closeFilesModal}
          data={this.state.currentRow}
        />
      </>
    );
  }
}

/* export default connect(null, null, null, {
  withRef: true,
})(TaskTypeReportList); */
