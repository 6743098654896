import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Icon, Button, Form} from 'semantic-ui-react'
import Feathers from '../../redux/FeathersRedux'
import {notify} from "react-notify-toast";

class ColorForm extends Component {
    state = {
        color: {
            nombre: '',
            activo: 1
        },
        loading: false
    };


    handleSubmit = _ =>{
        if (!this.state.color.nombre) {
            notify.show('Hace falta el nombre', 'error', 2000);
            return;
        }

        this.setState({
            loading: true
        }, () => {
            Feathers.service('color').create({
                ...this.state.color
            }).then(res => {
                notify.show('Color Creado', 'success');

                this.setState({
                    loading: false,
                    color: {
                        nombre: '',
                        activo: 1
                    }
                }, _ =>  this.props.onCreate());

            }).catch(err => {
                notify.show('Se produjo un error', 'error');
                this.setState({ loading: false })
            })
        })
    };


    render() {
        return (
            <div>
                <Form size={'large'}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid.Row>
                                    <Form.Input label='Nombre' name='nombre' value={this.state.color.nombre} type='text' placeholder='Nombre' onChange={(e, {name, value}) => this.setState({ color: {...this.state.color, [name] : value} })} required/>
                                </Grid.Row>
                                <Grid.Row>
                                    <Button disabled={this.state.loading}  style={{
                                        marginTop: 10,
                                    }} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='REGISTRAR' icon='send' labelPosition='left' />
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
        );
    }
}

ColorForm.propTypes = {};

export default ColorForm;
