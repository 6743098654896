import React, { Component } from "react";
import { Form, Grid, Button, Table, Dropdown } from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import AddRawMaterial from "./AddRawMaterial";
import { notify } from "react-notify-toast";
import swal from "sweetalert2";

export default class FormulasForm extends Component {
  constructor() {
    super();

    this.state = {
      materials: [],
      hardnesses: [],
      colors: [],
      products: [],
      formula: {
        material_id: "",
        dureza_id: "",
        color_id: "",
        nombre: "",
        densidad: 0,
        kilosMolienda: 0,
        //product_id: "",
      },
      productsFormula: [],
      material: "",
      product: "",
      dureza: "",
      color: "",
      modalVisible: false,
      rawMaterials: [],
      loading: false,
    };

    this.fetchMaterials = this.fetchMaterials.bind(this);
    this.fetchHardnesses = this.fetchHardnesses.bind(this);
    this.fetchColors = this.fetchColors.bind(this);

    this.setDureza = this.setDureza.bind(this);
    this.setMaterial = this.setMaterial.bind(this);
    this.setColor = this.setColor.bind(this);

    this.toggleModal = this.toggleModal.bind(this);
    this.addRawMaterial = this.addRawMaterial.bind(this);
    this.removeRawMaterial = this.removeRawMaterial.bind(this);
    this.save = this.save.bind(this);
  }

  fetchMaterials() {
    Feathers.service("material")
      .find({
        query: {
          $limit: "-1",
          activo: 1,
        },
      })
      .then((materials) =>
        this.setState({
          materials: materials.map((m) => {
            return {
              value: m.id,
              text: m.nombre,
            };
          }),
        })
      );
  }

  fetchColors() {
    Feathers.service("color")
      .find({
        query: {
          $limit: "-1",
          activo: 1,
        },
      })
      .then((colors) =>
        this.setState({
          colors: colors.map((c) => {
            return {
              value: c.id,
              text: c.nombre,
            };
          }),
        })
      );
  }

  fetchHardnesses() {
    Feathers.service("dureza")
      .find({
        query: {
          $limit: "-1",
        },
      })
      .then((hardnesses) =>
        this.setState({
          hardnesses: hardnesses.map((h) => {
            return {
              value: h.id,
              text: h.valor,
            };
          }),
        })
      );
  }

  fetchProducts = () => {
    Feathers.service("producto")
      .find({
        query: {
          $limit: "-1",
        },
      })
      .then((products) =>
        this.setState({
          products: products.map((p) => {
            return {
              value: p.id,
              text: p.nombre,
            };
          }),
        })
      );
  };

  setMaterial(value) {
    let material = this.state.materials.find((m) => m.value === value);

    this.setState({
      formula: {
        ...this.state.formula,
        material_id: value,
        nombre: this.state.dureza.length
          ? material.text + " " + this.state.dureza
          : material.text,
      },
      material: material.text,
    });
  }

  setColor(value) {
    let color = this.state.colors.find((c) => c.value === value);
    this.setState({
      formula: {
        ...this.state.formula,
        color_id: value,
      },
      color: color.text,
    });
  }

  setDureza(value) {
    let hardness = this.state.hardnesses.find((h) => h.value === value);

    this.setState({
      formula: {
        ...this.state.formula,
        dureza_id: value,
        nombre: this.state.material + " " + hardness.text,
      },
      dureza: hardness.text,
    });
  }

  setProduct = (value) => {
    let getProduct = this.state.products.find((p) => p.value === value);
    this.setState({
      formula: {
        ...this.state.formula,
        product_id: value,
      },
      product: getProduct.text,
    });
  };

  toggleModal() {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  }

  addRawMaterial(row) {
    let rawMaterials = [...this.state.rawMaterials];

    let exists = rawMaterials.find(
      (rm) => rm.materia_prima.id === row.materia_prima.id
    );
    if (exists) {
      return swal({
        type: "error",
        title: "Oops...",
        text: "¡Este material ya ha sido agregado!",
      });
    } else {
      rawMaterials.push(row);
    }

    this.setState({ rawMaterials });
  }

  removeRawMaterial(index) {
    let rawMaterials = [...this.state.rawMaterials];

    rawMaterials.splice(index, 1);

    this.setState({ rawMaterials });
  }

  save() {
    let total = 0;
    let formula = Feathers.service("formula").find({
      query: {
        dureza_id: this.state.formula.dureza_id,
        material_id: this.state.formula.material_id,
        color_id: this.state.formula.color_id,
      },
    });

    total = formula.total;

    if (total > 0) {
      return swal({
        type: "error",
        title: "Oops...",
        text: "¡Ya existe una fórmula con ese material y esa dureza!",
      });
    }

    swal({
      title: "¿Crear fórmula?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (!result.value) return;

      this.setState({ loading: true });

      let nueva_formula = await Feathers.service("formula").create({
        nombre: this.state.formula.nombre,
        dureza_id: this.state.formula.dureza_id,
        material_id: this.state.formula.material_id,
        color_id: this.state.formula.color_id,
        densidad: this.state.formula.densidad,
        kilos_molienda: this.state.formula.kilosMolienda,
        products: this.state.productsFormula,
        //product_id: this.state.formula.product_id,
      });

      await Feathers.service(
        "formula_materia_prima"
      ).create(
        this.state.rawMaterials.map((r) => {
          return {
            formula_id: nueva_formula.id,
            materia_prima_id: r.materia_prima.id,
            partes: +r.partes,
          };
        })
      );
      this.props.callback && this.props.callback();

      this.props.onCreate && this.props.onCreate();

      //notify.show("Fórmula registrada!", "success");
      swal({
        type: "success",
        title: "Formula registrada",
        text: "¡La formula se registró correctamente!",
      });
      this.setState({
        formula: {
          material_id: "",
          dureza_id: "",
          color_id: "",
          nombre: "",
          densidad: 0,
          kilosMolienda: 0,
          //product_id: ''
        },
        rawMaterials: [],
        productsFormula: [],
        loading: false,
      });
    });
  }

  componentDidMount() {
    this.fetchMaterials();
    this.fetchHardnesses();
    this.fetchColors();
    this.fetchProducts();
  }

  render() {
    return (
      <div>
        <Form size={"large"}>
          <Grid>
            <Grid.Row columns={2} divided>
              <Grid.Column largeScreen={8} mobile={16}>
                <Form.Dropdown
                  required
                  onChange={(e, { name, value }) => this.setMaterial(value)}
                  label="Material"
                  placeholder="Material"
                  options={this.state.materials}
                  selection
                  value={this.state.formula.material_id}
                />
                <Form.Group>
                  <Form.Input
                    required
                    name="kilosMolienda"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        formula: { ...this.state.formula, [name]: value },
                      })
                    }
                    value={this.state.formula.kilosMolienda}
                    type="number"
                    label="Kilos molienda"
                    placeholder="Kilos molienda"
                    width={8}
                  />
                  <Form.Input
                    required
                    name="densidad"
                    onChange={(e, { name, value }) =>
                      this.setState({
                        formula: { ...this.state.formula, [name]: value },
                      })
                    }
                    value={this.state.formula.densidad}
                    type="number"
                    label="Densidad"
                    placeholder="Densidad de la formula"
                    width={8}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Dropdown
                    required
                    onChange={(e, { name, value }) => this.setDureza(value)}
                    label="Dureza"
                    placeholder="Dureza"
                    options={this.state.hardnesses}
                    selection
                    fluid
                    value={this.state.formula.dureza_id}
                  />
                  <Form.Dropdown
                    required
                    onChange={(e, { name, value }) => this.setColor(value)}
                    label="Color de la formula"
                    placeholder="Color"
                    options={this.state.colors}
                    selection
                    fluid
                    value={this.state.formula.color_id}
                  />
                </Form.Group>
                {/* <Form.Dropdown
                  required
                  onChange={(e, { name, value }) => this.setProduct(value)}
                  label="Producto que podrá usar esta formula"
                  placeholder="Seleccione un producto"
                  options={this.state.products}
                  selection
                  value={this.state.formula.product_id}
                /> */}
                <Form.Input label="Productos para esta formula">
                  <Dropdown
                    placeholder="Seleccione los productos de esta formula"
                    fluid
                    multiple
                    selection
                    options={this.state.products}
                    value={this.state.productsFormula}
                    onChange={(e, { value }) =>
                      this.setState({
                        error: false,
                        productsFormula: [...value] 
                      })
                    }
                  />
                </Form.Input>
                <Form.Input
                  required
                  disabled={
                    !this.state.formula.dureza_id ||
                    !this.state.formula.material_id ||
                    !this.state.formula.color_id
                  }
                  onChange={(e, { name, value }) =>
                    this.setState({
                      formula: { ...this.state.formula, [name]: value },
                    })
                  }
                  value={this.state.formula.nombre}
                  name="nombre"
                  label="Nombre de la fórmula"
                  placeholder="Nombre de la fórmula"
                />
              </Grid.Column>
              <Grid.Column largeScreen={8} mobile={16}>
                <Button
                  color="blue"
                  icon="add"
                  fluid
                  onClick={this.toggleModal}
                  content="Agregar materia prima"
                />
                <Table compact={true}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Materia prima</Table.HeaderCell>
                      <Table.HeaderCell>Partes</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.rawMaterials.map((rm, i) => {
                      return (
                        <Table.Row key={rm.id}>
                          <Table.Cell>
                            {rm.materia_prima.nombre +
                              " - " +
                              rm.materia_prima.quimico}
                          </Table.Cell>
                          <Table.Cell>{rm.partes}</Table.Cell>
                          <Table.Cell>
                            <Button
                              onClick={() => this.removeRawMaterial(i)}
                              compact={true}
                              negative
                              size={"tiny"}
                              icon={"trash"}
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                    {!this.state.rawMaterials.length && (
                      <Table.Row>
                        <Table.Cell colSpan="3" textAlign="center">
                          Sin materias primas
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                <Button
                  loading={this.state.loading}
                  onClick={this.save}
                  floated="right"
                  positive
                  labelPosition="left"
                  icon="send"
                  label="CREAR FÓRMULA"
                  disabled={
                    !this.state.formula.nombre ||
                    !this.state.formula.dureza_id ||
                    !this.state.formula.material_id ||
                    !this.state.rawMaterials.length ||
                    this.state.loading
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>

        <AddRawMaterial
          count={this.state.rawMaterials.length}
          open={this.state.modalVisible}
          toggleModal={this.toggleModal}
          add={this.addRawMaterial}
        />
      </div>
    );
  }
}
