import React, { Component } from "react";
import {
  Modal,
  Grid,
  Icon,
  Button,
  Form,
  Dropdown,
  Header,
  Tab
} from "semantic-ui-react";
import Notifications, { notify } from "react-notify-toast";
import Feathers from "../redux/FeathersRedux";
//Componentes
import PermissionList from "../components/Rol&Permission/PermissionList";
import RoleList from "../components/Rol&Permission/RoleList";

export default class RolePermissionContainer extends Component {
  state = {
    ccurrIndex: 0
  };

  panes = [
    {
      menuItem: { key: "list_roles", icon: "list", content: "Roles" },
      pane: (
        <Tab.Pane style={{boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)"}} key={2}>
          <RoleList ref={ref => (this.list = ref)} />
        </Tab.Pane>
      )
    },
    {
      menuItem: { key: "list_permission", icon: "list", content: "Permisos" },
      pane: (
        <Tab.Pane style={{boxShadow: "0px 0px 7px 3px rgba(218,221,223,1)"}} key={1}>
          <PermissionList ref={ref => (this.list = ref)} />
        </Tab.Pane>
      )
    }
  ];

  setTab = index => {
    this.setState({
      currIndex: index || 0
    });
  };

  render() {
    return (
      <div>
        <div>
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Header as="h2" icon="users" content="Roles y permisos" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Tab
                  menu={{ secondary: true, pointing: true, color: "red" }}
                  panes={this.panes}
                  renderActiveOnly={false}
                  activeIndex={this.state.currIndex}
                  onTabChange={(event, data) => this.setTab(data.activeIndex)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}
