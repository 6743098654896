import React, { Component } from "react";
import { Modal, Grid, Icon, Dropdown, Button } from "semantic-ui-react";

export default class RawMaterialsInfoModal extends Component {
  render() {
    return (
      <Modal open={this.props.isOpened} size={"small"}>
        <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
          {this.props.material.nombre}
          <Button
            circular
            size="mini"
            basic
            icon="close"
            floated="right"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Header>
        <Modal.Content>
          <Grid
            columns={4}
            divided
            textAlign="center"
            style={{ fontSize: "16px" }}
          >
            <Grid.Row>
              <Grid.Column>
                <Grid.Row>
                  <Icon name="object ungroup"></Icon>{" "}
                  <span style={{ fontWeight: "bold" }}>Grupo</span>
                </Grid.Row>
                <Grid.Row>{this.props.material.grupo}</Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row>
                  <Icon name="address card"></Icon>{" "}
                  <span style={{ fontWeight: "bold" }}>Químico</span>
                </Grid.Row>
                <Grid.Row>{this.props.material.quimico || "N/D"}</Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row>
                  <Icon name="barcode"></Icon>{" "}
                  <span style={{ fontWeight: "bold" }}>Código</span>
                </Grid.Row>
                <Grid.Row>{this.props.material.codigo || "N/D"}</Grid.Row>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Grid.Row>
                  <Icon name="money" />{" "}
                  <span style={{ fontWeight: "bold" }}>Costo</span>
                </Grid.Row>
                <Grid.Row>
                  <span>
                    {this.props.material.costo || 0} {this.props.material.currency}
                  </span>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row>
                  <Icon name="densidad" />{" "}
                  <span style={{ fontWeight: "bold" }}>Densidad</span>
                </Grid.Row>
                <Grid.Row>{this.props.material.densidad || "N/D"}</Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row>
                  <Icon name="minus" />{" "}
                  <span style={{ fontWeight: "bold" }}>Mínimo</span>
                </Grid.Row>
                <Grid.Row>{this.props.material.min || "N/D"}</Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row>
                  <Icon name="plus" />{" "}
                  <span style={{ fontWeight: "bold" }}>Máximo</span>
                </Grid.Row>
                <Grid.Row>{this.props.material.max || "N/D"}</Grid.Row>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Grid.Row>
                  <Icon name="file" />{" "}
                  <span style={{ fontWeight: "bold" }}>Observaciones</span>
                </Grid.Row>
                <Grid.Row>{this.props.material.observaciones || "N/D"}</Grid.Row>
              </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
              <Grid.Column width={16}>
                <Grid.Row>
                  <Icon name="location arrow" />{" "}
                  <span style={{ fontWeight: "bold" }}>URL ficha técnica</span>
                </Grid.Row>
                <Grid.Row> <a href={this.props.material.url_ficha} target="_blank">{this.props.material.url_ficha || "N/D"}</a></Grid.Row>
              </Grid.Column>
            </Grid.Row> */}
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
