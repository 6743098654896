import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import {
  Button,
  Modal,
  Grid,
  Icon,
  Checkbox,
  Form,
  Label,
  Search,
  Popup,
} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import swal from "sweetalert2";
import "../react-toggle.css";
import Toggle from "react-toggle";
import FormulasInfoModal from "./FormulasInfoModal";
import FormulasEditModal from "./FormulasEditModal";
import moment from "moment";

import { exportToXls } from "../../utils/Utils";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

export default class FormulasList extends Component {
  constructor() {
    super();

    this.state = {
      formulas: [],
      total: 0,
      page: 0,
      loading: false,
      currentRow: null,
      modalOpened: false,
      isEditOpened: false,
      pageSize: 100,
      $sortDirection: 1,

      filterOptions: {
        id: null,
      },

      // Search Bar Results

      nameResults: [],

      // Search Bar Objects

      name: null,

      // Search Bar Loadings

      loadingName: false,
      columna: "",
      sortDirection: false,
      shouldExport: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.onClientEdited = this.onClientEdited.bind(this);
  }

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Material":
        array.data.sort((a, b) => {
          const nameA = a.material.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.material.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Dureza":
        array.data.sort((a, b) => {
          let A = a.dureza.valor;
          let B = b.dureza.valor;
          return A - B;
        });
        break;
      case "Color":
        array.data.sort((a, b) => {
          const nameA = a.color.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.color.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Densidad":
        array.data.sort((a, b) => {
          let A = a.densidad;
          let B = b.densidad;
          return A - B;
        });
        break;
      case "Kilos molienda":
        array.data.sort((a, b) => {
          let A = a.kilos_molienda;
          let B = b.kilos_molienda;
          return A - B;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData(state = {}) {
    let pageSize = state.pageSize || 100;
    this.setState({ loading: true }, () => {
      let query = {
        $limit: this.state.shouldExport ? "-1" : pageSize,
        $skip:
          pageSize * (state.page !== undefined ? state.page : this.state.page),
        $sort: {
          id: -1,
        },
        is_clone: 0,
      };

      if (this.state.filterOptions.id !== null) {
        query["id"] = this.state.filterOptions.id;
      }

      Feathers.service("formula")
        .find({
          query,
        })
        .then((res) => {
          this.handleSort(res);
          if (!this.state.shouldExport) {
            this.setState({
              formulas: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 10,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "admin_formulas");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 100,
            });
          }
        });
    });
  }

  onClientEdited() {
    this.fetchData({ page: this.state.page, pageSize: this.state.pageSize });
  }

  handleChange(event, row) {
    Feathers.service("formula").patch(row.original.id, {
      activo: event.target.checked ? 1 : 0,
    });
  }

  openModal(row) {
    this.setState({
      modalOpened: true,
      currentRow: row.original,
    });
  }

  openEditModal(row) {
    this.setState(
      {
        currentRow: row.original,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isEditOpened: true,
          });
        }, 100);
      }
    );
  }

  updateFormula = async (data) => {
    await Feathers.service("formula").patch(data.id, {
      nombre: `${data.nombre} - OBSOLETA`,
      activo: false,
      is_clone: true,
    });
  };

  updateCloneFormula = async (data) => {
    await Feathers.service("formula").patch(data.id, {
      nombre: `${data.nombre} - COPIA`,
      //activo: false,
      //is_clone: true,
    });
  };

  unsubscribeFormula = async (row) => {
    /* const formula_materia_prima = await Feathers.service("formula_materia_prima").find({
      query: {
        formula_id: row.original.id
      }
    });
     */
    //const materia_prima_active = formula_materia_prima.filter(mp => mp.materiaPrima.activo === true);

    swal
      .fire({
        title: "¿Desea dar de baja esta fórmula?",
        text: "Indique un motivo de baja",
        input: "text",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Si",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: (value) => {
          Feathers.service("formula").patch(row.original.id, {
            nombre: `${row.original.nombre} - OBSOLETA`,
            activo: false,
            is_clone: true,
            motivo_baja: value,
            fecha_baja: moment().format("YYYY-MM-DD"),
          });
          /* let nueva_formula = await Feathers.service("formula").create({
          nombre: row.original.nombre,
          dureza_id: row.original.dureza_id,
          material_id: row.original.material_id,
          color_id: row.original.color_id,
          densidad: row.original.densidad,
          kilos_molienda: row.original.kilos_molienda,
          product_id: row.original.product_id,
        }); */

          /* await Feathers.service(
          "formula_materia_prima"
        ).create(
          materia_prima_active.map((r) => {
            return {
              formula_id: nueva_formula.id,
              materia_prima_id: r.materiaPrima.id,
              partes: +r.partes,
            };
          })
        ); */
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then((result) => {
        if (!result.value) return;
        this.fetchData();
        this.props.onFetch && this.props.onFetch();
        swal.fire({
          type: "success",
          title: "¡La formula se dió de baja correctamente!",
          //text: "¡La formula se clonó correctamente!",
        });
      });
  };

  cloneFormula = async (row) => {
    const formula_materia_prima = await Feathers.service(
      "formula_materia_prima"
    ).find({
      query: {
        formula_id: row.original.id,
      },
    });

    const materia_prima_active = formula_materia_prima.filter(
      (mp) => mp.materiaPrima.activo === true
    );

    swal
      .fire({
        title: "¿Desea clonar esta fórmula?",
        //type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Si",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          let nueva_formula = await Feathers.service("formula").create({
            nombre: row.original.nombre,
            dureza_id: row.original.dureza_id,
            material_id: row.original.material_id,
            color_id: row.original.color_id,
            densidad: row.original.densidad,
            kilos_molienda: row.original.kilos_molienda,
            product_id: row.original.product_id,
          });

          await Feathers.service("formula_materia_prima").create(
            materia_prima_active.map((r) => {
              return {
                formula_id: nueva_formula.id,
                materia_prima_id: r.materiaPrima.id,
                partes: +r.partes,
              };
            })
          );
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then((result) => {
        if (!result.value) return;
        this.updateCloneFormula(row.original);
        this.fetchData();
        swal.fire({
          type: "success",
          title: "Formula clonada",
          text: "¡La formula se clonó correctamente!",
        });
      });
  };

  closeModal() {
    this.setState({
      modalOpened: false,
    });
  }

  closeEditModal() {
    this.setState({
      isEditOpened: false,
    });
  }

  handleResultSelect = async (e, { result }) => {
    this.setState({ name: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingName: true, name: value });

    setTimeout(() => {
      if (this.state.name.length < 1) return this.resetComponent();

      Feathers.service("formula")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loadingName: false,
            nameResults: res.data,
          });
        });
    }, 500);
  };

  resetComponent = () =>
    this.setState({ loadingName: false, nameResults: [], name: "" });

  handleCleanButton = (_) => {
    this.resetComponent();
    this.setState(
      {
        filterOptions: {
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre</label>
                  <Search
                    icon=""
                    loading={this.state.loadingName}
                    value={this.state.name}
                    results={this.state.nameResults}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    resultRenderer={renderSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre", accessor: "nombre" },
            {
              Header: "Material",
              Cell: (row) => {
                return <span>{row.original.material.nombre}</span>;
              },
            },
            {
              Header: "Dureza",
              Cell: (row) => {
                return <span>{row.original.dureza.valor}</span>;
              },
              maxWidth: 100,
            },
            {
              Header: "Color",
              Cell: (row) => {
                if (row.original.color === null) return "---";
                return <span>{row.original.color.nombre}</span>;
              },
              maxWidth: 200,
            },
            {
              Header: "Densidad",
              Cell: (row) => {
                if (row.original.densidad === null) return "---";
                return <span>{row.original.densidad}</span>;
              },
              maxWidth: 100,
            },
            {
              Header: "Kilos molienda",
              Cell: (row) => {
                if (row.original.kilos_molienda === null) return "---";
                return <span>{row.original.kilos_molienda}</span>;
              },
              maxWidth: 100,
            },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {row.original.is_clone ? (
                      <Button
                        size={"tiny"}
                        basic
                        color="grey"
                        compact
                        icon="eye"
                        onClick={() => this.openModal(row)}
                      />
                    ) : (
                      <>
                        <Popup
                          content="Dar de baja"
                          trigger={
                            <Button
                              size={"tiny"}
                              basic
                              color="red"
                              compact
                              icon="trash"
                              onClick={() => this.unsubscribeFormula(row)}
                            />
                          }
                        />
                        <Popup
                          content="Clonar"
                          trigger={
                            <Button
                              size={"tiny"}
                              basic
                              color="red"
                              compact
                              icon="clone"
                              onClick={() => this.cloneFormula(row)}
                            />
                          }
                        />
                        <Popup
                          content="Ver más"
                          trigger={
                            <Button
                              size={"tiny"}
                              basic
                              color="grey"
                              compact
                              icon="eye"
                              onClick={() => this.openModal(row)}
                            />
                          }
                        />
                        <Popup
                          content="Editar"
                          trigger={
                            <Button
                              size={"tiny"}
                              basic
                              color="blue"
                              compact
                              icon="pencil"
                              onClick={() => this.openEditModal(row)}
                            />
                          }
                        />
                        <Toggle
                          defaultChecked={!!row.original.activo}
                          icons={false}
                          onChange={(event) => this.handleChange(event, row)}
                        />
                      </>
                    )}
                  </div>
                );
              },
              maxWidth: 200,
            },
          ]}
          manual
          data={this.state.formulas}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={this.state.pageSize}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />

        <FormulasInfoModal
          open={this.state.modalOpened}
          formula={this.state.currentRow}
          onClose={this.closeModal}
        ></FormulasInfoModal>
        <FormulasEditModal
          open={this.state.isEditOpened}
          formula={this.state.currentRow}
          onClose={this.closeEditModal}
          callback={this.fetchData}
        ></FormulasEditModal>
      </div>
    );
  }
}
