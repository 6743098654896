import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import {
  Button,
  Modal,
  Grid,
  Icon,
  Checkbox,
  Form,
  Label,
  Search,
  Popup
} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../react-toggle.css";
import FormulasInfoModal from "./FormulasInfoModal";

const renderSearchResults = ({ id, nombre }) => (
  <Label key={id} content={nombre} />
);

export default class ObsoleteFormulasList extends Component {
  constructor() {
    super();

    this.state = {
      formulas: [],
      total: 0,
      page: 0,
      loading: false,
      currentRow: null,
      modalOpened: false,
      isEditOpened: false,
      pageSize: 100,
      $sortDirection: 1,

      filterOptions: {
        id: null,
      },

      // Search Bar Results

      nameResults: [],

      // Search Bar Objects

      name: null,

      // Search Bar Loadings

      loadingName: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  fetchData(state = {}) {
    let pageSize = state.pageSize || 100;

    this.setState({ loading: true }, () => {
      let query = {
        $limit: state.pageSize || 100,
        $skip:
          pageSize * (state.page !== undefined ? state.page : this.state.page),
        $sort: {
          nombre: this.state.$sortDirection,
        },
        is_clone: 1
      };

      if (this.state.filterOptions.id !== null) {
        query["id"] = this.state.filterOptions.id;
      }

      Feathers.service("formula")
        .find({
          query,
        })
        .then((res) => {
          this.setState({
            formulas: res.data,
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        });
    });
  }


  openModal(row) {
    console.log(row, 'aver');
    this.setState({
      modalOpened: true,
      currentRow: row.original,
    });
  }

  closeModal() {
    this.setState({
      modalOpened: false,
    });
  }

  handleResultSelect = async (e, { result }) => {
    this.setState({ name: result.nombre });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        id: result.id,
      },
    });

    this.fetchData(this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ loadingName: true, name: value });

    setTimeout(() => {
      if (this.state.name.length < 1) return this.resetComponent();

      Feathers.service("formula")
        .find({
          query: {
            nombre: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loadingName: false,
            nameResults: res.data,
          });
        });
    }, 500);
  };

  resetComponent = () =>
    this.setState({ loadingName: false, nameResults: [], name: "" });

  handleCleanButton = (_) => {
    this.resetComponent();
    this.setState(
      {
        filterOptions: {
          id: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre</label>
                  <Search
                    icon=""
                    loading={this.state.loadingName}
                    value={this.state.name}
                    results={this.state.nameResults}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    resultRenderer={renderSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre", accessor: "nombre" },
            {
              Header: "Material",
              Cell: (row) => {
                return <span>{row.original.material.nombre}</span>;
              },
            },
            {
              Header: "Motivo de baja",
              Cell: (row) => {
                return <span>{row.original.motivo_baja}</span>;
              },
            },
            {
              Header: "Dureza",
              Cell: (row) => {
                return <span>{row.original.dureza.valor}</span>;
              },
              maxWidth: 100,
            },
            {
              Header: "Color",
              Cell: (row) => {
                if (row.original.color === null) return "---";
                return <span>{row.original.color.nombre}</span>;
              },
              maxWidth: 150,
            },
            {
              Header: "Densidad",
              Cell: (row) => {
                if (row.original.densidad === null) return "---";
                return <span>{row.original.densidad}</span>;
              },
              maxWidth: 100,
            },
            {
              Header: "Kilos molienda",
              Cell: (row) => {
                if (row.original.kilos_molienda === null) return "---";
                return <span>{row.original.kilos_molienda}</span>;
              },
              maxWidth: 100,
            },
            {
              Header: "Ver",
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                      <Popup
                          content="Ver más"
                          trigger={
                            <Button
                              size={"tiny"}
                              basic
                              color="grey"
                              compact
                              icon="eye"
                              onClick={() => this.openModal(row)}
                            />
                          }
                        />
                  </div>
                );
              },
              maxWidth: 50,
            },
          ]}
          manual
          data={this.state.formulas}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={this.state.pageSize}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            if (column.id === "nombre") {
              let $sortDirection = this.state.$sortDirection;
              if ($sortDirection === 1) {
                $sortDirection = -1;
              } else {
                $sortDirection = 1;
              }

              await this.setState({
                $sortDirection,
              });

              this.fetchData(this.state);
            }
          }}
        />

        <FormulasInfoModal
          open={this.state.modalOpened}
          formula={this.state.currentRow}
          onClose={this.closeModal}
        ></FormulasInfoModal>
      </div>
    );
  }
}
