import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Grid, Icon, Form, Search, Label } from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../../redux/FeathersRedux";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { exportToXls } from "../../utils/Utils";

class RoleList extends Component {
  state = {
    total: 0,
    page: 0,
    loading: false,
    pageSize: 10,
    currentRow: {},
    $sort: {
      $nameSortDirection: null,
      $lastNameSortDirection: null,
    },
    roles: [],
    columna: "",
    sortDirection: false,
  };

  /**
   * Obtiene la info de permisos
   * @memberof PermissionList
   */
  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Número":
        array.data.sort((a, b) => {
          return a.ref_sae - b.ref_sae;
        });
        break;
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Activo":
        array.data.sort((a, b) => {
          const nameA = a.activo // ignore upper and lowercase
          const nameB = b.activo // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };
  fetchData = async (state = {}) => {
    let pageSize = state.pageSize || 10;

    let query = {
      $limit: pageSize,
      $skip: pageSize * state.page,
    };

    await this.setState({ loading: true }, () => {
      Feathers.service("perfil")
        .find({
          query,
        })
        .then((res) => {
          console.log(res);
          res = this.handleSort(res);
          this.setState({
            roles: [...res.data],
            total: res.total,
            loading: false,
            page: state.page,
            pageSize: state.pageSize || 10,
          });
        });
    });
  };

  /**
   * Método que sirve para ejecutar la accion de editar el rol/perfil selecionado
   * @memberof RoleList
   */
  openEdit = (item) => {
    const rolId = item.original.id;
    // this.props.dispatch(push("/role_permission/edit/role/" + userid));
    this.props.dispatch(push(`/role_permission/edit/role/${rolId}`));
  };

  render() {
    return (
      <div>
        <ReactTable
          columns={[
            { Header: "Número", accessor: "id" },
            { Header: "Nombre", accessor: "nombre" },
            {
              Header: "Activo",
              accessor: "activo",
              Cell: (item) => <span>{item ? "Si" : "No"}</span>,
            },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (item) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size={"tiny"}
                      basic
                      color="blue"
                      compact
                      icon="pencil"
                      onClick={() => this.openEdit(item)}
                    />
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.roles}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={10}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />
      </div>
    );
  }
}

//export default PermissionList;
export default connect(null, null, null, {
  withRef: true,
})(RoleList);
