import React, {Component} from 'react'
import TableBody, { Grid, Image, Modal, Header, Container, Button, Icon, Table, Dimmer, Loader, Segment, Popup } from 'semantic-ui-react'
import Config from '../config/index';
import Feathers from '../redux/FeathersRedux';
import './styles/HomeStyles.css'



export default class HomeContainer extends Component {
    
    state = {
        data: [],
        modalOpen : false,
        tableheaders: [],
        tableTitle : '',
        loading: false
    }


    getTableHeader = (headers) => {
        return(
        <Table.Header>
            <Table.Row>
            {
                headers.map( (v, i) => {
                    return(
                        <Table.HeaderCell key={i}>{v}</Table.HeaderCell>
                    )
                })
            }
                
            </Table.Row>
        </Table.Header>
        )
    }


    getTableBody =  () => {
       

            let infoBody = this.state.data
            if(infoBody.length > 0 ){
                if(infoBody[0].rodillo && infoBody[0].usuario){
                    return(
                        <Table.Body>
                            {
                                infoBody.map( (v, i) => 
                                <Table.Row key={i}>
                                    <Table.Cell>{v.id}</Table.Cell>
                                    <Table.Cell>{v.hora_inicio}</Table.Cell>
                                    <Table.Cell>{v.rodillo_id}</Table.Cell>
                                    <Table.Cell>{v.rodillo.partida_id}</Table.Cell>
                                    <Table.Cell>{v.rodillo.partida.pedido_id}</Table.Cell>
                                    <Table.Cell>{v.usuario.nombre} {v.usuario.apellido}</Table.Cell>
                                </Table.Row>
                                )
                            }
                        </Table.Body>
                    )
                }
                if(infoBody[0].partida && infoBody[0].usuario && !infoBody[0].rodillo){
                    return(
                        <Table.Body>
                            {
                                infoBody.map( (v, i) => 
                                <Table.Row key={i}>
                                    <Table.Cell>{v.id}</Table.Cell>
                                    <Table.Cell>{v.hora_inicio}</Table.Cell>
                                    <Table.Cell>{v.partida.id}</Table.Cell>
                                    <Table.Cell>{v.partida.material.nombre}</Table.Cell>
                                    <Table.Cell>{v.partida.pedido_id}</Table.Cell>
                                    <Table.Cell>{v.usuario.nombre} {v.usuario.apellido}</Table.Cell>
                                </Table.Row>
                                )
                            }
                        </Table.Body>
                    )
                }
                if(infoBody[0].vulcanizadora_id && infoBody[0].usuario && !infoBody[0].rodillo){
                    return(
                        // #Número", "Hora Inicio", "Vulcanizadora", "#Usuario"
                        <Table.Body>
                            {
                                infoBody.map( (v, i) => 
                                <Table.Row key={i}>
                                    <Table.Cell>{v.id}</Table.Cell>
                                    <Table.Cell>{v.hora_inicio}</Table.Cell>
                                    <Table.Cell>{v.vulcanizadora.nombre}</Table.Cell>
                                    
                                    <Table.Cell>{v.usuario.nombre} {v.usuario.apellido}</Table.Cell>
                                </Table.Row>
                                )
                            }
                        </Table.Body>
                    )
                }
            }else if(!this.state.loading){
                return(
                    <Table.Body>
                        <Table.Row>
                            No hay nada en producción
                        </Table.Row>
                    </Table.Body>
                )
            }
        
        
    }

    


    handleOnClick = (seccion) => {


        this.setState({
           loading: true,
            data : [],
            tableheaders: []
        })
        this.handleOpen()
        if(seccion === "desv"){
           this.setState({
               tableTitle: 'Desviste'
           })
            Feathers.service('desviste').find({
                query : {
                    status_tarea_id : 1,
                    incidencia_id: null
                }
            }).then(res => {
           
                this.setState({
                    data: res.data,
                    tableheaders: ["#Número", "Hora Inicio", "#Rodillo", "#Partida", "#Pedido", "#Usuario"],
                    loading: false
                })
                
            })
            return
        }
        if(seccion === "desm"){
            this.setState({
                tableTitle: 'Desmonte'
            })
             Feathers.service('desmonte').find({
                 query : {
                     status_tarea_id : 1,
                     incidencia_id: null
                 }
             }).then(res => {
            
                 this.setState({
                     data: res.data,
                     tableheaders: ["#Número", "Hora Inicio", "#Unidad", "#Partida", "#Pedido", "#Usuario"],
                     loading: false
                 })
                 
             })
             return
         }
         if(seccion === "cor"){
            this.setState({
                tableTitle: 'Corte'
            })
             Feathers.service('corte').find({
                 query : {
                     status_tarea_id : 1,
                     incidencia_id: null
                 }
             }).then(res => {
            
                 this.setState({
                     data: res.data,
                     tableheaders: ["#Número", "Hora Inicio", "#Unidad", "#Partida", "#Pedido", "#Usuario"],
                     loading: false
                 })
                 
             })
             return
         }
        if(seccion === "mol"){
            this.setState({
                tableTitle: 'Molino'
            })
            Feathers.service('molino').find({
                query : {
                    status_tarea_id : 1
                }
            }).then(res => {
                this.setState({
                    data: res.data,
                    tableheaders:  ["#Número", "Hora Inicio", "#Partida", "Material", "#Pedido",  "#Usuario"],
                    loading: false
                })
            })
        }

        if(seccion === "cons"){
            this.setState({
                tableTitle: 'Construcción'
            })
            Feathers.service('construccion').find({
                query: {
                    status_tarea_id : 1
                }
            }).then(res => {
                this.setState({
                    data: res.data,
                    tableheaders: ["#Número", "Hora Inicio", "#Rodillo", "#Partida", "#Pedido", "#Usuario"],
                    loading: false
                })
            })
        }
        if(seccion === "rect"){
            this.setState({
                tableTitle: 'Rectificado'
            })
            Feathers.service('rectificado').find({
                query : {
                    status_tarea_id : 1
                }
            }).then(res => {
           
                this.setState({
                    data: res.data,
                    tableheaders: ["#Número", "Hora Inicio", "#Rodillo", "#Partida", "#Pedido", "#Usuario"],
                    loading: false
                })
                
            })
            return
        }
        if(seccion === "desb"){
            this.setState({
                tableTitle: 'Desbaste'
            })
            Feathers.service('desbaste').find({
                query : {
                    status_tarea_id : 1
                }
            }).then(res => {
           
                this.setState({
                    data: res.data,
                    tableheaders: ["#Número", "Hora Inicio", "#Rodillo", "#Partida", "#Pedido", "#Usuario"],
                    loading: false
                })
                
            })
            return
        }
        // QA REMOVED
        // if(seccion === "qa"){
        //     this.setState({
        //         tableTitle: 'Control de Calidad'
        //     })
        //     Feathers.service('control_calidad').find({
        //         query : {
        //             status_tarea_id : 1
        //         }
        //     }).then(res => {
           
        //         this.setState({
        //             data: res.data,
        //             tableheaders: ["#Número", "Hora Inicio", "#Rodillo", "#Partida", "#Pedido", "#Usuario"],
        //             loading: false
        //         })
                
        //     })
        //     return
        // }
        if(seccion === "vulca"){
            this.setState({
                tableTitle: 'Vulcanizado'
            })
            Feathers.service('vulcanizado').find({
                query : {
                    status_tarea_id : 1
                }
            }).then(res => {
           
                this.setState({
                    data: res.data,
                    tableheaders: ["#Número", "Hora Inicio", "Vulcanizadora", "#Usuario"],
                    loading: false
                })
                
            })
            return
        }
    }

    handleOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleClose = () => {
         this.setState({ modalOpen: false })
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
                    size='small'
                    >
                    <Header icon='browser' content={this.state.tableTitle} />
                    <Modal.Content>
                        <Segment basic loading={this.state.loading}>
                            <Table celled loading>
                            {this.getTableHeader(this.state.tableheaders)}
                            {this.getTableBody()}
                            </Table>
                        </Segment>
                        
                    </Modal.Content>
                    <Modal.Actions>
                    <Button color='green' onClick={this.handleClose} inverted>
                        <Icon name='checkmark' /> Ok
                    </Button>
                    </Modal.Actions>
                </Modal>
            
            <h1>Plano Planta Bingham</h1>
                <Grid >
                    <Grid.Row style={{ padding: "0" }}>
                        <Grid.Column>
                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/01.png"} style={{ display: "inline-block"} } width={200}  height={150} onClick={() => this.handleOnClick("mol")} />} content='Molino' />
                        
                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/02.png"} style={ { display: "inline-block"} }  width={200} height={150} onClick={() => this.handleOnClick("cons")} />} content='Construcción' />
                        
                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/03.png"}  style={ { display: "inline-block"} }  width={200}  height={150} onClick={() => this.handleOnClick("rect")} />} content='Rectificado' />
                        
                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/04.png"}  style={ { display: "inline-block"} }  width={200} height={150}  onClick={() => this.handleOnClick("desb")} />} content='Desbaste' />
                        
                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/05.png"}  style={ { display: "inline-block"} } width={200}  height={150} onClick={() => this.handleOnClick("desv")}/>} content='Desviste' />
                        
                       
                        
                        
                        
                    
                        
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ padding: "0" }}>
                        <Grid.Column>
                        <Image className="image-map" src={Config.apiUrl + "/plano/06.png"} style={{ display: "inline-block"} } width={200}  height={75}  />

                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/07.png"} style={ { display: "inline-block"} }  width={170} height={75} onClick={() => this.handleOnClick("cons")} />} content='Construcción' />
                        
                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/08.png"}  style={ { display: "inline-block"} }  width={110}  height={75} onClick={() => this.handleOnClick("desm")} />} content='Desmonte' />
                       
                        
                        
                        <Image className="image-map" src={Config.apiUrl + "/plano/09.png"}  style={ { display: "inline-block"} }  width={500} height={75}   />
                    
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row style={{ padding: "0" }}>
                        <Grid.Column>
                        <Image className="image-map" src={Config.apiUrl + "/plano/10.png"} style={{ display: "inline-block"} } width={200}  height={75}  />

                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/11.png"} style={ { display: "inline-block"} }  width={170} height={75} onClick={() => this.handleOnClick("cons")} />} content='Construcción' />
                        
                        
                    
                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/12.png"}  style={ { display: "inline-block"} }  width={110}  height={75} onClick={() => this.handleOnClick("cor")} />} content='Corte' />
                        
                        
                        <Image className="image-map" src={Config.apiUrl + "/plano/13.png"}  style={ { display: "inline-block"} }  width={500} height={75}   />
                    
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ padding: "0" }}>
                        <Grid.Column>

                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/14.png"} style={{ display: "inline-block"} } width={200}  height={150} onClick={() => this.handleOnClick("qa")} />} content='Control de Calidad' />
                        
                        
                        <Image className="image-map" src={Config.apiUrl + "/plano/15.png"} style={ { display: "inline-block"} }  width={120} height={150}  />
                       
                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/16.png"}  style={ { display: "inline-block"} }  width={55}  height={150} onClick={() => this.handleOnClick("vulca")} />} content='Manchetas' />
                        
                        <Popup trigger={<Image className="image-map" src={Config.apiUrl + "/plano/17.png"}  style={ { display: "inline-block"} }  width={107} height={150}  onClick={() => this.handleOnClick("vulca")} />} content='Vulcanizadora' />
                        
                    
                        <Image className="image-map" src={Config.apiUrl + "/plano/18.png"}  style={ { display: "inline-block"} } width={166}  height={150} />
                       
                        <Image className="image-map" src={Config.apiUrl + "/plano/19.png"}  style={ { display: "inline-block"} } width={166}  height={150} />
                       
                        <Image className="image-map" src={Config.apiUrl + "/plano/20.png"}  style={ { display: "inline-block"} } width={166}  height={150} />
                        
                        
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}