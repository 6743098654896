import React from "react";
import {
  Divider,
  Form,
  Input,
  Button,
  Dropdown,
  Table,
  Header,
} from "semantic-ui-react";
import Feathers from "../redux/FeathersRedux";
import swal from "sweetalert2";
import { connect } from "react-redux";

const ALMACEN = 1;

class RawMaterialsStockContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      select_options: [],
      amounts: [],
      toAdd: {
        rawMaterial: {},
        cantidad: "",
      },
    };

    this.fetchRawMaterias = this.fetchRawMaterias.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.handleDDOnChange = this.handleDDOnChange.bind(this);
    this.handleIOnChange = this.handleIOnChange.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.saveAll = this.saveAll.bind(this);
    this.renderSaveAllButton = this.renderSaveAllButton.bind(this);
    this.renderTableBody = this.renderTableBody.bind(this);
  }

  renderSaveAllButton() {
    return (
      <Button
        positive
        floated="right"
        content="Cargar"
        icon="send"
        style={{ marginTop: 20 }}
        onClick={this.saveAll}
        disabled={this.state.amounts.length === 0}
      />
    );
  }

  saveAll() {
    let amounts = [...this.state.amounts];

    let amountsToSend = amounts.map((v) => {
      return {
        almacen_id: ALMACEN,
        clave_id: v.rawMaterial.value,
        cantidad: v.cantidad,
        usuario_id: this.props.user.id,
      };
    });

    Feathers.service("inventario")
      .create(amountsToSend)
      .then((res) => {
        swal("Éxito", "Se ha actualizado el inventario", "success");
        this.setState({
          amounts: [],
          toAdd: {
            rawMaterial: {},
            cantidad: "",
          },
        });
      });
  }

  deleteItem(index) {
    let amounts = [...this.state.amounts];
    amounts.splice(index, 1);

    this.setState({
      amounts,
      toAdd: {
        rawMaterial: {},
        cantidad: "",
      },
    });
  }

  handleDDOnChange(event, { value }) {
    const rawMaterial = this.state.select_options.find(
      (rm) => rm.value === value
    );
    this.setState({
      toAdd: { ...this.state.toAdd, rawMaterial },
    });
  }

  handleIOnChange(event, { value }) {
    this.setState({
      toAdd: { ...this.state.toAdd, cantidad: value },
    });
  }

  handleSubmitForm(event) {
    let amounts = [...this.state.amounts];

    if (JSON.stringify(this.state.toAdd.rawMaterial) == "{}") {
      swal({
        type: "warning",
        title: "Oops...",
        text: "No has seleccionado la materia prima",
      });

      return 0;
    }

    const find = amounts.find(
      (amount) =>
        amount.rawMaterial.value === this.state.toAdd.rawMaterial.value
    );

    if (find) {
      find.cantidad = +find.cantidad + +this.state.toAdd.rawMaterial.value;
    } else {
      amounts.push(this.state.toAdd);
    }

    this.setState({
      amounts,
      toAdd: {
        rawMaterial: {},
        cantidad: "",
      },
    });
  }

  fetchRawMaterias() {
    Feathers.service("materia_prima")
      .find({
        query: {
          doPagination: false,
        },
      })
      .then((res) => {
        this.setState({
          select_options: res.map((v) => {
            return {
              text: v.nombre + " -  " + v.quimico,
              value: v.id,
              //otro: v
            };
          }),
        });
      });
  }

  componentDidMount() {
    this.fetchRawMaterias();
  }

  renderForm() {
    return (
      <Form onSubmit={this.handleSubmitForm} size={"large"}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Materia Prima</label>
            <Dropdown
              value={this.state.toAdd.rawMaterial.value}
              placeholder="Selecciona Materia Prima"
              fluid
              search
              selection
              options={this.state.select_options}
              onChange={this.handleDDOnChange}
              required
            />
          </Form.Field>
          <Form.Field required>
            <label>Cantidad</label>
            <Input
              value={this.state.toAdd.cantidad}
              onChange={this.handleIOnChange}
              id="rm_cantidad"
              required
              min="1"
              pattern="^[0-9]*[.,]?[0-9]+$" // allow decimal numbers
            />
          </Form.Field>

          <Form.Button
            primary
            icon="plus"
            content="Agregar"
            fluid
            style={{ marginTop: 23 }}
            type="submit"
          />
        </Form.Group>
      </Form>
    );
  }

  renderTable() {
    return (
      <div>
        <h3>Materias Primas Cargadas</h3>
        <Table celled size={"large"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Materia prima</Table.HeaderCell>
              <Table.HeaderCell>Cantidad</Table.HeaderCell>
              <Table.HeaderCell>Acción</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderTableBody()}</Table.Body>
        </Table>
      </div>
    );
  }

  renderTableBody() {
    if (this.state.amounts.length > 0) {
      return this.state.amounts.map((v, i) => (
        <Table.Row key={i}>
          <Table.Cell>{v.rawMaterial.text}</Table.Cell>
          <Table.Cell>{v.cantidad}</Table.Cell>
          <Table.Cell>
            {" "}
            <Button
              negative
              fluid
              content="Eliminar"
              icon="trash"
              onClick={() => this.deleteItem(i)}
            />{" "}
          </Table.Cell>
        </Table.Row>
      ));
    } else {
      return (
        <Table.Row>
          <Table.Cell>Aún no has agregado materias primas</Table.Cell>
        </Table.Row>
      );
    }
  }

  render() {
    return (
      <div>
        <Header as="h1" icon="lab" content="Carga de Materia Prima" />
        <Divider />

        {this.renderForm()}

        {this.renderTable()}
        {this.renderSaveAllButton()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(RawMaterialsStockContainer);
