import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Grid, Icon, Form, Search, Label } from "semantic-ui-react";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import Feathers from "../../redux/FeathersRedux";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { exportToXls } from "../../utils/Utils";
import { notify } from "react-notify-toast";

const renderNameSearchResults = ({ id, nombre, apellido }) => (
  <Label key={id} content={nombre + " " + apellido} />
);
const renderEmailSearchResults = ({ id, email }) => (
  <Label key={id} content={email} />
);
const renderLoginSearchResults = ({ id, login }) => (
  <Label key={id} content={login} />
);
const renderPhoneSearchResults = ({ id, celular }) => (
  <Label key={id} content={celular} />
);

class FiredList extends Component {
  state = {
    users: [],
    total: 0,
    page: 0,
    loading: false,
    pageSize: 20,
    currentRow: {},
    $sort: {
      $nameSortDirection: null,
      $lastNameSortDirection: null,
    },

    filterOptions: {
      id: null,
      nombre: null,
      apellido: null,
      email: null,
      login: null,
      celular: null,
    },

    // Search bar results

    nameResults: null,
    emailResults: null,
    loginResults: null,
    celularResults: null,

    // Search Bar Objects

    name: null,
    email: null,
    login: null,
    celular: null,

    // Search bar loadings

    nameLoading: false,
    emailLoading: false,
    loginLoading: false,
    celularLoading: false,
    columna: "",
    sortDirection: false,
    shouldExport: false,
  };

  handleSort = (array) => {
    let column = this.state.columna;
    switch (column.Header) {
      case "Nombre":
        array.data.sort((a, b) => {
          const nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          const nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Apellido":
        array.data.sort((a, b) => {
          const nameA = a.apellido.toUpperCase(); // ignore upper and lowercase
          const nameB = b.apellido.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Email":
        array.data.sort((a, b) => {
          let nameA = a.email ? a.email.toUpperCase() : ""; // ignore upper and lowercase
          let nameB = b.email ? b.email.toUpperCase() : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Login":
        array.data.sort((a, b) => {
          let nameA = a.login ? a.login.toUpperCase() : ""; // ignore upper and lowercase
          let nameB = b.login ? b.login.toUpperCase() : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Celular":
        array.data.sort((a, b) => {
          let nameA = a.celular ? a.celular.toUpperCase() : ""; // ignore upper and lowercase
          let nameB = b.celular ? b.celular.toUpperCase() : ""; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    if (this.state.sortDirection) {
      array.data.reverse();
    }
    return array;
  };

  handleNameResultSelect = async (e, { result }) => {
    this.setState({ name: result.nombre + " " + result.apellido });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        nombre: result.nombre,
        apellido: result.apellido,
      },
    });

    this.fetchData(this.state);
  };

  handleEmailResultSelect = async (e, { result }) => {
    this.setState({ email: result.email });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        email: result.email,
      },
    });

    this.fetchData(this.state);
  };

  handleLoginResultSelect = async (e, { result }) => {
    this.setState({ login: result.login });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        login: result.login,
      },
    });

    this.fetchData(this.state);
  };

  handlePhoneResultSelect = async (e, { result }) => {
    this.setState({ celular: result.celular });

    await this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        celular: result.celular,
      },
    });

    this.fetchData(this.state);
  };

  handleNameSearchChange = (e, { value }) => {
    this.setState({ nameLoading: true, name: value });

    setTimeout(() => {
      if (this.state.name.length < 1) return this.resetNameComponent();

      Feathers.service("usuario")
        .find({
          query: {
            $or: [
              {
                nombre: { $like: `%${value}%` },
              },
              {
                apellido: { $like: `%${value}%` },
              },
            ],
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            nameLoading: false,
            nameResults: res.data,
          });
        });
    }, 500);
  };

  handleEmailSearchChange = (e, { value }) => {
    this.setState({ emailLoading: true, email: value });

    setTimeout(() => {
      if (this.state.email.length < 1) return this.resetEmailComponent();

      Feathers.service("usuario")
        .find({
          query: {
            email: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            emailLoading: false,
            emailResults: res.data,
          });
        });
    }, 500);
  };

  handleLoginSearchChange = (e, { value }) => {
    this.setState({ loginLoading: true, login: value });

    setTimeout(() => {
      if (this.state.login.length < 1) return this.resetLoginComponent();

      Feathers.service("usuario")
        .find({
          query: {
            login: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            loginLoading: false,
            loginResults: res.data,
          });
        });
    }, 500);
  };

  handlePhoneSearchChange = (e, { value }) => {
    this.setState({ celularLoading: true, celular: value });

    setTimeout(() => {
      if (this.state.celular.length < 1) return this.resetCelularComponent();

      Feathers.service("usuario")
        .find({
          query: {
            celular: { $like: `%${value}%` },
            activo: 1,
          },
        })
        .then((res) => {
          this.setState({
            celularLoading: false,
            celularResults: res.data,
          });
        });
    }, 500);
  };

  resetNameComponent = () =>
    this.setState({ nameLoading: false, nameResults: [], name: "" });

  resetEmailComponent = () =>
    this.setState({ emailLoading: false, emailResults: [], email: "" });

  resetLoginComponent = () =>
    this.setState({ loginLoading: false, loginResults: [], login: "" });

  resetCelularComponent = () =>
    this.setState({ celularLoading: false, celularResults: [], celular: "" });

  fetchData = (state = {}) => {
    console.log("fetch", this.props);
    let pageSize = state.pageSize || 20;

    let query = {
      $limit: this.state.shouldExport ? "-1" : pageSize,
      $skip: pageSize * state.page,
      activo: 0,
    };

    if (this.state.$sort.$nameSortDirection !== null) {
      query["$sort"] = {
        nombre: this.state.$sort.$nameSortDirection,
      };
    }

    if (this.state.$sort.$lastNameSortDirection !== null) {
      query["$sort"] = {
        apellido: this.state.$sort.$lastNameSortDirection,
      };
    }

    if (this.state.filterOptions.nombre) {
      query["nombre"] = this.state.filterOptions.nombre;
      query["apellido"] = this.state.filterOptions.apellido;
    }

    if (this.state.filterOptions.email) {
      query["email"] = this.state.filterOptions.email;
    }

    if (this.state.filterOptions.login) {
      query["login"] = this.state.filterOptions.login;
    }

    if (this.state.filterOptions.celular) {
      query["celular"] = this.state.filterOptions.celular;
    }

    this.setState({ loading: true }, () => {
      Feathers.service("usuario")
        .find({
          query,
        })
        .then((res) => {
          console.log(res);
          if (!this.state.shouldExport) {
            res = this.handleSort(res);
            this.setState({
              users: res.data,
              total: res.total,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 20,
            });
          }

          if (this.state.shouldExport) {
            exportToXls(res, "admin_users");
            this.setState({
              shouldExport: false,
              loading: false,
              page: state.page,
              pageSize: state.pageSize || 20,
            });
          }
        });
    });
  };

  openEdit = (row) => {
    const userid = row.original.id;
    this.props.dispatch(push("/users/" + userid));
  };

  handleCleanButton = (_) => {
    this.resetNameComponent();
    this.resetEmailComponent();
    this.resetLoginComponent();
    this.resetCelularComponent();
    this.setState(
      {
        filterOptions: {
          ...this.state.filterOptions,
          id: null,
          nombre: null,
          apellido: null,
          email: null,
          login: null,
          celular: null,
        },
      },
      (_) => this.fetchData(this.state)
    );
  };

  handleChange(event, row) {
    const checked = event.target.checked;
    this.setState({ loading: true }, () => {
      Feathers.service("usuario").patch(row.original.id, {
        activo: checked ? 1 : 0,
      });
    });
    setTimeout(() => {
      this.fetchData(this.state);
      this.props.onFetchFired && this.props.onFetchFired();
      notify.show("Cambio guardado.", "success", 2000);
    }, 1000);
  }

  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>Nombre</label>
                  <Search
                    icon=""
                    loading={this.state.nameLoading}
                    value={this.state.name}
                    results={this.state.nameResults}
                    onResultSelect={this.handleNameResultSelect}
                    onSearchChange={this.handleNameSearchChange}
                    resultRenderer={renderNameSearchResults}
                    placeholder="Nombre"
                    className="full-width"
                  />
                </Form.Field>

                <Form.Field>
                  <label>Email</label>
                  <Search
                    icon=""
                    loading={this.state.emailLoading}
                    value={this.state.email}
                    results={this.state.emailResults}
                    onResultSelect={this.handleEmailResultSelect}
                    onSearchChange={this.handleEmailSearchChange}
                    resultRenderer={renderEmailSearchResults}
                    placeholder="Email"
                    className="full-width"
                  />
                </Form.Field>

                <Form.Field>
                  <label>Usuario</label>
                  <Search
                    icon=""
                    loading={this.state.loginLoading}
                    value={this.state.login}
                    results={this.state.loginResults}
                    onResultSelect={this.handleLoginResultSelect}
                    onSearchChange={this.handleLoginSearchChange}
                    resultRenderer={renderLoginSearchResults}
                    placeholder="Usuario"
                    className="full-width"
                  />
                </Form.Field>

                <Form.Field>
                  <label>Celular</label>
                  <Search
                    icon=""
                    loading={this.state.celularLoading}
                    value={this.state.celular}
                    results={this.state.celularResults}
                    onResultSelect={this.handlePhoneResultSelect}
                    onSearchChange={this.handlePhoneSearchChange}
                    resultRenderer={renderPhoneSearchResults}
                    placeholder="Celular"
                    className="full-width"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Limpiar</label>
                  <Button
                    basic
                    color="red"
                    circular
                    icon="x"
                    onClick={this.handleCleanButton}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Exportar</label>
                  <Button
                    basic
                    color="green"
                    circular
                    icon="file excel outline"
                    onClick={() => {
                      this.setState({ shouldExport: true }, () => {
                        this.fetchData(this.state);
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Actualizar</label>
                  <Button
                    basic
                    color="blue"
                    circular
                    icon="refresh"
                    onClick={() => {
                      this.fetchData(this.state);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <ReactTable
          columns={[
            { Header: "Nombre", accessor: "nombre" },
            { Header: "Apellido", accessor: "apellido" },
            { Header: "Email", accessor: "email" },
            { Header: "Login", accessor: "login" },
            { Header: "Celular", accessor: "celular" },
            {
              Header: "Acciones",
              sortable: false,
              Cell: (row) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Toggle
                      defaultChecked={!!row.original.activo}
                      icons={false}
                      onChange={(event) => this.handleChange(event, row)}
                    />
                  </div>
                );
              },
            },
          ]}
          manual
          data={this.state.users}
          loading={this.state.loading}
          pages={Math.ceil(this.state.total / this.state.pageSize)}
          onFetchData={this.fetchData}
          defaultPageSize={this.state.pageSize}
          style={{ textAlign: "center" }}
          onSortedChange={async (newSorted, column, shiftKey) => {
            let direction;
            if (column.Header == this.state.columna.Header) {
              direction = this.state.sortDirection ? false : true;
            } else {
              direction = false;
            }

            this.setState(
              {
                columna: column,
                sortDirection: direction,
              },
              () => {
                console.log(this.state);
              }
            );
            this.fetchData();
          }}
        />
      </div>
    );
  }
}

export default connect(null, null, null, {
  withRef: true,
})(FiredList);
