import React, { Component } from "react";
import { Form, Search } from "semantic-ui-react";
import { formatDate, parseDate } from "react-day-picker/moment";
import DayPickerInput from "react-day-picker/DayPickerInput";

export default class TasksFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.filters.map((filter) => {
          switch (filter) {
            case "costumer":
              return (
                <Form.Field key={filter}>
                  <label>Cliente</label>
                  <Search
                    icon=""
                    loading={this.props.clienteLoading}
                    value={this.props.costumer}
                    results={this.props.costumers}
                    onResultSelect={this.props.onSelectCostumer}
                    onSearchChange={this.props.onSearchCostumerChange}
                    resultRenderer={this.props.costumerResultRenderer}
                    placeholder="Cliente"
                    className="full-width"
                  />
                </Form.Field>
              );
            case "rodillo":
              return (
                <Form.Field key={filter}>
                  <label>Rodillo</label>
                  <Form.Input
                    type="number"
                    value={this.props.rodilloValue}
                    onChange={(e, { _, value }) => {
                      this.props.onChangeRodillo(value);
                    }}
                    style={{ width: "130px" }}
                  />
                </Form.Field>
              );
            case "pedido":
              return (
                <Form.Field key={filter}>
                  <label>OT</label>
                  <Form.Input
                    type="number"
                    value={this.props.pedidoValue}
                    onChange={(e, { _, value }) => {
                      this.props.onPedidoChange(value);
                    }}
                    style={{ width: "130px" }}
                  />
                </Form.Field>
              );
            case "partida":
              return (
                <Form.Field key={filter}>
                  <label>Partida</label>
                  <Form.Input
                    type="number"
                    value={this.props.partidaValue}
                    onChange={(e, { _, value }) => {
                      this.props.onChangePartida(value);
                    }}
                    style={{ width: "130px" }}
                  />
                </Form.Field>
              );
            case "status":
              return (
                <Form.Field key={filter}>
                  <label>Estado</label>
                  <Form.Dropdown
                    selection
                    options={this.props.statusFilterOptions}
                    onChange={(e, { value }) => {
                      this.props.onStatusChange(value);
                    }}
                    value={this.props.statusValue}
                  />
                </Form.Field>
              );
            case "product":
              return (
                <Form.Field key={filter}>
                  <label>Producto</label>
                  <Form.Dropdown
                    selection
                    options={this.props.products}
                    onChange={(e, { value }) => {
                      this.props.onProductChange(value);
                    }}
                    value={this.props.productValue}
                  />
                </Form.Field>
              );
            case "stage":
              return (
                <Form.Field key={filter}>
                  <label>Etapa</label>
                  <Form.Dropdown
                    selection
                    options={this.props.stages}
                    onChange={(e, { value }) => {
                      this.props.onStageChange(value);
                    }}
                    value={this.props.stage}
                  />
                </Form.Field>
              );
            case "material":
              return (
                <Form.Field key={filter}>
                  <label>Material</label>
                  <Search
                    icon=""
                    loading={this.props.materialLoading}
                    value={this.props.material}
                    results={this.props.materials}
                    onResultSelect={this.props.handleMaterialResultSelect}
                    onSearchChange={this.props.handleMaterialSearchChange}
                    resultRenderer={this.props.renderMaterialSearchResults}
                    placeholder="Material"
                    className="full-width"
                  />
                </Form.Field>
              );
            case "entrega":
              return (
                <Form.Field key={filter}>
                  <label>Fecha Entrega</label>
                  <DayPickerInput
                    value={this.props.fechaEntrega}
                    format="YYYY-MM-DD"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    onDayChange={(date) => {
                      this.props.onDateChange(date);
                    }}
                  />
                </Form.Field>
              );
            default:
              break;
          }
        })}
      </>
    );
  }
}
