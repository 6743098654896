import React, { Component } from "react";
import TableBody, {
  Header,
  Segment,
  Grid,
  Icon,
  Form,
  Button,
  Table,
  Modal,
  Divider,
} from "semantic-ui-react";
import Feathers from "../../redux/FeathersRedux";
import swal from "sweetalert2";
import moment from "moment";
import VulcanizationTask from "../../components/Tasks/Vulcanization/VulcanizationTask";
import VulcanizationTemperature from "../../components/Tasks/Vulcanization/VulcanizationTemperature";
import { connect } from "react-redux";
import LoaderData from "../LoaderData";

class VulcanizeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vulcanizers: [],
      vulcanizadora_id: null,
      task: null,
      vulcanizations: [],
      readyTemperature: false,
    };

    this.getVulcanizers = this.getVulcanizers.bind(this);
    this.checkActiveTasks = this.checkActiveTasks.bind(this);
    this.createTask = this.createTask.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getVulcanizations = this.getVulcanizations.bind(this);
    this.renderVulcanizations = this.renderVulcanizations.bind(this);
    this.checkSpecificTask = this.checkSpecificTask.bind(this);
  }

  componentDidMount() {
    this.checkActiveTasks();
    this.getVulcanizers();
    this.getVulcanizations();
  }

  updateTask() {
    Feathers.service("vulcanizado")
      .get(this.state.task.id)
      .then((task) => this.setState({ task }));
  }

  goBack() {
    this.getVulcanizations();
    this.setState({ task: null });
  }

  checkActiveTasks() {
    Feathers.service("vulcanizado")
      .find({
        query: {
          usuario_id: this.props.user.id,
          status_tarea_id: 1,
        },
      })
      .then((res) => {
        if (res.total > 0) {
          this.setState({
            task: res.data[0],
            ready: true,
          });
        }
      });
  }

  async checkSpecificTask(tareaid) {
    console.log("tarea id");
    console.log(tareaid);

    let tasks = await Feathers.service("vulcanizado").find({
      query: {
        // usuario_id: this.prop s.user.id,
        status_tarea_id: 1,
        id: tareaid,
      },
    });

    if (tasks.total > 0) {
      this.setState({
        task: tasks.data[0],
        ready: true,
      });
    }
  }

  checkTemperature = async (taskId) => {
    const currentTask = await Feathers.service("vulcanizado").find({
      query: {
        status_tarea_id: 1,
        id: taskId,
      },
    });

    console.log(currentTask);
    if (currentTask.data.length > 0) {
      this.setState({
        task: currentTask,
        readyTemperature: true,
      });
    }
  };

  finishTask() {
    swal({
      title: "¿Finalizar tarea de vulcanizado?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (!result.value) return;

      Feathers.service("vulcanizado")
        .patch(this.state.task.id, {
          hora_fin: moment().format("YYYY-MM-DD HH:mm:ss"),
          status_tarea_id: 2,
          stopTemperature: true,
        })
        .then(async (res) => {
          const checkNotification = await Feathers.service(
            "notification_tasks"
          ).get(7);
          if (checkNotification.activo) {
            Feathers.service("notificacion").create({
              mensaje: `Se ha finalizado una tarea de vulcanizado #${this.state.task.id}`,
              perfil_id: "1",
              fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
              tipo: 4,
            });
          }
          this.getVulcanizers();
          this.getVulcanizations();
          this.setState({ task: null });
        });
    });
  }

  getVulcanizers() {
    Feathers.service("vulcanizadora")
      .find({
        query: {
          filtered: "true",
        },
      })
      .then((vulcanizers) => {
        this.setState({
          ready: true,
          vulcanizers: vulcanizers.map((v) => {
            return {
              text: v.nombre,
              value: v.id,
            };
          }),
        });
      });
  }

  getVulcanizations() {
    Feathers.service("vulcanizado")
      .find({
        query: {
          status_tarea_id: 1,
        },
      })
      .then((vulcanization) => {
        this.setState({
          vulcanizations: vulcanization.data,
        });
      });
  }

  renderVulcanizations() {
    let vulcanizaciones = this.state.vulcanizations;
    if (vulcanizaciones.length == 0) {
      return null;
    }
    return (
      <div>
        <Divider />
        <Header as="h3">Vulcanizado en Proceso</Header>

        <Table striped compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Vulcanizadora</Table.HeaderCell>
              <Table.HeaderCell>Hora Inicio de Tarea</Table.HeaderCell>
              <Table.HeaderCell>Hora Inicio de Vulcanizado</Table.HeaderCell>
              <Table.HeaderCell>Ir</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {vulcanizaciones.map((v, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{v.vulcanizadora.nombre}</Table.Cell>
                  <Table.Cell>{v.hora_inicio}</Table.Cell>
                  <Table.Cell>{v.hora_inicio_vulcanizado}</Table.Cell>
                  <Table.Cell>
                    <Button
                      basic
                      size="mini"
                      icon="play"
                      color="blue"
                      onClick={() => this.checkSpecificTask(v.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }

  nextData = async (vulcanizadora_id = 1) => {
    /**
     *
     * el limit 15 hace que sólo me traiga de 15 en 15 elementos, y como hay mas de 15 y el page no funciona bien
     * entonces me está trayendo nomás 15
     *
     */

    let res = await Feathers.service("pendiente_vulcanizado").find({
      query: {
        $limit: "-1",
        // $skip: 15 * this.state.page,
        $sort: {
          rodillo_id: 1,
        },
      },
    });

    // return
    let respuesta = JSON.parse(JSON.stringify(res));

    // si es horno puliuretano, que es la vulcanizadora 3, sólo se podrán elegir poliuretanos para vulcanizar
    if (vulcanizadora_id === 3) {
      respuesta = respuesta.filter((v) => {
        if (
          v.rodillo.partida.producto_id === 3 ||
          v.rodillo.partida.producto_id === 4
        )
          return v;
      });
    } else {
      respuesta = respuesta.filter((v) => {
        if (
          v.rodillo.partida.producto_id !== 3 &&
          v.rodillo.partida.producto_id !== 4
        )
          return v;
      });
    }

    return respuesta;
  };

  async createTask() {
    /*
        tenemos que checar si hay rodillos; sino, no hay porque iniciar la tarea
        */

    swal({
      title: "¿Crear tarea de vulcanizado?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (!result.value) return;

      // primero vamos a checar si hay rodillos

      let pendings = await this.nextData(this.state.vulcanizadora_id);

      if (pendings.length === 0)
        return swal({
          type: "error",
          title: "Oops...",
          text: "No hay nada que pasar a vulcanizar",
        });

      // después la creamos

      let task = await Feathers.service("vulcanizado").create({
        usuario_id: this.props.user.id,
        hora_inicio: moment().format("YYYY-MM-DD HH:mm:ss"),
        vulcanizadora_id: this.state.vulcanizadora_id,
        status_tarea_id: 1,
      });

      this.setState({ task });
      this.getVulcanizations();
      this.getVulcanizers();
    });
  }

  renderForm = () => {
    if (this.state.vulcanizers.length > 0) {
      return (
        <div>
          <Header as="h2" icon="building" content="Vulcanizado" />

          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width="12" computer="12" tablet="8">
                  <Form.Dropdown
                    label="Vulcanizadoras disponibles"
                    fluid
                    selection
                    options={this.state.vulcanizers}
                    onChange={(e, { name, value }) => {
                      this.setState({ vulcanizadora_id: value });
                    }}
                  ></Form.Dropdown>
                </Grid.Column>
                <Grid.Column width="4" computer="4" tablet="8">
                  <Button
                    onClick={this.createTask}
                    style={{ marginTop: "25px" }}
                    fluid
                    positive
                    disabled={!this.state.vulcanizadora_id}
                  >
                    CREAR TAREA
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          {this.renderVulcanizations()}
        </div>
      );
    }

    return (
      <div>
        <Header as="h2">Vulcanizado</Header>
        Las vulcanizadoras estan ocupadas
        {this.renderVulcanizations()}
      </div>
    );
  };

  render() {
    if (this.state.ready) {
      if (this.state.task) {
        return (
          <div>
            <Header as="h2">
              <Button
                onClick={this.goBack}
                icon="arrow left"
                style={{ backgroundColor: "transparent", color: "#000000de" }}
              />
              Vulcanizado
            </Header>

            <VulcanizationTask
              task={this.state.task}
              updateTask={this.updateTask}
              finishTask={this.finishTask}
            />
          </div>
        );
      }

      return this.renderForm();
    }

    return <LoaderData />;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(VulcanizeContainer);
