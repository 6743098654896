import React, {Component} from 'react';
import { Modal, Image, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types';

class ImageModal extends Component {
  render() {
    return (
      <Modal dimmer='blurring' open={this.props.open}>
        <Modal.Header style={{backgroundColor: "#f3f3f3"}} >Imagen
        <Button circular size='mini' basic icon='close' floated='right' onClick={this.props.onClose}></Button>
        </Modal.Header>
        <Modal.Content>
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Image src={this.props.image} />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

ImageModal.propTypes = {};

export default ImageModal;
