import React, {Component} from 'react';
import { Modal, Form, Grid, Button } from 'semantic-ui-react'
import FormInput from './FormInput'
import Notifications, {notify} from 'react-notify-toast';
import Feathers from '../../redux/FeathersRedux'
import { throws } from 'assert';
import swal from 'sweetalert2'

const componentForm = {
    street_number: {googleName: 'short_name', stateName: 'n_exterior'},
    route: {googleName: 'long_name', stateName: 'direccion'},
    locality: {googleName: 'long_name', stateName: 'ciudad'},
    sublocality_level_1: {googleName: 'long_name', stateName: 'colonia'},
    administrative_area_level_1: {googleName: 'short_name', stateName: 'estado'},
    country: {googleName: 'long_name', stateName: 'pais'},
    postal_code: {googleName: 'short_name', stateName: 'cp'}
};

const REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class ClientsEditModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cliente: {
                nombre: '',
                email: '',
                telefono: '',
                rfc: '',
                direccion: '',
                n_interior: '',
                n_exterior: '',
                colonia: '',
                ciudad: '',
                cp: '',
                estado: '',
                pais: '',
                lat: null,
                lng: null,
                moneda_id: null,
            },
            moneda_options: []
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async handleSubmit() {

        console.log("cp 1")

        if (!this.state.cliente.nombre) {
            console.log("cp 2")

            notify.show('El cliente debe llevar un nombre', 'error', 2000);
            swal({
                type: 'error',
                title: 'Oops...',
                text: 'El cliente debe llevar un nombre',
            })
            return;
        }

        if (!this.state.cliente.email) {
            console.log("cp 3")

            notify.show('El cliente debe llevar un email', 'error', 2000);
            swal({
                type: 'error',
                title: 'Oops...',
                text: 'El cliente debe llevar un email',
            })
            return;
        }

        /* if (!REGEX.test(this.state.cliente.email)) {
            console.log("cp 4")

            notify.show('Ingresa un email válido', 'error', 2000);
            swal({
                type: 'error',
                title: 'Oops...',
                text: 'Ingresa un email válido',
            })
            return;
        } */

        await this.setState({
            loading : true
        });

        Feathers.service('cliente').patch(this.state.cliente.id, {
            nombre: this.state.cliente.nombre,
            email: this.state.cliente.email,
            telefono: this.state.cliente.telefono,
            rfc: this.state.cliente.rfc,
            direccion: this.state.cliente.direccion,
            n_interior: this.state.cliente.n_interior,
            n_exterior: this.state.cliente.n_exterior,
            colonia: this.state.cliente.colonia,
            ciudad: this.state.cliente.ciudad,
            cp: this.state.cliente.cp,
            estado: this.state.cliente.estado,
            pais: this.state.cliente.pais,
            lat: this.state.cliente.lat,
            lng: this.state.cliente.lng,
            moneda_id: this.state.cliente.moneda_id,
        }).then(res => {

            this.props.callback && this.props.callback();

            //notify.show('Cliente actualizado', 'success');
            swal({
                type: "success",
                title: "Ciente actualizado",
                text: "¡El cliente se actualizó correctamente!",
              });
            this.setState({ loading: false })
        }).catch(err => {
            //notify.show('Se produjo un error', 'error');
            swal({
                type: "error",
                title: "¡Ha ocurrido un eror!",
                text: "Intente de nuevo",
            });
            this.setState({ loading: false })
        })
       
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (this.state.cliente.id !== nextProps.client.id) {
            this.setState({
                cliente: {...this.state.cliente, ...nextProps.client}
            })

            this.fetchMonedaOptions()
        }
    }

    fetchMonedaOptions = async _ => {
        let options = await Feathers.service('moneda').find({
            query: {
                $limit: '-1'
            }
        })

        let moneda_options = options.map(v => {
            return {
                key: v.id,
                value: v.id,
                text: v.nombre + " / " + v.cod
            }
        })


        this.setState({
            moneda_options
        })
    }

    fillInAddress = (place) => {
        // let place = this.autocomplete.getPlace();

        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];

            if (componentForm[addressType]) {
                let val = place.address_components[i][componentForm[addressType].googleName];

                this.setState({
                    cliente: {...this.state.cliente, [componentForm[addressType].stateName]: val}
                })
            }
        }

        this.setState({
            cliente: {
                ...this.state.cliente, 
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),        
            }
        })
    }

    render() {
        return (
            <Modal open={this.props.isOpened} size={'large'} >
                <Modal.Header style={{backgroundColor: "#f3f3f3"}}>{this.props.client.nombre}
                <Button circular size='mini' basic icon='close' floated='right' onClick={this.props.onClose}></Button>
                </Modal.Header>
                <Modal.Content>
                    <Form size={'large'} >
                        <Grid>
                            <Grid.Row columns={2} divided>
                                <Grid.Column>
                                    <Grid.Row>
                                        <Form.Input label='Nombre/Razón social' name='nombre' value={this.state.cliente.nombre} type='text' placeholder='Nombre/Razón social' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} required/>
                                        <Form.TextArea label='Email(s)' name='email' value={this.state.cliente.email} type='text' placeholder='Email' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} required/>
                                        <Form.Input label='Teléfono' name='telefono' value={this.state.cliente.telefono} type='text' placeholder='Teléfono' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                        <Form.Input label='RFC' name='rfc' value={this.state.cliente.rfc} type='text' placeholder='RFC' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                        <Form.Dropdown label='Moneda' value={this.state.cliente.moneda_id} options={this.state.moneda_options} selection placeholder={"Moneda"} onChange={(_, d) => {
                                            this.setState({
                                                cliente: {
                                                    ...this.state.cliente,
                                                    moneda_id: d.value
                                                }
                                            })
                                        }} />
                                    </Grid.Row>
                                </Grid.Column>
                                <Grid.Column>
                                    <Grid.Row>
                                        <FormInput placeChanged={this.fillInAddress} id='autocomplete2' label='Dirección' name='direccion' type='text' placeholder='Dirección' value={this.state.cliente.direccion} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                        <Form.Group>
                                            <Form.Input label='No. interior' value={this.state.cliente.n_interior} name='n_interior' placeholder='No. interior' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                            <Form.Input label='No. exterior' value={this.state.cliente.n_exterior} name='n_exterior' placeholder='No. exterior' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                        </Form.Group>
                                        <Form.Input label='Colonia' value={this.state.cliente.colonia} name='colonia' type='text' placeholder='Colonia' onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                        <Form.Group>
                                            <Form.Input label='Ciudad' value={this.state.cliente.ciudad} name='ciudad' placeholder='Ciudad' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                            <Form.Input label='C.P.' value={this.state.cliente.cp} name='cp' placeholder='C.P.' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Input label='Estado' value={this.state.cliente.estado} name='estado' placeholder='Estado' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })}/>
                                            <Form.Input label='País' value={this.state.cliente.pais} name='pais' placeholder='País' width={8} onChange={(e, {name, value}) => this.setState({ cliente: {...this.state.cliente, [name] : value} })} />
                                        </Form.Group>
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column floated='right' width={5}>
                                    <Button disabled={this.state.loading} loading={this.state.loading} type="button" onClick={this.handleSubmit} floated='right' positive content='ACTUALIZAR' icon='send' labelPosition='left' />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        )
    }
}